import React from "react";
import {NumericFormat} from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import {styled} from "@mui/material/styles";
import {getLabelWidth} from "../utilities/getLabelWidth"; // Used for state selection DDLB in Address Component
import * as font from "../utilities/fontUtilities";
import * as ddo from "../constants/customObjConstants";
import * as tag from "../constants/customMetadataConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"
import {saveAnswerTypeSingleSelect, singleSelectDropDown} from "./SingleSelect";
import {customColorObject, DEFAULT_DATE_ENTRY_WIDTH} from "../shared/metadataConstantsAndUtilities";


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
//<editor-fold desc="[CODE SECTION] Text Question Save Answer Routines ----------------------------------------------------------------------">

const LABEL_ABOVE = "above";
const LABEL_BELOW = "below";


function saveAnswerTypeText(callerRefStr, savedObjs, event) {
    const {
        question,
        surveyEditMode,
        displayMode
    } = savedObjs.props;
    let enteredValue
    if (callerRefStr.includes("date")) {
        if (isNaN(event)) return
        enteredValue = event.toISOString()
    } else enteredValue = event.target.value;

    let answer = savedObjs.question.answerText;

    // -----------------------------------------------------------------------------------------------------------------
    // Special handeling for From/To date stored as a comma delimited From/To
    if ((callerRefStr === "dateRangeEntryFrom-date-range")||(callerRefStr === "dateRangeEntryTo-date-range")) {
        answer = (answer) ? answer.split(",") : ["", ""];
        enteredValue = (callerRefStr === "dateRangeEntryFrom-date-range") ? enteredValue + "," + answer[1] : answer[0]  + "," + enteredValue;
    }
    // -----------------------------------------------------------------------------------------------------------------
    // Special Handeling for Address (Compplex QUestion Type)
    let addressAnswer = savedObjs.question.addressAnswer !== undefined ? {...savedObjs.question.addressAnswer} : {address: "", extended: "", city: "", zipPostalCode: ""};
    let addressAnswerSave = {...addressAnswer};
    if (callerRefStr !== "") {
        if (callerRefStr.substring(0, 8) === "address-") {

            addressAnswer[callerRefStr.substring(8)] = enteredValue;
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    if (surveyEditMode === portalShared.EDITABLE_SURVEY) {

        if (callerRefStr.substring(0, 8) === "address-") {
            if ((addressAnswer.address !== addressAnswerSave.address) ||
                (addressAnswer.extended !== addressAnswerSave.extended) ||
                (addressAnswer.city !== addressAnswerSave.city) ||
                (addressAnswer.zipPostalCode !== addressAnswerSave.zipPostalCode))
            {
                if (displayMode === portalShared.INSTANT_MODE) {
                    savedObjs.props.answerQuestionText(question, {addressAnswer: addressAnswer});
                }
                else {
                    savedObjs.props.answerQuestionTemplateText(question, {addressAnswer: addressAnswer});
                }
            }
        }
        else {

            if (question.answerText === enteredValue) {
                return; //If the answer didn't change, don't update it
            }

            // See if template or instance
            if (displayMode === portalShared.INSTANT_MODE) {
                savedObjs.props.answerQuestionText(question, {answerText: enteredValue});
            } else {
                savedObjs.props.answerQuestionTemplateText(question, {answerText: enteredValue});
            }
        }

    }
}


//</editor-fold>
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
//<editor-fold desc="[CODE SECTION] Text Entry Fields (QUESTION) -------------------------------------------------------------------------">


export function textEntryQuestion(textEntryType, inputType, itemObj, props) {
    //const self = this;
    const {questionnaire, question, questionNumber, surveyEditMode, displayMode, answerQuestionOptions, answerQuestionTemplateOptions} = this.props;

    let textObj = ddo.getQuestionNameText(question, questionNumber);

    // Used for state DDLB in address  ---------------------------------------------------------------------------------
    let answers = [];
    let selectedAnswer = null;

    if (inputType === "address") {
        answers = ddo.getAnswerOptions(question);
        selectedAnswer = answers.find((answerOption) => {
            return answerOption.selected === true;
        });

        if (!selectedAnswer) {
            selectedAnswer = {
                id: ""
            };
        }
    }
    // -----------------------------------------------------------------------------------------------------------------

    let savedObjs = {
        itemObj: itemObj,
        inputType: inputType,
        textEntryType: textEntryType,
        inputModifier: "",
        questionnaire: questionnaire,
        question: question,
        questionNameHidden: (tag.metadataValue(question, portalShared.CUSTOM_DDO_NAME) === "hide"),
        questionNumber: questionNumber === "0" ? "" : questionNumber,
        questionTextObj: textObj,
        answerText: "",
        answers: answers, // Used for states DDLB in address
        answerQuestionOptions: answerQuestionOptions,
        answerQuestionTemplateOptions: answerQuestionTemplateOptions,
        selectedAnswer: selectedAnswer, // Used for states DDLB in address
        displayMode: displayMode,
        surveyEditMode: surveyEditMode,
        readonlyMode: (surveyEditMode === portalShared.READONLY_SURVEY),
        //indent: tag.metadataValue(activeTemplate, portalShared.INDENT_LIKERT_QUESTION_NAME, "", "0px"),
        props: this.props
    }

    if (tag.metadataExistsObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT)) {
        savedObjs.customTemplateObj = tag.metadataValueObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT);

        savedObjs.customTemplateObj.minRows = parseInt(savedObjs.customTemplateObj.minRows); // Update min rows from string to numeric

        if (savedObjs.customTemplateObj.version < 2) {
            savedObjs.customTemplateObj.textStyle = "default";
            savedObjs.customTemplateObj.indent = "0px";
            savedObjs.customTemplateObj.label2 = "";

            savedObjs.customTemplateObj.minRows = parseInt(savedObjs.customTemplateObj.minRows);

            savedObjs.customTemplateObj.text.fontSize = savedObjs.customTemplateObj.text.fontSize ?? "12pt";
            savedObjs.customTemplateObj.text.fontSizeEntry = savedObjs.customTemplateObj.text.fontSizeEntry ?? "12";
            savedObjs.customTemplateObj.text.variant = savedObjs.customTemplateObj.text.variant ?? "caption";

            portalShared.setColor(savedObjs.customTemplateObj.unselectedFloatingLabel, portalShared.COLOR_GRAY_INDEX, portalShared.COLOR_GRAY_HEX);
            portalShared.setColor(savedObjs.customTemplateObj.selectedFloatingLabel, portalShared.COLOR_DODGER_BLUE_INDEX, portalShared.COLOR_DODGER_BLUE_HEX);
            portalShared.setColor(savedObjs.customTemplateObj.hoverFloatingLabel, portalShared.COLOR_DODGER_BLUE_INDEX, portalShared.COLOR_DODGER_BLUE_HEX);

            portalShared.setColor(savedObjs.customTemplateObj.placeHolder, portalShared.COLOR_GRAY_INDEX, portalShared.COLOR_GRAY_HEX);

            portalShared.setColor(savedObjs.customTemplateObj.text.colorObj, portalShared.COLOR_GRAY_INDEX, portalShared.COLOR_GRAY_HEX);

            savedObjs.customTemplateObj.unselectedText = {...portalShared.customColorObject};
            savedObjs.customTemplateObj.selectedText = {...portalShared.customColorObject};
            savedObjs.customTemplateObj.hoverText = {...portalShared.customColorObject};

            savedObjs.customTemplateObj.unselectedBorder = {...portalShared.customColorObject};
            savedObjs.customTemplateObj.selectedBorder = {...portalShared.customColorObject};
            savedObjs.customTemplateObj.hoverBorder = {...portalShared.customColorObject};

            portalShared.setColor(savedObjs.customTemplateObj.unselectedBorder, portalShared.COLOR_GRAY_INDEX, portalShared.COLOR_GRAY_HEX);
            portalShared.setColor(savedObjs.customTemplateObj.selectedBorder, portalShared.COLOR_DODGER_BLUE_INDEX, portalShared.COLOR_DODGER_BLUE_HEX);
            portalShared.setColor(savedObjs.customTemplateObj.hoverBorder, portalShared.COLOR_DODGER_BLUE_INDEX, portalShared.COLOR_DODGER_BLUE_HEX);

            savedObjs.customTemplateObj.hoverBorderWidth = "2px";

            portalShared.setColor(savedObjs.customTemplateObj.unselectedText, portalShared.COLOR_DODGER_BLUE_INDEX, portalShared.COLOR_DODGER_BLUE_HEX);
            portalShared.setColor(savedObjs.customTemplateObj.selectedText, portalShared.COLOR_DODGER_BLUE_INDEX, portalShared.COLOR_DODGER_BLUE_HEX);
            portalShared.setColor(savedObjs.customTemplateObj.hoverText, portalShared.COLOR_DODGER_BLUE_INDEX, portalShared.COLOR_DODGER_BLUE_HEX);

            portalShared.setColor(savedObjs.customTemplateObj.unselectedBackground, portalShared.COLOR_WHITE_INDEX, portalShared.COLOR_WHITE_HEX);
            portalShared.setColor(savedObjs.customTemplateObj.selectedBackground, portalShared.COLOR_WHITE_INDEX, portalShared.COLOR_WHITE_HEX);
            portalShared.setColor(savedObjs.customTemplateObj.hoverBackground, portalShared.COLOR_WHITE_INDEX, portalShared.COLOR_WHITE_HEX);
        }

        portalShared.matchObj(portalShared.getCustomQuestionTextObj(textEntryType), savedObjs.customTemplateObj);

    } else {
        if (textEntryType === portalShared.ADDRESS_FULL) {
            savedObjs.customTemplateObj = portalShared.getCustomQuestionAddressObj();
        } else {
            savedObjs.customTemplateObj = portalShared.getCustomQuestionTextObj(question.questionType); // All text types use the same custom object except ADDRESS_FULL
        }
    }

    if (tag.metadataExistsObj(question, portalShared.CUSTOM_DDO_QUE_LABEL)) {
        savedObjs.customLabelObj = tag.metadataValueObj(question, portalShared.CUSTOM_DDO_QUE_LABEL);
    }

    let htmlObj = "";

    switch(textEntryType) {
        case portalShared.FREE_TEXT:
        case portalShared.DATE:
        case portalShared.DATE_RANGE:
            htmlObj = freeTextEntry(savedObjs);
            break;
        case portalShared.EMAIL:
            htmlObj = emailTextEntry(savedObjs);
            break;
        case portalShared.ZIPPOSTAL_ONLY:
        case portalShared.PHONE:
        case portalShared.NUMBER:
        case portalShared.CURRENCY:
            htmlObj = numericTextEntry(savedObjs);
            break;
        // case portalShared.DATE:
        //     htmlObj = singleDateEntry(savedObjs, "singleDateEntry-");
        //     break;
        // case portalShared.DATE_RANGE:
        //     htmlObj = dateRangeEntry(savedObjs);
        //     break;
        case portalShared.ADDRESS_FULL:
            htmlObj = addressAnswerEntry(savedObjs, props);
            break;
        // no default
    }

    return htmlObj;
}




// sx={{input: {color: "red", "&:hover": {color: "red"}}}}

//</editor-fold>
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


// ---------------------------------------------------------------------------------------------------------------------
// Free Text Entry
// ---------------------------------------------------------------------------------------------------------------------


function freeTextEntry(savedObjs, addressFieldName, callerRefStrOverride = "") {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs, addressFieldName);

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs, savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    //let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj);

    //let additionalProps = getTextField(self, savedObjs, "freetext", textPropsObj, textStyleObj, styledObj, callerRefStrOverride);
    let additionalProps = getTextField(self, savedObjs, "freetext", textPropsObj, textStyleObj, null, callerRefStrOverride);

    let type = (savedObjs.inputType === "date" || savedObjs.inputType === "date-range") ? "date" : savedObjs.inputType;

    // let inputLabelPropsObj = (type === "date") ? {shrink: true} : {};

    // -----------------------------------------------------------------------------------------------------------------
    // CODE FOR DATE RANGE ---------------------------------------------------------------------------------------------

    let arrangeSideBySide = false;

    if (savedObjs.hasOwnProperty("customTemplateObj")) {
        arrangeSideBySide = (savedObjs.customTemplateObj.arrangeDateRange === "side");
    }

    let tempStyle = arrangeSideBySide ? {display: "flex", flexDirection: "row", width: "100%"} : {display: "block", width: "100%"};

    // -----------------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    return (
        //<form>

        <FormControl key={savedObjs.question.id + "-" + addressFieldName} style={{width: textPropsObj.width}}>

            <div style={tempStyle}>
                <div>

                    {addLabel(savedObjs, LABEL_ABOVE)}
                    <TextField
                        type={type}
                        label={textPropsObj.floatingLabel.text ? textPropsObj.floatingLabel.text : textPropsObj.floatingLabel}
                        name={textPropsObj.placeholder.text ? textPropsObj.placeholder.text : textPropsObj.placeholder}
                        variant={textPropsObj.variant}
                        size={textPropsObj.size}
                        {...{...additionalProps, label: textPropsObj.floatingLabel !== "" ? textPropsObj.floatingLabel : ""}}
                    />
                    {addLabel(savedObjs, LABEL_BELOW)}

                </div>

                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>

                {savedObjs.inputType === "date-range" ?

                    <div>

                        {addLabel(savedObjs, LABEL_ABOVE, true)}
                        <TextField
                            type={type}

                            variant={textPropsObj.variant}
                            size={textPropsObj.size}
                            {...{...additionalProps, label: textPropsObj.floatingLabel2 !== "" ? textPropsObj.floatingLabel2 : ""}}
                        />
                        {addLabel(savedObjs, LABEL_BELOW, true)}

                    </div> : ""
                }

            </div>

        </FormControl>

        //</form>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// eMail Text Entry
// ---------------------------------------------------------------------------------------------------------------------


function validateEmail(answerText) {
    // regex checks for one and only 1 '@'
    // no white space in the email
    // at least one character before the '@', between the '@' and the '.'
    // and one or more characters after the '.'
    let tempEmail = (answerText === null || answerText === undefined) ? "" : answerText;

    let errorFlag = !!(tempEmail.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/) || tempEmail.length === 0);

    let errorMessage = !errorFlag ? "Invalid email entry" : "";

    return {flag: !errorFlag, message: errorMessage};
}


function emailTextEntry(savedObjs)  {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs);

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs, savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    //let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj);

    textPropsObj.errorFunction = validateEmail;

    //let additionalProps = getTextField(self, savedObjs, "email", textPropsObj, textStyleObj, styledObj, "");
    let additionalProps = getTextField(self, savedObjs, "email", textPropsObj, textStyleObj, null, "");

    return (
        <FormControl key={savedObjs.question.id} style={{width: textPropsObj.width}}>
            {addLabel(savedObjs, LABEL_ABOVE)}
            <TextField
                name={textPropsObj.placeholder}
                variant={textPropsObj.variant}
                size={textPropsObj.size}
               {...{...additionalProps, label: textPropsObj.floatingLabel !== "" ? textPropsObj.floatingLabel : "Email"}}
            />
            {addLabel(savedObjs, LABEL_BELOW)}
        </FormControl>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Number Entry
// ---------------------------------------------------------------------------------------------------------------------


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    //Field name is used to pass a formatted object because it was the only string I could attach too
    let parsedName = JSON.parse(other.name);

    if (parsedName.format === "") {
        return (<NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            type={parsedName.type}
            decimalScale={parsedName.decimalScale}
            fixedDecimalScale
            thousandSeparator={parsedName.thousandSeparator}
            allowNegative={parsedName.allowNegative}
            prefix={parsedName.prefix}
            suffix={parsedName.suffix}
        />);
    }
    else
    {
        return (<PatternFormat
            placeholder="Zip Code"
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            type={parsedName.type}
            format={parsedName.format}
            allowEmptyFormatting mask={parsedName.mask}
            decimalScale={parsedName.decimalScale}
            thousandSeparator={parsedName.thousandSeparator}
            prefix={parsedName.prefix}
            suffix={parsedName.suffix}
        />);
    }
}


function numericTextEntry(savedObjs, addressFieldName, callerRefStrOverride = "") {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs, addressFieldName);

//     textPropsObj.allowNegative = tag.metadataExists(savedObjs.question, portalShared.ALLOW_NEGATIVES);
//     textPropsObj.isPercentage = tag.metadataExists(savedObjs.question, portalShared.IS_PERCENTAGE);
//     textPropsObj.noDecimal = tag.metadataExists(savedObjs.question, portalShared.NO_DECIMAL);
//     textPropsObj.useDecimal = tag.metadataExists(savedObjs.question, portalShared.ALLOW_DECIMAL);
//     if (textPropsObj.useDecimal) {
//         textPropsObj.decimalScale = tag.metadataValue(savedObjs.question, portalShared.ALLOW_DECIMAL, "", 2);
//     }

    // NOTE: since name property is passed to the formatter i use it to pass properties in an object
    let name = {thousandSeparator: true, decimalScale: 0, allowNegative: false, prefix: "", suffix: "", type: "text", format: "", mask: ""};

    switch(savedObjs.textEntryType) {
        case portalShared.NUMBER:
            name.suffix = textPropsObj.isPercentage ? "%" : "";
            name.decimalScale = textPropsObj.isDecimal ? textPropsObj.decimalScale : 0;
            name.thousandSeparator = textPropsObj.thousandSeparator;
            name.allowNegative = textPropsObj.allowNegative
            // name.format = tag.metadataValue(savedObjs.question, portalShared.CUSTOM_MASK, "", "")
            break;
        case portalShared.CURRENCY:
            name.decimalScale = textPropsObj.isDecimal ? textPropsObj.decimalScale : 0;
            name.prefix = "$";
            break;
        case portalShared.ZIPPOSTAL_ONLY:
        //case portalShared.ADDRESS_FULL:
            name.format = textPropsObj.zipFormat;
            name.mask = ""
            break;
        case portalShared.PHONE:
            name.type = "tel";
            name.format = "(###) ###-####";
            name.mask = ""
            break;
        // no default
    }

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs, savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styleObj = {
        fontSize: textStyleObj.fontObj.fontSize,
        fontStyle: textStyleObj.fontObj.fontStyle,
        fontWeight: textStyleObj.fontObj.fontWeight,
        fontFamily: textStyleObj.fontObj.fontFamily,
    }

    //let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, callerRefStrOverride);

    let callerRefStr = callerRefStrOverride !== "" ? callerRefStrOverride : ("numericEntry-" + textPropsObj.type);

    let answerText = savedObjs.question.answerText !== null ? savedObjs.question.answerText : "";

    let additionalProps = getTextField(self, savedObjs, "email", textPropsObj, textStyleObj, null, "");

    // If an address field then ust pull the required field out
    if ((savedObjs.question.addressAnswerDTO !== undefined)&&(savedObjs.question.addressAnswerDTO !== null))  {
        answerText = savedObjs.question.addressAnswerDTO[callerRefStrOverride.substring(8)];
    }

    let labelText = portalShared.getLabel[savedObjs.textEntryType].charAt(0).toUpperCase() + portalShared.getLabel[savedObjs.textEntryType].slice(1).toLowerCase();

    // Added to keep floating label to float when formatted data is entered
    let shrinkProp = "";
    if (answerText !== "") {
        shrinkProp = {shrink: true};
    }

    return (
        <FormControl key={savedObjs.question.id} style={{width: textPropsObj.width}}>
            {addLabel(savedObjs, LABEL_ABOVE)}
            <TextField
                id={savedObjs.textEntryType + "-" + savedObjs.question.id}
                name={JSON.stringify(name)}
                defaultValue={answerText}
                label={textPropsObj.floatingLabel !== "" ? textPropsObj.floatingLabel : labelText}
                placeholder={textPropsObj.placeholder}
                variant={textPropsObj.variant}
                size={textPropsObj.size}
                onBlur={saveAnswerTypeText.bind(self, callerRefStr, savedObjs)}
                min={"0"}
                max={"100"}
                disabled={savedObjs.readonlyMode}
                //inputProps={{autoComplete: "off"}}

                InputProps={{
                    inputComponent: NumberFormatCustom,
                    autoComplete: callerRefStrOverride === "address-zipPostalCode" ? "new-password" : "off",

                }}
                InputLabelProps={{...shrinkProp, style: {backgroundColor: textPropsObj.variant === "filled" ? "#E8E8E8" : "white", ...styleObj}}}

                {...{...additionalProps}}

            />
            {addLabel(savedObjs, LABEL_BELOW)}
        </FormControl>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Date Entry
// ---------------------------------------------------------------------------------------------------------------------


function singleDateEntry(savedObjs, refStr) {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs);

    // let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs, savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    // let styleObj = {
    //     fontSize: textStyleObj.fontObj.fontSize,
    //     fontStyle: textStyleObj.fontObj.fontStyle,
    //     fontWeight: textStyleObj.fontObj.fontWeight,
    //     fontFamily: textStyleObj.fontObj.fontFamily,
    // }
    //
    // let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, "DATE");

    // Note: Date range value are storedas a comma delimited string
    let answer = savedObjs.question.answerText;
    let dateRangeFromFlag = (refStr === "dateRangeEntryFrom-");
    if (refStr !== "singleDateEntry-") {
        answer = (answer) ? answer.split(",") : ["", ""];
        answer = (dateRangeFromFlag) ? answer[0] : answer[1];
    }

    //let customObj = savedObjs.customTemplateObj; // If there is a custom object , customObj.labelPosition === "above" ? (dateRangeToFlag ? customObj.label : customObj.label2) : ""

    let width = portalShared.DEFAULT_DATE_ENTRY_WIDTH;
    if (savedObjs.hasOwnProperty("customTemplateObj")) {
        width = portalShared.getWidth(savedObjs.customTemplateObj.widthEntry, savedObjs.customTemplateObj.widthType);
    }

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs, savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let additionalProps = getTextField(self, savedObjs, "email", textPropsObj, textStyleObj, null, "");

    return (
        <FormControl key={refStr + savedObjs.question.id}>
            {addLabel(savedObjs, LABEL_ABOVE, dateRangeFromFlag)}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/*<styledObj.styledDateField label={styledObj.floatingLabel} name={textPropsObj.placeholder} variant={textPropsObj.variant} size={textPropsObj.size}>*/}

                <DatePicker id={refStr + savedObjs.question.id}
                            label={textPropsObj.floatingLabel}
                            variant={textPropsObj.variant}
                            size={textPropsObj.size}
                            defaultValue={(answer) ? answer : ""}
                            value={(answer) ? answer : ""}
                            onBlur={saveAnswerTypeText.bind(self, refStr + textPropsObj.type, savedObjs)}
                            onChange={saveAnswerTypeText.bind(self, refStr + textPropsObj.type, savedObjs)}
                            disabled={savedObjs.readonlyMode}
                            renderInput={(params) => <TextField {...params}  variant="standard" error={false} sx={{width: width, marginBottom: "22px"}}/>}
                    // InputLabelProps={{shrink: true, ...styleObj}}
                    // inputProps={{autoComplete: "off"}}  // eslint-disable-line
                    // InputProps={{style: {...styleObj}}} // eslint-disable-line
                    //inputprops={{max: "2999-12-31"}}

                            {...{...additionalProps}}
                />

                {/*</styledObj.styledDateField>*/}
            </LocalizationProvider>
            {addLabel(savedObjs, LABEL_BELOW, dateRangeFromFlag)}
        </FormControl>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Date Range Entry
// ---------------------------------------------------------------------------------------------------------------------


function dateRangeEntry(savedObjs) {

    let arrangeSideBySide = false;

    let width = portalShared.DEFAULT_DATE_ENTRY_WIDTH;
    if (savedObjs.hasOwnProperty("customTemplateObj")) {
        width = portalShared.getWidth(savedObjs.customTemplateObj.widthEntry, savedObjs.customTemplateObj.widthType);

        arrangeSideBySide = (savedObjs.customTemplateObj.arrangeDateRange === "side");
    }

    let tempStyle = arrangeSideBySide ? {display: "flex", width: "100%"} : {display: "block", width: "100%"};

    return ( freeTextEntry(savedObjs, "dateRangeEntryFrom-")
        // <div style={tempStyle}>
        //     <div style={{width: width}}>
        //         {freeTextEntry(savedObjs, "dateRangeEntryFrom-")}
        //     </div>
        //     <div style={{width: "20px"}}>{portalShared.NON_BREAKING_SPACE}</div>
        //     <div style={{width: width}}>
        //         {freeTextEntry(savedObjs, "dateRangeEntryTo-")}
        //     </div>
        // </div>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Address Entry
// ---------------------------------------------------------------------------------------------------------------------


export function addressAnswerEntry(savedObjs){

    let portalPreviewMode = (savedObjs.displayMode === portalShared.TEMPLATE_MODE);

    const defaultLabels = ["Street 1", "Street 2", "City", "State", "Zip"];
    const customAddressObjNames = ["street1Obj", "street2Obj", "cityObj", "stateObj", "zipObj"];

    let addressObjects = [];

    defaultLabels.forEach((label, index) => {

        let clonedObj = portalShared.cloneObj(savedObjs);

        let propName = customAddressObjNames[index];

        if (customAddressObjNames[index] !== "stateObj") {
            clonedObj.props.answerQuestionOptions = savedObjs.props.answerQuestionOptions;
            clonedObj.props.answerQuestionTemplateOptions = savedObjs.props.answerQuestionTemplateOptions;
            clonedObj.props.answerQuestionTemplateText = savedObjs.props.answerQuestionTemplateText;
            clonedObj.props.answerQuestionText = savedObjs.props.answerQuestionText;

            clonedObj.customTemplateObj[propName].floatingLabel = {text: savedObjs.customTemplateObj[propName].floatingLabel, ...clonedObj.customTemplateObj.unselectedFloatingLabel};
            clonedObj.customTemplateObj.unselectedFloatingLabel = {text: clonedObj.customTemplateObj[propName].floatingLabel, ...clonedObj.customTemplateObj.unselectedFloatingLabel};
            clonedObj.customTemplateObj[propName].placeHolder = {text: "", ...clonedObj.customTemplateObj.placeHolderColor};
        } else {
            clonedObj.answerQuestionOptions = savedObjs.props.answerQuestionOptions;
            clonedObj.answerQuestionTemplateOptions = savedObjs.props.answerQuestionTemplateOptions;
            clonedObj.answerQuestionTemplateText = savedObjs.props.answerQuestionTemplateText;
            clonedObj.answerQuestionText = savedObjs.props.answerQuestionText;

            clonedObj.customTemplateObj[propName].floatingLabel = {text: savedObjs.customTemplateObj[propName].floatingLabel, ...clonedObj.customTemplateObj.unselectedFloatingLabel};
            clonedObj.customTemplateObj.unselectedFloatingLabel = {text: savedObjs.customTemplateObj[propName].floatingLabel, ...clonedObj.customTemplateObj.unselectedFloatingLabel};
            clonedObj.customTemplateObj[propName].placeHolder = {text: clonedObj.customTemplateObj[propName].placeHolder, ...clonedObj.customTemplateObj.placeHolderColor};
        }

        clonedObj.customTemplateObj["width"] = savedObjs.customTemplateObj[propName].width;
        clonedObj.customTemplateObj["widthEntry"] = savedObjs.customTemplateObj[propName].widthEntry;
        clonedObj.customTemplateObj["widthType"] = savedObjs.customTemplateObj[propName].widthType;
        clonedObj.customTemplateObj["max"] = savedObjs.customTemplateObj[propName].max;
        clonedObj.customTemplateObj["label"] = savedObjs.customTemplateObj[propName].label;

        addressObjects.push(clonedObj);
    });

    return (
        <div>
            <div>
                {freeTextEntry(addressObjects[0], customAddressObjNames[0], "address-address")}
            </div>

            <div>{portalShared.NON_BREAKING_SPACE}</div>

            <div>
                {freeTextEntry(addressObjects[1], customAddressObjNames[1], "address-extended")}
            </div>

            <div>{portalShared.NON_BREAKING_SPACE}</div>

            <div style={{width: "100%", justifyContent: "space-between"}}>
                <div style={{float: "left"}}>
                    {freeTextEntry(addressObjects[2], customAddressObjNames[2], "address-city")}
                </div>

                <div style={{float: "left", width: portalPreviewMode ? "11px" : "20px"}}>{portalShared.NON_BREAKING_SPACE}</div>

                <div style={{float: "left", width: "200px"}}>
                    {addLabel(addressObjects[3], LABEL_ABOVE)}
                    {/*{addressDropDownStates(savedObjs, statesTagsArray)}*/}
                    {singleSelectDropDown(addressObjects[3], customAddressObjNames[3])}
                    <div style={{marginTop: "-20px"}}>{addLabel(savedObjs, LABEL_BELOW)}</div>
                </div>

                <div style={{float: "left", width: portalPreviewMode ? "11px" : "20px"}}>{portalShared.NON_BREAKING_SPACE}</div>

                <div style={{float: "left"}}>
                    {numericTextEntry(addressObjects[4], customAddressObjNames[4], portalShared.ZIPPOSTAL_ONLY, "address-zipPostalCode")}
                </div>
            </div>

        </div>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// singleSelectDropDownStates !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// ---------------------------------------------------------------------------------------------------------------------

export function addressDropDownStates(savedObjs) {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs);

    textPropsObj.errorFunction = validateEmail;

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs, savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, "");

    let question = savedObjs.question;

    let selectedFlag = (savedObjs.selectedAnswer === null) ? false : (savedObjs.selectedAnswer.id !== "");
    let inputLabelAdjPx;
    let leftAdjPx = "0px";
    let formAdjPx;
    //let backgroundColor = "white";
    let backgroundColorLabel = "white";
    //let smallSize = "40px";
    //let largeSize = "56px";
    //let paddingLeft = "0px";
    let paddingTop = "0px";
    //let paddingTopSelect = "0px";
    let fontColor = styledObj.borderColor;

    if (textPropsObj.variant === "outlined") {
        formAdjPx = "0px";

        if (textPropsObj.size === "small") {
            inputLabelAdjPx = (selectedFlag) ? "0px" : "-8px";
            paddingTop = (selectedFlag) ? "0px" : "-8px";
            //paddingTopSelect = (selectedFlag) ? "0px" : "-8px";
        } else {
            inputLabelAdjPx = "0px";
        }

    } else if (textPropsObj.variant === "standard") {

        if (textPropsObj.size === "small") {
            //smallSize = (selectedFlag) ? "35px" : "40px";
            inputLabelAdjPx = (selectedFlag) ? "16px" : "12px";  // padding above floating text
            formAdjPx = (selectedFlag) ? "-6px" : "-11px";
            leftAdjPx = "-14px";
            //paddingTopSelect = "4px";  // padding above answer
        } else {
            inputLabelAdjPx = (selectedFlag) ? "16px" : "12px";  // padding above floating text
            formAdjPx = (selectedFlag) ? "-7px" : "-8px";  // Adjust bottom line position
            leftAdjPx = "-14px";
            //largeSize = "40px";
            //paddingTopSelect = "6px";  // padding above answer
        }

    } else {
        // Filled entry
        //backgroundColor = "#E8E8E8";
        if (selectedFlag) {
            backgroundColorLabel = "transparent";
            //paddingTopSelect = "16px";
            paddingTop = "20px";
        } else {
            backgroundColorLabel = "transparent";
            //paddingTopSelect = "0px";
            paddingTop = "0px";
        }
        //paddingLeft = "12px";

        if (textPropsObj.size === "small") {
            inputLabelAdjPx = (selectedFlag) ? "14px" : "12px";  // position of floating label
            formAdjPx = "-16px";
            leftAdjPx = (selectedFlag) ? "-2px" : "0px";
            //smallSize = "48px";
        } else {
            inputLabelAdjPx = (selectedFlag) ? "16px" : "16px"; // position of floating label
            formAdjPx = "-16px";
            leftAdjPx = (selectedFlag) ? "-2px" : "0px";
        }
    }

    savedObjs.answers = savedObjs.displayMode === portalShared.INSTANT_MODE ? savedObjs.question.answerOptions : savedObjs.question.answerOptionTemplates;

    let selectedId = "";
    for (let i = 0; i < savedObjs.answers.length; i++) {
        if (savedObjs.answers[i].selected) {
            selectedId = savedObjs.answers[i].id.toString();
        }
    }


    return (
        <FormControl
            variant="outlined"
            style={{width: "200px", marginTop: formAdjPx}}
            //className={savedObjs.classes.formControl}
        >
            {textPropsObj.floatingLabel === "" ? null : <InputLabel style={{color: fontColor, backgroundColor: backgroundColorLabel, paddingTop: paddingTop, marginLeft: leftAdjPx, marginTop: inputLabelAdjPx}}>{textPropsObj.floatingLabel + portalShared.NON_BREAKING_SPACE2}</InputLabel>}
            <styledObj.styledSelect label={styledObj.floatingLabel} name={textPropsObj.placeholder} variant={textPropsObj.variant} size={textPropsObj.size}>

                    labelId={question.id.toString()}
                    id={question.id.toString()}
                    value={selectedId}
                    onChange={saveAnswerTypeSingleSelect.bind(self, "addressStateDDLB", savedObjs)}
                    disabled={savedObjs.readonlyMode}
                    label={textPropsObj.floatingLabel}
                    {/*style={{*/}
                    {/*    height: textPropsObj.size === "small" ? smallSize : largeSize,*/}
                    {/*    backgroundColor: backgroundColor,*/}
                    {/*    paddingLeft: paddingLeft,*/}
                    {/*    paddingTop: paddingTopSelect*/}
                    {/*}}*/}
                    input={textPropsObj.variant === "outlined" ?
                        <OutlinedInput
                            labelWidth={getLabelWidth(textPropsObj.floatingLabel)}
                            id={"addressStates-" + question.id.toString()}
                        />
                        :
                        null
                    }
                >
                    {savedObjs.answers.map((answerOption) => {
                        return (
                            <MenuItem
                                value={answerOption.id.toString()}
                                key={answerOption.id.toString()}>
                                {answerOption.optionText}
                            </MenuItem>);
                    })}

            </styledObj.styledSelect>
        </FormControl>
    );

}


// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// Text Entry Utilities
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------


function addLabel(savedObjs, labelPosition, dateFromFlag = false) {

    // Label check is for addresses because they behave differently
    if (savedObjs.hasOwnProperty("customTemplateObj") && (savedObjs.customTemplateObj.hasOwnProperty("label") || savedObjs.customTemplateObj.hasOwnProperty("label2"))) {

        //let question = savedObjs.question;

        let label = dateFromFlag ? savedObjs.customTemplateObj.label2 : savedObjs.customTemplateObj.label;

        label = (savedObjs.customTemplateObj.labelPosition === labelPosition) ? label : "";

        if (savedObjs.questionNameHidden) {
            return <div></div>;
        }

        // Is there a label
        if (savedObjs.customTemplateObj.labelPosition !== undefined && savedObjs.customTemplateObj.labelPosition !== "none") {

            let customLabelObj = portalShared.getCustomQuestionLabelTextObj();
            if (savedObjs.hasOwnProperty("customLabelObj")) {
                customLabelObj = savedObjs.customLabelObj;
            }

            //let customObj = savedObjs.customTemplateObj.text;

            let CustomTypography = styled(Typography)({
                fontFamily: customLabelObj.fontFamily,
                fontWeight: customLabelObj.fontWeight,
                fontSize: customLabelObj.fontSize,  // 2em, 18pt
                fontStyle: customLabelObj.fontStyle,
                textAlign: customLabelObj.textAlign,  // left center right
                color: portalShared.getColor(customLabelObj.colorObj),
                opacity: portalShared.getOpacity(customLabelObj.colorObj),
            });

            return <CustomTypography>{label}</CustomTypography>;
        }
    }

    return null;
}


function getTextProperties(savedObjs, addressFieldName, defaultWidth = "100%", defaultMaxlength = "80") {

    let textDefObj = {};

    // let placeHolderName = "placeHolder";
    // if (savedObjs.textEntryType ===  portalShared.ADDRESS_FULL) {
    //     placeHolderName = "placeHolderColor"; // Because address is multiple fields that share a placeholder color, different property name was used.
    // }

    textDefObj.type = savedObjs.inputType;
    textDefObj.floatingLabel = "";
    textDefObj.floatingLabel2 = "";
    textDefObj.placeholder = "";
    textDefObj.variant = "outlined";
    textDefObj.size = "small";
    textDefObj.width = defaultWidth;
    textDefObj.maxLength = defaultMaxlength;
    textDefObj.multiline = false;
    textDefObj.maxRows = 1;
    textDefObj.minRows = 1;
    textDefObj.isPercentage = false;
    textDefObj.allowNegative = false;
    textDefObj.isDecimal = false;
    textDefObj.decimalScale = 2;
    textDefObj.thousandSeparator = true;
    textDefObj.zipFormat = "#####";
    textDefObj.customFormat = "";

    if (savedObjs.hasOwnProperty("customTemplateObj")) {

        let obj = savedObjs.customTemplateObj;

        if (savedObjs.textEntryType === portalShared.ADDRESS_FULL) {

            textDefObj.zipFormat = obj.zipFormat;
            textDefObj.variant = obj.style;
            textDefObj.size = obj.size;

            obj = obj[addressFieldName];

            textDefObj.floatingLabel = obj.floatingLabel?.text;
            textDefObj.placeholder = obj.placeHolder?.text;
            textDefObj.width = obj.width;
            textDefObj.maxLength = obj.max;

        } else {

            textDefObj.floatingLabel = obj.unselectedFloatingLabel?.text;
            textDefObj.floatingLabel2 = obj.floatingLabel2;
            textDefObj.placeholder = obj.placeHolder?.text;
            textDefObj.variant = obj.style;
            textDefObj.size = obj.size;
            textDefObj.width = obj.width;
            textDefObj.maxLength = obj.max;
            textDefObj.multiline = obj.multiline;
            textDefObj.maxRows = obj.maxRows;
            textDefObj.minRows = obj.minRows;
            textDefObj.isPercentage = obj.isPercentage;
            textDefObj.allowNegative = obj.isNegative;
            textDefObj.isDecimal = obj.isDecimal;
            textDefObj.decimalScale = obj.decimalScale;
            textDefObj.thousandSeparator = obj.thousandSeparator;
            textDefObj.zipFormat = obj.zipFormat;

        }
    }

    // textDefObj.floatingLabel = "";
    // textDefObj.placeholder = "";
    // textDefObj.multiline = "";
    //
    // if (savedObjs.hasOwnProperty("customTemplateObj")) {
    //     debugger;
    //     textDefObj.floatingLabel = savedObjs.customTemplateObj.unselectedFloatingLabel.text;
    //     textDefObj.placeholder = savedObjs.customTemplateObj.placeHolder.text;
    //     textDefObj.multiline = savedObjs.customTemplateObj.multiline;
    // }


    // LEGACY FIX for these 3 metadata tags
    // if (tag.metadataExists(savedObjs.question, portalShared.TEXT_FLOATING_LABEL, "", true)) {
    //     textDefObj.floatingLabel = tag.metadataValue(savedObjs.question, portalShared.TEXT_FLOATING_LABEL, "", savedObjs.floatingLabel);
    // }
    //
    // if (tag.metadataExists(savedObjs.question, portalShared.TEXT_PLACEHOLDER, "", true)) {
    //     textDefObj.placeholder = tag.metadataValue(savedObjs.question, portalShared.TEXT_PLACEHOLDER, "", savedObjs.placeholder);
    // }
    //
    // if (tag.metadataExists(savedObjs.question, portalShared.TEXT_MULTILINE, "", true)) {
    //     textDefObj.multiline = tag.metadataValue(savedObjs.question, portalShared.TEXT_PLACEHOLDER, "", savedObjs.multiline) !== false;
    // }

    textDefObj.errorFunction = function () {return {flag: false, message: ""};};

    return textDefObj;
}


// moved to metadataConstantsAndUtilities
// function colorNameToHex(color)
// {
//     let colorString = (typeof color === "string") ? color : "black" ;
//     var colors = {"aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
//         "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
//         "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
//         "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
//         "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
//         "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
//         "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
//         "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
//         "honeydew":"#f0fff0","hotpink":"#ff69b4",
//         "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
//         "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
//         "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
//         "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
//         "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
//         "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
//         "navajowhite":"#ffdead","navy":"#000080",
//         "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
//         "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
//         "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
//         "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
//         "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
//         "violet":"#ee82ee",
//         "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
//         "yellow":"#ffff00","yellowgreen":"#9acd32"};
//
//     if (typeof colors[colorString.toLowerCase()] !== 'undefined') {
//         return colors[colorString.toLowerCase()];
//     }
//
//     return color;
// }

export function getCustomColor(savedObjs, propName, defaultColor) {

    let color = defaultColor;
    if (savedObjs.hasOwnProperty("customTemplateObj") && savedObjs.customTemplateObj.hasOwnProperty(propName)) {
        color = portalShared.getColor(savedObjs.customTemplateObj[propName]);
    }
    return color;
}

export function getCustomColorObj(savedObjs, propName, defaultColor) {

    let color = defaultColor;
    if (savedObjs.hasOwnProperty("customTemplateObj") && savedObjs.customTemplateObj.hasOwnProperty(propName)) {
        if (savedObjs?.customTemplateObj[propName].hasOwnProperty("colorObj"))
        color = portalShared.getColor(savedObjs?.customTemplateObj[propName].colorObj);
    }
    return color;
}


export function getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj) {

    //let defaultGray = "#808080";
    // Set styledObj defaults
    let styledObj = {
        styledTextField: null,
        styledDateField: null,
        styledSelect: null,
        textColor:  getCustomColorObj(savedObjs, "placeHolder", portalShared.COLOR_GRAY_HEX),
        // textColorHovered: textStyleObj.type === "default" ? portalShared.DEFAULT_TEXT_FIELD_TEXT_COLOR_HOVERED : textStyleObj.fontObj.color,
        // textColorFocused: textStyleObj.type === "default" ? portalShared.DEFAULT_TEXT_FIELD_TEXT_COLOR_FOCUSED : textStyleObj.fontObj.color,
        floatingLabel: savedObjs?.customTemplateObj?.unselectedFloatingLabel?.text,
        floatingLabel2: savedObjs?.customTemplateObj?.floatingLabel2,
        floatingLabelColor: getCustomColor(savedObjs, "unselectedFloatingLabel", portalShared.COLOR_GRAY_HEX),
        floatingLabelColorHovered: getCustomColor(savedObjs, "hoverFloatingLabel", portalShared.COLOR_GRAY_HEX),
        floatingLabelColorFocused: getCustomColor(savedObjs, "selectedFloatingLabel", portalShared.COLOR_GRAY_HEX),

        unselectedText: getCustomColor(savedObjs, "unselectedText", portalShared.COLOR_GRAY_HEX),
        selectedText: getCustomColor(savedObjs, "selectedText", portalShared.COLOR_BLACK_HEX),
        hoverText: getCustomColor(savedObjs, "hoverText", portalShared.COLOR_BLACK_HEX),

        placeHolderColor: getCustomColor(savedObjs, "placeHolder", portalShared.COLOR_GRAY_HEX),
        placeHolderOpacity: portalShared.getOpacity(savedObjs.customTemplateObj.placeHolder),

        colorTextAnsweredBorderColor: getCustomColor(savedObjs, "selectedBorder"),

        backgroundColor: getCustomColor(savedObjs, "unselectedBackground", portalShared.COLOR_WHITE_HEX),
        backgroundColorHovered: getCustomColor(savedObjs, "hoverBackground", portalShared.COLOR_WHITE_HEX),
        backgroundColorFocused: getCustomColor(savedObjs, "selectedBackground", portalShared.COLOR_WHITE_HEX),

        backgroundFilledColor: getCustomColor(savedObjs, "unselectedBackground", portalShared.COLOR_LIGHT_GRAY_HEX),
        backgroundFilledColorHovered: getCustomColor(savedObjs, "hoverBackground", portalShared.COLOR_LIGHT_GRAY_HEX),
        backgroundFilledColorFocused: getCustomColor(savedObjs, "selectedBackground", portalShared.COLOR_LIGHT_GRAY_HEX),

        borderColor: getCustomColor(savedObjs, "unselectedBorder", portalShared.COLOR_GRAY_HEX),
        borderColorHovered: getCustomColor(savedObjs, "hoverBorder", portalShared.COLOR_DODGER_BLUE_HEX),
        borderColorFocused: getCustomColor(savedObjs, "selectedBorder", portalShared.COLOR_DODGER_BLUE_HEX),
    }

    let tempStyledObj = {
        //Properties for all text fields
        // "& .MuiInputBase-root": {
        //     color: styledObj.textColor //Text color for all text field
        // },
        // "&:hover": {
        //     color: styledObj.hoverText
        // },
        // "& .Mui-focused": {
        //     color: styledObj.selectedText
        // },
        "& .MuiInputLabel-root": {
            color: styledObj.floatingLabelColor // MUI 5 floating label color all variants NOT focused or hovered
        },
        "&:hover label": {
            color: styledObj.floatingLabelColorHovered // MUI 5 floating label color all variants hovered
        },
        "& label.Mui-focused": {
            color: styledObj.floatingLabelColorFocused // MUI 5 floating label color all variants focused
        },

        //All outlined text field properties
        "& .MuiOutlinedInput-root": {
            backgroundColor: styledObj.backgroundColor,
            "& fieldset": {
                borderColor: styledObj.borderColor // MUI 5 outlined border color NOT focused or hovered
            },
            "&:hover fieldset": {
                borderColor: styledObj.borderColorHovered // MUI 5 outlined border color hovered
            },
            "&.Mui-focused fieldset": {
                borderColor: styledObj.borderColorFocused // MUI 5 outlined border color focused
            },
            "&:hover": {
                backgroundColor: styledObj.backgroundColorHovered,
            },
            "&.Mui-focused": {
                backgroundColor: styledObj.backgroundColorFocused,
            }
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
            font: textStyleObj.fontObj.fontFamily,
            color: styledObj.placeHolderColor,
            opacity: styledObj.placeHolderOpacity
        },

        //All standard text field properties
        "&& .MuiInput-root::before": {
            borderColor: styledObj.borderColor, // MUI 5 standard underline NOT hovered or focused color
        },
        "&& .MuiInput-root:hover::before": {
            borderColor: styledObj.borderColorHovered // MUI 5 standard underline hover color
        },
        "&& .MuiInput-root::after": {
            borderColor: styledObj.borderColorFocused // MUI 5 standard underline focused color
        },
        "& .MuiInputBase-root.MuiInput-root ::placeholder": {
            font: textStyleObj.fontObj.fontFamily,
            color: styledObj.placeHolderColor,
            opacity: styledObj.placeHolderOpacity
        },

        //Background color for standard text field
        //On standard text field the background color blocks floating label
        "& .MuiInput-root": {
            backgroundColor: styledObj.backgroundColor,
            "&:hover": {
                backgroundColor: styledObj.backgroundColorHovered,
            },
            "&.Mui-focused": {
                backgroundColor: styledObj.backgroundColorFocused,
            },
        },

        //All filled text field properties
        "&& .MuiFilledInput-root": {
            backgroundColor: styledObj.backgroundFilledColor,
        },
        "&& .MuiFilledInput-root::before": {
            borderColor: styledObj.borderColor // MUI 5 filled underline NOT hovered or focused color
        },
        "&& .MuiFilledInput-root:hover::before": {
            borderColor: styledObj.borderColorHovered // MUI 5 filled underline hover color
        },
        "&& .MuiFilledInput-root::after": {
            borderColor: styledObj.borderColorFocused // MUI 5 filled underline focused color
        },
        "& .MuiInputBase-root.MuiFilledInput-root ::placeholder": {
            font: textStyleObj.fontObj.fontFamily,
            color: styledObj.placeHolderColor,
            opacity: styledObj.placeHolderOpacity
        },

        //Background colors for filled text field
        "& .MuiFilledInput-root": {
            backgroundColor: styledObj.backgroundFilledColor,
            "&:hover": {
                backgroundColor:styledObj.backgroundFilledColorHovered,
            },
            "&.Mui-focused": {
                backgroundColor: styledObj.backgroundFilledColorFocused,
            }
        },
    };

    styledObj.styledTextField = styled(TextField)(tempStyledObj);

    //styledObj.styledDateField = styled(DateField)(tempStyledObj);

    styledObj.styledSelect = styled(Select)(tempStyledObj);

    // Get any styledObj metadata overrides
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR)) {
    //     styledObj.floatingLabelColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR, "", styledObj.floatingLabelColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR_HOVERED)) {
    //     styledObj.floatingLabelColorHovered = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR_HOVERED, "", styledObj.floatingLabelColorHovered));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR_FOCUSED)) {
    //     styledObj.floatingLabelColorFocused = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR_FOCUSED, "", styledObj.floatingLabelColorFocused));  // Some of the theme props wont take a color string
    // }
    //
    // // Placeholder always seems to be the input base color at 50% opacity
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_PLACEHOLDER_COLOR)) {
    //     styledObj.placeholderColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_PLACEHOLDER_COLOR, "", styledObj.placeholderColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_PLACEHOLDER_OPACITY)) {
    //     styledObj.placeholderOpacity = tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_PLACEHOLDER_OPACITY, "", styledObj.placeholderOpacity);  // Some of the theme props wont take a color string
    // }
    //
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR)) {
    //     styledObj.textColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR, "", styledObj.textColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR_HOVERED)) {
    //     styledObj.textColorHovered = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR_HOVERED, "", styledObj.textColorHovered));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR_FOCUSED)) {
    //     styledObj.textColorFocused = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR_FOCUSED, "", styledObj.textColorFocused));  // Some of the theme props wont take a color string
    // }
    //
    //
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR)) {
    //     styledObj.backgroundColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR, "",  styledObj.backgroundColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR_HOVERED)) {
    //     styledObj.backgroundColorHovered = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR_HOVERED, "",  styledObj.backgroundColorHovered));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR_FOCUSED)) {
    //     styledObj.backgroundColorFocused = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR_FOCUSED,  "", styledObj.backgroundColorFocused));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR)) {
    //     styledObj.backgroundFilledColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR, "",  styledObj.backgroundFilledColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_HOVERED)) {
    //     styledObj.backgroundFilledColorHovered = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_HOVERED, "",  styledObj.backgroundFilledColorHovered));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_FOCUSED)) {
    //     styledObj.backgroundFilledColorFocused = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_FOCUSED,  "", styledObj.backgroundFilledColorFocused));  // Some of the theme props wont take a color string
    // }
    //
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR)) {
    //     styledObj.borderColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR, "",  styledObj.borderColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR_HOVERED)) {
    //     styledObj.borderColorHovered = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR_HOVERED, "",  styledObj.borderColorHovered));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR_FOCUSED)) {
    //     styledObj.borderColorFocused = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR_FOCUSED,  "", styledObj.borderColorFocused));  // Some of the theme props wont take a color string
    // }
    //
    //
    // // NOT sure this is a thing?????
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_COLOR_ANSWERED_BORDER)) {
    //     styledObj.colorTextAnsweredBorderColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_COLOR_ANSWERED_BORDER, "", "gray"));  // Some of the theme props wont take a color string
    // }


    // let callerRefStr = (callerRefStrOverride !== "") ? callerRefStrOverride : ("textEntry-" + textPropsObj.type)

    // let answerText = savedObjs.question.answerText !== null ? savedObjs.question.answerText : "";
    //
    // // If an address field then just pull the required field out
    // if ((savedObjs.question.addressAnswerDTO !== undefined)&&(savedObjs.question.addressAnswerDTO !== null)) {
    //     answerText = savedObjs.question.addressAnswerDTO[callerRefStrOverride.substring(8)];
    // }

    return styledObj;
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// TextField sx porp for styling
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

function getTextField(self, savedObjs, entryType, textPropsObj, textStyleObj, styledObj, callerRefStrOverride = "") {

    let styleObj = {
        fontSize: textStyleObj.fontObj.fontSize,
        fontStyle: textStyleObj.fontObj.fontStyle,
        fontWeight: textStyleObj.fontObj.fontWeight,
        fontFamily: textStyleObj.fontObj.fontFamily,
    }
    let error = textPropsObj.errorFunction(savedObjs.question.answerText);

    let callerRefStr = (callerRefStrOverride !== "") ? callerRefStrOverride : ("textEntry-" + textPropsObj.type)

    let answerText = savedObjs.question.answerText !== null ? savedObjs.question.answerText : "";

    // If an address field then just pull the required field out
    if ((savedObjs?.question?.addressAnswer)) {
        answerText = savedObjs.question.addressAnswer[callerRefStrOverride.substring(8)];
    }

    let autoComplete = "off";
    if (callerRefStrOverride !== "") {
        if (callerRefStrOverride.substring(0,8) === "address-") {
            autoComplete = "new-pasword";
        }
    }

    let width = (savedObjs.hasOwnProperty("customTemplateObj") ? savedObjs.customTemplateObj.width : portalShared.DEFAULT_DATE_ENTRY_WIDTH + "px")

    //let placeholderColor = portalShared.DEFAULT_TEXT_FIELD_PLACEHOLDER_COLOR;
    //let placeholderOpacity = portalShared.DEFAULT_TEXT_FIELD_PLACEHOLDER_OPACITY;
    //if (savedObjs?.customTemplateObj) {
    let placeholderColor = portalShared.getColor(savedObjs.customTemplateObj.placeHolderColor);  // Some of the theme props wont take a color string
    let placeholderOpacity = portalShared.getOpacity(savedObjs.customTemplateObj.placeHolderColor);  // Some of the theme props wont take a color string
    //}

    let type = (savedObjs.inputType === "date" || savedObjs.inputType === "date-range") ? "date" : savedObjs.inputType;

    let shrinkObj = (type === "date") ? {shrink: true} : {};

    return {
        defaultValue: answerText,
        id: entryType + "-" + savedObjs.question.id,
        label: textPropsObj.floatingLabel,
        placeholder: textPropsObj.placeholder,
        multiline: textPropsObj.multiline,
        maxRows: textPropsObj.maxRows,
        minRows: textPropsObj.minRows,
        variant: textPropsObj.variant,
        size: textPropsObj.size,
        onBlur: saveAnswerTypeText.bind(self, callerRefStr, savedObjs),
        error: error.flag,
        helperText: error.message,
        disabled: savedObjs.readonlyMode,
        inputProps: {maxLength: textPropsObj.maxLength, autoComplete: autoComplete, style: {...styleObj}},
        InputLabelProps: {
            style: {
                backgroundColor: textPropsObj.variant === "outlined" ? "white" : "",
                autoComplete: "off", ...styleObj,
            },
            ...shrinkObj
        },
        sx: {...sxObjGet(savedObjs), width: width},


    }
}

// ---------------------------------------------------------------------------------------------------------------------
// CUSTOM TEXT FIELD (STANDARD, OUTLINED and FILLED)
// ---------------------------------------------------------------------------------------------------------------------

function sxObjGet(savedObjs) {
    let sxObj = {}; // GREAT Web Page for describing sx Text Entry Styling https://muhimasri.com/blogs/mui-textfield-colors-styles/

    let obj = savedObjs.customTemplateObj;

    // portalShared.setColor(obj.placeHolder, null, "orange", "hex");
    //
    // portalShared.setColor(obj.unselectedFloatingLabel, null, "blue", "hex");
    // portalShared.setColor(obj.selectedFloatingLabel, null, "yellow", "hex");
    // portalShared.setColor(obj.hoverFloatingLabel, null, "purple", "hex");
    //
    // portalShared.setColor(obj.unselectedText, null, "red", "hex");
    // portalShared.setColor(obj.selectedText, null, "green", "hex");
    // portalShared.setColor(obj.hoverText, null, "purple", "hex");
    //
    // portalShared.setColor(obj.unselectedBorder, null, "gray", "hex"); // Y
    // portalShared.setColor(obj.selectedBorder, null, "cyan", "hex");
    // portalShared.setColor(obj.hoverBorder, null, "cyan", "hex"); // Y
    //
    // portalShared.setColor(obj.unselectedBackground, null, "white", "hex"); // Outlined Only
    // portalShared.setColor(obj.selectedBackground, null, "white", "hex"); // Outlined Only
    // portalShared.setColor(obj.hoverBackground, null, "white", "hex"); // Outlined Only
    //
    // obj.borderWidth = "1px";
    // obj.hoverBorderWidth = "1px";
    // obj.selectedBorderWidth = "2px";

    switch (savedObjs.customTemplateObj.style) {
        case "standard":
            sxObj = sxStandardTextField(savedObjs, portalShared.getColor, portalShared.getOpacity);
            break;
        case "outlined":
            sxObj = sxOutlinedTextField(savedObjs, portalShared.getColor, portalShared.getOpacity);
            break;
        case "filled":
            sxObj = sxFilledTextField(savedObjs, portalShared.getColor, portalShared.getOpacity);
            break;
        // no default
    }
    return sxObj;
}

// ---------------------------------------------------------------------------------------------------------------------
// STANDARD TEXT FIELD
// ---------------------------------------------------------------------------------------------------------------------

function sxStandardTextField(savedObjs, getColor, getOpacity) {
    let obj = savedObjs.customTemplateObj;

    let sxObj = {
        input: {
            "&::placeholder": {
                color: getColor(obj.placeHolder),
                opacity: getOpacity(obj.placeHolder),

            },
        },
        "& .MuiInput-root": {
            color: getColor(obj.unselectedText), // unselected text color *
            opacity: getOpacity(obj.unselectedText),
            // Bottom border
            "&:before": {
                borderColor: getColor(obj.unselectedBorder), // unselected border
                opacity: getOpacity(obj.unselectedBorder),
                borderWidth: obj.borderWidth
            },
            "&.Mui-focused": {
                color: getColor(obj.selectedText),     // focused text color *
                opacity: getOpacity(obj.selectedText), // focused text opacity *
                backgroundColor: portalShared.convertColorObjToRgba(obj.selectedBackground), // focused background color/opacity (convert to rgba() to include opacity) *
            },
            "&:hover:not(.Mui-focused)": {
                color: getColor(obj.hoverText),     // hovered text color *
                opacity: getOpacity(obj.hoverText), // hovered text opacity *
                //backgroundColor: portalShared.convertColorObjToRgba(obj.hoverBackground), // hovered background color/opacity (convert to rgba() to include opacity) *
            },
            // Border on focus
            "&:after": {
                borderColor: getColor(obj.selectedBorder), // selected border
                opacity: getOpacity(obj.selectedBorder),
                borderWidth: obj.selectedBorderWidth
            },
            ":hover:not(.Mui-focused)": {
                "&:before": {
                    borderColor: getColor(obj.hoverBorder), // hover border
                    opacity: getOpacity(obj.hoverBorder),
                    borderWidth: obj.hoverBorderWidth
                },
            },
        },
        // Label
        "& .MuiInputLabel-standard": {
            color: getColor(obj.unselectedFloatingLabel), // unselected floating label
            opacity: getOpacity(obj.unselectedFloatingLabel),
            "&.Mui-focused": {
                color: getColor(obj.selectedFloatingLabel), // selected floating label
                opacity: getOpacity(obj.selectedFloatingLabel)
            },
        },
        "&:hover label": {
            color: getColor(obj.hoverFloatingLabel), // selected floating label
            opacity: getOpacity(obj.hoverFloatingLabel)
        }
    };

    return sxObj;
}


// ---------------------------------------------------------------------------------------------------------------------
// OUTLINED TEXT FIELD
// ---------------------------------------------------------------------------------------------------------------------

function sxOutlinedTextField(savedObjs, getColor, getOpacity) {

    let obj = savedObjs.customTemplateObj;

    let sxObj = {
        input: {
            "&::placeholder": {
                color: getColor(obj.placeHolder),
                opacity: getOpacity(obj.placeHolder),

            },
        },
        "& .MuiOutlinedInput-root": {
            color: getColor(obj.unselectedText),        // unselected text color *
            opacity: getOpacity(obj.unselectedText),    // unselected text opacity *
            backgroundColor: portalShared.convertColorObjToRgba(obj.unselectedBackground), // unselected background color/opacity (convert to rgba() to include opacity) *
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: getColor(obj.unselectedBorder), // unselected border color *
                opacity: getOpacity(obj.unselectedBorder),   // unselected border opacity *
                borderWidth: obj.borderWidth,                // unselected border width *
            },
            "&.Mui-focused": {
                color: getColor(obj.selectedText),     // focused text color *
                opacity: getOpacity(obj.selectedText), // focused text opacity *
                backgroundColor: portalShared.convertColorObjToRgba(obj.selectedBackground), // focused background color/opacity (convert to rgba() to include opacity) *
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: getColor(obj.selectedBorder), // focused border color *
                    opacity: getOpacity(obj.selectedBorder),   // focused border opacity *
                    borderWidth: obj.selectedBorderWidth,      // focused border width *
                },
            },
            "&:hover:not(.Mui-focused)": {
                color: getColor(obj.hoverText),     // hovered text color *
                opacity: getOpacity(obj.hoverText), // hovered text opacity *
                backgroundColor: portalShared.convertColorObjToRgba(obj.hoverBackground), // hovered background color/opacity (convert to rgba() to include opacity) *
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: getColor(obj.hoverBorder), // hovered border color *
                    opacity: getOpacity(obj.hoverBorder),   // hovered border opacity *
                    borderWidth: obj.hoverBorderWidth,      // hovered border width *
                },
            },
        },
        "& .MuiInputLabel-outlined": {
            color: getColor(obj.unselectedFloatingLabel),     // unselected floating label color *
            opacity: getOpacity(obj.unselectedFloatingLabel), // unselected floating label opacity *
            "&.Mui-focused": {
                color: getColor(obj.selectedFloatingLabel),  // selected floating label color *
                opacity: getOpacity(obj.selectedFloatingLabel),       // selected floating label opacity *
            },
        },
        "&:hover label": {
            color: getColor(obj.hoverFloatingLabel), // selected floating label
            opacity: getOpacity(obj.hoverFloatingLabel)
        }
    };

    return sxObj;
}

// ---------------------------------------------------------------------------------------------------------------------
// FILLED TEXT FIELD
// ---------------------------------------------------------------------------------------------------------------------

function sxFilledTextField(savedObjs, getColor, getOpacity) {

    let obj = savedObjs.customTemplateObj;

    let sxObj = {
        input: {
            "&::placeholder": {
                color: getColor(obj.placeHolder),
                opacity: getOpacity(obj.placeHolder),
            },
        },
        "& .MuiFilledInput-root": {
            color: getColor(obj.unselectedText), // unselected text
            opacity: getOpacity(obj.unselectedText),
            "&:before": {
                borderColor: getColor(obj.unselectedBorder), // unselected border
                opacity: getOpacity(obj.unselectedBorder),
                borderWidth: obj.borderWidth
            },
            "&.Mui-focused": {
                color: getColor(obj.selectedText),     // focused text color *
                opacity: getOpacity(obj.selectedText), // focused text opacity *
                backgroundColor: portalShared.convertColorObjToRgba(obj.selectedBackground), // focused background color/opacity (convert to rgba() to include opacity) *
                // "& .MuiOutlinedInput-notchedOutline": {
                //     borderColor: getColor(obj.selectedBorder), // focused border color *
                //     opacity: getOpacity(obj.selectedBorder),   // focused border opacity *
                //     borderWidth: obj.selectedBorderWidth,      // focused border width *
                // },
            },
            ":hover:not(.Mui-focused)": {
                color: getColor(obj.hoverText), // hover text color
                opacity: getOpacity(obj.hoverText), // hover text opacity
                "&:before": {
                    borderColor: getColor(obj.hoverBorder),
                    borderWidth: getOpacity(obj.hoverBorder),
                },
                // "&:before": {
                //     color: "red",
                //     borderColor: getColor(obj.hoverBorder), // hover border
                //     opacity:  getOpacity(obj.hoverBorder),
                //     borderWidth: obj.hoverBorderWidth
                //
                // },
            },
            // Border on focus
            "&:after": {
                borderColor: getColor(obj.selectedBorder), // selected border
                opacity: getOpacity(obj.selectedBorder),
                borderWidth: obj.selectedBorderWidth
            },

        },
        "& .MuiInputLabel-filled": {
            color: getColor(obj.unselectedFloatingLabel),  // unselected/selected floating label
            opacity: getOpacity(obj.unselectedFloatingLabel),
            "&.Mui-focused": {
                color: getColor(obj.selectedFloatingLabel), // selected floating label
                opacity: getOpacity(obj.selectedFloatingLabel),
            }
        },
        "&:hover label": {
            color: getColor(obj.hoverFloatingLabel), // selected floating label
            opacity: getOpacity(obj.hoverFloatingLabel)
        }
    };

    return sxObj;
}

// placeHolder: {text: "", ...customColorObject},
//
// unselectedFloatingLabel: {text: "", ...customColorObject},
// selectedFloatingLabel: {...customColorObject},
// hoverFloatingLabel: {...customColorObject},
//
// unselectedText: {...customColorObject},
// selectedText: {...customColorObject},
// hoverText: {...customColorObject},
//
// unselectedBorder: {...customColorObject},
// selectedBorder: {...customColorObject},
// hoverBorder: {...customColorObject},
//
// unselectedBackground: {...customColorObject},
// selectedBackground: {...customColorObject},
// hoverBackground: {...customColorObject},
//
// borderWidth: "1px",
//     hoverBorderWidth: "2px",
//     selectedBorderWidth: "2px",
import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Typography from "@mui/material/Typography";
import {
    initializeGroupEditorCreate,
    initializeGroupEditorEdit,
    updateGroupTemplate,
    clearGroupEditor,
    validateGroupNameAndQualifierUnique,
    setGroupLikertMode,
    addGroupQuestion
} from "../../../actions/groupEditorActions";
import {
    getQuestionTemplate,
    createQuestionTemplate,
    saveQuestionTemplate
} from "../../../actions/questionTemplateActions";
import ActionEditor from "../actions/ActionEditor";
import {getGroupTemplate} from "../../../actions/groupTemplateActions";
import {setShowGroup} from '../../../actions/answerEditorActions'
import {createActionTemplate} from "../../../actions/actionTemplateActions";
import QuestionEditor from "../questions/QuestionEditor";
import QuestionViewerExpandable from "../questions/QuestionViewerExpandable";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import ArgoMetadataEditor from "../../common/ArgoMetadataEditor";
import ArgoSelectExisting from "../../common/ArgoSelectExisting";
import ArgoTextEntryWithCustomOverride from "../../common/ArgoTextEntryWithCustomOverride";
import ArgoLikertAnswerGenerator from "../../common/ArgoLikertAnswerGenerator";
import ArgoReviewTemplate from "../../common/ArgoReviewTemplate";
import ArgoCheckboxHook from "../../common/ArgoCheckboxHook";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoRadioGroupHook from "../../common/ArgoRadioGroupHook";
import * as validate from "../../../utilities/validate";
import * as expander from "../../../utilities/expander";
import * as metadata from "../../../utilities/metadata";
import * as metadataTemplateTypes from "../../../constants/metadataTemplateTypes";
import * as questionTypes from "../../../constants/questionTypeConstants";
import * as groupType from "../../../constants/groupTypeLikertOrDefault";
import * as icon from "../../../constants/iconConstants";
import * as TEMPLATE from "../../../constants/templateConstants";
import * as portalShared from "../../../portal/shared/metadataConstantsAndUtilities"
import ArgoCustomLikertInputStyling from "../../common/ArgoCustomLikertInputStyling";


const QUESTION_DEPTH = 0;

class GroupEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expander: {},
            expandAll: true,
            isThisAnExistingQuestion: "0",
            isLikert: false,
            likertIndentPixels: "40",
            questionTemplateSearch: "",
            questionDetail: null,
            openQuestionReview: false,
            selectedQuestionInfoKey: "",
            nameErrorText: " ",
            answerErrorText: " ",
            metadataErrorText: "",
            nameQualifierErrorText: " ",
            descErrorText: " ",
            descriptionErrorText: " ",
            answersError: null,
            isLoading: false,
            isEditCreate: false,
            toolbarErrorText: "",
            questionList: [],
            editQuestionKey: null,
            likertErrorText: null,
            nameDisplay: {...metadata.defaultCustomMetadataObj},
            descDisplay: {...metadata.defaultCustomMetadataObj},
            questionRenderedArray: [],
            allAccordionsExpanded: false,
            questionsLoaded: true,
            showActionEditor: false,
            showGroupEditor: false,
            headerInitializer: "",
            openQuestionEditor: false,
            questionTemplate: {},
            metadataSet: false,
            initialScroll: false
        };
    }

    componentDidMount() {

        let page = document.getElementById("GroupEditor-height-div");
        if (page) {
            page.scrollIntoView(false);
        }

        if (this.props.templateKey || this.props.templateId) {

            let groupTemplate = this.props.groupTemplate[this.props.templateKey];

            if (groupTemplate && (!this.props.templateId || (groupTemplate.id === this.props.templateId))) {
                this.setState({isLikert: (groupTemplate.questionGroupType === "LIKERT")});
                this.props.initializeGroupEditorEdit(this.props.groupTemplate[this.props.templateKey]);

                this.pageInit(this.props);
            }
            else if (this.props.templateKey) {
                this.setState({isLoading: true});
                this.props.getGroupTemplate(this.props.templateKey, this.props.templateId);
            }
        }
        else {
            this.props.initializeGroupEditorCreate(this.props.createInitialTemplate);
        }

        this.props.groupExpanderSetStateCallback({openGroupEditorComplete: true});
    }

    componentWillReceiveProps(nextProps) {

        if (!this.state.initialScroll) {
            let scrollTo = (this.props.scrollTo === "") ? "GroupEditor-height-div" : this.props.scrollTo;
            let page = document.getElementById(scrollTo);
            if (page) {
                page.scrollIntoView(false);
                this.setState({initialScroll: true});
                return;
            }
        }

        // let tempExpanderObj = {};
        // for (let i=0; i<nextProps.questionKeys.length; i++) {
        //     tempExpanderObj[nextProps.questionKeys[i]] = {open: false, viewerExpandableObj: {}};
        // }

        let groupTemplate = this.props.groupTemplate[this.props.templateKey];

        let tempIsLikert = {isLikert: this.state.isLikert};
        if (groupTemplate) {
            tempIsLikert = {isLikert: (groupTemplate.questionGroupType === "LIKERT")};
        }

        if (!this.state.metadataSet) {
            this.setState(Object.assign({expander: expander.presetExpander(nextProps.questionKeys, this.state.expander)}, tempIsLikert));
        }

        // if templateOriginal is loaded and the templateKey matches the current templateKey and is different from the loaded template (check id here?)
        let isTemplateLoaded = (nextProps.templateOriginal && nextProps.templateOriginal.templateKey === nextProps.templateKey
            && nextProps.template.templateKey !== nextProps.templateKey);

        // The loaded template is stale and the correct template is loaded
        let editTemplateReady = nextProps.template.id !== nextProps.templateId &&
            (nextProps.groupTemplate[nextProps.templateKey] && nextProps.groupTemplate[nextProps.templateKey].id === nextProps.templateId);


        if (isTemplateLoaded && editTemplateReady) {
            this.setState({isLoading: false});
            nextProps.initializeGroupEditorEdit(nextProps.templateOriginal);

            this.pageInit(nextProps);

        }

        //expander.initializeExpander(this.state.expander, nextProps.template.questionKeys, this.setStateCallback);

        nextProps.questionKeys && nextProps.questionKeys.map((templateKey, index) => {
            if (!nextProps.questionTemplate[templateKey]) {
                nextProps.getQuestionTemplate(templateKey, null, true, index);
            }

            return false;
        });

        let questionList = [];
        for (let i = 0; i < nextProps.questionTemplateList.length; i++) {
            if (nextProps.questionKeys.indexOf(nextProps.questionTemplateList[i].templateKey) === -1) {
                questionList.push(nextProps.questionTemplateList[i]);  // CLONE this.state.questionList minus selected question
            }
        }

        if (!this.state.metadataSet) {
            this.setState({questionList: questionList});  // If there was a row removed then setState with new array of questions
        }

        this.checkForErrorsOnScreen(nextProps);
    }

    componentWillUnmount() {
        this.props.clearGroupEditor();
    }

    // Allow child components to set errors on this page.
    setStateCallback = (stateObj, saveQuestionAfter = false) => {

        if (saveQuestionAfter) {
            this.setState(stateObj, () => this.questionEditorSave());
        } else {
            this.setState(stateObj);
        }
    }

    pageInit = (currProps) => {

        let metadataObj = currProps.templateOriginal.metadata;  // Get metadata from original template to init page

        let tempNameDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_NAME);

        let tempDescDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_DESC);

        this.setState({
            nameDisplay: metadata.setCustomMetadataObj(tempNameDisplayObj),
            descDisplay: metadata.setCustomMetadataObj(tempDescDisplayObj),
            // likertTitlesDisplay: metadata.setCustomMetadataObj(tempLikertTitlesDisplayObj),
            // likertResponsiveLabelsDisplay: metadata.setCustomMetadataObj(tempLikertResponsiveLabelDisplayObj),
        });
    };

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "CANCEL":
                this.props.cancel();
                break;
            case "DONE":
                // this.props.cancel();
                break;
            case "SAVE":
                this.saveGroup();
                break;
            case "BACK":
                // Used by info icon button on answer accordion
                this.setState({openQuestionReview: false});
                break;
            // no default
        }
    }

    onTextChange = (event, fieldName) => {
        let value = event.target.value;

        if ((fieldName === "nameQualifier") && (value==="")) {
            value = null;
        }

        this.handleInputChangeAndUpdateTemplate(event, fieldName, value);
    };

    onTextChangeLikertIndentPixels = (event) => {
        let value = event.target.value;

        this.setState({likertIndentPixels: value});

        let updatedTemplate = portalShared.cloneObj(this.props.template);
        let customGroupObj = JSON.parse(updatedTemplate.metadata[portalShared.CUSTOM_DDO_GRP_FORMAT]);
        customGroupObj.likertObj.indent = value + "px";
        updatedTemplate.metadata[portalShared.CUSTOM_DDO_GRP_FORMAT] = JSON.stringify(customGroupObj);

        this.props.updateGroupTemplate(updatedTemplate);
    };

    onSelectChange = (event, fieldName) => {

        let selected = event.target.value;

        let tempLikertMode = "";
        let tempLikertAnswerTextDisplay = "";
        //let tempLikertResponsiveLabels = "";

        switch (fieldName) {
            case "questionType":
                this.handleInputChangeAndUpdateTemplate(event, fieldName, selected);
                break;
            case "reportingCategory":
                let index = event.target.value;
                selected = this.state.reportingCategories[index];  // Note: "None: has been added to thee metadata list this.state.reportingCategories, so metadata needs to adjust

                this.setState({[fieldName]: index}, () => metadata.setCustomMetadata("ZZZZZA", this.props.template, this.props.updateQuestionTemplate, this.setStateCallback,"keyMetricCategory", selected, portalShared.KEY_METRIC_CATEGORY_QUESTION, selected, QUESTION_DEPTH));
                break;
            case "likertAddAnswerTemplate":
                this.setState({[fieldName]: selected, likertAddAnswerTemplateFormat: "radios", likertMode: icon.LIKERT_ICON_MODE_DEFAULT, likertAnswerTextDisplay: icon.LIKERT_TITLES_ALL, likertResponsiveLabels: icon.LIKERT_ICON_LABELS_NO_TEXT});
                break;

            case "likertAddAnswerTemplateFormat":

                if (selected === "stars" || selected === "facesA" || selected === "facesD") {
                    tempLikertMode = icon.LIKERT_ICON_MODE_NO_FILL;
                } else {
                    tempLikertMode = icon.LIKERT_ICON_MODE_DEFAULT;
                }

                if (selected === "radios" || selected === "thumbs") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;

                } else if (selected === "stars") {

                    if (this.state.likertAddAnswerTemplate === "4" || this.state.likertAddAnswerTemplate === "6") {
                        tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;
                    } else {
                        tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;
                    }

                } else if (selected === "facesA" || selected === "facesD") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;

                    // } else if (selected === "circles" && this.state.likertAddAnswerTemplate === "11" && (this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS || this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS_ALT)) {
                    //
                    //     tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_HIDDEN;

                } else if (selected === "circles" || selected === "squares") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_EMBEDDED;

                } else {
                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_HIDDEN;
                }

                this.setState({[fieldName]: selected, likertMode: tempLikertMode, likertAnswerTextDisplay: tempLikertAnswerTextDisplay, likertResponsiveLabels: icon.LIKERT_ICON_LABELS_NO_TEXT});
                break;

            case "likertMode":

                if (this.state.likertAddAnswerTemplateFormat === "radios" || this.state.likertAddAnswerTemplateFormat === "thumbs") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;

                } else if (this.state.likertAddAnswerTemplateFormat === "stars") {

                    if (this.state.likertAddAnswerTemplate === "4" || this.state.likertAddAnswerTemplate === "6") {
                        tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;
                    } else {
                        tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;
                    }

                } else if (this.state.likertAddAnswerTemplateFormat === "facesA" || this.state.likertAddAnswerTemplateFormat === "facesD") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;

                    // } else if (this.state.likertAddAnswerTemplateFormat === "circles" && this.state.likertAddAnswerTemplate === "11" && (selected === icon.LIKERT_ICON_MODE_COLORS || selected === icon.LIKERT_ICON_MODE_COLORS_ALT)) {
                    //
                    //     tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_HIDDEN;

                } else if (this.state.likertAddAnswerTemplateFormat === "circles" || this.state.likertAddAnswerTemplateFormat === "squares") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_EMBEDDED;

                } else {
                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_HIDDEN;
                }

                this.setState({[fieldName]: selected, likertAnswerTextDisplay: tempLikertAnswerTextDisplay, likertResponsiveLabels: icon.LIKERT_ICON_LABELS_NO_TEXT});
                break;

            case "likertAnswerTextDisplay":
                this.setState({[fieldName]: selected, likertResponsiveLabels: icon.LIKERT_ICON_LABELS_NO_TEXT});
                break;

            default:
                this.setState({[fieldName]: selected});
        }
    };


    handleInputChangeAndUpdateTemplate = (event, fieldName, value) => {

        let updatedTemplate = {...this.props.template};
        updatedTemplate[fieldName] = value;

        this.props.updateGroupTemplate(updatedTemplate);

        if (fieldName === "name" || fieldName === "nameQualifier")
        {
            this.props.validateGroupNameAndQualifierUnique(updatedTemplate.name, updatedTemplate.nameQualifier);
        }
    };


    pickQuestion = (questionTemplateKey) => {
        // Make sure question is not already added to group
        if (!this.props.questionKeys.includes(questionTemplateKey.templateKey)) {
            this.setState({questionTemplateSearch: ""});
            this.props.addGroupQuestion(questionTemplateKey.templateKey, this.props.groupTemplate);
        }

        this.setState({questionTemplateSearch: ""});
    };


    questionRenderedFunction = () => {
        let questionRenderedArray = [...this.state.questionRendered]
        questionRenderedArray.push(true);
        this.setState({questionRenderedArray: questionRenderedArray})
    }


    handleMetadataChange = (metadata) => {
        let updatedTemplate = {...this.props.template};
        updatedTemplate.metadata = metadata;
        this.props.updateGroupTemplate(updatedTemplate);
    };


    checkForErrorsOnScreen = (props) => {

        // Group editor drawer can have 5 types of errors.
        // name is blank or duplicate name/nameQualifier
        // Likert group type requires answers and there are none
        // note: answers can have blank optionText or metadata with no key,  The answer editor will do those checks and use setStateCallback to set error on this drawer
        // note: answer editor will also display error text on the answers with blank optionText or metatdata with bank keys
        // metadata key is blank
        // note: metadata editor will display an error message on metadata entry with the missing key

        let nameErrorText = validate.templateNameAndQualifierUnique(props, "Group Name");
        let metadataErrorText = this.state.metadataErrorText;
        let toolbarErrorText = "";

        if (nameErrorText !== " ") {
            toolbarErrorText = nameErrorText;
        }

        if (metadataErrorText !== "") {
            toolbarErrorText = toolbarErrorText === "" ? this.state.metadataErrorText : toolbarErrorText + ",  " + this.state.metadataErrorText;
        }

        if (this.state.isLikert) {
            // Npte: Specific answwer errors ie.. missing answer optionText or answer metadata errors are checked by answerEditor
            // Note: Requres a callback to answerEditor to disable review button and set toolbar error message
            if (props.template.answerOptionTemplates.length === 0) {
                let likertErrorText = "Likert groups require Answers and at least one Question!"
                toolbarErrorText = toolbarErrorText === "" ? likertErrorText : toolbarErrorText + ",  " + likertErrorText;  // Specific error messsage for toolbar
            }
        }

        // If noc-specific errors on page then use defualt error message for toolbar.
        // if (toolbarSaveButtonDisabled && toolbarErrorText === "") {
        //     toolbarErrorText = "Errors on page!"
        // }

        this.setState({nameErrorText: nameErrorText, metadataErrorText: metadataErrorText, toolbarErrorText: toolbarErrorText})
    };

    setMetadataErrorText = (metadataErrorText) => {
        // NOTE: setState callback is not working, state is NOT being set before call, so added fix
        this.setState({metadataErrorText: metadataErrorText, metadataSet: false}, () => this.checkForErrorsOnScreen(this.props));
    }

    metadataChanged = (updatedTemplate, depth) => {
        // Note: Timing issues was causing componentWillReceiveProps to trigger and a setState was canceling another setState
        this.setState({metadataSet: true}, () => this.props.updateGroupTemplate(updatedTemplate, depth));
    }

    // ZZZZZ
    // validateAnswers = (answerOptionTemplates) => {
    //     for (let answerOptionTemplate of answerOptionTemplates) {
    //         // Every answer option must have a name, but cannot exceed 255 characters.
    //         if (answerOptionTemplate.optionText.length === 0 || answerOptionTemplate.optionText.length > 255) {
    //             // Fail validation
    //             return false;
    //         }
    //     }
    //     return true;
    // };

    openQuestionDetail = (templateKey) => {
        this.setState({questionDetail: templateKey});
    };

    closeQuestionDetail = () => {
        this.setState({questionDetail: null});
    };

    editQuestion = (index) => {
        let questionKey = this.props.questionKeys[index];
        let newState = {
            isEditCreate: true,
            editQuestionKey: questionKey
        };

        this.setState(newState);
    };

    saveGroup = () => {

        let template = {
            ...this.props.template,
            questionKeys: [...this.props.questionKeys]
        };

        // If edit is being called from group expander, after CategoryEditor state is set it calls saveGroup
        if (this.props.groupExpanderEdit) {
            this.props.groupExpanderSetStateCallback({groupTemplate: template}, true, TEMPLATE.TYPE.GROUP);
            return;
        }

        this.props.setShowGroup(true, this.props.answerIndex);
        this.props.save(template);

        // Fix for the enter key propagating to the next focusable element
        //e.preventDefault();
    };

    saveNewQuestion = (template) => {
        if (this.state.editQuestionKey) {
            // Update existing question
            this.props.saveQuestionTemplate(template);
        } else {
            // Create new question
            this.props.createQuestionTemplate(template, QUESTION_DEPTH)
        }

        this.setState({isThisAnExistingQuestion: "0", questionTemplateSearch: "", editQuestionKey: null});
    };

    closeNewQuestion = () => {
        this.setState({isThisAnExistingQuestion: "0", questionTemplateSearch: "", editQuestionKey: null});

        // Fix for the enter key propagating to the next focusable element
        //e.preventDefault();
    };

    toggleIsThisAnExistingQuestion = (event) => {
        let value = event.target.value;
        let newState = {
            isThisAnExistingQuestion: value,
            questionTemplateSearch: (value)? this.state.questionTemplateSearch: ""
        };

        this.setState(newState);
    };

    onCheckboxChange = (event) => {

        let isChecked = event.target.checked;

        this.setState({isLikert: isChecked}, () => this.props.setGroupLikertMode(this.props.template, isChecked));
    };

    checkIsLikert = () => {

        if (this.props.templateOriginal !== undefined) {
            if (this.props.templateOriginal.hasOwnProperty("questionGroupType")) {
                return this.props.template.questionGroupType === groupType.GROUP_TYPE_LIKERT; // LIKERT or DEFAULT
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    answerErrorCallback = () => {
        // // ZZZZZ TODO capture answer errors
    }

    addSelectedCategory = (categoryTemplate) => {
        if (!this.props.template.categoryKeys.includes(categoryTemplate.templateKey)) {
            this.setState({categoryTemplateSearch: ""});
            this.props.addQuestionnaireCategory(categoryTemplate.templateKey);
            this.props.getCategoryTemplate(categoryTemplate.templateKey);
        }
    };

    // -----------------------------------------------------------------------------------------------------------------
    // ACTION EDITORS --------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    saveAction = (template) => {
        template.answerIndex = this.state.answerIndex;
        this.props.createActionTemplate(template);
        this.setState({showActionEditor: false, answerIndex: null, headerInitializer: ""});
    };

    closeActionEditor = () => {
        this.setState({showActionEditor: false, answerIndex: null, headerInitializer: ""});
    };

    actionEditor = () => {
        let template = {
            name: this.state.headerInitializer
        };

        return (
            <div className="layout vertical" style={{height: "100%"}}>
                <div className="layout vertical" style={{height: "100%"}}>
                    <ActionEditor
                        save={this.saveAction}
                        cancel={this.closeActionEditor}
                        createInitialTemplate={template}
                        showCreateToggle={this.isCreateActionSelector}
                        width={this.props.width}
                    />
                </div>
            </div>
        );
    };

    // -----------------------------------------------------------------------------------------------------------------
    // ANSWER SHOW GROUP FUNCTIONS -------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    closeShowGroupEditor = () => {
        this.setState({showGroupEditor: false});
    };

    saveShowGroup = (groupTemplate) => {
        this.props.createGroupTemplate(groupTemplate);
        this.setState({showGroupEditor: false});
    };

    groupEditor = () => {

        return (
            <div className="layout vertical" style={{height: "100%"}}>
                <div className="layout vertical" style={{height: "100%"}}>
                    <GroupEditor
                        createMode={true}
                        save={this.saveShowGroup}
                        cancel={this.closeShowGroupEditor}
                        groupAddShowGroupMode={true}
                        templateMode={"Create Group"}
                        scrollTo="GroupEditor-container-outer-div"
                        //createInitialTemplate={template}
                    />
                </div>
            </div>
        );
    };

    // -----------------------------------------------------------------------------------------------------------------
    // QUESTION EDITOR FUNCTIONS ------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    questionEditorSave = () => {
        this.props.saveQuestionTemplate(this.state.questionTemplate);
        this.setState({openQuestionEditor: false});
    };

    questionEditorCancel = () => {
        this.setState({openQuestionEditor: false});
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {

        if (this.state.isLoading) {
            //TODO place a loading screen here
            return null;
        }

        if (this.state.showActionEditor) {
            return this.actionEditor();
        }

        if (this.state.showGroupEditor) {
            return this.groupEditor();
        }

        if (this.state.openQuestionReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.QUESTION}
                templateKey={this.state.selectedQuestionInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        if (this.state.isThisAnExistingQuestion === "1") {
            let template = {
                name: this.state.questionTemplateSearch
            };

            if (this.state.isLikert) {
                // Lock down the Question type to Likert. This is used when creating a Likert Question Group.
                // We shouldn't have any type of question other than Likert in the Group.
                template.questionType = questionTypes.LIKERT_RADIO
            }

            return <QuestionEditor title="Create Question"
                                   createMode={true}
                                   save={this.saveNewQuestion}
                                   cancel={this.closeNewQuestion}
                                   showCreateToggle={this.isEditCreateSelector}
                                   isLikert={this.state.isLikert}
                                   createInitialTemplate={template}
                                   questionDepth={QUESTION_DEPTH}
                                   templateKey={this.state.editQuestionKey}
                                   width={this.props.width}
            />;
        }

        // Edit from question expander
        if (this.state.openQuestionEditor) {

            return <QuestionEditor title="Edit Question"
                                   save={this.questionEditorSave}
                                   cancel={this.questionEditorCancel}
                                   templateKey={this.state.questionTemplate.templateKey}
                                   templateId={this.state.questionTemplate.id}
                                   filter={this.props.filter}
                                   templateMode={TEMPLATE.MODE.CURRENT}
                                   questionDepth={QUESTION_DEPTH}
                                   questionExpanderEdit={true}
                                   questionExpanderSetStateCallback={this.setStateCallback}
                                   width={this.props.width}
                                   scrollTo="GroupEditor-container-outer-div"
            />;
        }

        let propsObj = {
            fileName: "GroupEditor",
            onTextChange: this.onTextChange,
            templateType: TEMPLATE.TYPE.GROUP,
            template: this.props.template,
            answerOptionTemplates: this.props.answerOptionTemplates,
            templateUpdateFunction: this.props.updateGroupTemplate,
            setStateCallbackFunction: this.setStateCallback,
            setCustomMetadataFunction: metadata.setCustomMetadata,
            depth: this.props.depth
        }

        let groupTemplateClone = portalShared.cloneObj(this.props.template);

        groupTemplateClone.questionKeys = this.props.questionKeys; // Pick up question changes for PortalPreview

        let likertQuestionIndent = "";
        if (this.props.template.hasOwnProperty("metadata")) {
            if (this.props.template.metadata.hasOwnProperty(portalShared.CUSTOM_DDO_GRP_FORMAT)) {
                let customGroupObj = JSON.parse(this.props.template.metadata[portalShared.CUSTOM_DDO_GRP_FORMAT]);
                // LEGACY FIX
                if (customGroupObj.likertObj.indent === 0) {
                    likertQuestionIndent = "40"
                } else {
                    likertQuestionIndent = customGroupObj.likertObj.indent.replace("px","");
                }
            }
        }

        return (
            this.props.template ?
                <div id="GroupEditor-container-outer-div" style={{height: "100%"}}>

                    <div id="GroupEditor-ArgoToolbarHook-container-div">
                        <ArgoToolbarHook
                            fileName="GroupEditor"
                            fieldName="save-or-cancel"
                            toolbarErrorText={this.state.toolbarErrorText}
                            width={this.props.width}
                            onClick={this.handleToolbarButtonClick}
                        />
                    </div>

                    <div id="GroupEditor-height-div" style={{height: "50px"}}></div>

                    <div id="GroupEditor-page-div" style={{height: "100%", paddingRight: "40px"}}>

                        <div id="GroupEditor-container-inner-div" style={{flexGrow: "2", paddingLeft: "32px"}}>

                            <div>
                                {this.props.title === "" ? "" :
                                    <div>
                                        <div>&nbsp;</div>
                                        <Typography style={{fontWeight: "bold"}}>{this.props.title}</Typography>
                                    </div>
                                }
                            </div>


                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                                <ArgoCheckboxHook
                                    fileName="GroupEditor"
                                    fieldName="isLikert"
                                    label="Group is Likert"
                                    onChange={this.onCheckboxChange}
                                    checked={this.state.isLikert}
                                    disabled={!this.props.createMode || (this.props.questionKeys?.length > 0) || (this.props.answerOptionTemplates?.length > 0)}
                                    width="250px"
                                    paddingTop="20px"
                                />

                                {this.state.isLikert ?
                                    <div style={{display: "flex", paddingTop: "25px"}}>
                                        <ArgoTextFieldHook
                                            fileName="GroupEditor"
                                            fieldName="likertIndentPixels"
                                            label="Question Indent in Pixels"
                                            value={likertQuestionIndent}
                                            type="number"
                                            onChange={this.onTextChangeLikertIndentPixels}
                                            width="140px"
                                        />
                                    </div> : ""
                                }

                            </div>

                            <div style={{color: 'red', fontSize: 'small'}}>{this.state.likertErrorText}</div>

                            {/*{this.props.showCreateToggle && this.props.showCreateToggle()}*/}

                            <div>&nbsp;</div>

                            <ArgoTextEntryWithCustomOverride
                                templateType={TEMPLATE.TYPE.GROUP}
                                textEntryType={TEMPLATE.TEXT_TYPE.GROUP.NAME}
                                fieldName="name"
                                fieldTextObjName="nameDisplay"
                                label="Group Name"
                                labelCustom="Display (Name)"
                                value={this.props.template.name}
                                maxlength={255}
                                errorText={this.state.nameErrorText}
                                initTo={this.state.nameDisplay.selected}
                                initToObj={this.state.nameDisplay.metadataValueObj}
                                propsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_NAME}, propsObj)}
                            />

                            <div id="GroupEditor-qualifier-container-div"
                                 style={{display: "flex", alignItems: "left", width: "100%"}}>

                                <ArgoTextFieldHook
                                    fileName="GroupEditor"
                                    fieldName="nameQualifier"
                                    label="Name Qualifier"
                                    value={this.props.template.nameQualifier}
                                    maxLength={255}
                                    multiline={true}
                                    onChange={this.onTextChange}
                                    width="96%"
                                    divContainerHeight="52px"
                                />

                                <div style={{width: "83px"}}>&nbsp;</div>

                            </div>

                            <div>&nbsp;</div>
                            <div>&nbsp;</div>

                            <ArgoTextEntryWithCustomOverride
                                templateType={TEMPLATE.TYPE.GROUP}
                                textEntryType={TEMPLATE.TEXT_TYPE.GROUP.DESC}
                                fieldName="description"
                                fieldTextObjName="descDisplay"
                                label="Description/Instructions"
                                labelCustom="Display (Description)"
                                value={this.props.template.description}
                                maxlength={255}
                                initTo={this.state.descDisplay.selected}
                                initToObj={this.state.descDisplay.metadataValueObj}
                                propsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_DESC}, propsObj)}
                            />

                            {/*// ===========================================================================================================*/}
                            {/*// METADATA EDITOR */}
                            {/*// ===========================================================================================================*/}

                            <div>&nbsp;</div>

                            <div id="GroupEditor-metadata-container-div" style={{width: "100%", paddingBottom: "40px"}}>
                                <ArgoMetadataEditor
                                    entityType={metadataTemplateTypes.GroupTemplate}
                                    template={this.props.template}
                                    //onChange={this.props.updateGroupTemplate}
                                    onChangeCallback={this.metadataChanged}
                                    metadata={this.props.template.metadata}
                                    errorText={this.state.metadataErrorText}
                                    setMetadataErrorText={this.setMetadataErrorText}
                                />
                            </div>

                            {/*// ===========================================================================================================*/}
                            {/*// LIKERT GROUP FORMATTING AND TITLES */}
                            {/*// AND */}
                            {/*// LIKERT MODE ANSWER GENERATOR */}
                            {/*// ===========================================================================================================*/}

                            {/*{this.answerOptionEditorLikert()}*/}

                            <ArgoLikertAnswerGenerator
                                groupTemplateMode={true}
                                fileName="GroupEditor"
                                componentMode="likert-answers"
                                templateType={TEMPLATE.TYPE.GROUP}
                                template={this.props.template}
                                isLikert={this.state.isLikert}
                                displayAsLikert={this.state.isLikert}
                                answerOptionTemplates={this.props.answerOptionTemplates}
                                answerErrorCallback={this.answerErrorCallback}
                                templateUpdateFunction={this.props.updateGroupTemplate}
                                setMetadataErrorText={this.setMetadataErrorText}
                                questionDepth={this.props.questionDepth}
                                setStateCallback={this.setStateCallback}
                                customMetadataKey={portalShared.CUSTOM_DDO_GRP_FORMAT}
                                propsObj={propsObj}
                            />

                            <div>&nbsp;</div>
                            <div>&nbsp;</div>

                            {/*// ===========================================================================================================*/}
                            {/*// LIKERT ANSWER CUSTOMIZATION */}
                            {/*// ===========================================================================================================*/}

                            {(this.state.isLikert) && (this.props.answerOptionTemplates.length > 0) ?
                                <ArgoCustomLikertInputStyling
                                    templateType={TEMPLATE.TYPE.GROUP}
                                    template={this.props.template}
                                    depth={this.props.questionDepth}
                                    customMetadataTag={portalShared.CUSTOM_DDO_GRP_FORMAT}
                                    templateUpdateFunction={this.props.updateGroupTemplate}
                                    inputType={this.props.answerOptionTemplates[0].metadata[icon.ICON_TYPE]}
                                />
                                : ""}

                            {/*// ===========================================================================================================*/}
                            {/*// ADD QUESTION TO GROUP */}
                            {/*// ===========================================================================================================*/}

                            <ArgoRadioGroupHook
                                fileName="GroupEditor"
                                fieldName="isThisAnExistingQuestion"
                                label={this.state.isLikert ? "Is this an existing likert question?" : "Is this an existing question?"}
                                value={this.state.isThisAnExistingQuestion}
                                onChange={this.toggleIsThisAnExistingQuestion}
                                checked={true}
                            />

                            <ArgoSelectExisting
                                mode={TEMPLATE.TYPE.QUESTION}
                                useTypeFilter={true}
                                likert={this.state.isLikert}
                                paddingRight="0px"
                                onAddTemplate={this.pickQuestion.bind(this)}
                            />

                            <div>&nbsp;</div>

                            {/*// ===========================================================================================================*/}
                            {/*// ASSIGNED QUESTIONS LIST */}
                            {/*// ===========================================================================================================*/}

                            <QuestionViewerExpandable
                                parentType={TEMPLATE.TYPE.GROUP}
                                parentTemplate={this.props.template}
                                questionKeys={this.props.questionKeys}
                                questionTemplate={this.props.questionTemplate}
                                setStateCallback={this.setStateCallback}
                                hidePortalPreview={this.state.isLikert}
                                isLikert={this.state.isLikert}
                            />

                            {/*// ===========================================================================================================*/}
                            {/*// PORTAL PREVIEW */}
                            {/*// ===========================================================================================================*/}

                            <div id="GroupEditor-space-above-portal-preview" style={{height: "20px"}}>&nbsp;</div>

                            <ArgoPortalPreview
                                calledBy="GroupEditor"
                                createMode={this.props.createMode}
                                templateType={TEMPLATE.TYPE.GROUP}
                                template={groupTemplateClone}
                            />

                        </div>
                    </div>

                </div> : <div id="GroupEditor-container-outer-div"/>
        );
    }
}

GroupEditor.defaultProps = {
    createMode: false,
    title: "",
    groupAddShowGroupMode: false,
    groupExpanderEdit: false,
    groupExpanderSetStateCallback: function () {},
    scrollTo: "",
    width: "100%"
};

GroupEditor.propTypes = {
    scrollTo: PropTypes.string,
    createMode: PropTypes.bool,
    title: PropTypes.string,
    save: PropTypes.func,
    cancel: PropTypes.func,
    templateId: PropTypes.number,
    templateKey: PropTypes.string,
    showCreateToggle: PropTypes.func,
    createInitialTemplate: PropTypes.object,
    groupAddShowGroupMode: PropTypes.bool,
    groupExpanderEdit: PropTypes.bool,
    groupExpanderSetStateCallback: PropTypes.func,
    width: PropTypes.string
};

function mapStateToProps(state, props) {

    // if (props.groupAddShowGroupMode) {
    //
    // }

    return {
        options: state.groupEditor.options,

        template: state.groupEditor.template,
        groupTemplate: state.groupTemplate,
        templateOriginal: state.groupTemplate[props.templateKey],

        questionKeys: state.groupEditor.questionKeys,
        answerOptionTemplates: state.groupEditor.template.answerOptionTemplates,
        questionTemplateList: state.questionTemplateList,
        questionTemplate: state.questionTemplate,


        metadataDefinition: state.metadataDefinition[metadataTemplateTypes.GroupTemplate],
        answerIndex: state.showGroup.answerIndex
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        initializeGroupEditorCreate,
        initializeGroupEditorEdit,
        setGroupLikertMode,
        addGroupQuestion,
        updateGroupTemplate,
        clearGroupEditor,
        getQuestionTemplate,
        getGroupTemplate,
        createQuestionTemplate,
        saveQuestionTemplate,
        validateGroupNameAndQualifierUnique,
        setShowGroup,
        createActionTemplate
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupEditor);

import * as TEMPLATE from "../../constants/templateConstants";
import * as questionTypes from "../../constants/questionTypeConstants";



// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// DEFAULT COLORS
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


// Survey Default Colors -------=---------------------------------------------------------------------------------------

export const COLOR_BLACK_INDEX = 0;
export const COLOR_BLACK_HEX = "#000000";

export const COLOR_WHITE_INDEX = 27;
export const COLOR_WHITE_HEX = "#FFFFFF";

export const DEFAULT_PLATINUM_HEX = "#E5E4E2"; // Platinum gray used for standard buttons background color

export const COLOR_GRAY_INDEX = 1;
export const COLOR_GRAY_HEX = "#808080";

export const COLOR_DARK_GRAY_INDEX = 2;
export const COLOR_DARK_GRAY_HEX = "#2F4F4F";




export const DEFAULT_UNSELECTED_COLOR = COLOR_GRAY_HEX;

export const COLOR_LIGHT_GRAY_INDEX = 1; // Used for background on UI sections like text input, and default portal system button hover
export const COLOR_LIGHT_GRAY_HEX = "#F8F8F8"; // Used for background on UI sections like text input, and default portal system button hover

export const COLOR_PRIMARY_BLUE_INDEX = 19;
export const COLOR_PRIMARY_BLUE_HEX = "#01579b";

export const COLOR_DODGER_BLUE_INDEX = 18; // dodger blue index from DDLB
export const COLOR_DODGER_BLUE_HEX = "#2497F3";

export const DEFAULT_TEXT_FIELD_PLACEHOLDER_COLOR = COLOR_GRAY_HEX;
export const DEFAULT_TEXT_FIELD_PLACEHOLDER_OPACITY = 1;

export const DEFAULT_ANSWER_ICON_COLOR_UNSELECTED = COLOR_GRAY_HEX;
export const DEFAULT_ANSWER_ICON_COLOR_SELECTED = COLOR_DODGER_BLUE_HEX;


// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// CONSTANTS
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const NON_BREAKING_SPACE = "\u00A0";
export const NON_BREAKING_SPACE2 = "\u00A0\u00A0";


export const OBJ_TYPE_COLOR = "color";
export const OBJ_TYPE_ICON = "icon";
export const OBJ_TYPE_TEXT = "text";
export const OBJ_TYPE_BUTTON = "button";
export const OBJ_TYPE_CUSTOM_QNR_APP_HEADER = "custom-qnr-app-header";
export const OBJ_TYPE_CUSTOM_QNR_START_PAGE = "custom-qnr-start-page";
export const OBJ_TYPE_CUSTOM_QNR_MAIN_PAGES = "custom-qnr-main-pages";
export const OBJ_TYPE_CUSTOM_QNR_CLOSE_PAGE = "custom-qnr-close-page";
export const OBJ_TYPE_CUSTOM_QNR_SYSTEM = "custom-qnr-system-buttons";
export const OBJ_TYPE_CUSTOM_QNR_ALERT_TEXT = "custom-qnr-alert-text";
export const OBJ_TYPE_CUSTOM_CAT = "custom-cat";
export const OBJ_TYPE_CUSTOM_GRP = "custom-grp"; // NO CUSTOM GROUP
export const OBJ_TYPE_CUSTOM_LIKERT = "custom-likert"; // Used on GROUP and QUESTION templates
export const OBJ_TYPE_CUSTOM_QUESTION_LIKERT = "custom-question-likert"; // Question likert properties needed there own metadata entry for space concerns
export const OBJ_TYPE_CUSTOM_QUE_RADIO = "custom-que-radio";
export const OBJ_TYPE_CUSTOM_QUE_CHECKBOX = "custom-que-checkbox";
export const OBJ_TYPE_CUSTOM_QUE_TEXT = "custom-que-text";
export const OBJ_TYPE_CUSTOM_QUE_ADDRESS_TEXT = "custom-que-address-text";
export const OBJ_TYPE_CUSTOM_QUE_ADDRESS = "custom-que-address";
export const OBJ_TYPE_CUSTOM_QUE_DATE_RANGE = "custom-que-date-range";


// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// CLONE Object
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export function cloneObj(obj) {
    return JSON.parse(JSON.stringify(obj));
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// COPY MODIFIED OBJECT INTO DEFAULT OBJECT (RECURSION !!!!!)
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export function setObj(defaultObj, currentObj) {

    if (defaultObj && currentObj) {
        for (var key in defaultObj) {
            // NOTE: NULL typeof is "object"
            if ((typeof defaultObj[key] === "object") && (defaultObj[key] !== null)) {
                if (currentObj.hasOwnProperty(key)) {
                    setObj(defaultObj[key], currentObj[key]); // RECURSION !!!!!
                }
            }
            else if (currentObj.hasOwnProperty(key)) {
                defaultObj[key] = currentObj[key];
            }
        }
    }
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// COPY MODIFIED OBJECT INTO DEFAULT OBJECT (RECURSION !!!!!)
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export function matchObj(defaultObj, currentObj) {

    if (defaultObj && currentObj) {
        for (var key in defaultObj) {
            // NOTE: NULL typeof is "object"
            if ((typeof defaultObj[key] === "object") && (defaultObj[key] !== null)) {
                if (currentObj.hasOwnProperty(key)) {
                    setObj(defaultObj[key], currentObj[key]); // RECURSION !!!!!
                }
                else {
                    currentObj[key] = cloneObj(defaultObj[key]); // Add the missing object property to currentObj
                }
            }
            else if (!currentObj.hasOwnProperty(key)) {
                currentObj[key] = defaultObj[key]; // Add property to curent obj
            }
        }
    }
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// GET Metadata Object/Value
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export function getMetadataObj(metadata, tag) {
    let metadataObj = null;
    if (metadata.hasOwnProperty(tag)) {
        metadataObj = JSON.parse(cloneObj(metadata[tag])); // Objects are stored stringified
    }
    return metadataObj;
}

export function getMetadataValue(metadata, tag) {
    let metadataValue = "";
    if (metadata.hasOwnProperty(tag)) {
        metadataValue = metadata[tag];
    }
    return metadataValue;
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// COLOR Object
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const customColorObject = {
    version: 1,
    objType: OBJ_TYPE_COLOR,
    colorType: "default", // default, select, hex or rgb
    colorIndex: COLOR_BLACK_INDEX,
    colorEntry: COLOR_BLACK_HEX,
    color: COLOR_BLACK_HEX,
    opacityEntry: "100",
    opacity: "100%"
};

export function setCustomColorObject(currentObj) {

    let colorObj = cloneObj(customColorObject);

    setObj(colorObj, currentObj);

    return colorObj;
}

export function setCustomColorObjectToFrom(toObj) {
    let tempFromObj = cloneObj(toObj);

    setObj(toObj, tempFromObj);

    return toObj;
}

// This works on colorObjs and iconObjs
export function getColor(obj) {

    let color = COLOR_WHITE_HEX;

    if (obj) {
        if (obj.hasOwnProperty("colorType")) {
            color = (obj.colorType !== "select") ? obj.colorEntry : obj.color; // depending on colory entry type decides where to get color from.
        }
    }
    return color;
}

export function getOpacity(obj) {
    let opacity = 1;

    if (obj) {
        if (obj.hasOwnProperty("opacityEntry")) {
            if (obj.opacityEntry !== "100") {
                opacity = parseFloat("." + obj.opacityEntry);
            }
        }
    }

    return opacity;
}

export function setColor(obj, index, color, colorType = "select") {
    obj.colorType = colorType;
    obj.colorIndex = index;
    obj.colorEntry = color;
    obj.color = color;
}

export function getWidth(widthEntry, widthType) {
    return widthEntry + (widthType === "percentage" ? "%" : "px");
}


// setColorObj = (selectedMode, savedObj) => {
//
//     let isDefault = (selectedMode === "default");
//
//     let colorObj = {};
//
//     colorObj.backgroundColor = isDefault ?  "" : savedObj.backgroundColor;
//     colorObj.colorType = isDefault ?  "default" : savedObj.colorType;
//     colorObj.colorIndex = isDefault ?  null : savedObj.colorIndex;
//     colorObj.colorEntry = isDefault ?  "black" : savedObj.colorEntry;
//     colorObj.color = isDefault ?  "black" : savedObj.color;
//     colorObj.opacity = isDefault ? "100%" : savedObj.opacity;
//     colorObj.opacityEntry = isDefault ? "100" : savedObj.opacityEntry;
//
//     return colorObj;
// }

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// ICON Object
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


//export const ICON_POSITION  = "icon-position";
// export const ICON_UNSELECTED_COLOR  = "icon-unselected-color";
// export const ICON_SELECTED_COLOR  = "icon-selected-color";
//
// //export const OVERRIDE_ICON_POSITION  = "display-def-icon-position";
//
// export const OVERRIDE_ICON_UNSELECTED_COLOR  = "display-def-icon-unselected-color"; // (Group/Question) level icon color override
// export const OVERRIDE_ICON_UNSELECTED_OPACITY  = "display-def-icon-unselected-opacity"; // (Group/Question) level icon opacity override
//
// export const OVERRIDE_ICON_UNSELECTED_BACKGROUND_COLOR  = "display-def-icon-unselected-background-color"; // (Group/Question) level icon color override
// export const OVERRIDE_ICON_UNSELECTED_BACKGROUND_OPACITY  = "display-def-icon-unselected-background-opacity"; // (Group/Question) level icon opacity override
//
// export const OVERRIDE_ICON_UNSELECTED_TEXT_COLOR  = "display-def-icon-unselected-text-color"; // (Group/Question) level icon color override
// export const OVERRIDE_ICON_UNSELECTED_TEXT_OPACITY  = "display-def-icon-unselected-text-opacity"; // (Group/Question) level icon opacity override
//
// export const OVERRIDE_ICON_SELECTED_BORDER_COLOR  = "display-def-icon-selected-border-color"; // (Group/Question) level icon color override
// export const OVERRIDE_ICON_SELECTED_BORDER_OPACITY  = "display-def-icon-selected-border-opacity"; // (Group/Question) level icon opacity override
//
// export const OVERRIDE_ICON_SELECTED_COLOR  = "display-def-icon-selected-color"; // (Group/Question) level icon color override
// export const OVERRIDE_ICON_SELECTED_OPACITY  = "display-def-icon-selected-opacity"; // (Group/Question) level icon opacity override
//
// export const OVERRIDE_ICON_SELECTED_TEXT_COLOR  = "display-def-icon-selected-text-color"; // (Group/Question) level icon color override
// export const OVERRIDE_ICON_SELECTED_TEXT_OPACITY  = "display-def-icon-selected-text-opacity"; // (Group/Question) level icon opacity override
//
// export const OVERRIDE_ICON_TEXT_FONT  = "display-def-icon-text-font"; // (Group/Question) level icon font override
//
// export const OVERRIDE_ICON_SQUARE_BORDER_WIDTH  = "display-def-icon-square-border-width"; // (Group/Question) level icon font override


export const customIconObject = {
    version: 1,
    objType: OBJ_TYPE_ICON,
    hidden: true, // We should just use icon === none and remove this field
    icon: "InfoIcon", // none or select icon info, clear/solid or comment clear/solid
    size: 18,
    padding: 2,
    colorType: "default", // default, select, hex or rgb
    colorIndex: null,
    colorEntry: "black",
    color: "black",
    opacityEntry: "100",
    opacity: "100%"
};



// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// TEXT (NAMES, DESCRIPTIONS, SCRIPTS, ANSWER TEXT, ANSWER ENTRIES, TITLES...)
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const CUSTOM_DDO_NAME = "custom-ddo-name"; // Used by Questionnaire, Category, Group and Question
export const CUSTOM_DDO_DESC = "custom-ddo-desc"; // Used by Questionnaire, Category, Group and Question
export const DEFAULT_FONT  = "roboto";
export const DEFAULT_FONT_FAMILY  = "Roboto, \"Helvetica Neue\", Helvetica, Arial, sans-serif";

// Custom Text
export const customTextObject = {
    version: 1,
    objType: OBJ_TYPE_TEXT,
    title: "",
    format: "default", // or custom
    hide: false,
    font: DEFAULT_FONT,
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: "normal", // normal, italics, oblique
    fontSizing: "points",
    fontSizeEntry: "",
    fontSize: "",
    variant: "",
    fontStyle: "normal", // normal, bold, bolder
    textAlign: "left",   // left center right
    iconObj: null, // Only Description Entries have icons
    colorObj: {...customColorObject}
}

export function getCustomTextObj(templateType, textEntryType) {

    let textObj = cloneObj(customTextObject);

    let textDefaultsObj = getTextDefaults(templateType, textEntryType);

    // Font default sizes are set depending on the type ie.. Template Type and Text Entry Type
    textObj.variant = textDefaultsObj.variant;
    textObj.fontSizeEntry = textDefaultsObj.fontSizeEntry;
    textObj.fontSize = textDefaultsObj.fontSize;

    if (textEntryType === "description" ||
        textEntryType === QNR_START_PAGE_SCRIPT ||
        textEntryType === QNR_CLOSE_PAGE_SCRIPT) {

        textObj.iconObj = {...customIconObject};
        textObj.iconObj.icon = (textEntryType === "description") ? "InfoIcon" : "none";
        textObj.iconObj.size = (templateType === templateType.QUESTION) ? 16 : 18; // Question descriptions are 16, others are 18
    }

    return textObj;
}

export function setCustomTextObject(currentObj) {

    let textObj = cloneObj(customTextObject); // Clone

    setObj(textObj, currentObj);

    return textObj;
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// QUESTIONNAIRE
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const PORTAL_TAB_TITLE = "custom-ui-portal-tab-title"; // Used to set the web browser tab name
export const KEY_METRIC_CATEGORY_QUESTIONNAIRE = "reporting-category-questionnaire"; // Changed to Key Metric Category in UI

export const CUSTOM_DDO_DEFAULTS  = "custom-ddo-defaults"; // (Questionnaire) custom overrides for all default text
export const CUSTOM_DDO_BUTTONS_RAISED  = "custom-ddo-buttons-raised"; // (Questionnaire) custom overrides for all raised buttons
export const CUSTOM_DDO_BUTTONS_PLAIN  = "custom-ddo-buttons-plain"; // (Questionnaire) custom overrides for all plain buttons

//export const CUSTOM_DDO_QNR_FORMAT_ALERTS  = "custom-ddo-questionnaire-format-alerts"; // (Questionnaire)



export const NO_SUBMIT  = "display-def-no-submit";  // (Questionnaire) Used to suppress submit button on real-time template mode (NOT FOR PUBLIC USE)

// Survey Presentation Overrides ---------------------------------------------------------------------------------------

//export const FORMAT_IS_METADATA  = "display-def-value-is-format";  // (Questionnaire) Actual metadata value is the ddo (TBD)

export const HIDE_READONLY_HEADER  = "display-def-hide-readonly-header";
export const SHOW_READONLY_HEADER  = "display-def-show-readonly-header";
export const FILTER_READONLY_HEADER  = "display-def-filter-readonly-header";  // This is the default left it here in case they change their minds

export const ALIGN_ALL_ANSWERS_RIGHT  = "align-all-answers-right";  // (Questionnaire) Align all answers right

// Hide first question

// System butttons


// ---------------------------------------------------------------------------------------------------------------------
// Questionnaire has 4 tab objects HEADER, START PAGE, MAIN PAGES and CLOSE PAGE
// CLOSE PAGE is included by default in all questionnaires, but can be removed by setting close page DDLB to NO



// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// HEADER SECTION (SURVEY APPBAR)
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_QNR_APP_HEADER_OBJ  = "custom-obj-qnr-app-header";
export const CUSTOM_QNR_APP_HEADER_OBJ_LEGACY  = "custom-ddo-questionnaire-format-app-header";

// Application Title Bar -----------------------------------------------------------------------------------------------
// export const APPBAR  = "display-def-appbar";
// export const HEADER_APPBAR_TITLE_LEFT  = "display-def-appbar-title-left";  //(Questionnaire) scr= ot Text or #pageNum
// export const HEADER_APPBAR_TITLE_CENTER  = "display-def-appbar-title-center"; //(Questionnaire) scr= ot Text or #pageNum
// export const HEADER_APPBAR_TITLE_RIGHT  = "display-def-appbar-title-right";  //(Questionnaire) scr= ot Text or #pageNum

export const HEADER_APPBAR_IMAGE_LEFT = "image-app-title-bar-left"; // (Questionnaire)
export const HEADER_APPBAR_IMAGE_CENTER = "image-app-title-bar-center"; // (Questionnaire)
export const HEADER_APPBAR_IMAGE_RIGHT = "image-app-title-bar-right"; // (Questionnaire)

export const customQuestionnaireAppHeaderObj = {
    version: 1,
    objType: OBJ_TYPE_CUSTOM_QNR_APP_HEADER,
    //format: "custom",             // LEGACY, No longer used if this exists in metadata it must be custom
    colorObj: {...customColorObject},  // APPBAR Color NOT used yet
    height: "",                     // APPBAR Height NOT used yet NOT sure we should allow them to adjust height
    appHeaderLeftType: "none",      // none, text or image
    appHeaderLeftTextObj: null,     // If Text standard text object, if Image use separate metadata entry
    appHeaderCenterType: "none",    // none, text or image
    appHeaderCenterTextObj: null,   // If Text standard text object, if Image use separate metadata entry
    appHeaderRightType: "none",     // none, text or image
    appHeaderRightTextObj: null,    // If Text standard text object, if Image use separate metadata entry
}

export function getCustomQuestionnaireAppHeaderObj() {

    let customObj = cloneObj(customQuestionnaireAppHeaderObj);

    if (customObj.appHeaderLeftType === "text") {
        customObj.appHeaderLeftTextObj = cloneObj(customTextObject);
    } else {
        customObj.appHeaderLeftTextObj = null; // None and Image
    }

    if (customObj.appHeaderCenterType === "text") {
        customObj.appHeaderCenterTextObj.metadataValueObj = cloneObj(customTextObject);
    } else {
        customObj.appHeaderCenterTextObj = null; // None and Image
    }

    if (customObj.appHeaderRightType === "text") {
        customObj.appHeaderRightTextObj.metadataValueObj = cloneObj(customTextObject);
    } else {
        customObj.appHeaderRightTextObj = null; // None and Image
    }

    return customObj;
}

export function setCustomQuestionnaireAppHeaderObj(newObj) {
    let appHeaderObj = cloneObj(customQuestionnaireAppHeaderObj);

    setObj(appHeaderObj, newObj);

    return appHeaderObj;
}


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// START PAGE SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_QNR_START_PAGE_OBJ  = "custom-obj-qnr-start-page";
export const QNR_START_PAGE_SCRIPT  = "user-script"; // (Questionnaire) // Allows TOKENS so must be stand-alone metadata entry
export const QNR_START_PAGE_SCRIPT_FORMAT_OBJ  = "user-script-format-obj"; // (Questionnaire)


export const customQuestionnaireStartPageObj = {
    version: 1,
    objType: OBJ_TYPE_CUSTOM_QNR_START_PAGE,
    //format: "custom",                               // LEGACY, NOT USED ANYMORE
    hideName: false,                        // Hade questionnaire name from start page
    titleText: "",                          // NOT used for future expansion
    titleFormat: "default",                     // NOT used for future expansion
    titleTextCustomObj: null,                             // NOT used for future expansion
    scriptFormat: "default",                        // NOT used for future expansion
    scriptCustomObj: null,                          // NOT used for future expansion
    defaultText: "Begin Questionnaire?",
    defaultTextFormat: "default",
    defaultTextCustomObj: null
}

export function getCustomQuestionnaireStartPageObj() {
    return cloneObj(customQuestionnaireStartPageObj);
}

export function getCustomTextStartPageScript() {
    let customObj = cloneObj(customTextObject);

    let textDefault = getTextDefaults(TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.START_PAGE_SCRIPT);

    customObj.fontSizeEntry = textDefault.fontSizeEntry;
    customObj.fontSize = textDefault.fontSize;
    customObj.variant = textDefault.variant;

    return customObj;
}

export function setCustomQuestionnaireStartPageObj(newObj) {

    let startPageObj = cloneObj(customQuestionnaireStartPageObj);

    setObj(startPageObj, newObj);

    return startPageObj;
}

// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// MAIN PAGES SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_QNR_MAIN_PAGES_OBJ  = "custom-obj-qnr-main-pages";

export const INSTANT_MODE  = "INSTANT_MODE";
export const TEMPLATE_MODE  = "TEMPLATE_MODE";

export const SKIP_FIRST_QUESTION  = "SKIP_FIRST_QUESTION";
export const SHOW_FIRST_QUESTION  = "SHOW_FIRST_QUESTION";

export const EDITABLE_SURVEY  = "EDITABLE_SURVEY";
export const READONLY_SURVEY  = "READONLY_SURVEY";

export const READONLY_SURVEY_URL_SHOW_HEADER_NONE  = "READONLY_SURVEY_URL_SHOW_HEADER_NONE";
export const READONLY_SURVEY_URL_SHOW_HEADER_TRUE  = "READONLY_SURVEY_URL_SHOW_HEADER_TRUE";
export const READONLY_SURVEY_URL_SHOW_HEADER_FALSE  = "READONLY_SURVEY_URL_SHOW_HEADER_FALSE";
export const READONLY_SURVEY_URL_SHOW_HEADER_FILTERED  = "READONLY_SURVEY_URL_SHOW_HEADER_FILTERED";

export const SHOW_ANSWERED_FIRST_QUESTION  = "display-def-show-answered-first-question";  // (Questionnaire) Used to show the first question if being answered from an email and passed in


// Good article on question numbers: https://help.alchemer.com/help/question-numbering
export const SHOW_QUESTION_NUMBERS  = "display-def-show-question-numbers";  // (Questionnaire) . or ) default just the number
export const SUBNUMBER_LIKERT_QUESTIONS  = "display-def-subnumber-likert-questions";  // (Questionnaire) a or a) or (a) or Xa or X.#   ...by default none
export const SUBNUMBER_SHOWGROUP_QUESTIONS  = "display-def-subnumber-showgroup-questions";  // (Questionnaire) a or a) or (a) or Xa or X.#  ...by default none
export const RESTART_QUESTION_NUMBERS_EACH_PAGE  = "display-def-restart-numbers-each-page";  // (Questionnaire)


// IMAGE metadata has to be kept seperate because each image is stored as a Base64 encoded text
export const PAGE_HEADER_IMAGE_LEFT   = "image-page-header-left"; // (Questioniare)
export const PAGE_HEADER_IMAGE_CENTER = "image-page-header-center"; // (Questioniare)
export const PAGE_HEADER_IMAGE_RIGHT  = "image-page-header-right"; // (Questioniare)

export const PAGE_FOOTER1_IMAGE_LEFT   = "image-page-footer-left"; // (Questioniare)
export const PAGE_FOOTER1_IMAGE_CENTER = "image-page-footer-center"; // (Questioniare)
export const PAGE_FOOTER1_IMAGE_RIGHT  = "image-page-footer-right"; // (Questioniare)

export const PAGE_FOOTER2_IMAGE_LEFT   = "image-page-footer-left2"; // (Questioniare)
export const PAGE_FOOTER2_IMAGE_CENTER = "image-page-footer-center2"; // (Questioniare)
export const PAGE_FOOTER2_IMAGE_RIGHT  = "image-page-footer-right2"; // (Questioniare)

export const customButtonObj = {
    version: 1,
    objType: OBJ_TYPE_BUTTON,
    color: COLOR_DODGER_BLUE_HEX,
    height: 20,
    width: 100,
    background: COLOR_WHITE_HEX,
    borderColor: COLOR_WHITE_HEX,
    border: "none",
    borderWidth: 0,
    borderRadius: 0,
    hoverColor: COLOR_DODGER_BLUE_HEX,
    hoverFontWeight: "normal",
    hoverBackground: COLOR_WHITE_HEX,
    hoverBorderColor: COLOR_WHITE_HEX,
    hoverBorderWidth: 0,
}

export const customQuestionnaireMainPagesObj = {
    version: 1,
    objType: OBJ_TYPE_CUSTOM_QNR_MAIN_PAGES,
    //format: "default",
    pagingMode: "full", // full or one
    answeredFirstQuestion: "show", // hide/show default is show, NOTE: Portal preview should never hide first question
    questionNumbering: "none", // none, all, page
    questionNums: "#", // #, #. #)
    questionNumsLikert: "none", // none or a format pattern
    questionNumsShowGroup: "none", // none or a format pattern

    buttonObj: {},
    text: cloneObj(customTextObject), // For future expansion to set font globally

    pageHeader: "no",           // no or yes
    pageHeaderLeftType: "none", // none, text or image
    pageHeaderLeftTextObj: "",
    pageHeaderCenterType: "none",
    pageHeaderCenterTextObj: "",
    pageHeaderRightType: "none",
    pageHeaderRightTextObj: "",

    pageFooter1: "no",           // no or yes
    pageFooter1LeftType: "none", // none, text or image
    pageFooter1LeftTextObj: "",
    pageFooter1CenterType: "none",
    pageFooter1CenterTextObj: "",
    pageFooter1RightType: "none",
    pageFooter1RightTextObj: "",

    pageFooter2: "no",           // no or yes
    pageFooter2LeftType: "none", // none, text or image
    pageFooter2LeftTextObj: "",
    pageFooter2CenterType: "none",
    pageFooter2CenterTextObj: "",
    pageFooter2RightType: "none",
    pageFooter2RightTextObj: ""
}

export function getCustomQuestionnaireMainPagesObj() {

    return cloneObj(customQuestionnaireMainPagesObj);
}

export function setCustomQuestionnaireMainPagesObj(newObj) {

    let mainPagesObj = cloneObj(customQuestionnaireMainPagesObj);

    setObj(mainPagesObj, newObj);

    return mainPagesObj;
}

// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// CLOSE PAGE SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_QNR_CLOSE_PAGE_OBJ  = "custom-obj-qnr-close-page"; // NOTE: Close page defaults to included.
export const CUSTOM_QNR_CLOSE_PAGE_OBJ_LEGACY  = "custom-ddo-questionnaire-format-close-page"; // NOTE: Close page defaults to included.
export const QNR_CLOSE_PAGE_SCRIPT  = "user-script-bottom";
export const QNR_CLOSE_PAGE_SCRIPT_FORMAT_OBJ  = "user-script-bottom-format-obj"; // (Questionnaire)
export const QNR_CLOSE_PAGE_DEFAULT_TEXT  =  "Thank you! We appreciate you taking the time to share your feedback and help us in offering a premier client experience. Your answers have been recorded, please close your browser window."; // (Questionnaire)


export const customQuestionnaireClosePageObj = {
    version: 1,
    objType: OBJ_TYPE_CUSTOM_QNR_CLOSE_PAGE,
    //format: "default", LEGACY not used anymore
    image: "greencheck",
    noGreenCheck: false,
    iconObj: {...customIconObject}, // Future expansion
    titleText: "",              // NOT used for future expansion
    titleFormat: "default",                         // NOT used for future expansion
    titleTextCustomObj: null,                       // NOT used for future expansion
    scriptFormat: "default",                        // NOT used for future expansion
    scriptCustomObj: null,                          // NOT used for future expansion
    defaultText: QNR_CLOSE_PAGE_DEFAULT_TEXT,
    defaultTextFormat: "default",
    defaultTextCustomObj: null,
}

export function getCustomQuestionnaireClosePageObj() {
    return cloneObj(customQuestionnaireClosePageObj);
}

export function setCustomQuestionnaireClosePageObj(newObj) {

    let closePageObj = cloneObj(customQuestionnaireClosePageObj);

    setObj(closePageObj, newObj);

    return closePageObj;
}

// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// SYSTEM SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_QNR_SYSTEM_OBJ  = "custom-obj-qnr-system";

export const customQuestionnaireSystemObj = {
    version: 1,
    objType: OBJ_TYPE_CUSTOM_QNR_SYSTEM,
    hideFirstQuestion: false,
    readonlyMetadataArray: [],
    systemButtons: {
        format: "default",
        type: "text", // text, outlined, contained
        size: "large", // small, medium, large.  NOTE: Currently not used.
        width: "100px", // NOTE: Currently not used.
        text: cloneObj(customTextObject),
        textHover: cloneObj(customTextObject),
        backgroundColor: {...customColorObject},
        backgroundColorHover: {...customColorObject},
        borderColor: {...customColorObject}, // NOTE: Only used by button type outlined
        borderColorHover: {...customColorObject}, // NOTE: Only used by button type outlined
    },
    floatingLabel: {text: "", ...customColorObject},
    ddlbBorderColorAfterSelection: {...customColorObject}
}

export function getCustomQuestionnaireSystemObj() {
    let systemObj = cloneObj(customQuestionnaireSystemObj);

    systemObj.systemButtons.text.colorObj.colorType = "select"; // default, select, hex or rgb
    systemObj.systemButtons.text.colorObj.colorIndex = COLOR_DODGER_BLUE_INDEX;
    systemObj.systemButtons.text.colorObj.colorEntry = COLOR_DODGER_BLUE_HEX; // Dodger Blue
    systemObj.systemButtons.text.colorObj.color = COLOR_DODGER_BLUE_HEX;

    systemObj.systemButtons.textHover.colorObj.colorType = "select"; // default, select, hex or rgb
    systemObj.systemButtons.textHover.colorObj.colorIndex = COLOR_DODGER_BLUE_INDEX;
    systemObj.systemButtons.textHover.colorObj.colorEntry = COLOR_DODGER_BLUE_HEX; // Dodger Blue
    systemObj.systemButtons.textHover.colorObj.color = COLOR_DODGER_BLUE_HEX;

    systemObj.systemButtons.backgroundColor.colorType = "hex"; // default, select, hex or rgb
    systemObj.systemButtons.backgroundColor.colorIndex = COLOR_WHITE_HEX;
    systemObj.systemButtons.backgroundColor.colorEntry = COLOR_WHITE_HEX;
    systemObj.systemButtons.backgroundColor.color = COLOR_WHITE_HEX;

    systemObj.systemButtons.backgroundColorHover.colorType = "hex"; // default, select, hex or rgb
    systemObj.systemButtons.backgroundColorHover.colorIndex = COLOR_LIGHT_GRAY_HEX;
    systemObj.systemButtons.backgroundColorHover.colorEntry = COLOR_LIGHT_GRAY_HEX;
    systemObj.systemButtons.backgroundColorHover.color = COLOR_LIGHT_GRAY_HEX;

    systemObj.systemButtons.borderColor.colorType = "hex"; // default, select, hex or rgb
    systemObj.systemButtons.borderColor.colorIndex = COLOR_GRAY_HEX;
    systemObj.systemButtons.borderColor.colorEntry = COLOR_GRAY_HEX;
    systemObj.systemButtons.borderColor.color = COLOR_GRAY_HEX;

    systemObj.systemButtons.borderColorHover.colorType = "select"; // default, select, hex or rgb
    systemObj.systemButtons.borderColorHover.colorIndex = COLOR_DODGER_BLUE_INDEX;
    systemObj.systemButtons.borderColorHover.colorEntry = COLOR_DODGER_BLUE_HEX; // Dodger Blue
    systemObj.systemButtons.borderColorHover.color = COLOR_DODGER_BLUE_HEX;

    return systemObj;
}

export function setCustomQuestionnaireSystemObj(newObj) {

    let systemObj = cloneObj(customQuestionnaireSystemObj);

    setObj(systemObj, newObj);

    return systemObj;
}


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// ALERTS SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

// NOTE: Not currently used, server sends error code.  To implement this server would need to send the questionnaire metadata or full template

export const ALERT_MESSAGE_QUESTIONNAIRE_INVALID_KEY = "This key is invalid.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_INVALID_KEY = "This questionnaire key is invalid.";
export const ALERT_MESSAGE_QUESTIONNAIRE_COMPLETED = "Completed and is no longer available.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_COMPLETED = "This questionnaire has already been completed and is no longer available.";
export const ALERT_MESSAGE_QUESTIONNAIRE_DEACTIVATED = "Deactivated and is no longer available.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_DEACTIVATED = "This questionnaire has been deactivated.";
export const ALERT_MESSAGE_QUESTIONNAIRE_EXPIRED = "Expired and is no longer available.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_EXPIRED = "This questionnaire has expired and is no longer available.";
export const ALERT_MESSAGE_QUESTIONNAIRE_CANCELED = "Canceled. The link remains active until the expiration date.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_CANCELED = "Questionnaire canceled. The link remains active until the expiration date.";
export const ALERT_MESSAGE_QUESTIONNAIRE_UNAVAILABLE = "This survey will be available for read-only viewing once the customer has opened the survey link.";

export const customQuestionnaireAlertsObj = {
    version: 1,
    objType: OBJ_TYPE_CUSTOM_QNR_ALERT_TEXT,
    invalidKeyText: "This questionnaire key is invalid.",
    compleatedText: "This questionnaire has already been completed and is no longer available.",
    deactivatedText: "This questionnaire has been deactivated.",
    expiredText: "This questionnaire has expired and is no longer available.",
    canceledText: "Questionnaire canceled. The link remains active until the expiration date.",
}

export function getCustomQuestionnaireAlertsObj() {

    return cloneObj(customQuestionnaireAlertsObj);
}

export function setCustomQuestionnaireAlertsObj(newObj) {

    let alertsObj = cloneObj(customQuestionnaireAlertsObj);

    setObj(alertsObj, newObj);

    return alertsObj;
}


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// CATEGORY SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_DDO_CAT_FORMAT  = "custom-ddo-category-format"; // (Category)
// export const CUSTOM_DDO_CAT_TITLE_BAR_LEFT = "custom-ddo-category-title-bar-left"; // (Category)
// export const CUSTOM_DDO_CAT_TITLE_BAR_CENTER = "custom-ddo-category-title-bar-center"; // (Category)
// export const CUSTOM_DDO_CAT_TITLE_BAR_RIGHT = "custom-ddo-category-title-bar-right"; // (Category)

//export const CATEGORY_NEW_PAGE  = "display-def-new-page";   // (Category) Page Number 1..x || close  (Question on SIMPLE questionnaires)
export const PAGE_OF  = "display-def-page-of";   // (Category) Page Number 1...

//export const CATEGORY_TITLE_BAR_DEFAULT_HEIGHT = "46";

// Category title bar Left/Center/Right Text
// export const CATEGORY_TITLE_BAR  = "display-def-category-title-bar"; // (Category)
// export const CATEGORY_TITLE_BAR_LEFT  = "display-def-category-title-bar-left";  // (Category)
// export const CATEGORY_TITLE_BAR_CENTER  = "display-def-category-title-bar-center"; // (Category)
// export const CATEGORY_TITLE_BAR_RIGHT  = "display-def-category-title-bar-right";  // (Category)


export const customCategoryObject = {
    version: 1,
    objType: OBJ_TYPE_CUSTOM_CAT,
    portalPageBreak: false,
    pageOf: "", // Page Number 1...
    titlebar: false,
    height: "46",
    colorObj: null,
    leftTitleCustomTextObj: null,
    centerTitleCustomTextObj: null,
    rightTitleCustomTextObj: null,
}

export function getCustomCategoryObjectDefault() {
    return cloneObj(customCategoryObject);
}


export function setCustomCategoryObjectTitleBarDefaults(customObj) {

    customObj.colorObj = {...customColorObject};
    customObj.leftTitleCustomTextObj = cloneObj(customTextObject);
    customObj.centerTitleCustomTextObj = cloneObj(customTextObject);
    customObj.rightTitleCustomTextObj = cloneObj(customTextObject);

    setColor(customObj.colorObj, COLOR_PRIMARY_BLUE_INDEX, COLOR_PRIMARY_BLUE_HEX);

    customObj.colorObj.colorType = "select";
    customObj.colorObj.colorIndex = COLOR_PRIMARY_BLUE_INDEX;
    customObj.colorObj.color = COLOR_PRIMARY_BLUE_HEX;
}

export function setCustomCategoryObject(currentObj) {
    let categoryObj = getCustomCategoryObjectDefault();

    setObj(categoryObj, currentObj); // Set Values into default obj

    return categoryObj;
}

export function getCategoryTitleBarTextDefaultObject() {
    let customObj = cloneObj(customTextObject);

    customObj.fontSizeEntry = "21";
    customObj.variant = "h4";

    return customObj;
}

// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// LIKERT SECTION - Used by Groups and Questions
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const DEFAULT_GRAYBAR_COLOR  = "#EEEEEE"; // (Group/Question)
export const DEFAULT_GRAYBAR_HEIGHT  = "40"; // (Group/Question)
export const USE_LIKERT_INDENT  = "display-def-use-likert-indent"; // (Question) TEMP FIX until UI can be updated for question
export const LIKERT_QUESTION_SPACE_AFTER  = "display-def-likert-question-space-after"; // (Likert space after question default is 10px
export const LIKERT_ANSWERS_SPACE_AFTER  = "display-def-likert-answers-apace-after"; // (Likert space after answers default is 10px
export const DISPLAY_LIKERT_TITLEBAR_AFTERSPACE_HEIGHT  = "display-def-likert-titlebar-afterspace-height"; // (Group, Question) responsive label override


export const customLikertObject = {
    version: 3,
    objType: OBJ_TYPE_CUSTOM_LIKERT,
    alignAnswersRight: false,
    alignQuestionsAndAnswers: false,
    indent: "40px", // Indent for Likert questions

    spaceBefore: "0px",
    spaceAfter: "0px",

    title: "none",
    titleType: "none",     // standard, graybar
    titleLeft: "",
    titleCenter: "",
    titleRight: "",
    likertTitlesFormat: "default",

    grayBarColorObj: cloneObj(customColorObject),
    grayBarHeight: DEFAULT_GRAYBAR_HEIGHT, // USE_LIKERT_GRAYBAR_HEIGHT

    spaceAfterTitle: "0px",

    titlesCustomTextObj: cloneObj(customTextObject),

    responsiveLabels: "No",
    responsiveLabelsCustomTextObj: cloneObj(customTextObject),

    customLikertInputStyling: "default",

    // For NON - Circles and Squares

    unselectedIcon: {...customColorObject},
    hoverUnselectedIcon: {...customColorObject},
    selectedIcon: {...customColorObject},
    hoverSelectedIcon: {...customColorObject},

    // For Circles and Squares

    unselectedBorder: {...customColorObject},
    hoverUnselectedBorder: {...customColorObject},
    selectedBorder: {...customColorObject},
    hoverSelectedBorder: {...customColorObject},

    unselectedBackground: {...customColorObject},
    hoverUnselectedBackground: {...customColorObject},
    selectedBackground: {...customColorObject},
    hoverSelectedBackground: {...customColorObject},

    unselectedText: {...customColorObject},
    hoverUnselectedText: {...customColorObject},
    selectedText: {...customColorObject},
    hoverSelectedText: {...customColorObject},

    unselectedBorderWidth: 1,
    hoverUnselectedBorderWidth: 2,
    selectedBorderWidth: 1,
    hoverSelectedBorderWidth: 2,

    unselectedBorderStyle: "solid",
    hoverUnselectedBorderStyle: "solid",
    selectedBorderStyle: "solid",
    hoverSelectedBorderStyle: "solid",

    unselectedBorderRadius: "50%", // Circles are allways 50%, squares can be customized ie.. 5px NOT YET IMPLEMENTED in UI
    hoverUnselectedBorderRadius: "50%",
    selectedBorderRadius: "50%",
    hoverSelectedBorderRadius: "50%",

    unselectedBoxShadow: "",        // Not currently used
    hoverUnselectedBoxShadow: "",   // Not currently used
    selectedBoxShadow: "",          // Not currently used
    hoverSelectedBoxShadow: "",     // Not currently used

    hideEmbeddedText: false, // Used by squares and circles which can have embedded answer text in the center of the input

    unselectedFontFamily: DEFAULT_FONT_FAMILY,
    hoverUnselectedFontFamily: DEFAULT_FONT_FAMILY,     // Currently NOT used, could not find a way to implement
    selectedFontFamily: DEFAULT_FONT_FAMILY,            // Currently NOT used, could not find a way to implement
    hoverSelectedFontFamily: DEFAULT_FONT_FAMILY,       // Currently NOT used, could not find a way to implement

    unselectedFontSize: 18,
    hoverUnselectedFontSize: 18,
    selectedFontSize: 18,
    hoverSelectedFontSize: 18,

    unselectedFontStyle: "normal",
    hoverUnselectedFontStyle: "normal",
    selectedFontStyle: "normal",
    hoverSelectedFontStyle: "normal",

    unselectedFontWeight: "normal",
    hoverUnselectedFontWeight: "normal",
    selectedFontWeight: "normal",
    hoverSelectedFontWeight: "normal",

    containerHeight: '40px',    // Used by squares and circles, maybe someday we can modify so spacing is wider
    containerWidth: '40px',     // Used by squares and circles, maybe someday we can modify so spacing is wider
    containerMinWidth: '40px',  // Used by squares and circles, maybe someday we can modify so spacing is wider
}

export function getCustomLikertObject(templateType, textEntryTypesArray) {

    let likertObj = cloneObj(customLikertObject);

    // textEntryTypesArray souuld be in format [titles , responsive labels]

    let fontsObj = getTextDefaults(templateType, textEntryTypesArray[0]);

    likertObj.titlesCustomTextObj.fontSizeEntry = fontsObj.fontSizeEntry;
    likertObj.titlesCustomTextObj.fontSize = fontsObj.fontSize;
    likertObj.titlesCustomTextObj.variant = fontsObj.variant;

    fontsObj = getTextDefaults(templateType, textEntryTypesArray[1]);

    likertObj.responsiveLabelsCustomTextObj.fontSizeEntry = fontsObj.fontSizeEntry;
    likertObj.responsiveLabelsCustomTextObj.fontSize = fontsObj.fontSize;
    likertObj.responsiveLabelsCustomTextObj.variant = fontsObj.variant;

    likertObj.grayBarColorObj.colorType = "hex";
    likertObj.grayBarColorObj.colorIndex = 0;
    likertObj.grayBarColorObj.colorEntry = DEFAULT_GRAYBAR_COLOR;
    likertObj.grayBarColorObj.color = DEFAULT_GRAYBAR_COLOR;

    // =================================================================================================================
    // LIKERT
    // =================================================================================================================

    // For NON - Circles and Squares -----------------------------------------------------------------------------------

    setColor(likertObj.unselectedIcon, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(likertObj.hoverUnselectedIcon, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(likertObj.selectedIcon, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(likertObj.hoverSelectedIcon, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    // For Circles and Squares -----------------------------------------------------------------------------------------

    setColor(likertObj.unselectedBorder, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(likertObj.hoverUnselectedBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(likertObj.selectedBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(likertObj.hoverSelectedBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    // -------------------------------------------------

    setColor(likertObj.unselectedBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(likertObj.hoverUnselectedBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(likertObj.selectedBackground, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(likertObj.hoverSelectedBackground, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    // -------------------------------------------------

    setColor(likertObj.unselectedText, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(likertObj.hoverUnselectedText, COLOR_DARK_GRAY_INDEX, COLOR_DARK_GRAY_HEX);
    setColor(likertObj.selectedText, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(likertObj.hoverSelectedText, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);

    // -------------------------------------------------

    return likertObj;
}


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// GROUP SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_DDO_GRP_FORMAT  = "custom-ddo-group-format"; // (Group)

export const customGroupObject = {
    version: 3,
    objType: OBJ_TYPE_CUSTOM_GRP,
    likertObj: cloneObj(customLikertObject)
}

export function getCustomGroupObj() {
    let groupObj = cloneObj(customGroupObject);

    groupObj.likertObj = getCustomLikertObject(TEMPLATE.TYPE.GROUP, [TEMPLATE.TEXT_TYPE.GROUP.LIKERT_TITLES, TEMPLATE.TEXT_TYPE.GROUP.RESPONSIVE_LABLES]);

    return groupObj;
}

export function setCustomGroupObj(newObj) {
    let groupObj = cloneObj(customGroupObject);

    setObj(groupObj, newObj);

    return groupObj;
}

// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// QUESTION TYPES SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


export const YES_NO = "YES_NO";
export const CHECKBOX = "CHECKBOX";
export const RADIO = "RADIO";
export const LIKERT_RADIO = "LIKERT_RADIO";
export const NUMBER = "NUMBER";
export const CURRENCY = "CURRENCY";
export const PHONE = "PHONE";
export const DATE = "DATE";
export const DATE_RANGE = "DATE_RANGE";
export const EMAIL = "EMAIL";
export const FREE_TEXT = "FREE_TEXT";
export const ADDRESS_FULL = "ADDRESS_FULL";
export const ZIPPOSTAL_ONLY = "ZIPPOSTAL_ONLY";
export const STATE_ONLY = "STATE_ONLY";


export const getLabel = {
    [YES_NO]: "Yes/No",
    [CHECKBOX]: "Checkbox",
    [RADIO]: "Single Select",
    [LIKERT_RADIO]: "Likert",
    [NUMBER]: "Number",
    [CURRENCY]: "Currency",
    [PHONE]: "Phone",
    [DATE]: "Date",
    [DATE_RANGE]: "Date Range",
    [EMAIL]: "Email",
    [FREE_TEXT]: "Free Text",
    [ADDRESS_FULL]: "Address",
    [ZIPPOSTAL_ONLY]: "Zip",
    [STATE_ONLY]: "State",
};


export const supported = {
    FREE_TEXT,
    YES_NO,
    RADIO,
    CHECKBOX,
    LIKERT_RADIO,
    DATE,
    DATE_RANGE,
    EMAIL,
    PHONE,
    CURRENCY,
    NUMBER,
    ADDRESS_FULL,
    ZIPPOSTAL_ONLY,
    STATE_ONLY,
};


export const questionTypesList = [
    {value: "FREE_TEXT", text: "Free Text"},
    {value: "YES_NO", text: "Yes/No"},
    {value: "RADIO", text: "Single Select"},
    {value: "CHECKBOX", text: "Checkbox"},
    {value: "LIKERT_RADIO", text: "Likert"},
    {value: "DATE", text: "Date"},
    {value: "DATE_RANGE", text: "Date Range"},
    {value: "EMAIL", text: "Email"},
    {value: "PHONE", text: "Phone"},
    {value: "CURRENCY", text: "Currency"},
    {value: "NUMBER", text: "Number"},
    {value: "ADDRESS_FULL", text: "Address"},
    {value: "ZIPPOSTAL_ONLY", text: "Zip"},
    {value: "STATE_ONLY", text: "State"}
];


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// QUESTION SECTION
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

export const CUSTOM_DDO_QUE_FORMAT  = "custom-ddo-question-format"; // (Question) // Format varies depending on question type
export const CUSTOM_DDO_QUE_LABEL  = "custom-ddo-question-label"; // (Question) Answer labels (For Text and Date Entries
export const KEY_METRIC_CATEGORY_QUESTION = "reporting-category-question"; // Changed to Key Metric Category in UI
export const DEFAULT_DATE_ENTRY_WIDTH  = "250"; // (Question) DATE and DATE_RANGE

//export const TEXT_FLOATING_LABEL  = "display-def-floating-label"; // (Question) - Text Entry
//export const TEXT_PLACEHOLDER  = "display-def-placeholder"; // (Question) - Text Entry

//export const TEXT_WIDTH  = "display-def-entry-width"; // (Question) - Text Entry ###% default = 100%

//export const TEXT_MULTILINE  = "display-def-multiline"; // (Question) - Text Entry Default false
// export const TEXT_FLOATING_LABEL2  = "display-def-floating-label2"; // (Question) - Text Entry
// export const TEXT_PLACEHOLDER2  = "display-def-placeholder2"; // (Question) - Text Entry
// export const TEXT_VARIANT2  = "display-def-entry-variant2"; // (Question) - Text Entry: 'standard' | 'outlined' | 'filled' default = outlined
// export const TEXT_SIZE2  = "display-def-entry-size2"; // (Question) - Text Entry small | medium, default = small
// export const TEXT_WIDTH2  = "display-def-entry-width2"; // (Question) - Text Entry ###% default = 100%
// export const TEXT_MAXLENGTH2  = "display-def-maxlength"; // (Question) - Text Entry
// export const DISPLAY_DATE_RANGE_AS_SIDE_BY_SIDE  = "display-def-date-range-side-by-side";
// export const DISPLAY_DATE_RANGE_AS_ONE_PER_ROW  = "display-def-date-range-one-per-row";


// ---------------------------------------------------------------------------------------------------------------------
// Covers Question Types: FREE_TEXT, EMAIL, PHONE, NUMBER, CURRENCY, DATE, DATE_RANGE, ZIPPOSTAL_ONLY
// ---------------------------------------------------------------------------------------------------------------------

export const customQuestionTextObj = {
    version: 2,
    objType: OBJ_TYPE_CUSTOM_QUE_TEXT,
    questionType: null,
    indent: "0px",
    style: "outlined",         // outlined, standard, filled
    size: "small",             // small, medium

    widthType: 'percentage',   // percentage, pixels // Date Range width will be applied to both dates
    widthEntry: "100",         // numeric width
    width: "100%",             // numeric width + "px"
    borderWidth: "1px",
    hoverBorderWidth: "2px",
    selectedBorderWidth: "2px",

    max: "255",
    multiline: false,
    maxRows: 1, // Max rows the entry will expand to
    minRows: 1, // Starting number of row for text entry
    textStyle: "default",       // default, custom

    text: cloneObj(customTextObject), // Could be answer text formatting NOT SURE !!!!!

    labelPosition: "none",      // none, above, below
    label: "",

    label2: "", // Label  ---- TO DATE (Date Range) // NOTE: Label position will be applied to both labels, also any custom text will be applied to both.
    floatingLabel2: "", // Floating Label  ---- TO DATE (Date Range) // NOTE: Label position will be applied to both labels, also any custom text will be applied to both.

    arrangeDateRange: "row",

    labelDisplay: "default",    // default, custom Label Text

    color: "default",

    placeHolder: {text: "", ...customColorObject},

    unselectedFloatingLabel: {text: "", ...customColorObject},
    selectedFloatingLabel: {...customColorObject},
    hoverFloatingLabel: {...customColorObject},

    unselectedText: {...customColorObject},
    selectedText: {...customColorObject},
    hoverText: {...customColorObject},

    unselectedBorder: {...customColorObject},
    selectedBorder: {...customColorObject},
    hoverBorder: {...customColorObject},

    unselectedBackground: {...customColorObject},
    selectedBackground: {...customColorObject},
    hoverBackground: {...customColorObject},

    isNegative: false,
    isPercentage: false,
    isDecimal: false,
    decimalScale: 2,
    zipFormat: "#####",
    thousandSeparator: true
};

export function getCustomQuestionTextObj(questionType) {

    let questionObj = cloneObj(customQuestionTextObj);

    questionObj.text = getCustomTextObj(TEMPLATE.TYPE.QUESTION, TEMPLATE.TEXT_TYPE.QUESTION.ANSWER_TEXT); // Get default Answer text details

    let fontsObj = getTextDefaults(TEMPLATE.TYPE.QUESTION, TEMPLATE.TEXT_TYPE.QUESTION.LABEL);

    questionObj.text.fontSizeEntry = fontsObj.fontSizeEntry;
    questionObj.text.fontSize = fontsObj.fontSize;
    questionObj.text.variant = fontsObj.variant;

    setColor(questionObj.text.colorObj, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);

    setColor(questionObj.placeHolder, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);

    setColor(questionObj.unselectedFloatingLabel, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(questionObj.selectedFloatingLabel, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.hoverFloatingLabel, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    setColor(questionObj.unselectedText, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.selectedText, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.hoverText, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    setColor(questionObj.unselectedBorder, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(questionObj.selectedBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.hoverBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    setColor(questionObj.unselectedBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(questionObj.selectedBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(questionObj.hoverBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);

    if (questionType === DATE || questionType === DATE_RANGE) {
        questionObj.widthType = "pixels";
        questionObj.widthEntry = DEFAULT_DATE_ENTRY_WIDTH;
        questionObj.width = DEFAULT_DATE_ENTRY_WIDTH + "px";
    }

    questionObj.questionType = questionType;

    switch (questionType) {
        case FREE_TEXT:
            questionObj.unselectedFloatingLabel.text = getLabel[FREE_TEXT];
            break;
        case EMAIL:
            questionObj.unselectedFloatingLabel.text = getLabel[EMAIL];
            break;
        case PHONE:
            questionObj.unselectedFloatingLabel.text = getLabel[PHONE];
            break;
        case CURRENCY:
            questionObj.unselectedFloatingLabel.text = getLabel[CURRENCY];
            questionObj.isDecimal = true;
            break;
        case NUMBER:
            questionObj.unselectedFloatingLabel.text = getLabel[NUMBER];
            break;
        case ZIPPOSTAL_ONLY:
            questionObj.unselectedFloatingLabel.text = getLabel[ZIPPOSTAL_ONLY];
            break;
        // no default
    }

    return questionObj;
}

export function setCustomQuestionTextObj(newObj) {

    let questionObj = cloneObj(customQuestionTextObj);

    setObj(questionObj, newObj);

    return questionObj;
}

export function getCustomQuestionLabelTextObj() {

    let customTextObj = getCustomTextObj(TEMPLATE.TYPE.QUESTION, TEMPLATE.TEXT_TYPE.QUESTION.ANSWER_TEXT);

    let fontsObj = getTextDefaults(TEMPLATE.TYPE.QUESTION, TEMPLATE.TEXT_TYPE.QUESTION.LABEL);

    customTextObj.fontSizeEntry = fontsObj.fontSizeEntry;
    customTextObj.fontSize = fontsObj.fontSize;
    customTextObj.variant = fontsObj.variant;

    setColor(customTextObj.colorObj, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);

    return customTextObj;
}

// ---------------------------------------------------------------------------------------------------------------------
// Covers Question Types: SINGLE SELECT and YES_NO and STATE_ONLY
// ---------------------------------------------------------------------------------------------------------------------

export const DISPLAY_DEF_SELECT_NO_QUESTION_NUMBER  = "display-def-select-no-question-number";  // (Question SELECT (DDLB))

// export const squaresAndCirclesObj = {
//     height: '40px',
//     width: '40px',
//     minWidth: '40px',
//     border: 'solid',
//     borderHover: 'solid',
//     borderWidth: "1px",
//     borderWidthHover: "1px",
//     borderRadius: "5px",
//     borderRadiusHover: "5px",
//     boxShadow: "2px 4px #888888",
//     boxShadowHover: "2px 4px #888888",
//     fontFamily: "",
//     fontFamilySelected: "",
//     fontColor: "",
//     fontColorSelected: "",
//     fontSize: "16px",
//     fontSizeSelected: "16px",
//     fontWeight: "normal",
//     fontWeightSelected: "normal",
//     fontStyle: "normal",
//     fontStyleSelected: "normal"
// }

export const customQuestionSingleSelectObj = {
    version: 3,
    objType: OBJ_TYPE_CUSTOM_QUE_RADIO,
    questionType: null,
    indent: "0px",              // Indent Question Text
    align: "Left",              // Left, Center, Right
    displayAs: "single",        // single, likert
    style: "radios",            // radios, ddlb, standard, clear, outlined
    likertStyle: "radios",      // radios, buttongroup (squares), circle
    circleType: "standard",     // standard, primary, alternate
    showSelectedLabel: "no",    // no, yes  NOTE SURE WHAT THIS IS FOR
    hideEmbeddedAnswerText: "no", // no, yes  Used for Squares and Circles to show/hide answer text inside square/circle - HIDE_BUTTONGROUP_NAME
    arrange: "row",             // row, side, wrap
    separatorWidth: "10px",     // spacing between input controls
    state: "",
    property: "",
    textStyle: "default",       // default, custom
    text: cloneObj(customTextObject),
    color: "default",           // default, custom

    // For NON-Likert entries ==========================================================================================

    selectedControl: {...customColorObject},
    unselectedControl: {...customColorObject},
    hoverControl: {...customColorObject},           // NOTE: This should have been hoverUnselectedControl but did not change name to avoid issues with OOTB
    hoverSelectedControl: {...customColorObject},   // NOTE: This has not been implemented

    selectedBackground: {...customColorObject},
    unselectedBackground: {...customColorObject},
    hoverBackground: {...customColorObject},
    hoverSelectedBackground: {...customColorObject},

    selectedBorder: {...customColorObject},
    unselectedBorder: {...customColorObject},
    hoverBorder: {...customColorObject},
    hoverSelectedBorder: {...customColorObject},

    selectedText: {...customColorObject},
    unselectedText: {...customColorObject},
    hoverText: {...customColorObject},
    hoverSelectedText: {...customColorObject},

    floatingLabel: {text: "", ...customColorObject},
    unselectedFloatingLabel: {text: "", ...customColorObject},
    selectedFloatingLabel: {...customColorObject},
    hoverFloatingLabel: {...customColorObject},

    // For Likert Entries ==============================================================================================

    likertObj: getCustomLikertObject(TEMPLATE.TYPE.QUESTION, [TEMPLATE.TEXT_TYPE.QUESTION.LIKERT_TITLES, TEMPLATE.TEXT_TYPE.QUESTION.RESPONSIVE_LABLES]),

    // =================================================================================================================

    ddlbBorderColorAfterSelection: {...customColorObject},
    widthType: 'percentage',
    widthEntry: "100",
    width: "100%",
    noQueNum: true,                      // DISPLAY_DEF_SELECT_NO_QUESTION_NUMBER
};


export function getCustomQuestionSingleSelectObj(questionType) {

    let questionObj = cloneObj(customQuestionSingleSelectObj);

    questionObj.text = getCustomTextObj(TEMPLATE.TYPE.QUESTION, TEMPLATE.TEXT_TYPE.QUESTION.ANSWER_TEXT); // Get default Answer text details

    questionObj.questionType = questionType;
    questionObj.style = (questionType === STATE_ONLY) ? "ddlb" : "radios";

    setColor(questionObj.likertObj.grayBarColorObj, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);

    // -------------------------------------------------------

    setSingleSelectDefaultColors(questionObj);

    // -------------------------------------------------------

    questionObj.likertObj = getCustomLikertObject(TEMPLATE.TYPE.QUESTION, [TEMPLATE.TEXT_TYPE.QUESTION.LIKERT_TITLES, TEMPLATE.TEXT_TYPE.QUESTION.RESPONSIVE_LABLES]);

    return questionObj;
}

export function setSingleSelectDefaultColors(questionObj, inputType) {

    let index = (inputType === "outlined") ? COLOR_WHITE_INDEX : COLOR_DODGER_BLUE_INDEX;
    let color = (inputType === "outlined") ? COLOR_WHITE_HEX : COLOR_DODGER_BLUE_HEX;

    setColor(questionObj.selectedControl, index, color);
    setColor(questionObj.unselectedControl, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(questionObj.hoverControl, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.hoverSelectedControl, index, color);

    // -------------------------------------------------------

    index = (inputType === "outlined") ? COLOR_WHITE_INDEX : COLOR_DODGER_BLUE_INDEX;
    color = (inputType === "outlined") ? COLOR_WHITE_HEX : COLOR_DODGER_BLUE_HEX;

    setColor(questionObj.selectedBackground, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    index = (inputType === "standard") ? 0 : COLOR_WHITE_INDEX;  // Default Light Gray has is not selectable so set to HEX
    color = (inputType === "standard") ? COLOR_LIGHT_GRAY_HEX : COLOR_WHITE_HEX;

    setColor(questionObj.selectedBackground, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX, (inputType === "standard") ? "hex" : "select");
    setColor(questionObj.hoverBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(questionObj.hoverSelectedBackground, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    // -------------------------------------------------------

    setColor(questionObj.selectedBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.unselectedBorder, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(questionObj.hoverBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.hoverSelectedBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    // -------------------------------------------------------

    index = (inputType !== "radios" && inputType !== "ddlb") ? COLOR_WHITE_INDEX : COLOR_DODGER_BLUE_INDEX;
    color = (inputType !== "radios" && inputType !== "ddlb") ? COLOR_WHITE_HEX : COLOR_DODGER_BLUE_HEX;

    setColor(questionObj.selectedText, index, color);
    setColor(questionObj.unselectedText, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(questionObj.hoverText, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    index = (inputType !== "radios") ? COLOR_WHITE_INDEX : COLOR_DODGER_BLUE_INDEX;
    color = (inputType !== "radios") ? COLOR_WHITE_HEX : COLOR_DODGER_BLUE_HEX;

    setColor(questionObj.hoverSelectedText, index, color);

    setColor(questionObj.floatingLabel, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(questionObj.unselectedFloatingLabel, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(questionObj.selectedFloatingLabel, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.hoverFloatingLabel, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

}

export function setLikertInputDefaults(customObj) {

    // Border

    setColor(customObj.unselectedBorder, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(customObj.hoverUnselectedBorder, COLOR_DARK_GRAY_INDEX, COLOR_DARK_GRAY_HEX);
    setColor(customObj.selectedBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(customObj.hoverSelectedBorder, COLOR_DARK_GRAY_INDEX, COLOR_DARK_GRAY_HEX);

    // Background

    setColor(customObj.unselectedBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(customObj.hoverUnselectedBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(customObj.selectedBackground, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(customObj.hoverSelectedBackground, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    // Text

    setColor(customObj.unselectedText, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(customObj.hoverUnselectedText, COLOR_DARK_GRAY_INDEX, COLOR_DARK_GRAY_HEX);
    setColor(customObj.selectedText, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(customObj.hoverSelectedText, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

}



export function setCustomQuestionSingleSelectObj(newObj) {

    let questionObj = cloneObj(customQuestionSingleSelectObj);

    setObj(questionObj, newObj);

    return questionObj;
}

export function legacyFixLikertObj(obj, propName) {
    let tempObj = obj === null ? {} : obj;
    let value = null;
    if (tempObj.hasOwnProperty("version")) {
        value = tempObj.likertObj[propName];
    } else if (tempObj) {
        value = tempObj[propName];
    }
    return value;
}


// ---------------------------------------------------------------------------------------------------------------------
// Covers Question Types: CHECKBOXS
// ---------------------------------------------------------------------------------------------------------------------

export const customQuestionCheckboxObj = {
    version: 1,
    objType:OBJ_TYPE_CUSTOM_QUE_CHECKBOX,
    questionType: questionTypes.CHECKBOX,
    align: "Left",              // Left, Center, Right
    separatorWidth: "10px",     // spacing between input controls
    style: "checkboxes",        // checkboxes, outlined
    arrange: "row",             // row, side, wrap
    textStyle: "default",       // default, custom
    text: cloneObj(customTextObject),

    color: "default",           // default, custom

    unselectedControl: {...customColorObject},
    selectedControl: {...customColorObject},
    hoverControl: {...customColorObject},
    hoverSelectedControl: {...customColorObject},

    unselectedText: {...customColorObject},
    selectedText: {...customColorObject},
    hoverText: {...customColorObject},
    hoverSelectedText: {...customColorObject}
}

export function getCustomQuestionCheckboxObj() {

    let questionObj = cloneObj(customQuestionCheckboxObj);

    questionObj.text = getCustomTextObj(TEMPLATE.TYPE.QUESTION, TEMPLATE.TEXT_TYPE.QUESTION.ANSWER_TEXT); // Get default Answer text details

    setColor(questionObj.unselectedControl, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(questionObj.selectedControl, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.hoverControl, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.hoverSelectedControl, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    // -----------------------------------------------------------------------------------------------------------------

    setColor(questionObj.unselectedText, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(questionObj.selectedText, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.hoverText, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(questionObj.hoverSelectedText, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    return questionObj;
}

export function setCustomQuestionCheckboxObj(newObj) {

    let questionObj = cloneObj(customQuestionCheckboxObj);

    setObj(questionObj, newObj);

    return questionObj;
}

// ---------------------------------------------------------------------------------------------------------------------
// Covers Question Types: FULL_ADDRESS
// ---------------------------------------------------------------------------------------------------------------------

export const customQuestionAddressTextObj = {
    version: 1,
    objType: OBJ_TYPE_CUSTOM_QUE_ADDRESS_TEXT,
    widthType: "percentage",     // percentage, pixels
    widthEntry: "100",           // numeric width
    width:  "100%",              // numeric width + "px"
    max:  "",                    // Max characters allowed
    label:  "",
    floatingLabel:  "",
    placeHolder: ""
}

export function getCustomQuestionAddressTextObj(floatingLabel, max) {
    let addressTextObj = cloneObj(customQuestionAddressTextObj);

    addressTextObj.floatingLabel = floatingLabel;
    addressTextObj.max = max;

    return addressTextObj;
}

// Covers Question Types: ADDRESS
export const customQuestionAddressObj= {
    version: 1,
    objType: OBJ_TYPE_CUSTOM_QUE_ADDRESS,
    questionType: questionTypes.ADDRESS_FULL,
    style: "outlined",          // outlined, standard, filled
    size: "small",             // small, medium
    multiline: false,
    maxRows: 1,     // Max rows the entry will expand to
    minRows: 1,     // Starting number of row for text entry NOTE: Not currently used for Addressobj
    selectedAnswer: {id: ""},

    textStyle: "default",             // default, custom

    text: cloneObj(customTextObject), // Font selected here will apply to all entry fields in address

    color: "default",                 // default, custom

    placeHolderColor: {...customColorObject},

    unselectedFloatingLabel: {...customColorObject},
    selectedFloatingLabel: {...customColorObject},
    hoverFloatingLabel: {...customColorObject},

    unselectedBorder: {...customColorObject},
    selectedBorder: {...customColorObject},
    hoverBorder: {...customColorObject},
    hoverSelectedBorder: {...customColorObject},

    unselectedBackground: {...customColorObject},
    selectedBackground: {...customColorObject},
    hoverBackground: {...customColorObject},
    hoverSelectedBackground: {...customColorObject},

    selectedText: {...customColorObject},
    unselectedText: {...customColorObject},
    hoverText: {...customColorObject},
    hoverSelectedText: {...customColorObject},

    fiveDigitZip: true,

    labelPosition: "none",      // none, left, above, below
    labelsObj: cloneObj(customTextObject),         // Font selected here will apply to all labels in address

    labelDisplay: "default",    // default, custom Label Text

    street1Obj: getCustomQuestionAddressTextObj("Street 1", "255"),
    street2Obj: getCustomQuestionAddressTextObj("Street 2", "255"),
    cityObj: getCustomQuestionAddressTextObj("City", "255"),
    stateObj: getCustomQuestionAddressTextObj("State", "255"),
    zipObj: getCustomQuestionAddressTextObj("Zip", "10"),
}

export function getCustomQuestionAddressObj() {
    let addressObj = cloneObj(customQuestionAddressObj);

    setColor(addressObj.placeHolderColor, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);

    setColor(addressObj.unselectedFloatingLabel, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(addressObj.selectedFloatingLabel, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(addressObj.hoverFloatingLabel, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    setColor(addressObj.unselectedBorder, COLOR_GRAY_INDEX, COLOR_GRAY_HEX);
    setColor(addressObj.selectedBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(addressObj.hoverBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);
    setColor(addressObj.hoverSelectedBorder, COLOR_DODGER_BLUE_INDEX, COLOR_DODGER_BLUE_HEX);

    setColor(addressObj.unselectedBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(addressObj.selectedBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(addressObj.hoverBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);
    setColor(addressObj.hoverSelectedBackground, COLOR_WHITE_INDEX, COLOR_WHITE_HEX);

    setColor(addressObj.selectedText, COLOR_BLACK_INDEX, COLOR_BLACK_HEX);
    setColor(addressObj.unselectedText, COLOR_BLACK_INDEX, COLOR_BLACK_HEX);
    setColor(addressObj.hoverText, COLOR_BLACK_INDEX, COLOR_BLACK_HEX);
    setColor(addressObj.hoverSelectedText, COLOR_BLACK_INDEX, COLOR_BLACK_HEX);

    return addressObj;
}

export function setCustomQuestionAddressObj(newObj) {

    let questionObj = cloneObj(customQuestionAddressObj);

    questionObj.text = getCustomTextObj(TEMPLATE.TYPE.QUESTION, TEMPLATE.TEXT_TYPE.QUESTION.ANSWER_TEXT); // Get default Answer text details

    setObj(questionObj, newObj);

    return questionObj;
}


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
// OTHER...
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



// export function getCustomLikertObject(templateType) {
//     let customObj = {...customLikertObject};
//
//     let textDefault = getTextDefaults(templateType);
//
//     customObj.fontSizeEntry = textDefault.fontSizeEntry;
//     customObj.fontSize = textDefault.fontSize;
//     customObj.variant = textDefault.variant;
//
//     return customObj;
// }

// const customRadioObject = {
//     backgroundColor: "",
//     colorType: "default",
//     colorIndex: null,
//     colorEntry: "black",
//     color: "black"
// }
//
//
// export const customQuestionSingleSelect = {
//     indent: 0,
//     arrange: "row",             // row, side, wrap
//     state: "",
//     property: "",
//     color: "default",           // default, custom
//     selectedBorder: customRadioObject,
//     selectedBackground: customRadioObject,
//     selectedControl: customRadioObject,
//     selectedText: customRadioObject,
//     unselectedBorder: customRadioObject,
//     unselectedBackground: customRadioObject,
//     unselectedControl: customRadioObject,
//     unselectedText: customRadioObject
// }
//
//
// export const customQuestionObject =  {
//     questionType: "",
//     displayAs: "single",        // single, likert
//     style: "radios",            // radios, ddlb, standard, clear, outlined
//     likertStyle: "radios",      // radios, buttongroup (squares), circle
//     circleType: "standard",     // standard, primary, alternate
//     showSelectedLabel: "no",    //no, yes
//     ...customLikertObject,
//     ...customQuestionSingleSelect
// };
//
// export function getCustomQuestionObject(questionType) {
//     let obj = {...customQuestionObject};
//
//     obj.questionType = questionType;
//
//     return obj;
// }

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// DEFAULT TEXT OBJECT BY TEMPLATE TYPE
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


export function getTextDefaults(templateType, textEntryType) {

    // NOTE: taken from QD Portal: getTitleDefaultVariant() in displayDefObjConstants.js

    let textDefaultsObj = {
        variant: "",
        fontSizeEntry: "",
        fontSize: ""
    };

    // const h1FontSize = '36px';  // Questionnaire
    // const h2FontSize = '31px';  // Category
    // const h3FontSize = '26px';  // Group
    // const h4FontSize = '21px';  // Question
    // const h5FontSize = '16px';  // Answer
    // const h6FontSize = '16px';  // Info/Comment Gray
    // const captionFontSize = '12px'; // Label
    // const subtitle1FontSize = '12px';  // Label Gray
    // const subtitle2FontSize = '12px';  // Label Secondary Color
    // const body1FontSize = '14px';
    // const body2FontSize = '14px'; // Italic
    // const overlineFontSize = '10px';
    // const buttonFontSize = '32px';

    switch(templateType) {
        case TEMPLATE.TYPE.QUESTIONNAIRE:
            switch(textEntryType) {
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.NAME:
                    textDefaultsObj.variant = "h1";
                    textDefaultsObj.fontSizeEntry = "36";
                    textDefaultsObj.fontSize = "36pt";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.DESC:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.APP_HEADER:
                    textDefaultsObj.variant = "h3";
                    textDefaultsObj.fontSizeEntry = "26";
                    textDefaultsObj.fontSize = "26px";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.PAGE_HEADER:
                    textDefaultsObj.variant = "h3";
                    textDefaultsObj.fontSizeEntry = "26";
                    textDefaultsObj.fontSize = "26px";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.PAGE_FOOTER1:
                    textDefaultsObj.variant = "h3";
                    textDefaultsObj.fontSizeEntry = "26";
                    textDefaultsObj.fontSize = "26px";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.PAGE_FOOTER2:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.START_PAGE_SCRIPT:
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.CLOSE_PAGE_SCRIPT:
                    textDefaultsObj.variant = "h4";
                    textDefaultsObj.fontSizeEntry = "21";
                    textDefaultsObj.fontSize = "21pt";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.START_PAGE_TEXT:
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.CLOSE_PAGE_TEXT:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.PORTAL_SYSTEM_BUTTONS:
                    textDefaultsObj.variant = "body1";
                    textDefaultsObj.fontSizeEntry = "14";
                    textDefaultsObj.fontSize = "14pt";
                    break;
                default:
                    console.log("ArgoSelectPortalTextDisplayType.js Switch default E02"); // eslint-disable-line
            }
            break;
        case TEMPLATE.TYPE.CATEGORY:
            switch(textEntryType) {
                case TEMPLATE.TEXT_TYPE.CATEGORY.NAME:
                    textDefaultsObj.variant = "h2";
                    textDefaultsObj.fontSizeEntry = "31";
                    textDefaultsObj.fontSize = "31pt";
                    break;
                case TEMPLATE.TEXT_TYPE.CATEGORY.DESC:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_TYPE.CATEGORY.SCRIPT:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_TYPE.CATEGORY.HEADER_LEFT:
                    textDefaultsObj.variant = "h4";
                    textDefaultsObj.fontSizeEntry = "21";
                    textDefaultsObj.fontSize = "21pt";
                    break;
                case TEMPLATE.TEXT_TYPE.CATEGORY.HEADER_CENTER:
                    textDefaultsObj.variant = "h4";
                    textDefaultsObj.fontSizeEntry = "21";
                    textDefaultsObj.fontSize = "21pt";
                    break;
                case TEMPLATE.TEXT_TYPE.CATEGORY.HEADER_RIGHT:
                    textDefaultsObj.variant = "h4";
                    textDefaultsObj.fontSizeEntry = "21";
                    textDefaultsObj.fontSize = "21pt";
                    break;
                default:
                    console.log("ArgoSelectPortalTextDisplayType.js.js Switch default E03"); // eslint-disable-line
            }
            break;
        case TEMPLATE.TYPE.GROUP:
            switch(textEntryType) {
                case TEMPLATE.TEXT_TYPE.GROUP.NAME:
                    textDefaultsObj.variant = "h3";
                    textDefaultsObj.fontSizeEntry = "26";
                    textDefaultsObj.fontSize = "26pt";
                    break;
                case TEMPLATE.TEXT_TYPE.GROUP.DESC:
                    textDefaultsObj.variant = "h5";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_TYPE.GROUP.LIKERT_TITLES:
                    textDefaultsObj.variant = "caption";
                    textDefaultsObj.fontSizeEntry = "12";
                    textDefaultsObj.fontSize = "12pt";
                    break;
                case TEMPLATE.TEXT_TYPE.GROUP.RESPONSIVE_LABLES:
                    textDefaultsObj.variant = "caption";
                    textDefaultsObj.fontSizeEntry = "12";
                    textDefaultsObj.fontSize = "12pt";
                    break;
                default:
                    console.log("ArgoSelectPortalTextDisplayType.js Switch default E04"); // eslint-disable-line
            }
            break;
        case TEMPLATE.TYPE.QUESTION:
            switch(textEntryType) {
                case TEMPLATE.TEXT_TYPE.QUESTION.NAME:
                    textDefaultsObj.variant = "h6";
                    textDefaultsObj.fontSizeEntry = "16";
                    textDefaultsObj.fontSize = "16pt";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTION.DESC:
                    textDefaultsObj.variant = "body1";
                    textDefaultsObj.fontSizeEntry = "14";
                    textDefaultsObj.fontSize = "14pt";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTION.LABEL:
                    textDefaultsObj.variant = "caption";
                    textDefaultsObj.fontSizeEntry = "12";
                    textDefaultsObj.fontSize = "12pt";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTION.LIKERT_TITLES:
                    textDefaultsObj.variant = "caption";
                    textDefaultsObj.fontSizeEntry = "12";
                    textDefaultsObj.fontSize = "12pt";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTION.RESPONSIVE_LABLES:
                    textDefaultsObj.variant = "caption";
                    textDefaultsObj.fontSizeEntry = "12";
                    textDefaultsObj.fontSize = "12pt";
                    break;
                case TEMPLATE.TEXT_TYPE.QUESTION.ANSWER_TEXT:
                    textDefaultsObj.variant = "caption";
                    textDefaultsObj.fontSizeEntry = "12";
                    textDefaultsObj.fontSize = "12pt";
                    break;
                default:
                    console.log("ArgoSelectPortalTextDisplayType.js.js Switch default E05"); // eslint-disable-line
            }
            break;
        default:
            console.log("ArgoSelectPortalTextDisplayType.js Switch default E06"); // eslint-disable-line
    }

    return textDefaultsObj;
}


export function convertFontVariantToPoints(variant) {

    let fontSizePoints = "12"

    switch(variant) {
        case "h1":
            fontSizePoints = "36";
            break;
        case "h2":
            fontSizePoints = "31";
            break;
        case "h3":
            fontSizePoints = "26";
            break;
        case "h4":
            fontSizePoints = "21";
            break;
        case "h5":
            fontSizePoints = "16";
            break;
        case "h6":
            fontSizePoints = "16";
            break;
        case "caption":
            fontSizePoints = "12";
            break;
        case "subtitle1":
            fontSizePoints = "12";
            break;
        case "subtitle2":
            fontSizePoints = "12";
            break;
        case "body1":
            fontSizePoints = "14";
            break;
        case "body2":
            fontSizePoints = "14";
            break;
        case "overline":
            fontSizePoints = "10";
            break;
        // no default
    }


    return fontSizePoints;
}


export const textAlignList = [
    {value: "left", text: "Left"},
    {value: "center", text: "Center"},
    {value: "right", text: "Right"}
]

export const fontsList = [
    {value: "arial", text: "Arial"},
    {value: "arialblack", text: "Arial Black"},
    {value: "comic", text: "Comic Sans"},
    {value: "courier", text: "Courier New"},
    {value: "georgia", text: "Georgia"},
    {value: "impact", text: "Impact"},
    {value: "lucida", text: "Lucida"},
    {value: "lucidaSans", text: "Lucida Sans"},
    {value: "palatino", text: "Palatino"},
    {value: "roboto", text: "Roboto"},
    {value: "tahoma", text: "Tahoma"},
    {value: "trebuchet", text: "Trebuchet"},
    {value: "verdana", text: "Verdana"}
];

export const sizingTypeList = [
    {value: "variant", text: "Variant"},
    {value: "points", text: "Points"},
    {value: "pixels", text: "Pixels"}
];

export const variantTypesList = [
    {value: "h1", text: "h1"},
    {value: "h2", text: "h2"},
    {value: "h3", text: "h3"},
    {value: "h4", text: "h4"},
    {value: "h5", text: "h5"},
    {value: "h6", text: "h6"},
    {value: "body1", text: "body1"},
    {value: "body2", text: "body2"},
    {value: "caption", text: "caption"},
    {value: "subtitle1", text: "subtitle1"},
    {value: "subtitle2", text: "subtitle2"}
];

export const fontStyleList = [
    {value: "normal", text: "Normal"},
    {value: "italic", text: "Italic"},
    {value: "oblique", text: "Oblique"}
];

export const fontWeightList = [
    {value: "normal", text: "Normal"},
    {value: "bold", text: "Bold"},
    {value: "bolder", text: "Bolder"}
];





// export const DISPLAY_AS  = "display-as"; // (Question)
// export const DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT  = "display-def-align-question-answer-right"; // (Question)
// export const DISPLAY_DEF_LIKERT_QUESTION_ALIGN_WITH_ANSWERS  = "display-def-likert-question-align-with-answers"; // (Question)
export const DISPLAY_DEF_SHOW_GROUP_STANDARD  = "display-def-show-group-standard"; // (Question)

export const CUSTOM_DDO_ANSWER_FORMAT  = "custom-ddo-answer-format"; // (Answer)

export const USE_LIKERT_ANSWERS_HEADER  = "display-def-use-answers-header"; // (Group/Question)  // Answer Text is titles, like for radios and circles only

export const USE_LIKERT_RESPONSIVE_LABEL  = "display-def-use-responsive-label"; // (Answer) Buttongroup - Display value when selected beneath button group square or circle (Responsive Labels)
export const USE_LIKERT_DEFAULT_RESPONSIVE_LABEL  = "display-def-use-default-responsive-label"; // (Question) Buttongroup - Display DEFAULT value when selected beneath button group square or circle (Responsive Labels)

export const DISPLAY_DEF_RADIO_AS_BUTTON_GROUP  = "display-def-radio-as-buttongroup";
export const DISPLAY_DEF_BUTTON_AS_CIRCLE  = "display-def-button-as-circles";

export const DISPLAY_DEF_HIDE_BUTTONGROUP_NAME  = "display-def-hide-buttongroup-name";






export function getButtonFormatCustomObj() {

    //let defaultFont = getDefaultVariant();

    return {
        variant: "text",
        background: 'white',
        borderColor: '#ff0000',
        borderRadius: 3,
        border: 2,
        lineHeight: 1.5,
        color: 'red',
        height: 48,
        width: 100,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        fontColor: 'red',
        fontSize: 16,
        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
        textOverride: "",
        lebelTextTransform: 'capitalize',
        hoverBackground: 'red',
        hoverBorderColor: '#0000ff',
        hoverBorderRadius: 3,
        hoverBorder: 2,
        hoverLineHeight: 1.5,
        hoverColor: 'yellow',
        hoverHeight: 48,
        hoverWidth: 100,
        hoverPadding: '0 30px',
        hoverBoxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        hoverFontColor: 'green',
        hoverFontSize: 16,
        hoverFontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
    };
}


export function getFontFamily(font = "roboto") {

    let fontFamily = 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif';

    switch (font) {
        case "arial":
            fontFamily = "Arial, Arial, Helvetica, sans-serif";
            break;
        case "arialblack":
            fontFamily = "Arial Black, Arial Black, Gadget, sans-serif";
            break;
        case "comic":
            fontFamily = "Comic Sans MS, Comic Sans MS5, cursive";
            break;
        case "courier":
            fontFamily = "Courier New, Courier New, monospace";
            break;
        case "georgia":
            fontFamily = "Georgia1, Georgia, serif";
            break;
        case "impact":
            fontFamily = "Impact, Impact5, Charcoal6";
            break;
        case "lucida":
            fontFamily = "Lucida Console, Monaco5, monospace";
            break;
        case "lucidasans":
            fontFamily = "Lucida Sans Unicode, Lucida Grande, sans-serif";
            break;
        case "palatino":
            fontFamily = "Palatino Linotype, Book Antiqua3, Palatino, serif";
            break;
        case "roboto":
            fontFamily = "Roboto, \"Helvetica Neue\", Helvetica, Arial, sans-serif";
            break;
        case "tahoma":
            fontFamily = "Tahoma, Geneva, sans-serif";
            break;
        case "times":
            fontFamily = "Times New Roman, Times New Roman, Times, serif";
            break;
        case "trebuchet":
            fontFamily = "Trebuchet MS1, Trebuchet MS, sans-serif";
            break;
        case "verdana":
            fontFamily = "Verdana, Verdana, Geneva, sans-serif";
            break;
        // case "mssans":
        //     fontFamily = "MS Sans Serif4, Geneva, sans-serif";
        //     break;
        // case "msserif":
        //     fontFamily = "MS Serif4, New York6, serif";
        //     break;
        // no default
    }

    return fontFamily;
}





// Single Select Radios --------=---------------------------------------------------------------------------------------

export const DEFAULT_UNSELECTED_RADIO_CONTROL_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_SELECTED_RADIO_CONTROL_COLOR = COLOR_DODGER_BLUE_HEX;
export const DEFAULT_BUTTON_HEIGHT = 38;

// Single Select Outlined Radios --------=------------------------------------------------------------------------------

export const DEFAULT_UNSELECTED_OUTLINED_RADIO_CONTROL_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_SELECTED_OUTLINED_RADIO_CONTROL_COLOR = COLOR_WHITE_HEX;


// Single Select Buttons --------=--------------------------------------------------------------------------------------

export const DEFAULT_UNSELECTED_BUTTON_TEXT_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_BUTTON_BORDER_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_BUTTON_BACKGROUND_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_SELECTED_BUTTON_TEXT_COLOR = COLOR_WHITE_HEX;
export const DEFAULT_SELECTED_BUTTON_BORDER_COLOR = COLOR_DODGER_BLUE_HEX;
export const DEFAULT_SELECTED_BUTTON_BACKGROUND_COLOR = COLOR_DODGER_BLUE_HEX;
export const DEFAULT_HOVER_BUTTON_TEXT_COLOR = COLOR_DODGER_BLUE_HEX;
export const DEFAULT_HOVER_BUTTON_BORDER_COLOR = COLOR_DODGER_BLUE_HEX;
export const DEFAULT_HOVER_BUTTON_BACKGROUND_COLOR = COLOR_WHITE_HEX;
export const DEFAULT_HOVER_SELECTED_BUTTON_TEXT_COLOR = COLOR_WHITE_HEX;
export const DEFAULT_HOVER_SELECTED_BUTTON_BORDER_COLOR = COLOR_DODGER_BLUE_HEX;
export const DEFAULT_HOVER_SELECTED_BUTTON_BACKGROUND_COLOR = COLOR_DODGER_BLUE_HEX;

export const DEFAULT_BUTTON_BORDER_WIDTH = "2";
export const DEFAULT_BUTTON_BORDER_RADIUS = "0";
export const DEFAULT_BUTTON_BOX_SHADOW = "none";

// ---------------------------------------------------------------------------------------------------------------------
// SYSTEM BUTTONS
// ---------------------------------------------------------------------------------------------------------------------



// =====================================================================================================================
// -----------------------------=---------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// =====================================================================================================================

// Text overrides ------------------------------------------------------------------------------------------------------
// Array[0] = text: hide or show | left | center | right
// Array[1] = icon: none | info | comment | default  --- comment and icon can only be used with text show or left
// Array[2] = variant: default | 'h1' | 'h2' | 'h3'  | 'h4'  | 'h5'  | 'h6'  | 'subtitle1'  | 'subtitle2'  | 'body1'  | 'body2'  | 'caption'  | 'button'  | 'overline'


// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const DO_NOT_USE_SHOW_GROUP  = "display-def-do-not-use-show-group"; //(Question), Likert groups share answers so questions with this tag will suppress show groups (NO UI IMPLEMENTATION)





// ---------------------------------------------------------------------------------------------------------------------

export const METADATA_VALUE_PREFIX_PAGENUM  = "#pageNum";  // "#pageNum"
export const METADATA_VALUE_PREFIX_SRC  = "#src=";  // For passing an image URL

// ---------------------------------------------------------------------------------------------------------------------
// Single Select Question Display Overrides (Non-Likert)
// Default display is radio buttons if < 6 answers, otherwise DDLB
// Default position if 2 answers is side-by-side, otherwise one-per-row
// ---------------------------------------------------------------------------------------------------------------------

// export const DISPLAY_SINGLE_SELECT_AS_RADIOS_LABEL_RIGHT  = "display-def-as-radios-right";
// export const DISPLAY_SINGLE_SELECT_AS_RADIOS_LABEL_TOP  = "display-def-as-radios-top";
// export const DISPLAY_SINGLE_SELECT_AS_RADIOS_LABEL_BOTTOM  = "display-def-as-radios-bottom";

// export const DISPLAY_SINGLE_SELECT_AS_RADIOS  = "display-def-as-radios"; // Question Type Yes/No radio is DEFAULT, Single Select
// export const DISPLAY_SINGLE_SELECT_AS_OUTLINED_RADIOS  = "display-def-as-outlined-radios"; // Question Type Yes/No, Single Select
// export const DISPLAY_SINGLE_SELECT_AS_STANDARD_BUTTONS  = "display-def-as-standard-buttons"; // Question Type Yes/No, Single Select
// export const DISPLAY_SINGLE_SELECT_AS_CLEAR_BUTTONS  = "display-def-as-clear-buttons"; // Question Type Yes/No, Single Select
// export const DISPLAY_SINGLE_SELECT_AS_DDLB  = "display-def-as-ddlb"; // Question Type Yes/No, Single Select
//export const DISPLAY_SINGLE_SELECT_AS_BUTTONGROUP  = "display-def-as-buttongroup";

//export const DISPLAY_SINGLE_SELECT_AS_ICON_ONLY  = "display-def-radio-as-icon-only"; // For single select with icons hide the radio button and use the icon as the button

// export const DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE_WRAP  = "display-def-side-by-side-wrap";
// export const DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE  = "display-def-side-by-side"; // Question Type Yes/No defulat is radio, side by side , Single Select
// export const DISPLAY_SINGLE_SELECT_AS_ONE_PER_ROW  = "display-def-one-per-row"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_SIDE_BY_SIDE_SEPARATOR  = "display-def-side-by-side-separator"; // Question Type Yes/No, Single Select

// ---------------------------------------------------------------------------------------------------------------------
// Multi-Select Checkboxes
// ---------------------------------------------------------------------------------------------------------------------

export const UNSELECTED_COLOR_BORDER  = "display-def-unselected-color-border";
export const UNSELECTED_COLOR_BACKGROUND  = "display-def-unselected-color-background";
export const UNSELECTED_COLOR_CONTROL  = "display-def-unselected-color-control"; // (group/question)
export const UNSELECTED_COLOR_TEXT  = "display-def-unselected-color-text";
export const SELECTED_COLOR_BORDER  = "display-def-selected-color-border"; // (group/question)
export const SELECTED_COLOR_BACKGROUND  = "display-def-selected-color-background"; // (group/question)
export const SELECTED_COLOR_CONTROL  = "display-def-selected-color-control"; // (group/question)
export const SELECTED_COLOR_TEXT  = "display-def-selected-color-text";
export const HOVER_COLOR_BORDER  = "display-def-hover-color-border"; // (group/question)
export const HOVER_COLOR_BACKGROUND  = "display-def-hover-color-background"; // (group/question)
export const HOVER_COLOR_TEXT  = "display-def-hover-color-text";
export const HOVER_SELECTED_COLOR_BORDER  = "display-def-hover-selected-color-border"; // (group/question)
export const HOVER_SELECTED_COLOR_BACKGROUND  = "display-def-hover-selected-color-background"; // (group/question)

//export const HOVER_SELECTED_COLOR_CONTROL  = "display-def-hover-selected-color-control"; // (group/question)
export const HOVER_SELECTED_COLOR_TEXT  = "display-def-hover-selected-color-text";

export const BUTTON_BORDER_WIDTH  = "display-def-button-border-width";
export const BUTTON_BORDER_RADIUS  = "display-def-button-border-radius";
export const BUTTON_BOX_SHADOW  = "display-def-button-box-shadow";
export const BUTTON_HEIGHT  = "display-def-button-height";

export const DEFAULT_WIDTH  = "365px"; // (question) radios, outlined buttons, outlined checkboxes
export const OVERRIDE_WIDTH  = "display-def-override-width"; // (question) radios, outlined buttons, outlined checkboxes value = "12px" pixel width
export const CENTER_ANSWERS  = "display-def-center-answers"; // (question) radios, outlined buttons, outlined checkboxes value = "12px" pixel width

export const DISPLAY_CHECKBOXES_AS_OUTLINED  = "display-def-checkboxes-as-outlined";       // (Question) Default display for checkboxes is checkboxes when < 5 answers, otherwise ddlb, this overrides to force checkboxes

// ---------------------------------------------------------------------------------------------------------------------
//
// ---------------------------------------------------------------------------------------------------------------------


// ---------------------------------------------------------------------------------------------------------------------
// Single Select Question Display Overrides (Likert)
// ---------------------------------------------------------------------------------------------------------------------

// export const USE_LIKERT_GRAYBAR_COLOR  = "display-def-use-graybar-color"; // (Group, Question) graybar title backgrond color
// export const USE_LIKERT_GRAYBAR_HEIGHT  = "display-def-use-graybar-height"; // (Group, Question) graybar title height
// export const USE_CUSTOM_QUESTION_FOR_TITLE  = "display-def-use-custom-question-for-title"; // (Group, Question) graybar title overrides
// export const USE_CUSTOM_DESC_FOR_TITLE  = "display-def-use-custom-desc-for-title"; // (Group, Question) graybar title overrides

export const HIDE_BUTTONGROUP_NAME  = "display-def-hide-buttongroup-name";  // (Answer) Buttongroup - Suppress the display of the number in the button of a buttongroup // (Answer) Radios - don't show title above

// export const DISPLAY_BUTTONS_AS_CIRCLES  = "display-def-button-as-circles"; // (Question) Buttongroup
// export const DISPLAY_BUTTONS_AS_CIRCLES_COLORS  = "display-def-button-as-circles-colors"; // (Question) Buttongroup


// ---------------------------------------------------------------------------------------------------------------------
// ANSWSERS SECTION
// ---------------------------------------------------------------------------------------------------------------------


// //export const ANSWER_LABEL_TEXT_COLOR  = "display-def-answer-label-text-color"; // (question) label text color
// export const ANSWER_TEXT_MATCH_QUESTION_NAME  = "display-def-answer-match-question-name"; // (question) match custom question (value can be font size pt override ie.. "10pt"
// export const ANSWER_TEXT_MATCH_QUESTION_DESC  = "display-def-answer-match-question-desc"; // (question) match custom question (value can be font size pt override ie.. "10pt"


// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export function convertColorObjToRgba(colorObj) {

    let color = getColor(colorObj);
    let hex = color.includes("#") ? color : colorNameToHex(color);

    let opacity = getOpacity(colorObj);

    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);

    let rgba = "rgba(" + r + "," + g + "," + b + "," + opacity + ")";

    return rgba;
}

export function colorNameToHex(color)
{
    let colorString = (typeof color === "string") ? color : "black" ;
    var colors = {
        "aliceblue":"#f0f8ff",
        "antiquewhite":"#faebd7",
        "aqua":"#00ffff",
        "aquamarine":"#7fffd4",
        "azure":"#f0ffff",
        "beige":"#f5f5dc",
        "bisque":"#ffe4c4",
        "black":"#000000",
        "blanchedalmond":"#ffebcd",
        "blue":"#0000ff",
        "blueviolet":"#8a2be2",
        "brown":"#a52a2a",
        "burlywood":"#deb887",
        "cadetblue":"#5f9ea0",
        "chartreuse":"#7fff00",
        "chocolate":"#d2691e",
        "coral":"#ff7f50",
        "cornflowerblue":"#6495ed",
        "cornsilk":"#fff8dc",
        "crimson":"#dc143c",
        "cyan":"#00ffff",
        "darkblue":"#00008b",
        "darkcyan":"#008b8b",
        "darkgoldenrod":"#b8860b",
        "darkgray":"#a9a9a9",
        "darkgreen":"#006400",
        "darkkhaki":"#bdb76b",
        "darkmagenta":"#8b008b",
        "darkolivegreen":"#556b2f",
        "darkorange":"#ff8c00",
        "darkorchid":"#9932cc",
        "darkred":"#8b0000",
        "darksalmon":"#e9967a",
        "darkseagreen":"#8fbc8f",
        "darkslateblue":"#483d8b",
        "darkslategray":"#2f4f4f",
        "darkturquoise":"#00ced1",
        "darkviolet":"#9400d3",
        "deeppink":"#ff1493",
        "deepskyblue":"#00bfff",
        "dimgray":"#696969",
        "dodgerblue":"#1e90ff",
        "firebrick":"#b22222",
        "floralwhite":"#fffaf0",
        "forestgreen":"#228b22",
        "fuchsia":"#ff00ff",
        "gainsboro":"#dcdcdc",
        "ghostwhite":"#f8f8ff",
        "gold":"#ffd700",
        "goldenrod":"#daa520",
        "gray":"#808080",
        "green":"#008000",
        "greenyellow":"#adff2f",
        "honeydew":"#f0fff0",
        "hotpink":"#ff69b4",
        "indianred ":"#cd5c5c",
        "indigo":"#4b0082",
        "ivory":"#fffff0",
        "khaki":"#f0e68c",
        "lavender":"#e6e6fa",
        "lavenderblush":"#fff0f5",
        "lawngreen":"#7cfc00",
        "lemonchiffon":"#fffacd",
        "lightblue":"#add8e6",
        "lightcoral":"#f08080",
        "lightcyan":"#e0ffff",
        "lightgoldenrodyellow":"#fafad2",
        "lightgrey":"#d3d3d3",
        "lightgreen":"#90ee90",
        "lightpink":"#ffb6c1",
        "lightsalmon":"#ffa07a",
        "lightseagreen":"#20b2aa",
        "lightskyblue":"#87cefa",
        "lightslategray":"#778899",
        "lightsteelblue":"#b0c4de",
        "lightyellow":"#ffffe0",
        "lime":"#00ff00",
        "limegreen":"#32cd32",
        "linen":"#faf0e6",
        "magenta":"#ff00ff",
        "maroon":"#800000",
        "mediumaquamarine":"#66cdaa",
        "mediumblue":"#0000cd",
        "mediumorchid":"#ba55d3",
        "mediumpurple":"#9370d8",
        "mediumseagreen":"#3cb371",
        "mediumslateblue":"#7b68ee",
        "mediumspringgreen":"#00fa9a",
        "mediumturquoise":"#48d1cc",
        "mediumvioletred":"#c71585",
        "midnightblue":"#191970",
        "mintcream":"#f5fffa",
        "mistyrose":"#ffe4e1",
        "moccasin":"#ffe4b5",
        "navajowhite":"#ffdead",
        "navy":"#000080",
        "oldlace":"#fdf5e6",
        "olive":"#808000",
        "olivedrab":"#6b8e23",
        "orange":"#ffa500",
        "orangered":"#ff4500",
        "orchid":"#da70d6",
        "palegoldenrod":"#eee8aa",
        "palegreen":"#98fb98",
        "paleturquoise":"#afeeee",
        "palevioletred":"#d87093",
        "papayawhip":"#ffefd5",
        "peachpuff":"#ffdab9",
        "peru":"#cd853f",
        "pink":"#ffc0cb",
        "plum":"#dda0dd",
        "powderblue":"#b0e0e6",
        "purple":"#800080",
        "rebeccapurple":"#663399",
        "red":"#ff0000",
        "rosybrown":"#bc8f8f",
        "royalblue":"#4169e1",
        "saddlebrown":"#8b4513",
        "salmon":"#fa8072",
        "sandybrown":"#f4a460",
        "seagreen":"#2e8b57",
        "seashell":"#fff5ee",
        "sienna":"#a0522d",
        "silver":"#c0c0c0",
        "skyblue":"#87ceeb",
        "slateblue":"#6a5acd",
        "slategray":"#708090",
        "snow":"#fffafa",
        "springgreen":"#00ff7f",
        "steelblue":"#4682b4",
        "tan":"#d2b48c",
        "teal":"#008080",
        "thistle":"#d8bfd8",
        "tomato":"#ff6347",
        "turquoise":"#40e0d0",
        "violet":"#ee82ee",
        "wheat":"#f5deb3",
        "white":"#ffffff",
        "whitesmoke":"#f5f5f5",
        "yellow":"#ffff00",
        "yellowgreen":"#9acd32"};

    if (typeof colors[colorString.toLowerCase()] !== 'undefined') {
        return colors[colorString.toLowerCase()];
    }

    return color;
}













import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {bindActionCreators} from "redux";
import {getToken, initializeAuthorization} from "../actions/responseActions";
import {connect} from "react-redux";
import Typography from '@mui/material/Typography';


// based on https://stackoverflow.com/a/51771466
class ErrorPage extends React.Component {

    // constructor(props) {
    //     super(props);
    // }

    // https://stackoverflow.com/a/56559162

    render() {

        return <div>
                <div style={{height: '30px'}}></div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Card style={{width: "300px", alignSelf: "center"}}
                          raised={true}
                          width="150px"
                    >
                        <CardContent>
                            <Typography variant={"h4"}
                                        style={{textAlign: "center"}}>{this.props.message}</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div style={{height: '30px'}}></div>
            </div>
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getToken, initializeAuthorization}, dispatch);
}

function mapStateToProps(state) {
    return {
        message: state.errorResponse.message,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);

import React, {Component} from "react";
import PropTypes from "prop-types";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import * as portalShared from "../../portal/shared/metadataConstantsAndUtilities";


class ArgoQuestionFormatDefinition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            metadataName: "",
            metadataValue: "",
            customFormat: props.initTo
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({customFormat: nextProps.initTo})
    }

    onSelectChange = (event, fieldName) => {

        let selected = event.target.value;

        let tempObj = {};
        switch(this.props.questionType) {
            case portalShared.FREE_TEXT:
            case portalShared.EMAIL:
            case portalShared.PHONE:
            case portalShared.NUMBER:
            case portalShared.CURRENCY:
            case portalShared.DATE:
            case portalShared.DATE_RANGE:
            case portalShared.ZIPPOSTAL_ONLY:
                tempObj = portalShared.getCustomQuestionTextObj(this.props.questionType);
                break;

            case portalShared.YES_NO:
            case portalShared.RADIO:
            case portalShared.STATE_ONLY:
                tempObj = portalShared.getCustomQuestionSingleSelectObj(this.props.questionType);
                break;

            case portalShared.CHECKBOX:
                tempObj = portalShared.getCustomQuestionCheckboxObj();
                break;

            case portalShared.ADDRESS_FULL:
                tempObj = portalShared.getCustomQuestionAddressObj();
                break;

            // no default
        }

        let mpo = this.props.metadataPropsObj;

        this.setState({[fieldName]: selected}, () => {mpo.setCustomMetadataFunction(this.props.fieldTextObjName, mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, selected, mpo.metadataTag, tempObj, mpo.depth)});
    };


    render() {

        return (
            <React.Fragment>
                {((this.props.questionType !== portalShared.STATE_ONLY) && (this.props.questionType !== portalShared.LIKERT_RADIO))   ?
                    <React.Fragment>
                        <div style={{width: "15px"}}></div>

                        <ArgoTextFieldSelectHook
                            fileName="ArgoQuestionFormatDefinition"
                            fieldName="customFormat"
                            label="Portal Format"
                            value={this.state.customFormat}
                            onChange={this.onSelectChange}
                            width="130px"
                            menuItems={[{value: "default", text: "Default"}, {value: "custom", text: "Custom"}]}
                        />

                    </React.Fragment>
                    :
                    <div style={{width: "145px"}}></div>
                }
            </React.Fragment>
        );
    }
}

ArgoQuestionFormatDefinition.defaultProps = {
};

ArgoQuestionFormatDefinition.propTypes = {
    questionType: PropTypes.string,
    fieldName: PropTypes.string,
    fieldTextObjName: PropTypes.string,
    initTo: PropTypes.string,
    metadataPropsObj: PropTypes.object
};

export default ArgoQuestionFormatDefinition;

import * as React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

class ArgoScreenSizePopUp extends React.Component {
    render() {
        return(
            <React.Fragment>
            {window.screen.height === 1080 && window.screen.width === 1920 ? "" :
                <Dialog open={this.props.loggedIn &&
                    this.props.showWelcomeMessage &&
                    localStorage.getItem("hasSeenWelcomeMessage") === "false"} onClose={this.props.closeFunction}>
                    <DialogTitle style={{color: "red"}}>Warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{color: "red"}}>
                            This application was designed to operate with an available screen resolution of 1080 x 1920 or higher.
                            Adjust your screen resolution settings to view the application correctly.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.closeFunction} color="primary">OK</Button>
                    </DialogActions>
                </Dialog>
            }
            </React.Fragment>
        )
    }
}

ArgoScreenSizePopUp.defaultProps = {
    closeFunction: null
};

ArgoScreenSizePopUp.propTypes = {
    loggedIn: PropTypes.bool,
    showWelcomeMessage: PropTypes.bool,
    closeFunction: PropTypes.func
};

function mapStateToProps(state) {
    return {
        loggedIn: state.login.loggedIn,
        showWelcomeMessage: state.login.showWelcomeMessage
    };
}

export default connect(mapStateToProps)(ArgoScreenSizePopUp);


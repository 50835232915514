import React, {PureComponent} from "react";
import Countdown from 'react-countdown';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {styled} from "@mui/material/styles";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {
    getQuestionnaire,
    startQuestionnaire,
    completeQuestionnaire,
    getQuestionnaireInstance,
    getQuestionnaireReadonlyInstance,
    setQuestionnaireTemplate,
    currentPage,
    nextPage,
    prevPage,
    getNewTokenId,
    clearPortalPreviewMode
} from "./actions/portalActions";
import Content from "./Content";
import ErrorPage from "./common/ErrorPage";
import PortalPageRenderer from "./PortalPageRenderer";
import {isRunningInInternetExplorer} from "./utilities/misc";
import * as ddoUtil from "./utilities/createPortalJsonObj";
import * as tag from "./constants/customMetadataConstants";
import * as ddo from "./constants/customObjConstants";
import * as color from "../constants/colorConstants";
import * as portalShared from "./shared/metadataConstantsAndUtilities"
import {COLOR_PRIMARY_BLUE_HEX} from "../constants/colorConstants";
//import {createTheme} from '@mui/material/styles';
//import Hidden from '@mui/material/Hidden';
//import * as mode from "./utilities/displayOneQuestionPerPage";



// const theme = createTheme({
//     palette: {
//         primary: {
//             main: "#01579B"
//         },
//     }
// });

class PortalContainer extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            ddo: null, //Display Definition Object
            getQuestionnaireSent: false,
            currPageObjIndex: 0,
            canceled: false,
            displayMode: portalShared.INSTANT_MODE,
            firstQuestionMode: portalShared.SHOW_FIRST_QUESTION,
            surveyEditMode: portalShared.EDITABLE_SURVEY,
            surveyReadonlyHeaderMode: portalShared.READONLY_SURVEY_URL_SHOW_HEADER_NONE,
            switchOn: false,
            pageTitle: "",
            clearPage: false,
            showTokenExpirationModal: false,
            tokenValidationExpired: false,
            refKey: "",
            parsedJwtExp: 3540000,
            initComponent: true,
            portalCount: 0,
            startEventSent: false
        };
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.questionnaireTemplateGraph) {
            let templateGraphObjFromAdminString = JSON.stringify(nextProps.questionnaireTemplateGraph);
            let templateObjFromStoreString = JSON.stringify(nextProps.questionnaireTemplate); // Saved graph obj from admin in REDUX store

            // When a questionnaire is created for portal preview it will be set into the redux store
            if (nextProps.portalPreview && ((templateGraphObjFromAdminString !== templateObjFromStoreString) || (this.state.ddo === null))) {

                // Portal Preview mode and the templateObj has changed from the one in the redux store
                this.portalPreviewMain(nextProps);
            }
        }
        else if (this.state.ddo === null) {
            if ((this.props.questionnaireInstanceId !== null)&&(!this.state.getQuestionnaireSent)) {
                this.props.getQuestionnaire(this.props.questionnaireInstanceId);  // Get the full instance
                this.setState({getQuestionnaireSent: true});
            }
            else if (nextProps.questionnaireInstance !== null) {

                // ########################################
                // CREATE SURVEY JSON OBJECT FROM INSTANCE
                // ########################################

                let ddo = ddoUtil.createJsonObjFromQuestionnaire(nextProps.questionnaireInstance, portalShared.INSTANT_MODE, this.state.firstQuestionMode, this.state.surveyEditMode, this.state.surveyReadonlyHeaderMode, nextProps.metadata);

                // By default, if NO Portal Tab Title is given then use Questionnaire Name
                let portalTabTitle = nextProps.questionnaireInstance.name;
                if (tag.metadataExists(nextProps.questionnaireInstance, portalShared.PORTAL_TAB_TITLE)) {
                    portalTabTitle = tag.metadataValue(nextProps.questionnaireInstance, portalShared.PORTAL_TAB_TITLE);
                }
                this.setPageTabTitle({title: portalTabTitle});

                this.setState({ddo: ddo});  // Set the Display Definition Object
            }
            else if (nextProps.questionnaireTemplate !== null) {

                // ########################################
                // CREATE SURVEY JSON OBJECT FROM TEMPLATE
                // ########################################

                // If questionnaire portal preview mode or admin override then wait for template from server to build ddo (Display Definition Object)
                let ddo = ddoUtil.createJsonObjFromQuestionnaire(nextProps.questionnaireTemplate, portalShared.TEMPLATE_MODE, portalShared.SHOW_FIRST_QUESTION, portalShared.EDITABLE_SURVEY);

                this.setState({ddo: ddo});  // Set the Display Definition Object
            }
        }
    }

    componentWillUnmount() {
        this.props.clearPortalPreviewMode(); // Clear portal preview redux store props
    }

    initComponent = () => {

        this.setState({initComponent: false});

        if (this.props.portalPreview) {
            this.portalPreviewMain(this.props);
        } else {
            this.portalMain();
        }
    }

    // from https://stackoverflow.com/questions/58917064/dynamic-page-title-with-reactjs
    setPageTabTitle (data = {}) {
        data.title = data.title || 'QD Portal Questionnaire';
        document.title = data.title;
        const favicon = document.querySelector('link[rel="icon"]')
        if (favicon) favicon.href = "/argoBlue.png"
    }

    portalMain = () => {

        this.setPageTabTitle({title: this.state.pageTitle});

        let currURL =  new URL(window.location.href);

        let parsedURL = currURL.pathname.split("/");
        let refKey = parsedURL[2];

        //let refKey = this.props.match.params.key;

        // -------------------------------------------------------------------------------------------------------------
        // Get and PARSE the JWT (JSON Web Token) Security Token to get the timeout amount (JWT Security Token)
        let parsedJWT = this.state.parsedJwtExp;

        let token = localStorage.id_token; // getItem(STORAGE_TOKEN);

        if (localStorage.id_token !== undefined) {

            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            parsedJWT = JSON.parse(jsonPayload); // 1659472470
        }

        let parsedJwtExp = (parsedJWT.exp === undefined) ? this.state.parsedJwtExp : parsedJWT.exp;

        // -------------------------------------------------------------------------------------------------------------

        this.setState({refKey: refKey, parsedJwtExp: parsedJwtExp});

        if (refKey !== undefined) {

            // If a ref key then a query string can answer the first question key?firstQuestionIndex=2
            if (token === "") {
                this.props.getNewTokenId(refKey);
            }

            // show warning message modal for expiring token
            setTimeout(() => {
                this.setState({showTokenExpirationModal: true});
            }, parsedJwtExp);  // default was 3540000

            let urlParams = currURL.search;
            let query = new URLSearchParams(currURL.search); //need new ways to get ?readonly=true and other query options

            let answerIndex = null;
            let surveyEditMode = portalShared.EDITABLE_SURVEY;
            let surveyReadonlyHeaderMode = portalShared.READONLY_SURVEY_URL_SHOW_HEADER_NONE;

            // NOTE: there are 2 types of refKeys, the instance and the readonly instance, if readonly parameter is used you must use the readonly refKey
            // example URL http://localhost:3001/qd2portal/ExIB5E2kqqz95ASxp5mT?readonly=true for readonly mode
            // .get is a function in the new URLSearchParams
            if ((query !== "") && (query.get("readonly") === "true")) {
                // &header=false|filtered parameter NOTE: the questionnaire can use metadata for this HIDE_READONLY_HEADER or FILTERED_READONLY_HEADER
                surveyEditMode = portalShared.READONLY_SURVEY;
                if (query.get("header") === "false") {
                    surveyReadonlyHeaderMode = portalShared.READONLY_SURVEY_URL_SHOW_HEADER_FALSE;
                }

                // NOTE: show header is by default is filtered, but this will OVERRIDE questionnaire hide or show metadata tag.
                if (query.get("header") === "filter") {
                    surveyReadonlyHeaderMode = portalShared.READONLY_SURVEY_URL_SHOW_HEADER_FILTERED;
                }

                if (query.get("header") === "true") {
                    surveyReadonlyHeaderMode = portalShared.READONLY_SURVEY_URL_SHOW_HEADER_TRUE;
                }
            }
            else {

                // I think the way this works is ?answer=###  where ### is the ID of the first answer on the survey
                answerIndex = urlParams.match(/\d+/);
                if (answerIndex) {
                    answerIndex = answerIndex[0];
                } else{
                    answerIndex = null;
                }
            }

            let firstQuestionMode = (answerIndex === null) ? portalShared.SHOW_FIRST_QUESTION : portalShared.SKIP_FIRST_QUESTION;

            // If the first question is answered then start questionnaire will be triggered with get instance and first question answered
            this.setState({firstQuestionMode: firstQuestionMode, displayMode: portalShared.INSTANT_MODE, surveyEditMode: surveyEditMode, surveyReadonlyHeaderMode: surveyReadonlyHeaderMode});

            // NOTE: there are 2 types of refKeys, the instance and the readonly instance, if readonly parameter is used you must use the readonly refKey
            if (surveyEditMode === portalShared.READONLY_SURVEY) {
                this.props.getQuestionnaireReadonlyInstance(refKey, answerIndex); // Get the instance ID
            }
            else {
                this.props.getQuestionnaireInstance(refKey, answerIndex); // Get the instance ID
            }

        }

    }

    portalPreviewMain = (props) => {

        let clonedTemplateObj = null;
        let ddo = null;

        if (this.state.portalCount !== props.portalCount) {
            props.setQuestionnaireTemplate(props.questionnaireTemplateGraph);
            //props.setTemplateObj(props.templateObj);
            props.currentPage(0);

            clonedTemplateObj = portalShared.cloneObj(props.questionnaireTemplateGraph);
            ddo = ddoUtil.createJsonObjFromQuestionnaire(clonedTemplateObj, portalShared.TEMPLATE_MODE, portalShared.SHOW_FIRST_QUESTION, portalShared.EDITABLE_SURVEY);

            // Convert questionnaire template graph to a survey ddo (Display Definition Object)
            //ddo = ddoUtil.createJsonObjFromQuestionnaire(clonedTemplateObj, portalShared.TEMPLATE_MODE, portalShared.SHOW_FIRST_QUESTION, portalShared.EDITABLE_SURVEY);

            this.setState({initComponent: false, portalCount: props.portalCount, ddo: ddo, displayMode: portalShared.TEMPLATE_MODE});
        }

    }

    startSurvey = () => {

        let isInstance = (this.props.questionnaireInstance !== null);
        let qnr =  isInstance ? this.props.questionnaireInstance : this.props.questionnaireTemplate;

        if (isInstance && qnr.started) {
            this.props.startQuestionnaire(qnr.id);
        }
        this.props.nextPage(this.props.currPageIndex);
    };


    cancelSurvey = () => {
        this.setState({canceled: true});
    };


    finishSurvey = () => {

        let isInstance = (this.props.questionnaireInstance !== null);
        let qnr =  isInstance ? this.props.questionnaireInstance : this.props.questionnaireTemplate;

        if (isInstance) {
            this.props.completeQuestionnaire(qnr.id);
        }

        // Check the last category defined, IF it is a new-page "close" then after submit advance to close page
        let categories = isInstance ? qnr.questionCategories : qnr.questionCategoryTemplates;
        let i = categories.length-1;

        if (tag.metadataExistsObj(qnr, portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ) || tag.metadataExistsObj(qnr, portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ_LEGACY)) {
            this.props.nextPage(this.props.currPageIndex);
        }
        else if (tag.metadataExistsInObj(categories[i], portalShared.CUSTOM_QNR_START_PAGE_OBJ, "portalPageBreak", true)) {
            //if (tag.metadataValue(categories[i], portalShared.CATEGORY_NEW_PAGE) === 'close') {
                this.props.nextPage(this.props.currPageIndex);
            //}
        }
        else {
            this.setState({clearPage: true});
        }
    };


    nextPage = () => {
        this.props.nextPage(this.props.currPageIndex);
    };


    prevPage = () => {
        this.props.prevPage(this.props.currPageIndex);
    };

    //
    // closeSurvey = () => {
    //
    // };

    onlyCloseExpirationModal = () => {
        this.setState({showTokenExpirationModal: false});
    };

    closeExpirationModalAndGetNewToken = () => {
        this.props.getNewTokenId(this.state.refKey);

        this.setState({
            showTokenExpirationModal: false,
            tokenValidationExpired: false
        });

        setTimeout(() => {
            this.setState({showTokenExpirationModal: true});
        }, this.state.parsedJwtExp); // 3540000
    };

    disableTokenExpirationModalOkButton = () => {
        this.setState({tokenValidationExpired: true});
    };

    getAppHeaderTitleLegacyObj = (qnr, position, type, titleObj, metadataImageTag) => {
// ZZZZZ
        let returnObj = {
            showImage: false,
            text: "",
            variant: "h2",
            image: "",
            format: "default",
            textObj: null,
            CustomTypography: null
        };

        // Images are stored seperate in metadata because we use Base64 encryption
        if (type === "text") {
            returnObj.text   = titleObj.title;
            returnObj.textObj   = titleObj;
            returnObj.format   = titleObj.format;

            if (titleObj.format === "custom") {
                returnObj.CustomTypography = styled(Typography)({
                    fontFamily: titleObj.fontFamily,
                    fontWeight: titleObj.fontWeight,
                    color: titleObj.colorObj.colorType === "default" ? "white" : titleObj.colorObj.color,
                    fontSize: titleObj.fontSize,  // 2em, 18pt
                    fontStyle: titleObj.fontStyle,
                    textAlign: position,  // left center right
                    opacity: titleObj.opacity
                });
            }
        }
        else if (type === "image") {
            returnObj.showImage   = tag.metadataExists(qnr, metadataImageTag);
            if (returnObj.showImage) {
                returnObj.image   = tag.metadataValue(qnr, metadataImageTag);
            }
        }

        return returnObj;
    }

    getAppHeaderTitleObj = (qnr, position, type, titleObj, metadataImageTag) => {

        let returnObj = {
            showImage: false,
            text: "",
            variant: "h2",
            image: "",
            format: "default",
            textObj: null,
            CustomTypography: null
        };

        // Images are stored seperate in metadata because we use Base64 encryption
        if (type === "text") {
            returnObj.text   = titleObj.title;
            returnObj.textObj   = titleObj;
            returnObj.format   = titleObj.format;

            if (titleObj.format === "custom") {
                returnObj.CustomTypography = styled(Typography)({
                    fontFamily: titleObj.fontFamily,
                    fontWeight: titleObj.fontWeight,
                    color: portalShared.getColor(titleObj.colorObj), // titleObj.colorObj.colorType === "default" ? "white" : titleObj.colorObj.color,
                    fontSize: titleObj.fontSize,  // 2em, 18pt
                    fontStyle: titleObj.fontStyle,
                    textAlign: position,  // left center right
                    opacity: titleObj.opacity
                });
            }
        }
        else if (type === "image") {
            returnObj.showImage   = tag.metadataExists(qnr, metadataImageTag);
            if (returnObj.showImage) {
                returnObj.image   = tag.metadataValue(qnr, metadataImageTag);
            }
        }

        return returnObj;
    }

    getAppHeaderTitle = (titleObj, width, textAlign) => {

        return(<div style={{width: width, display: "flex", flexDirection: "row", justifyContent: textAlign, alignItems: "center"}}>
                {titleObj.showImage ?
                    <img src={`data:image/png;base64,${titleObj.image}`} alt="" style={{verticalAlign: "middle",height: "60px"}}/>
                    :
                    (titleObj.format === "custom") ?
                        <titleObj.CustomTypography>{titleObj.text}</titleObj.CustomTypography>
                        :
                        <Typography variant={titleObj.variant} color="inherit">
                            {titleObj.text}
                        </Typography>
                }
            </div>
        )
    }


    render() {

        let errorMessage = this.props.message;

        if (this.state.initComponent) {
            this.initComponent();
        }

        const MainComponent = isRunningInInternetExplorer() ? `div` : `main`;

        let currPageDefObj = null;
        let totalNumberOfPages = 0;

        //let customColor = "";

        // let uploadedImageStyles = {
        //     verticalAlign: 'middle',
        //     height: '60px'
        // };

        // Check to make sure the ddo (Display Definition Object) has been added to the local state
        if (this.state.ddo !== null && this.props.currPageIndex !== undefined) {
            currPageDefObj = this.state.ddo[ddo.OBJ.KEY.DDO.PAGE_ARRAY][this.props.currPageIndex];
            totalNumberOfPages = this.state.ddo[ddo.OBJ.KEY.DDO.PAGE_ARRAY].length;
        }

        let isInstance = (this.props.questionnaireInstance !== null);
        let qnr =  isInstance ? this.props.questionnaireInstance : this.props.questionnaireTemplate;

        //Commenting out to allow ErrorPage to work. purpose of return unknown
        // if (qnr === null) {
        //     return (<div></div>); // Questionnare NOT yet loaded
        // }

        let showAppBar = (tag.metadataExists(qnr , portalShared.CUSTOM_QNR_APP_HEADER_OBJ, "", true) || tag.metadataExists(qnr , portalShared.CUSTOM_QNR_APP_HEADER_OBJ_LEGACY, "", true));
        let leftTitleObj = null;
        let centerTitleObj = null;
        let rightTitleObj = null;

        // Portal preview does not show appBar
        if (showAppBar && !this.props.portalPreview) {

            if (tag.metadataExists(qnr , portalShared.CUSTOM_QNR_APP_HEADER_OBJ_LEGACY, "", true)) {
                let appHeaderLegacyObj = JSON.parse(tag.metadataValue(qnr , portalShared.CUSTOM_QNR_APP_HEADER_OBJ_LEGACY));
                let appHeaderObj = portalShared.getCustomQuestionnaireAppHeaderObj();

                appHeaderObj.appHeaderLeftType = "image";
                appHeaderObj.appHeaderCenterType = "none";
                appHeaderObj.appHeaderRightType = "text";

                appHeaderObj.appHeaderRightTextObj = portalShared.cloneObj(portalShared.customTextObject);
                appHeaderObj.appHeaderRightTextObj.title = appHeaderLegacyObj.textRight;
                appHeaderObj.appHeaderRightTextObj.format = "custom";
                appHeaderObj.appHeaderRightTextObj.hide = false;
                appHeaderObj.appHeaderRightTextObj.objType = "text";
                appHeaderObj.appHeaderRightTextObj.fontSizing = "points";
                appHeaderObj.appHeaderRightTextObj.fontSizeEntry = "14";
                appHeaderObj.appHeaderRightTextObj.fontSize = "14pt";
                appHeaderObj.appHeaderRightTextObj.variant = "h3";
                appHeaderObj.appHeaderRightTextObj.fontStyle = "normal";
                appHeaderObj.appHeaderRightTextObj.textAlign = "left";
                appHeaderObj.appHeaderRightTextObj.colorObj.color = "#000000";

                leftTitleObj = this.getAppHeaderTitleObj(qnr, "left", appHeaderObj.appHeaderLeftType, appHeaderObj.appHeaderLeftTextObj, portalShared.HEADER_APPBAR_IMAGE_LEFT);
                centerTitleObj = this.getAppHeaderTitleObj(qnr, "center", appHeaderObj.appHeaderCenterType, appHeaderObj.appHeaderCenterTextObj, portalShared.HEADER_APPBAR_IMAGE_CENTER);
                rightTitleObj = this.getAppHeaderTitleObj(qnr, "right", appHeaderObj.appHeaderRightType, appHeaderObj.appHeaderRightTextObj, portalShared.HEADER_APPBAR_IMAGE_RIGHT);
            }
            else {
                // Has to be an instance so use metadata.metadataItems property
                let appHeaderObj = tag.metadataValueObj(qnr, portalShared.CUSTOM_QNR_APP_HEADER_OBJ);

                leftTitleObj = this.getAppHeaderTitleObj(qnr, "left", appHeaderObj.appHeaderLeftType, appHeaderObj.appHeaderLeftTextObj, portalShared.HEADER_APPBAR_IMAGE_LEFT);
                centerTitleObj = this.getAppHeaderTitleObj(qnr, "center", appHeaderObj.appHeaderCenterType, appHeaderObj.appHeaderCenterTextObj, portalShared.HEADER_APPBAR_IMAGE_CENTER);
                rightTitleObj = this.getAppHeaderTitleObj(qnr, "right", appHeaderObj.appHeaderRightType, appHeaderObj.appHeaderRightTextObj, portalShared.HEADER_APPBAR_IMAGE_RIGHT);
            }
        }

        // If no start page then START event is sent when survey loaded
        if (!tag.metadataExists(qnr, portalShared.CUSTOM_QNR_START_PAGE_OBJ)) {
            if (qnr) {
                if ((isInstance) && ((qnr.started !== undefined) && !qnr.started && !this.state.startEventSent)) {
                    this.setState({startEventSent: true});
                    this.props.startQuestionnaire(qnr.id);
                }
            }
        }

        let mainPadding = "20px 0px 20px 0px";
        if (!this.props.portalPreview && showAppBar)  {
            // Portal Preview will NEVER have an AppBar
            mainPadding = "80px 0px 20px 0px"; // If there is an AppBar then adjust padding to push survey below AppBar
        }

        return (
            (this.props.questionnaireInstance || this.props.questionnaireTemplate || errorMessage !== "") &&

            <div style={{height: '100%', width: "100%", display: "flex", flexDirection: "column"}}>
                {(!this.props.portalPreview && showAppBar)  ?

                        <AppBar position="fixed" component="div" style={{backgroundColor: color.COLOR_PRIMARY_BLUE_HEX}} elevation={0}>
                            <Toolbar>
                                <div style={{display: "inline-flex", width: "100%"}}>
                                    {this.getAppHeaderTitle(leftTitleObj, "30%", "left")}
                                    {this.getAppHeaderTitle(centerTitleObj, "40%", "center")}
                                    {this.getAppHeaderTitle(rightTitleObj, "30%", "right")}
                                </div>
                            </Toolbar>
                        </AppBar> : ""
                }

                <MainComponent style={{backgroundColor: '#FAFAFA', display: "flex", overflowY: "auto", overflowX: "hidden", alignItems: "center", padding: mainPadding}}>
                    {this.state.clearPage ? <div></div> :
                        <Content>

                            {(currPageDefObj !== null) ?
                                <PortalPageRenderer pageDefObj={currPageDefObj}
                                                    pageDefObjCurrIndex={this.props.currPageIndex}
                                                    totalPages={totalNumberOfPages}
                                                    questionnaireInstance={this.props.questionnaireInstance}
                                                    questionnaireTemplate={this.props.questionnaireTemplate}
                                                    style={{width: '1000px'}}
                                                    start={this.startSurvey}
                                                    cancel={this.cancelSurvey.bind(this)}
                                                    finish={this.finishSurvey.bind(this)}
                                                    next={this.nextPage}
                                                    previous={this.prevPage}
                                                    close={this.closeSurvey}
                                                    answerQuestionOptions={this.props.answerQuestionOptions}
                                                    answerQuestionTemplateOptions={this.props.answerQuestionTemplateOptions}
                                                    answerQuestionText={this.props.answerQuestionText}
                                                    answerQuestionTemplateText={this.props.answerQuestionTemplateText}
                                                    displayMode={this.state.displayMode}
                                                    surveyEditMode={this.state.surveyEditMode}
                                                    templateType={this.props.templateType}
                                                    portalPreview={this.props.portalPreview}
                                                    portalCount={this.props.portalCount}
                                />
                                :
                                <ErrorPage/>
                            }
                        </Content>
                    }
                </MainComponent>
                {
                    this.state.showTokenExpirationModal ?
                        <div style={{position: 'absolute'}}>
                            <Modal
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                open={this.state.showTokenExpirationModal}
                                onClose={this.onlyCloseExpirationModal}
                                style={{display: "flex",alignItems: "center",justifyContent: "center"}}
                            >
                                <div style={{backgroundColor: 'white', width: '500px', height: '200px', padding: '10px'}}>
                                    {
                                        this.state.tokenValidationExpired ?
                                            <Typography>Session Expired</Typography> :
                                            <div>
                                                <Typography variant="h6" id="modal-title" style={{color: "red", marginBottom: '10px'}}>
                                                    Warning: Your session is about to expire
                                                </Typography>
                                                <Typography variant="subtitle1" id="simple-modal-description" style={{marginBottom: '20px'}}>
                                                    Click "OK" to continue your session, otherwise your session will expire in:
                                                </Typography>
                                                <Countdown date={Date.now() + 60000} onComplete={this.disableTokenExpirationModalOkButton}/>
                                                <div style={{display: "flex", justifyContent: "center"}}>
                                                    <Button disabled={this.state.tokenValidationExpired} style={{border: 'solid', borderColor: 'black', borderWidth: '2px', marginTop: '30px'}}
                                                            onClick={this.closeExpirationModalAndGetNewToken}
                                                    >
                                                        OK
                                                    </Button>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </Modal>
                        </div>
                        : null
                }
            </div>
        );
    }
}


PortalContainer.defaultProps = {
    portalPreview: false,
    templateType: "",
    templateId: null,
    templateObj: null,
    questionnaireInstanceId: null,
    questionnaireInstance: null,
    questionnaireTemplateId: null,
    questionnaireTemplate: null
};


PortalContainer.propTypes = {
    portalPreview: PropTypes.bool,
    templateType: PropTypes.string,
    templateId: PropTypes.number,
    templateObj: PropTypes.object,
    questionnaireTemplateGraph: PropTypes.object,
    portalCount: PropTypes.number,
};


function mapStateToProps(state) {
    return {
        theme: state.portalTheme,
        refKey: state.portal.refKey,
        metadata: state.portal.metadata,
        questionnaireInstanceId: state.portal.questionnaireInstanceId,
        questionnaireInstance: state.portal.questionnaireInstance,
        questionnaireTemplateId: state.portal.questionnaireTemplateId,
        questionnaireTemplate: state.portal.questionnaireTemplate,
        currPageIndex: state.portal.currPageIndex,
        portal: state.portal,
        message: state.errorResponse.message,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        dispatch,
        startQuestionnaire,
        completeQuestionnaire,
        getQuestionnaire,
        getQuestionnaireInstance,
        getNewTokenId,
        getQuestionnaireReadonlyInstance,
        setQuestionnaireTemplate,
        currentPage,
        nextPage,
        prevPage,
        clearPortalPreviewMode
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalContainer);

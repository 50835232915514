import React, {Component} from 'react';
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';
import {getQuestionnaireTemplate,
    createQuestionnaireTemplateAndCloseDrawer,
    saveQuestionnaireTemplateAndCloseDrawer,
    cloneQuestionnaireTemplateAndCloseDrawer,
    questionnaireTemplateInit,
    questionnaireTemplateClone} from "../../../actions/questionnaireTemplateActions";
import {notificationOpen} from "../../../actions/notificationActions";
import QuestionnaireEditor from "./QuestionnaireEditor";
import UserContext from "../../../UserContext";
import {QD_URL} from "../../../constants/systemConstants";
import * as TEMPLATE from "../../../constants/templateConstants";


class QuestionnaireContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            templateKey: "",
            createMode: true,
            cloneMode: false,
            saveToolbarButtonDisabled: true,
            toolbarErrorText: ""
        };
    }

    static contextType = UserContext;  // From blueTheme.js

    componentDidMount() {

        const currURL = window.location.href;
        const objURL = new URL(currURL);

        let pathnameArray = objURL.pathname.split("/");

        let createMode = (pathnameArray[3] === ""); // Must be in create mode
        let templateKey = pathnameArray[3];
        let templateId = createMode ? null : parseInt(pathnameArray[4]);
        let cloneMode = createMode ? false : (pathnameArray[5] === "1"); // Clone mode "0" = false, "1" = true

        if (!createMode) {
            this.props.getQuestionnaireTemplate(templateKey, templateId);
        }

        this.setState({templateKey, templateId, createMode, cloneMode});
    }

    saveClone = (templateEditor, options) => {
        if (options.hasError) {
            this.props.notificationOpen("Validate fields before saving.");
            return;
        }

        let updatedTemplate = {
            ...templateEditor,
            categoryKeys: this.props.categoryKeysEditor
        };

        // Don't clone any published data
        updatedTemplate.startDate = null;
        updatedTemplate.endDate = null;
        updatedTemplate.notes = "";

        this.props.questionnaireTemplateClone();
        this.props.cloneQuestionnaireTemplateAndCloseDrawer(updatedTemplate, this.props.filter, this.props.versionsFilter, TEMPLATE.MODE.CURRENT, this.navigateToQuestionnaireList);
    };

    save = (templateEditor, options) => {
        this.props.questionnaireTemplateInit();
        if (options.hasError) {
            this.props.notificationOpen("Validate fields before saving.");
            return;
        }

        let updatedTemplate = {
            ...templateEditor,
            categoryKeys: this.props.categoryKeysEditor,
        };

        // -------------------------------------------------------------------------------------------------------------

        if (this.state.createMode) {
            updatedTemplate.questionnaireType = this.props.questionnaireType;

            this.props.createQuestionnaireTemplateAndCloseDrawer(updatedTemplate, this.props.filter, this.navigateToQuestionnaireList);
        }
        else {
            this.props.saveQuestionnaireTemplateAndCloseDrawer(updatedTemplate, this.props.filter, this.props.versionsFilter, TEMPLATE.MODE.CURRENT, this.navigateToQuestionnaireList);
        }
    };

    handleToolbarButtonClick = (event, fieldName, buttonTitle, templateEditor, options) => {
        switch (buttonTitle) {
            case "SAVE":
                if (this.state.cloneMode) {
                    this.saveClone(templateEditor, options);
                }
                else {
                    this.save(templateEditor, options);
                }
                break;
            case "CANCEL":
                this.navigateToQuestionnaireList();
                break;
            // no default
        }
    };

    navigateToQuestionnaireList = () => {
        this.props.navHook(QD_URL + "/questionnaires", {replace: true});
    };


    render() {

        return (
            <div id="QuestionnaireContainer-main-div">

                <div id="QuestionnaireContainer-container-div" style={{padding: "0px 0px", overflow: "visible", marginTop: "64px"}}>

                    <QuestionnaireEditor
                        createMode={this.state.createMode}
                        templateKey={this.state.templateKey}
                        templateId={this.state.templateId}
                        cloneMode={this.state.cloneMode}
                        width="100%"
                        handleToolbarButtonClick={this.handleToolbarButtonClick}
                    />

                </div>
            </div>
        );
    }
}

QuestionnaireContainer.defaultProps = {

};

QuestionnaireContainer.propTypes = {

};

function mapStateToProps(state) {
    return {
        categoryKeysEditor: state.questionnaireEditor.categoryKeys,
        questionnaireType: state.questionnaireEditor.questionnaireType,
        filter: state.filter[TEMPLATE.FILTER.current.questionnaire],
        versionsFilter: state.filter[TEMPLATE.FILTER.versions.questionnaire]
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getQuestionnaireTemplate,
        createQuestionnaireTemplateAndCloseDrawer,
        saveQuestionnaireTemplateAndCloseDrawer,
        cloneQuestionnaireTemplateAndCloseDrawer,
        notificationOpen,
        questionnaireTemplateInit,
        questionnaireTemplateClone
    }, dispatch);
}


function myParams(Component) {
    return props => <Component {...props} navHook={useNavigate()} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(myParams(QuestionnaireContainer));


export const pageEmpty = {
    pageNumber: 0,
    pageSize: null,
    totalRecords: 0
};

export const pageDefault = {
    pageNumber: 1,
    pageSize: 10,
    totalRecords: 0
};

// Used by main selectfields for number of menuitems
export const selectPageDefault = {
    pageNumber: 1,
    pageSize: 10,
    totalRecords: 0
};

// Used by select existing tags DDLB
export const selectDdlbPageDefault = {
    pageNumber: 1,
    pageSize: 20,
    totalRecords: 0
};

// List pages default to sorted by last updated
export const sortDefault = {
    name: "Last updated",
    direction: "desc",
};

// DDLB's we want sorted by title
export const selectSortDefault = {
    name: "Title",
    direction: "asc"
};

export const filterDefault = {
    title: "",
    nameQualifier: "",
    updatedBy: "",
    status: "",
    type:"",
    notType:"",
    integrationType:"",
    outstanding: false,
    current: false,
    tags: [],
    templateKey: "",  // Used by versions pages to get selected key
    templateName: "", // Used by versions pages to get selected name
    templateNameQualifier: "", // Used by versions pages to get selected nameQualifier
    mode: "",         // used by the selectExistingFilter to know which templateType (category, group or question)
    list: [],         // used by the selectExistingFilter to store DDLB items
    defaultActions: {},
    ucid: ""
};

// used by the some DDLBs that need to have no pageing, sorting or filtering
export const selectDdlbSortFilterObject = {
    ...selectDdlbPageDefault,
    ...selectSortDefault,
    ...filterDefault
};

// noFilter is used by DDLBs that need to have no pageing, sorting or filtering
export const selectPageSortFilterObject = {
    ...selectPageDefault,
    ...selectSortDefault,
    ...filterDefault
};

// defaultfilter is used by screen inits that need to have default pageing, sorting or filtering
export const initialPageSortFilterObject = {
    ...pageDefault,
    ...sortDefault,
    ...filterDefault
};

export const initialIntegrationLogsPageSortFilterObject = {
    ...pageDefault,
    name: "createDate",
    direction: "desc",
    ...filterDefault
};








import {axiosWrap, POST} from "./axiosWrapper";
import {systemConstants, STORAGE_USERNAME} from "../constants/systemConstants";

let token = null;

export function getAuthorizationToken(username, password, rememberMe) {
    let data = {
        username,
        password,
        rememberMe
    };

    data = JSON.stringify(data);

    return axiosWrap(POST, systemConstants.QD_API_AUTH_URL, data).then(
        data => {
            token = data.id_token;
            return data; //data is in the form of {"id_token": jfaslka}
        },
        error => {
            return error;
        }
    );
}

export function getToken() {
    return token;
}

export function clearStoredToken() {
    token = null;
}

export function getUserName() {
    let username = "";

    if (localStorage.getItem(STORAGE_USERNAME))
    {
        username = localStorage.getItem(STORAGE_USERNAME);
    } else if (sessionStorage.getItem(STORAGE_USERNAME))
    {
        username = sessionStorage.getItem(STORAGE_USERNAME);
    }

    return username;
}

import * as font from "../utilities/fontUtilities";
import * as tag from "../constants/customMetadataConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"
import * as TEMPLATE from "../../constants/templateConstants";


export const RESPONSIVE_LABEL_DEFAULT = {
    ANSWERS_1_2: {
        "N1": "Poor",
        "N2": "Good"
    },
    ANSWERS_1_3: {
        "N1": "Poor",
        "N2": "Average",
        "N3": "Good"
    },
    ANSWERS_1_4: {
        "N1": "Very Poor",
        "N2": "Poor",
        "N3": "Good",
        "N4": "Very Good"
    },
    ANSWERS_1_5: {
        "N1": "Very Poor",
        "N2": "Poor",
        "N3": "Average",
        "N4": "Good",
        "N5": "Very Good"
    },
    ANSWERS_1_6: {
        "N1": "Extremely Poor",
        "N2": "Very Poor",
        "N3": "Poor",
        "N4": "Good",
        "N5": "Very Good",
        "N6": "Extremely Good"
    },
    ANSWERS_1_7: {
        "N1": "Extremely Poor",
        "N2": "Very Poor",
        "N3": "Poor",
        "N4": "Average",
        "N5": "Good",
        "N6": "Very Good",
        "N7": "Extremely Good"
    },
    ANSWERS_1_10: {
        "N1": "Extremely Poor",
        "N2": "Very Poor",
        "N3": "Poor",
        "N4": "Semi-Poor",
        "N5": "Below Average",
        "N6": "Above Average",
        "N7": "Semi-Good",
        "N8": "Good",
        "N9": "Very Good",
        "N10": "Extremely Good"
    },
    ANSWERS_0_10: {
        "N0": "Extremely Poor",
        "N1": "Very Poor",
        "N2": "Poor",
        "N3": "Semi-Poor",
        "N4": "Below Average",
        "N5": "Average",
        "N6": "Above Average",
        "N7": "Semi-Good",
        "N8": "Good",
        "N9": "Very Good",
        "N10": "Extremely Good"
    }
};


// parent template could be a group or question.
export function getResponsiveLabelProperties(customTemplateTag, parentTemplate) {

    let templateType = (customTemplateTag === portalShared.CUSTOM_DDO_GRP_FORMAT ? TEMPLATE.TYPE.GROUP : TEMPLATE.TYPE.QUESTION);

    let fontsObj = portalShared.getTextDefaults(templateType, TEMPLATE.TEXT_TYPE.GROUP.RESPONSIVE_LABLES);

    let responsiveLabelsObj = {
        fontFamily: font.DEFAULT_FONT_FAMILY,
        width: "100%",
        fontSize: fontsObj.fontSize,
        textAlign: "center",
        color: "black",
        colorEntry: "default",
        fontWeight: "normal",
        fontStyle: "normal",
        opacity: "100%"
    }


    let customTemplateObj = null;
    if (tag.metadataExistsObj(parentTemplate, customTemplateTag)) {
        customTemplateObj = tag.metadataValueObj(parentTemplate, customTemplateTag);
    }

    if ((customTemplateObj !== null) && (typeof customTemplateObj === "object") && (customTemplateObj.likertObj.responsiveLabelsCustomTextObj.format === "custom")) {
        responsiveLabelsObj = {
            fontFamily: customTemplateObj.likertObj.responsiveLabelsCustomTextObj.fontFamily,
            fontSize: customTemplateObj.likertObj.responsiveLabelsCustomTextObj.fontSize,
            fontWeight: customTemplateObj.likertObj.responsiveLabelsCustomTextObj.fontWeight,
            fontStyle: customTemplateObj.likertObj.responsiveLabelsCustomTextObj.fontStyle,
            textAlign: "center",
            width: "100%",
            color: portalShared.getColor(customTemplateObj.likertObj.responsiveLabelsCustomTextObj.colorObj),
            colorEntry: customTemplateObj.likertObj.responsiveLabelsCustomTextObj.colorObj.colorEntry,
            opacity: customTemplateObj.likertObj.responsiveLabelsCustomTextObj.colorObj.opacity,
        }
    }

    return responsiveLabelsObj;
}




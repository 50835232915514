import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "../../common/ArgoTextFieldSelectHook";
import ArgoIconButtonToggleViewFieldTextHook from "../../common/ArgoIconButtonToggleViewFieldTextHook";
import * as validate from "../../../utilities/validate";
import {
    initializeEditorEdit,
    addIntegration,
    updateIntegrationStore,
    clearEditor,
    getIntegration,
    checkIntegrationNameForDup
} from "../../../actions/integrationEditorActions";
import {IntegrationTypes} from "../../../constants/integrationConstants";


class IntegrationEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            nameErrorText: " ",
            typeErrorText: "",
            toolbarErrorText: "",
            urlErrorText: "",
            apiKey1ErrorText: "",
            apikey2ErrorText: "",
            info3ErrorText: "",
            info4ErrorText: "",
            info5ErrorText: "",
            startName: "",
            viewApiKey1: false,
            viewApiKey2: false,
        };
    }

    componentWillMount() {
        if (this.props.id) {
            this.setState({isLoading: true});
            this.props.getIntegration(this.props.id);
        }

        this.checkForErrorsOnPage(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isLoading && nextProps.integration) {
            this.setState({isLoading: false, startName: nextProps.integration.name});
            nextProps.initializeEditorEdit(nextProps.integration);
        }

        this.checkForErrorsOnPage(nextProps);
    }

    componentWillUnmount() {
        this.props.clearEditor();
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "SAVE":
                this.props.save(this.props.integration);
                break;
            case "CANCEL":
                this.props.cancel();
                break;
            // no default
        }
    }

    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        let updatedIntegration = {...this.props.integration}; // copy integration template

        // Because of issues with the ApiKeys being treated as passwords, I modified the way change is stored to keep prev entries and not the ****
        if ((fieldName === "apiKey1") || (fieldName === "apiKey2"))
        {
            let newPayload = "";
            if (value !== "") {
                for (let i = 0; i < value.length; i++) {
                    if (value.substring(i, i + 1) === "*") {
                        newPayload = newPayload + updatedIntegration[fieldName].substring(i, i + 1);
                    }
                    else {
                        newPayload = newPayload + value.substring(i, i + 1);
                        updatedIntegration[fieldName] = updatedIntegration[fieldName].substring(0,i) + " " + updatedIntegration[fieldName].substring(i);
                    }
                }
            }

            updatedIntegration[fieldName] = newPayload.replace(/\s/g, '');
            //updatedIntegration[fieldName] = value.length < updatedIntegration[fieldName].length ? value : updatedIntegration[fieldName] + value.substring(updatedIntegration[fieldName].length);
        }
        else
        {
            updatedIntegration[fieldName] = value;
        }
        this.props.updateIntegrationStore(updatedIntegration);

        if (fieldName === "name")
        {
            this.props.checkIntegrationNameForDup(updatedIntegration.name);
        }

    };

    checkForErrorsOnPage = (props) => {
        let isOAUTH2 = props.integration.integrationType === "OAUTH2";
        let nameErrorText = validate.templateNameUnique(props, "Name")
        let typeErrorText = props.integration.integrationType.length === 0 ? "Type is required" : " ";
        let urlErrorText = props.integration.integrationUrl.length === 0 ? "URL is required." : " ";
        let apiKey1ErrorText = props.integration.apiKey1.length === 0 ? isOAUTH2 ? "Client ID is required." : "API Key 1 is required." : " ";
        let apiKey2ErrorText = props.integration.apiKey2.length === 0 ? isOAUTH2 ? "Secret is required." : "API Key 2 is required." : " ";
        let info3ErrorText = isOAUTH2 ? props.integration.info3.length === 0 ? "Token Endpoint is required." : " " : " ";
        let info4ErrorText = isOAUTH2 ? props.integration.info4.length === 0 ? "Grant Type is required." : " " : " ";
        let info5ErrorText = isOAUTH2 ? props.integration.info5.length === 0 ? "Scope is required." : " " : " ";
        let toolbarErrorText = "";

        if (nameErrorText !== " ") {
            toolbarErrorText = nameErrorText;
        }

        if (typeErrorText !== " ") {
            toolbarErrorText = toolbarErrorText === "" ? typeErrorText : toolbarErrorText + ",  " + typeErrorText;
        }

        if (urlErrorText !== " ") {
            toolbarErrorText = toolbarErrorText === "" ? urlErrorText : toolbarErrorText + ",  " + urlErrorText;
        }

        if (apiKey1ErrorText !== " ") {
            toolbarErrorText = toolbarErrorText === "" ? apiKey1ErrorText : toolbarErrorText + ",  " + apiKey1ErrorText;
        }

        if (apiKey2ErrorText !== " ") {
            toolbarErrorText = toolbarErrorText === "" ? apiKey2ErrorText : toolbarErrorText + ",  " + apiKey2ErrorText;
        }

        if (info3ErrorText !== " ") {
            toolbarErrorText = toolbarErrorText === "" ? info3ErrorText : toolbarErrorText + ",  " + info3ErrorText;
        }

        if (info4ErrorText !== " ") {
            toolbarErrorText = toolbarErrorText === "" ? info4ErrorText : toolbarErrorText + ",  " + info4ErrorText;
        }

        if (info5ErrorText !== " ") {
            toolbarErrorText = toolbarErrorText === "" ? info5ErrorText : toolbarErrorText + ",  " + info5ErrorText;
        }

        this.setState({nameErrorText, typeErrorText, urlErrorText, apiKey1ErrorText, apiKey2ErrorText, info3ErrorText, info4ErrorText, info5ErrorText, toolbarErrorText});
    };

    toggleViewApiKey = (fieldName, value) => {
        this.setState({[fieldName]: value});
    };

    // For future use when Test Connection Button is ready
    // testConnection = (id, DTO) => {
    //     // Test Connection
    // };

    render() {
        if (this.state.isLoading) {
            //TODO place a loading screen here
            return null;
        }

        return (
            <div style={{height: "100%"}}>

                <ArgoToolbarHook
                    fileName="IntegrationEditor"
                    fieldName="save-or-cancel"
                    toolbarErrorText={this.state.toolbarErrorText}
                    onClick={this.handleToolbarButtonClick}
                    width={this.props.width}
                />

                <div id="IntegrationEditor-height-div" style={{height: "50px"}}>&nbsp;</div>

                <div style={{height: "100%", marginTop: "30px"}}>
                    <div style={{justifyContent: "space-between"}}>
                        <div style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                            <ArgoTextFieldHook
                                fileName="IntegrationEditor"
                                fieldName="name"
                                label="Name"
                                value={this.props.integration.name}
                                multiline
                                autoFocus
                                onChange={this.onTextChange}
                                errorText={this.state.nameErrorText}
                            />

                            <ArgoTextFieldSelectHook
                                fileName="IntegrationEditor"
                                fieldName="integrationType"
                                label="Type"
                                value={this.props.integration.integrationType}
                                menuItems={IntegrationTypes}
                                onChange={this.onTextChange}
                                errorText={this.state.typeErrorText}
                            />

                            <ArgoTextFieldHook
                                fileName="IntegrationEditor"
                                fieldName="integrationUrl"
                                label="URL"
                                value={this.props.integration.integrationUrl}
                                multiline
                                autoFocus
                                onChange={this.onTextChange}
                                errorText={this.state.urlErrorText}
                            />

                            <div style={{width: "100%", display: "flex"}}>

                                <ArgoTextFieldHook
                                    fileName="IntegrationEditor"
                                    fieldName="apiKey1"
                                    label={this.props.integration.integrationType === "OAUTH2" ? "Client ID" : "API Key 1"}
                                    value={this.state.viewApiKey1 ? this.props.integration.apiKey1 : "*".repeat(this.props.integration.apiKey1.length)}
                                    multiline
                                    autoFocus
                                    onChange={this.onTextChange}
                                    errorText={this.state.apiKey1ErrorText}
                                />

                                <ArgoIconButtonToggleViewFieldTextHook
                                    fileName="IntegrationEditor"
                                    fieldName="viewApiKey1"
                                    onClick={this.toggleViewApiKey}
                                />

                            </div>

                            <div style={{width: "100%", display: "flex"}}>

                                <ArgoTextFieldHook
                                    fileName="IntegrationEditor"
                                    fieldName="apiKey2"
                                    label={this.props.integration.integrationType === "OAUTH2" ? "Secret" : "API Key 2"}
                                    value={this.state.viewApiKey2 ? this.props.integration.apiKey2 : "*".repeat(this.props.integration.apiKey2.length)}
                                    multiline
                                    autoFocus
                                    onChange={this.onTextChange}
                                    errorText={this.state.apiKey2ErrorText}
                                />

                                <ArgoIconButtonToggleViewFieldTextHook
                                    fileName="IntegrationEditor"
                                    fieldName="viewApiKey2"
                                    onClick={this.toggleViewApiKey}
                                />
                            </div>

                            {this.props.integration.integrationType === "OAUTH2" ?
                                <div>
                                    <ArgoTextFieldHook
                                        fileName="IntegrationEditor"
                                        fieldName="info3"
                                        label="Token Endpoint"
                                        value={this.props.integration.info3}
                                        multiline
                                        autoFocus
                                        onChange={this.onTextChange}
                                        errorText={this.state.info3ErrorText}
                                    />

                                    <ArgoTextFieldHook
                                        fileName="IntegrationEditor"
                                        fieldName="info4"
                                        label="Grant Type"
                                        value={this.props.integration.info4}
                                        multiline
                                        autoFocus
                                        onChange={this.onTextChange}
                                        errorText={this.state.info4ErrorText}
                                    />

                                    <ArgoTextFieldHook
                                        fileName="IntegrationEditor"
                                        fieldName="info5"
                                        label="Scope"
                                        value={this.props.integration.info5}
                                        multiline
                                        autoFocus
                                        onChange={this.onTextChange}
                                        errorText={this.state.info5ErrorText}
                                    />
                                </div> : ""}

                            {/*<Button id="IntegrationEditor-test-connection" variant="contained" primary style={IntegrationEditorStyle.button}*/}
                            {/*onClick={this.testConnection.bind(this, "", "")} tabIndex={1}>TEST CONNECTION</Button>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

IntegrationEditor.propTypes = {
    id: PropTypes.number,
    integration: PropTypes.object,
    options: PropTypes.object,
    initializeEditorEdit: PropTypes.func,
    updateIntegrationStore: PropTypes.func,
    clearEditor: PropTypes.func,
    getIntegration: PropTypes.func,
    save: PropTypes.func,
    cancel: PropTypes.func,
    width: PropTypes.string
};

function mapStateToProps(state) {
    return {
        integration: state.integrationEditor.integration,
        options: state.integrationEditor.options
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        initializeEditorEdit,
        addIntegration,
        updateIntegrationStore,
        clearEditor,
        getIntegration,
        checkIntegrationNameForDup
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationEditor);

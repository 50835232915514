import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import ChatIcon from '@mui/icons-material/Chat';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import AnnouncementTwoToneIcon from '@mui/icons-material/AnnouncementTwoTone';
import CommentIcon from '@mui/icons-material/Comment';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';
import {styled} from "@mui/material/styles";
import PortalQuestion from "./PortalQuestion";
import {answerQuestionOptions, answerQuestionTemplateOptions, answerQuestionText, answerQuestionTemplateText} from "./actions/portalActions";
import {getCategoryTitleBar} from "./utilities/getCategoryTitleBar";
import * as custom from "./utilities/getCustomTypography";
import * as font from "./utilities/fontUtilities";
import * as ddo from "./constants/customObjConstants";
import * as tag from "./constants/customMetadataConstants";
import * as portalShared from "./shared/metadataConstantsAndUtilities"
import green_checkmark from './constants/green_checkmark.png'
import {TITLE} from "./constants/customObjConstants";


const descriptionIcons= {
    "InfoIcon": InfoIcon,
    "InfoOutlinedIcon": InfoOutlinedIcon,
    "InfoTwoToneIcon": InfoTwoToneIcon,
    "ChatBubbleIcon": ChatBubbleIcon,
    "ChatBubbleOutlineOutlinedIcon": ChatBubbleOutlineOutlinedIcon,
    "ChatBubbleTwoToneIcon": ChatBubbleTwoToneIcon,
    "ChatIcon": ChatIcon,
    "ChatOutlinedIcon": ChatOutlinedIcon,
    "ChatTwoToneIcon": ChatTwoToneIcon,
    "AnnouncementIcon": AnnouncementIcon,
    "AnnouncementOutlinedIcon": AnnouncementOutlinedIcon,
    "AnnouncementTwoToneIcon": AnnouncementTwoToneIcon,
    "CommentIcon": CommentIcon,
    "CommentOutlinedIcon": CommentOutlinedIcon,
    "CommentTwoToneIcon": CommentTwoToneIcon
};


class PortalPageRenderer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initComponent: true
        };
    }

    navigateBack = () => {
        if (this.props.history) {
            this.props.history.push("/");
        }
    };

    // -----------------------------------------------------------------------------------------------------------------
    // Add  Page Object
    // -----------------------------------------------------------------------------------------------------------------

    getQnrObj = () => {

        let qnr;
        if (this.props.portalPreview) {
            qnr = (this.props.questionnaireTemplateFromStore !== null) ? this.props.questionnaireTemplateFromStore : this.props.questionnaireTemplate;
        }
        else {
            // On Portal Preview second load on the same screen we are getting a empty object {} this is to account for that!
            let tempQuestionnaireInstance = portalShared.cloneObj(this.props.questionnaireInstance);
            if (tempQuestionnaireInstance !== null) {
                let tempQuestionnaireInstanceString = JSON.stringify(tempQuestionnaireInstance);
                if (tempQuestionnaireInstanceString === "{}") {
                    tempQuestionnaireInstance = null;
                }
            }

            qnr = this.props.questionnaireInstance;
        }

        return portalShared.cloneObj(qnr);
    }

    addSectionToPage = (qnr, sectionObj, pageNum, sectionNum, numberOfPages) => {
        // Get an array of item objects in this section
        let sectionItems = ddo.getObjValue(sectionObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM_ARRAY);

        // Convert the stored values to the actual text
        let justify = ddo.getObjValueWithConversion(sectionObj, ddo.OBJ.KEY.PAGE.SECTION.JUSTIFY);
        let alignItems = ddo.getObjValueWithConversion(sectionObj, ddo.OBJ.KEY.PAGE.SECTION.ALIGN_ITEMS);
        let classType = ddo.getObjValueWithConversion(sectionObj, ddo.OBJ.KEY.PAGE.SECTION.CLASS_TYPE);

        //let qnr = this.getQnrObj(); // Get Questionnaire Obj (Instance or Template)

        let sectionClass = {padding: "8px 8px 8px 8px"}; // controlGrid
        if (classType === ddo.SECTION.CLASS_TYPE.FOOTER) {
            sectionClass = {padding: "10px 16px", borderTop: "solid rgba(0, 0, 0, 0.1) 1px", width: "100%"}; // footerGrid
        }
        // switch (classType) {
        //     case ddo.SECTION.CLASS_TYPE.CONTROL:
        //         sectionClass = this.props.classes.controlGrid;
        //         break;
        //     case ddo.SECTION.CLASS_TYPE.FOOTER:
        //         sectionClass = this.props.classes.footerGrid;
        //         break;
        //     default:
        //         break;
        // }

        let divAndKeyName = "Section-" + pageNum + "-" + sectionNum;

        if (sectionItems[0].type === "LIKERT_GROUP") {

            // Fix for when create group likert but actual showGroup has not been created yet
            let questionsArray = sectionItems[0].questions;
            let showGroupKey = questionsArray.length ? ddo.showGroup(questionsArray[0].showGroupKey) : false;
            return (
                <div id={divAndKeyName} key={divAndKeyName} style={{width: "100%", display: showGroupKey ? "inline" : "none"}}>
                    <Grid
                        id={"OuterGridA-" + pageNum + "-" + sectionNum}
                        key={"Section-" + pageNum + "-" + sectionNum}
                        container
                        justifyContent={justify}
                        alignItems={alignItems}
                        style={{
                            ...sectionClass,
                            overflow: "unset"
                        }}>
                        {sectionItems.map((item, index) => this.addItemToSection(qnr, item, pageNum, sectionNum, index, numberOfPages, sectionClass))}
                    </Grid>
                </div>
            );
        }
//         else if (true) {
// debugger;
//             // Fix for when create group likert but actual showGroup has not been created yet
//             //let questionsArray = sectionItems[0].questions;
//             //let showGroupKey = questionsArray.length ? ddo.showGroup(questionsArray[0].showGroupKey) : false;
//             return (
//                 <div id={divAndKeyName} key={divAndKeyName} style={{display: sectionItems[0].showGroupKey ? "inline" : "none"}}>
//                     <Grid
//                         id={"OuterGridA-" + pageNum + "-" + sectionNum}
//                         key={"Section-" + pageNum + "-" + sectionNum}
//                         container
//                         justifyContent={justify}
//                         alignItems={alignItems}
//                         style={{
//                             ...sectionClass,
//                             overflow: 'unset'
//                         }}>
//                         {this.addItemToSection(qnr, sectionItems[0], pageNum, sectionNum, 0, numberOfPages, sectionClass)}
//                     </Grid>
//                 </div>
//             );
//
//         }
        else if (sectionItems[0].format === "TITLE_FORMAT_METADATA") {
            // ZZZZZ QQQQQ YYYYY
            // This is for readonly mode to display metadata at top of survey
            // Reorder array so "Date Surveyed" is the Top Item
            let newSectionItems = [];
            let splitName = null;
            let dateIndex = 0;
            for (var i = 0; i < sectionItems.length; i++) {
                splitName = sectionItems[i].name.split("|");
                if (splitName[0] === "Date Surveyed") {
                    newSectionItems.push(sectionItems[i]);
                    dateIndex = i;
                    break;
                }
            }

            for (var ii = 0; ii < sectionItems.length; ii++) {
                splitName = sectionItems[ii].name.split("|");
                if (dateIndex !== ii) {
                    newSectionItems.push(sectionItems[ii]);
                }
            }

            return (
                <div id={divAndKeyName} key={divAndKeyName}>
                    <Grid
                        id={"OuterGridB-" + pageNum + "-" + sectionNum}
                        key={"Section-" + pageNum + "-" + sectionNum}
                        container
                        justifyContent={justify}
                        alignItems={alignItems}
                        style={{
                            ...sectionClass,
                            overflow: "unset"
                        }}>
                        {newSectionItems.map((item, index) => this.addItemToSection(qnr, item, pageNum, sectionNum, index, numberOfPages, sectionClass))}
                    </Grid>
                </div>
            );
        }
        else {

            return (
                <div id={divAndKeyName} key={divAndKeyName} style={{display: ddo.showGroup(qnr, ddo.getObjValue(sectionItems[0], ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_GROUP_KEY)) ? "inline" : "none"}}>
                    <Grid
                        id={"OuterGridC-" + pageNum + "-" + sectionNum}
                        key={"Section-" + pageNum + "-" + sectionNum}
                        container
                        justifyContent={justify}
                        alignItems={alignItems}
                        style={{...sectionClass, overflow: "unset"}}>
                        {sectionItems.map((item, index) => this.addItemToSection(qnr, item, pageNum, sectionNum, index, numberOfPages, sectionClass))}
                    </Grid>
                </div>
            );
        }
    };


    getAriaLabelForButton = (btnMode, btnName) => {

        let ariaLabel = "";

        switch (btnMode) {
            case ddo.BUTTON.MODE.START:
                ariaLabel = btnName + ". Use this button to start the questionnaire";
                break;
            case ddo.BUTTON.MODE.CANCEL:
                ariaLabel = btnName + ". Use this button to cancel the questionnaire";
                break;
            case ddo.BUTTON.MODE.NEXT:
                ariaLabel = btnName + ". Use this button to navigate to the next page of the questionnaire";
                break;
            case ddo.BUTTON.MODE.PREVIOUS:
                ariaLabel = btnName + ". Use this button to navigate back one page of the questionnaire";
                break;
            case ddo.BUTTON.MODE.FINISH:
                ariaLabel = btnName + ". Use this button to submit your completed questionnaire";
                break;
            case ddo.BUTTON.MODE.CLOSE:
                ariaLabel = btnName + ". Use this button to close the questionnaire";
                break;
            case ddo.BUTTON.MODE.VIEW_PDF:
                ariaLabel = btnName + ". Use this button to view the questionnaire as PDF";
                break;
            default:
                break;
        }
        return ariaLabel;
    }


    addItemToSection  = (qnr, itemObj, pageNum, sectionNum, itemNum, numberOfPages, sectionClass) => {

        // -------------------------------------------------------------------------------------------------------------
        // Calculate pages numbers depending on if there is a start and/or close page
        // -------------------------------------------------------------------------------------------------------------

        let currPageDisplayNumber = tag.metadataExists(qnr, portalShared.CUSTOM_QNR_START_PAGE_OBJ) ? pageNum : (pageNum + 1);
        let currPageDisplayOfNumber = numberOfPages;

        if (tag.metadataExistsObj(qnr, portalShared.CUSTOM_QNR_START_PAGE_OBJ)) {
            currPageDisplayOfNumber -= 1;
        }
        if (!(tag.metadataExistsObj(qnr, portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ) || tag.metadataExistsObj(qnr, portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ_LEGACY))) {
            currPageDisplayOfNumber -= 1;
        }

        // -------------------------------------------------------------------------------------------------------------

        let titlesObj = ddo.convertTitleSourceReturnTitlesObj(qnr, itemObj, currPageDisplayNumber, currPageDisplayOfNumber, ("-p" + pageNum + "-s" + sectionNum + "-i" + itemNum));

        // Special condition if the title is a question name and it is to be aligned with answer then exit out of title generation.
        // if ((itemObj.source === ddo.TITLE.SOURCE.QUE_NAME) && (tag.metadataExists(titlesObj.question, portalShared.DISPLAY_DEF_ALIGN_ANSWERS_WITH_QUESTION))) {
        //     return;
        // }

        let htmlObj = null;
        let CustomTypographyStyle = null;
        let customLeftObj = null;
        let customCenterObj = null;
        let customRightObj = null;
        // let customLeftObj2 = null;
        // let customCenterObj2 = null;
        // let customRightObj2 = null;
        let customObj = null;


        let itemType = ddo.getSectionItemType(itemObj); // Title, Button or Question

        switch(itemType) {
            case ddo.OBJ.TYPE.SECTION_ITEM.TITLE:

                // itemType TITLE can be for category title bar, headers or footers or spacers

                let variant = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.TITLE.VARIANT); // Variant can be single variant ie.. h5 or custom object

                // Note: if variant is a custom object then custom convertedVariant will be blank
                let convertedVariant = (typeof variant === "object") ? "" : font.getDefaultVariantForItem(ddo.OBJ.TYPE.SECTION_ITEM.TITLE, variant);

                let fontObj = font.getFontObjFromVariant(convertedVariant); // MUI 5 converted to using fontsize in rem not pt, and our CSS not working this it to override
                //let iconFontObj = null;

                // Check default text to see if custom styling requested
                if (itemObj.source === "D") {

                    if (tag.metadataExists(qnr, portalShared.CUSTOM_DDO_DEFAULTS)) {

                        variant = tag.metadataValue(qnr, portalShared.CUSTOM_DDO_DEFAULTS);
                        format = ddo.TITLE.FORMAT.CUSTOM;
                    }
                }

                let showGroupKey = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_GROUP_KEY);
                //let showGroupObjArray = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_GROUP_OBJ_ARRAY);

                let uploadedImageStyles = {
                    verticalAlign: 'middle',
                    height: '60px'
                };

                // -----------------------------------------------------------------------------------------------------

                let format = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.TITLE.FORMAT);

                switch (format) {

                    case ddo.TITLE.FORMAT.SPACER:
                        htmlObj = <Grid style={sectionClass} key={titlesObj.itemObj.id} container item sm={12}></Grid>;
                        break;

                    case ddo.TITLE.FORMAT.CATEGORY_TITLE_BAR:  // =================================== CATEGORY TITLE BAR

                        htmlObj = getCategoryTitleBar(qnr, titlesObj.textObj, itemObj, convertedVariant, uploadedImageStyles, titlesObj.itemObj.id);
                        break;

                    case ddo.TITLE.FORMAT.PAGE_HEADER:
                    case ddo.TITLE.FORMAT.PAGE_FOOTER1:
                    case ddo.TITLE.FORMAT.PAGE_FOOTER2:


                        let objName = {
                            [ddo.TITLE.FORMAT.PAGE_HEADER]: "pageHeader",
                            [ddo.TITLE.FORMAT.PAGE_FOOTER1]: "pageFooter1",
                            [ddo.TITLE.FORMAT.PAGE_FOOTER2]: "pageFooter2",
                        }

                        let mainPagesObj = {};
                        if (tag.metadataExists(qnr, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ)) {
                            mainPagesObj = tag.metadataValueObj(qnr, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ);
                        }

                        customLeftObj = custom.getCustomTitleTypographyFromObject(qnr, mainPagesObj, "left", objName[format] + "LeftTextObj");
                        customCenterObj = custom.getCustomTitleTypographyFromObject(qnr, mainPagesObj, "center", objName[format] + "CenterTextObj");
                        customRightObj = custom.getCustomTitleTypographyFromObject(qnr, mainPagesObj, "right", objName[format] + "RightTextObj");

                        htmlObj =
                            <Grid style={sectionClass} key={format + "-" + titlesObj.itemObj.id} container item sm={12}>

                                <div style={{display: "inline-flex", width: "100%"}}>

                                    <div style={{
                                        width: "30%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "left",
                                        alignItems: "center"
                                    }}>

                                        {titlesObj.textObj.titleLeftIsImage ?
                                            <img src={`data:image/png;base64,${titlesObj.textObj.titleLeft}`} alt=""
                                                 style={uploadedImageStyles}/>
                                            :
                                            customLeftObj.useCustom ?
                                                <customLeftObj.customTypography>{customLeftObj.title}</customLeftObj.customTypography>
                                                :
                                                <Typography
                                                    variant={itemObj.variant}>{titlesObj.textObj.titleLeft}</Typography>
                                        }
                                    </div>

                                    <div style={{
                                        width: "40%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>

                                        {titlesObj.textObj.titleCenterIsImage ?
                                            <img src={`data:image/png;base64,${titlesObj.textObj.titleCenter}`} alt="" style={uploadedImageStyles}/>
                                            :
                                            customCenterObj.useCustom ?
                                                <customCenterObj.customTypography>{customCenterObj.title}</customCenterObj.customTypography>
                                                :
                                                <Typography variant={itemObj.variant}>{titlesObj.textObj.titleCenter}</Typography>
                                        }
                                    </div>

                                    <div style={{
                                        width: "30%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "right",
                                        alignItems: "center"
                                    }}>

                                        {titlesObj.textObj.titleRightIsImage ?
                                            <img src={`data:image/png;base64,${titlesObj.textObj.titleRight}`} alt="" style={uploadedImageStyles}/>
                                            :
                                            customRightObj.useCustom ?
                                                <customRightObj.customTypography>{customRightObj.title}</customRightObj.customTypography>
                                                :
                                                <Typography variant={itemObj.variant}>{titlesObj.textObj.titleRight}</Typography>
                                        }
                                    </div>

                                </div>

                            </Grid>;
                        break;
                    case ddo.TITLE.FORMAT.CUSTOM:

                        customObj = variant;  // Custom object was stored in variant

                        CustomTypographyStyle = custom.getCustomTypography(customObj);

                        let skipName = false;

                        if ((titlesObj.source === ddo.TITLE.SOURCE.QUE_NAME)||(titlesObj.source === ddo.TITLE.SOURCE.QUE_DESC)) {
                            // Check for custom question format
                            if (tag.metadataExists(titlesObj.question, portalShared.CUSTOM_DDO_QUE_FORMAT)) {
                                let questionCustomObj = JSON.parse(tag.metadataValue(titlesObj.question, portalShared.CUSTOM_DDO_QUE_FORMAT));

                                // If question is in likert mode display of name will be handled in likert section of singleSelect
                                skipName = (questionCustomObj.displayAs === 'likert');
                            }
                        }
                        if (titlesObj.source === ddo.TITLE.SOURCE.QUE_DESC) {
                            if (qnr.description !== "") {
                                titlesObj.text = qnr.description;
                            }
                        }

                        // Only Descriptions and Scripts can have leading icons
                        let iconObj = {
                            hasIcon: false,
                            icon: descriptionIcons["InfoIcon"],
                            size: "16px",
                            paddingTop: "2px",
                            color: "black"
                        };

                        if (customObj.hasOwnProperty("iconObj") && customObj.iconObj !== null) {

                            iconObj.hasIcon = customObj.iconObj.icon !== "none";
                            if (iconObj.hasIcon) {
                                iconObj.icon = descriptionIcons[customObj.iconObj.icon]; // Get the icon from const JSON obj declared above
                                iconObj.size = customObj.iconObj.size + "px";
                                iconObj.paddingTop = customObj.iconObj.padding + "px";
                                iconObj.color = customObj.iconObj.hasOwnProperty("colorEntry") ? portalShared.getColor(customObj.iconObj) : "black"; // Legacy FIX
                            }
                        }
                        else if (titlesObj.isDescription) {
                            iconObj.hasIcon = !(itemObj.source === TITLE.SOURCE.QNR_DESC); // LEGACY FIX by default I decided to NOT show info icon on questionnaire description if there is an older version of the custom object
                        }

                        let CustomIcon = styled(iconObj.icon)({color: iconObj.color});

                        if (!skipName) {
                            htmlObj = <Grid key={"TitleCustom-" + titlesObj.objKey} item sm={12} style={{
                                ...sectionClass,
                                display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none", width: "100%"
                            }}>
                                <div style={{display: "flex", width: "100%", justifyContent: customObj.textAlign}}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: customObj.textAlign,
                                        width: "100%"
                                    }}>

                                        <div style={{display: (iconObj.hasIcon) ? "inline" : "none"}}>
                                            <CustomIcon sx={{
                                                height: iconObj.size,
                                                width: iconObj.size,
                                                paddingTop: iconObj.paddingTop
                                            }}/>
                                        </div>
                                        <div style={{display: (iconObj.hasIcon) ? "inline" : "none"}}>
                                            &nbsp;
                                        </div>
                                        <CustomTypographyStyle>{titlesObj.text}</CustomTypographyStyle>
                                    </div>
                                </div>

                            </Grid>;
                        }

                        break;
                    case ddo.TITLE.FORMAT.PLAIN:
                        // If text is "TOPOGRAPHY" Then display defaults chart

                        if ((titlesObj.text !== undefined) && (titlesObj.text.toUpperCase() === "!TYPOGRAPHY!")) {
                            htmlObj = font.displayDefaultTopographyDetails(pageNum, sectionNum, itemNum);
                        }
                        else {

                            htmlObj = <Grid key={"TitlePlainLeft-" + (titlesObj.itemObj.id === null ? titlesObj.itemObj.source : "") + itemNum} item sm={12} style={{...sectionClass, width: "100%", display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
                                <div style={{textAlign: "left", width: "100%"}}>
                                    <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}>{titlesObj.text}</Typography>
                                </div>
                            </Grid>;
                        }
                        break;
                    case ddo.TITLE.FORMAT.PLAIN_CENTER:
                        // ToDo ZZZZZ if there is a description wait to do gray bar labels

                        htmlObj = <Grid key={"TitlePlainCenter-" + titlesObj.itemObj.id} item sm={12} style={{...sectionClass, display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
                            <div style={{textAlign: "center"}}>
                                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}>{titlesObj.text}</Typography>
                            </div>
                        </Grid>;
                        break;
                    case ddo.TITLE.FORMAT.PLAIN_RIGHT:
                        // ToDo ZZZZZ if there is a description wait to do gray bar labels

                        htmlObj = <Grid key={"TitlePlainRight-" + titlesObj.itemObj.id} item sm={12} style={{...sectionClass, display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
                            <div style={{textAlign: "right"}}>
                                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}>{titlesObj.text}</Typography>
                            </div>
                        </Grid>;
                        break;
                    // case ddo.TITLE.FORMAT.ICON:
                    //
                    //     if (itemObj.source === ddo.TITLE.SOURCE.QUE_NAME) {
                    //         titlesObj.questionId = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.ID);
                    //         //tempQuestion = ddo.getQuestion(qnr, titlesObj.questionId);
                    //
                    //         //likertQuestion = tag.metadataExists(tempQuestion, portalShared.DISPLAY_SINGLE_SELECT_AS_LIKERT);
                    //     }
                    //
                    //     iconFontObj = font.getVariantPropertiesObj(convertedVariant); // Get icon defaults based on description template type
                    //
                    //     debugger;
                    //     htmlObj = <Grid key={"TitleFormatInfo-" + titlesObj.itemObj.id} item container sm={12} style={{...sectionClass, display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
                    //         <div style={{display: 'flex', alignItems: "center"}}>
                    //             <div style={{display: "inline"}}>
                    //                 <InfoIcon style={{fontSize: iconFontObj.iconSize, color: iconFontObj.color, paddingTop: iconFontObj.iconPadding}}/>
                    //             </div>
                    //             <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor} style={{marginLeft: '5px'}}>{titlesObj.text}</Typography>
                    //         </div>
                    //         {/*{*/}
                    //         {/*    this.renderGrayBarLabelsIfRequired(itemObj, qnr, titlesObj.group, titlesObj.question)*/}
                    //         {/*}*/}
                    //     </Grid>;
                    //     break;
                    case ddo.TITLE.FORMAT.METADATA:
                        // This is used to format the metadata display at the top of a readonly survey ????? ZZZZZ YYYYY QQQQQ
                        // METADATA used to allow titles to have text|variant I don't think this will be needed anymore ZZZZZ
                        let tagName = titlesObj.text.substring(0, titlesObj.text.indexOf("|"));
                        let valueName = titlesObj.text.substring(titlesObj.text.indexOf("|") + 1);

                        fontObj = font.getFontObjFromVariant(font.VARIANT.CAPTION);

                        htmlObj = <Grid key={"TitleFormatMetadata-" + itemNum} item container sm={12} style={{...sectionClass, display: "inline"}}>
                            <div style={{float: "left"}}>
                                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}>{tagName}</Typography>
                            </div>
                            <div style={{float: "left"}}>
                                {":"}&nbsp;&nbsp;
                            </div>
                            <div style={{float: "left"}}>
                                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}><b>{valueName}</b></Typography>
                            </div>
                        </Grid>;
                        break;
                    default:
                        break;
                }
                break;
            case ddo.OBJ.TYPE.SECTION_ITEM.BUTTON:

                let btnName = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.NAME);
                let btnMode = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.MODE);
                btnName = ddo.convertButtonNameDefault(btnName, btnMode);

                let onClickFunction = function () {};

                switch (btnMode) {
                    case ddo.BUTTON.MODE.START:
                        onClickFunction = this.props.start.bind(this);
                        break;
                    case ddo.BUTTON.MODE.CANCEL:
                        onClickFunction = this.props.cancel;
                        break;
                    case ddo.BUTTON.MODE.NEXT:
                        onClickFunction = this.props.next.bind(this);
                        break;
                    case ddo.BUTTON.MODE.PREVIOUS:
                        onClickFunction = this.props.previous.bind(this);
                        break;
                    case ddo.BUTTON.MODE.FINISH:
                        if (this.props.surveyEditMode === portalShared.READONLY_SURVEY) {
                            return "";  // No submit button in readonly
                        }
                        if (!this.props.portalPreview) {
                            onClickFunction = this.props.finish; // Don't allow the submit onClick in PortalPreview
                        }
                        break;
                    case ddo.BUTTON.MODE.CLOSE:
                        onClickFunction = this.props.closeSurvey;
                        break;
                    case ddo.BUTTON.MODE.VIEW_PDF:
                        onClickFunction = this.props.next;
                        break;
                    default:
                        break;
                }

                let btnKeyQualifier =  titlesObj.itemObj.mode + "-" + pageNum + "-" + sectionNum + "-" + itemNum;

                // let ariaLabel = this.getAriaLabelForButton(btnMode, btnName); NOTE: This does not appear to work for buttons

                if (tag.metadataExistsObj(qnr, portalShared.CUSTOM_QNR_SYSTEM_OBJ)) {

                    let customSystemObj = tag.metadataValueObj(qnr, portalShared.CUSTOM_QNR_SYSTEM_OBJ);

                    let type = customSystemObj.systemButtons.type; // text, outlined, contained
                    let fontFamily = customSystemObj.systemButtons.text.font;
                    let fontWeight = customSystemObj.systemButtons.text.fontWeight; //
                    let fontColor = portalShared.getColor(customSystemObj.systemButtons.text.colorObj);
                    let fontWeightHover = customSystemObj.systemButtons.textHover.fontWeight;
                    let fontColorHover = portalShared.getColor(customSystemObj.systemButtons.textHover.colorObj);
                    let backgroundColor = portalShared.getColor(customSystemObj.systemButtons.backgroundColor);
                    let backgroundColorHover = portalShared.getColor(customSystemObj.systemButtons.backgroundColorHover);
                    let borderColor = portalShared.getColor(customSystemObj.systemButtons.borderColor);
                    let borderColorHover = portalShared.getColor(customSystemObj.systemButtons.borderColorHover);

                    const SystemButton = styled(Button)({
                        "&:hover": {
                            borderColor: borderColorHover,
                            backgroundColor: backgroundColorHover,
                            //color: fontColorHover,
                        }});

                    htmlObj =  <Grid key={"Button-" + btnKeyQualifier} item>
                        <SystemButton
                            variant={type}
                            sx={{borderColor: borderColor, backgroundColor: backgroundColor, width: "100px"}}
                            disableElevation={true}
                            onClick={onClickFunction}
                        >
                            <Typography fontFamily={fontFamily} color={fontColor} fontWeight={fontWeight} sx={{ "&:hover": { color: fontColorHover, fontWeight: fontWeightHover } }}>{btnName}</Typography>
                        </SystemButton>
                    </Grid>;


                } else {

                    const SystemButton = styled(Button)({
                        "&:hover": {
                            borderColor: portalShared.COLOR_WHITE_HEX,
                            backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX,
                        }});

                    htmlObj =  <Grid key={"Button-" + btnKeyQualifier} item>
                        <SystemButton
                            variant={"text"}

                            sx={{backgroundColor: portalShared.COLOR_WHITE_HEX, width: "100px"}}
                            disableElevation={true}
                            onClick={onClickFunction}
                        >
                            <Typography color={portalShared.COLOR_DODGER_BLUE_HEX} sx={{ "&:hover": { color: portalShared.COLOR_DODGER_BLUE_HEX, fontStyle: "bold" } }}>{btnName}</Typography>
                        </SystemButton>
                    </Grid>;

                }

                break;

            case ddo.OBJ.TYPE.SECTION_ITEM.QUESTION:

                titlesObj.questionId = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.ID);
                titlesObj.question = ddo.getQuestion(qnr, titlesObj.questionId);
                let questionShowGroupKey = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_GROUP_KEY);
                //let questionShowGroupObjArray = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_GROUP_OBJ_ARRAY);
                titlesObj.qNum = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_NUMBER);

                htmlObj =  <Grid key={"QuestionGrid-" + titlesObj.itemObj.id + "-" + itemNum} item style={{...sectionClass, width: "100%", minHeight: "fit-content", display: ddo.showGroup(qnr, questionShowGroupKey) ? "inline" : "none"}} xs={12}>
                    <PortalQuestion key={"Question-" + titlesObj.question.id + "-" + itemNum}
                                    questionnaire={qnr}
                                    showGroupKey={questionShowGroupKey}
                                    question={titlesObj.question}
                                    questionNumber={titlesObj.qNum}
                                    itemObj={itemObj}
                                    answerQuestionOptions={this.props.answerQuestionOptions}
                                    answerQuestionTemplateOptions={this.props.answerQuestionTemplateOptions}
                                    answerQuestionText={this.props.answerQuestionText}
                                    answerQuestionTemplateText={this.props.answerQuestionTemplateText}
                                    displayMode={this.props.displayMode}
                                    surveyEditMode={this.props.surveyEditMode}
                                    theme={this.props.theme}
                        //likertIndex={index}
                        //surveyEditMode={this.props.surveyEditMode}
                    />
                </Grid>;
                break;

            case ddo.OBJ.TYPE.SECTION_ITEM.LIKERT_GROUP:

                let likertGroup = ddo.getGroup(qnr, itemObj[ddo.OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.ID]);
                let likertQuestions = ddo.getLikertGroupQuestions(itemObj);
                //let likertShowGroupKey = itemObj.questions[0].showGroupKey; ToDo ZZZZZ this needs to return by question and ignore if metadata says to since LIKERTS only have one answers set and multiple questions
                //let likertShowGroupObjArray = itemObj.questions[0].showGroupObjArray;

                htmlObj =  <Grid key={"Likert-" + titlesObj.itemObj.id} item style={{...sectionClass, width: "100%", minHeight: "fit-content", display: 'inline'}} xs={12}>
                    {likertQuestions.map((questionItemObj, index) =>
                        <PortalQuestion     key={"LikertQuestion-" + index + "-" + questionItemObj.id}
                                            questionnaire={qnr}
                                            showGroupKey={ddo.getLikertQuestionShowGroupKey(qnr, questionItemObj)}
                                            group={likertGroup}
                                            question={ddo.getQuestion(qnr, questionItemObj[ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.ID])}
                                            questionNumber={questionItemObj.showNumber}
                                            itemObj={itemObj}
                                            answerQuestionOptions={this.props.answerQuestionOptions}
                                            answerQuestionTemplateOptions={this.props.answerQuestionTemplateOptions}
                                            answerQuestionText={this.props.answerQuestionText}
                                            answerQuestionTemplateText={this.props.answerQuestionTemplateText}
                                            theme={this.props.theme}
                                            likertIndex={index}
                                            displayMode={this.props.displayMode}
                                            surveyEditMode={this.props.surveyEditMode}
                        />
                    )}
                </Grid>;
                break;
            case ddo.OBJ.TYPE.SECTION_ITEM.ICON:

                htmlObj = <div key="GreenCheckIcon" style={{display: "flex", justifyContent: "center"}}><img src={green_checkmark} alt="Green check mark logo for questionnaire complete"/></div>;
                break;
            default:

                break;
        }

        return htmlObj;
    };


    // -----------------------------------------------------------------------------------------------------------------
    // RENDER
    // -----------------------------------------------------------------------------------------------------------------

    render() {

        let pageSections = this.props.pageDefObj[ddo.OBJ.KEY.DDO.PAGE_SECTION_ARRAY];

        let qnr = this.getQnrObj(); // Get Questionnaire Obj (Instance or Template)

        return (
            ((this.props.questionnaireInstance) || (this.props.questionnaireTemplate && qnr?.questionCategoryTemplates)) &&
            <form key={"MainForm-id-" + qnr.id + "-portalCount-" + this.props.portalCount}>
                {pageSections.map((section, index) =>
                    this.addSectionToPage(qnr, section, this.props.pageDefObjCurrIndex, index, this.props.totalPages)
                )}
            </form>
        );
    }
}

PortalPageRenderer.defaultProps = {
    showProgress: true,
    questionnaireInstance: null,
    questionnaireTemplate: null
};


PortalPageRenderer.propTypes = {
    //classes: PropTypes.object.isRequired,
    pageDefObj: PropTypes.object.isRequired,
    pageDefObjCurrIndex: PropTypes.number,
    totalPages: PropTypes.number,
    questionnaireInstance: PropTypes.object,
    questionnaireTemplate: PropTypes.object,
    questionnaire: PropTypes.object,
    start: PropTypes.func,
    cancel: PropTypes.func,
    finish: PropTypes.func,
    next: PropTypes.func,
    previous: PropTypes.func,
    history: PropTypes.object,
    portal: PropTypes.object,
    displayMode: PropTypes.string,
    surveyEditMode: PropTypes.string,
    templateType: PropTypes.string,
    portalPreview: PropTypes.bool,
    portalCount: PropTypes.number
};

function mapStateToProps(state) {
    return {
        theme: state.theme,
        questionnaireTemplateFromStore: state.portal.questionnaireTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        dispatch,
        answerQuestionOptions,
        answerQuestionTemplateOptions,
        answerQuestionText,
        answerQuestionTemplateText,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalPageRenderer);

import * as icon from "../constants/iconConstants";
import React from "react";
import * as portalShared from "../portal/shared/metadataConstantsAndUtilities"


export const IS_STRINGIFIED_OBJ = "IS_STRINGIFIED_OBJ";


export const defaultCustomMetadataObj = {
    selected: "default",
    metadataValue: "",
    metadataValueObj: null,
}

export function getDefaultMetadataObj(){
    return portalShared.cloneObj(defaultCustomMetadataObj);
}

export function setCustomMetadataObj(obj){
    return {
        selected: obj.selected,
        metadataValue: obj.metadataValue,
        metadataValueObj: obj.metadataValueObj
    };
}

export function setCustomMetadataObjSelected(selected){
    return {
        selected: selected,
        metadataValue: "",
        metadataValueObj: null
    };
}

export function checkMetadataObjectExist(metadata, obj) {
    return metadata.hasOwnProperty(obj);
}

export function getCustomMetadataValue(metadata, obj, valueType) {
    return valueType === IS_STRINGIFIED_OBJ ? JSON.parse(metadata[obj]) : metadata[obj];
}

export function isHiddenMetadata(metadataName)  {
    // ZZZZZ YYYYY QQQQQ
    let  hideMetadata = false;
    let  hideAllMetadata = true; // To show specific types of metadata set hideAllMetadata = true, and change specific sections from true to false.

    // METADATA HIDE/SHOW
    switch (metadataName) {
        case icon.ICON_POSITION:
        case icon.ICON_TYPE:
        case icon.ICON_COLOR:
        case icon.ICON_COLOR_OBJ:
        case icon.ICON_TITLES:
        case portalShared.USE_LIKERT_RESPONSIVE_LABEL:
            hideMetadata = hideAllMetadata && true;
            break;
        case portalShared.KEY_METRIC_CATEGORY_QUESTIONNAIRE:
        case portalShared.QNR_START_PAGE_SCRIPT:
        case portalShared.QNR_CLOSE_PAGE_SCRIPT:
        // case portalShared.DISPLAY_AS: // Used by question render as button group option
        //     hideMetadata = hideAllMetadata && true;
        //     break;
        case portalShared.DISPLAY_DEF_RADIO_AS_BUTTON_GROUP:
        case portalShared.DISPLAY_DEF_BUTTON_AS_CIRCLE:
            hideMetadata = hideAllMetadata && true;
            break;
        case portalShared.KEY_METRIC_CATEGORY_QUESTION:
        // case portalShared.DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT:
        // case portalShared.DISPLAY_DEF_LIKERT_QUESTION_ALIGN_WITH_ANSWERS:
        case portalShared.DISPLAY_DEF_SHOW_GROUP_STANDARD:
        case portalShared.DISPLAY_DEF_HIDE_BUTTONGROUP_NAME:
            hideMetadata = hideAllMetadata && true;
            break;
        case "display-def-name":
        case "display-def-desc":
            // Legacy FIX
            hideMetadata = hideAllMetadata && true;
            break;
        // case portalShared.DISPLAY_DEF_LIKERT_TITLE_LEFT:
        // case portalShared.DISPLAY_DEF_LIKERT_TITLE_CENTER:
        // case portalShared.DISPLAY_DEF_LIKERT_TITLE_RIGHT:
        //     hideMetadata = hideAllMetadata && true;
        //     break;
        default:
            break;
    }

    // Hide metadata related to Portal display.
    if (metadataName.includes("custom-ddo")) {
        hideMetadata = hideAllMetadata && true;  // TOGGLE: to see metadata entries for "custom-ddo..."
    }

    if (metadataName.includes("custom-obj")) {
        hideMetadata = hideAllMetadata && true;  // TOGGLE: to see metadata entries for "custom-ddo..."
    }

    // custom-ui are metadata added by UI such as portal tab title, but do not require a custom-ddo
    if (metadataName.includes("custom-ui")) {
        hideMetadata = hideAllMetadata && true;  // TOGGLE: to see metadata entries for "custom-ui..."
    }

    if (metadataName.includes("image-")) {
        hideMetadata = hideAllMetadata && true;  // TOGGLE: to see metadata entries for "custom-ui..."
    }

    if (metadataName.includes("user-script")) {
        hideMetadata = hideAllMetadata && true;  // TOGGLE: to see metadata entries for "custom-ui..."
    }

    if (metadataName === "sendJSON") {
        hideMetadata = hideAllMetadata && true;  // TOGGLE: to see metadata entry for send JSON object
    }

    // Note we can't hide portalShared.KEY_METRIC_CATEGORY_QUESTIONNAIRE because its in the config and shows up in the metadata name autoselect.  would be good to fix this in the future and hide it.
    // LEGACY key metric was referenced as reporting category
    if (metadataName.includes(portalShared.KEY_METRIC_CATEGORY_QUESTIONNAIRE)) {
        hideMetadata = hideAllMetadata && true;
    }

    // Note we can't hide portalShared.KEY_METRIC_CATEGORY_QUESTION because its in the config and shows up in the metadata name autoselect.  would be good to fix this in the future and hide it.
    // LEGACY key metric was referenced as reporting category
    if (metadataName.includes(portalShared.KEY_METRIC_CATEGORY_QUESTION)) {
        hideMetadata = hideAllMetadata && true;
    }

    return hideMetadata;
}


export function countNumberOfVisibleMetadataEntries(metadata) {
    let count = 0;

    for (let name in metadata) {
        if (!isHiddenMetadata(name)) {
            ++count;
        }
    }
    return count;
}


export function listMetadata (template, label = "", color = "darkgray") {
    let metadataArray = [];

    // Convert metadata to an array of objects to .map() function can be used.
    for (var key in template.metadata) {
        if (template.metadata.hasOwnProperty(key)) {
            // Don't display custom-ddo... objects since they are stringified objects
            if (!isHiddenMetadata(key)) {
                let value = template.metadata[key];
                metadataArray.push({key: key, value: value});
            }
        }
    }

    return (
        <div>
            <div style={{color: color}}>{label === "" ? "Metadata:" : (label + " Metadata:")}</div>
            {metadataArray.map((row) => {
                return (
                    <div style={{width: "100%", display: "flex", justifyContent: "flex-start", overflowWrap: "break-word"}}>
                        <div style={{color: "darkgray", padding: ".3% 1%"}}>Name:</div>
                        <div style={{padding: ".3% 1% .3% 0%", width: "22%"}}>{row.key}</div>
                        <div style={{color: "darkgray", padding: ".3% 1% .3% 0%"}}>Value:</div>
                        <div style={{padding: ".3% 0% .3% 0%", width: "68%"}}>{row.value}</div>
                    </div>
                );
            })}

            <div>&nbsp;</div>
        </div>
    );
}


export function setTextObjSelectedAndValueAndValueObj(metadata, metadataPropertyName, titlebar = false) {

    let tempObj = {...defaultCustomMetadataObj};

    // For categories if Title Bar is seleted then name and desc are forced to "HIDE" mode
    if (metadata.hasOwnProperty(metadataPropertyName)) {
        if (titlebar) {
            tempObj.selected = "hide";
        }
        else {
            // Note: "\"hide\"" occured in the past when a string was saved to the metadata value, this is a patch to allow those issues to work
            tempObj.metadataValue = (metadata[metadataPropertyName] === "\"hide\"") ? "hide" : metadata[metadataPropertyName];

            if (tempObj.metadataValue === "hide") {
                tempObj.selected = "hide";
            } else {
                tempObj.metadataValueObj = tempObj.metadataValue === "" ? "" : JSON.parse(tempObj.metadataValue);
                tempObj.selected = "custom";
            }
        }
    }
    else {
        if (titlebar) {
            tempObj.selected = "hide";
        }
    }

    return tempObj;
}


export function setMetadata(metadataTag, metadataValue, template, updateTemplateFunction, depth) {

    let updatedTemplate = {...template};
    let tempMetadata = {...updatedTemplate.metadata};

    tempMetadata[metadataTag] = metadataValue;

    updateTemplateFunction(tempMetadata, depth);
}


export function deleteMetadata(metadataTag, template, updateTemplateFunction, depth) {

    let updatedTemplate = {...template};
    let tempMetadata = {...updatedTemplate.metadata};

    if (tempMetadata.hasOwnProperty(metadataTag)) {
        delete tempMetadata[metadataTag];
    }

    updateTemplateFunction(tempMetadata, depth);
}


// export function setCustomMetadata(template, updateTemplateFunction, setStateCallbackFunction, fieldName, selected, metadataTag, metadataValueObj, depth = 0) {
//     let updatedTemplate = {...template};
//     let tempMetadata = {...updatedTemplate.metadata};
//     let metadataValueStr = (typeof metadataValueObj === "string") ? metadataValueObj : JSON.stringify(metadataValueObj);
//
//     // NOTE: Reporting category can be "None" its added to the DDLB however the metadata config list does not have it. So index is adjusted +1
//     if ((selected === "default")||(selected === "None")) {
//         if (tempMetadata.hasOwnProperty(metadataTag)) {
//             delete tempMetadata[metadataTag];
//         }
//     }
//     else {
//         tempMetadata[metadataTag] = metadataValueStr;
//     }
//     let displayAsLikert = false; // this.state.displayAsLikert;
//     let metadataSaveName = "";
//     let metadataSaveObjName = "";
//     let setStateFlag = true;
//     switch(fieldName) {
//         case "nameDisplayDefinition":
//             metadataSaveName = "nameDisplayMetadataValue";
//             metadataSaveObjName = "nameDisplayMetadataValueObj";
//             break;
//         case "descDisplayDefinition":
//             metadataSaveName = "descDisplayMetadataValue";
//             metadataSaveObjName = "descDisplayMetadataValueObj";
//             break;
//         case "typeDisplayDefinition":
//             // Question custom can be displayed as radio mode or likert mode
//             metadataSaveName = "typeDisplayMetadataValue";
//             metadataSaveObjName = "typeDisplayMetadataValueObj";
//             displayAsLikert = metadataValueObj.questionType === "RADIO" ? (metadataValueObj.displayAs === "likert") : false;
//             break;
//         case "labelDisplayDefinition":
//             // Question Editor
//             metadataSaveName = "labelDisplayMetadataValue";
//             metadataSaveObjName = "labelDisplayMetadataValueObj";
//             break;
//         case "scriptDisplayDefinition":
//             // Category Script
//             metadataSaveName = "scriptDisplayMetadataValue";
//             metadataSaveObjName = "scriptDisplayMetadataValueObj";
//             break;
//         case "leftDisplayDefinition":
//             // Category title bar embedded text
//             metadataSaveName = "leftDisplayMetadataValue";
//             metadataSaveObjName = "leftDisplayMetadataValueObj";
//             break;
//         case "centerDisplayDefinition":
//             // Category title bar embedded text
//             metadataSaveName = "centerDisplayMetadataValue";
//             metadataSaveObjName = "centerDisplayMetadataValueObj";
//             break;
//         case "rightDisplayDefinition":
//             // Category title bar embedded text
//             metadataSaveName = "rightDisplayMetadataValue";
//             metadataSaveObjName = "rightDisplayMetadataValueObj";
//             break;
//         case "reportingCategory":
//             // Questionnaires and Questions can be assigned a reporting category
//             setStateFlag = false;
//             break;
//         // no default
//     }
//     if (setStateFlag) {
//         setStateCallbackFunction({
//             [fieldName]: selected,
//             [metadataSaveName]: metadataValueStr,
//             [metadataSaveObjName]: metadataValueObj,
//             "displayAsLikert": displayAsLikert
//         });
//     }
//     handleMetadataChange(template, updateTemplateFunction, tempMetadata, depth);
// }



export function setCustomMetadata(fieldTextObjName, template, updateTemplateFunction, setStateCallbackFunction, fieldName, selected, metadataTag, metadataValueObj, depth = 0) {

    let updatedTemplate = portalShared.cloneObj(template);
    let tempMetadata = {...updatedTemplate.metadata};

    let metadataValueStr = "";

    if (metadataTag === portalShared.CUSTOM_DDO_CAT_FORMAT) {
        setStateCallbackFunction(fieldTextObjName, template, updateTemplateFunction, setStateCallbackFunction, fieldName, selected, metadataTag, metadataValueObj, depth);
        return; // !!!!!!!!!!!!!!!!!!!!!!! special case category custom title bar
    }

    // Questionnaires and Questions can be assigned a key metric category
    // If not a stringified object like keyMetricCategory

    if (fieldName !== "keyMetricCategory") {

        if (metadataValueObj !== "hide") {
            let tempMetadataValueObj = portalShared.cloneObj(metadataValueObj);

            if ((typeof fieldTextObjName === "string") && fieldTextObjName.includes(".")) {

                let splitStringArray = fieldTextObjName.split(".");
                if (splitStringArray[0] === "useCustomObj") {
                    let metadataObj = JSON.parse(template.metadata[metadataTag]);
                    metadataObj[splitStringArray[1]] = metadataValueObj;
                    metadataValueStr = JSON.stringify(metadataObj);
                }

            } else {

                if (typeof metadataValueObj === "string") {
                    tempMetadataValueObj = JSON.parse(metadataValueObj);
                    tempMetadataValueObj.selected = selected;
                } else {
                    tempMetadataValueObj.selected = selected;
                }

                metadataValueStr = JSON.stringify(tempMetadataValueObj);

                // If Question Type, then set displayAs
                // if (fieldName === "typeDisplay") {
                //     metadataValueObj.displayAs = metadataValueObj.questionType === "RADIO" ? "single" : "";
                // }

                setStateCallbackFunction({
                    [fieldTextObjName]: {
                        selected: selected,
                        metadataValue: JSON.stringify(metadataValueObj),
                        metadataValueObj: metadataValueObj
                    }
                });
            }
        }
        else {
            metadataValueStr = "hide";

            setStateCallbackFunction({
                [fieldTextObjName]: {
                    selected: selected,
                    metadataValue: "",
                    metadataValueObj: null
                }
            });
        }
    }

    if ((metadataTag === portalShared.CUSTOM_QNR_START_PAGE_OBJ)||(metadataTag === portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ)||(metadataTag === portalShared.CUSTOM_DDO_CAT_FORMAT)) {
        return; // !!!!!!!!!!!!!!!!!!!!!!! special case for start/close page
    }

    // NOTE: Key metric category can be "None" it's added to the DDLB however the metadata config list does not have it. So index is adjusted +1
    // So if default or None is selected value then remove metadata entry
    if ((selected === "default") || (selected === "None")) {
        if (tempMetadata.hasOwnProperty(metadataTag)) {
            delete tempMetadata[metadataTag];
        }
    }
    else {
        tempMetadata[metadataTag] = (fieldName === "keyMetricCategory") ? selected : metadataValueStr;
    }

    handleMetadataChange(template, updateTemplateFunction, tempMetadata, depth);
}


export function handleMetadataChange(template, updateTemplateFunction, metadata, depth) {

    let updatedTemplate = {...template};
    updatedTemplate.metadata = metadata;
    updateTemplateFunction(updatedTemplate, depth);
}

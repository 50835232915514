import IntegrationApi from "../api/IntegrationApi";
import * as types from "../constants/actionConstants";
import {updatePage, updateSort} from "./pageAndSortActions";


const initPageAndSortThenGetIntegrationLogsList = (id, name, filter) => {
    return (dispatch) => {
        dispatch(updatePage("integrationLogs", 1, filter.pageSize));
        // dispatch(updateSort("integrationLogs", "Event date", "desc"));
        dispatch(getIntegrationLogs(id, name, {pageNumber: 1,  pageSize: filter.pageSize, name: "Event date", direction: "desc"}));
    };
};

// Integration Logs does not have a filter so only pageable is required
const getIntegrationLogs = (id, name, pageable, str = "") => {
    return (dispatch) => {
        IntegrationApi.getIntegrationLogList(id, pageable, str).then((data) => {
            if(data.collection.length > 0) name = data.collection[0].integrationEvent.integration.name
            dispatch({
                type: types.INTEGRATION_LOGS_LIST_GET_SUCCESS,
                list: data.collection,
                id: id,
                name: name,
                totalRecords: data.page.totalElements
            });
        });
    };
};

const clearIntegrationLogs = () => {
    return {
        type: types.INTEGRATION_LOGS_LIST_CLEAR
    };
};


const getIntegrationLogById = (id) => {
    return (dispatch) => {
        dispatch({type: types.INTEGRATION_LOG_GET_INIT, id});
        IntegrationApi.getIntegrationLogById(id).then((integrationLog) => {
            dispatch({type: types.INTEGRATION_LOG_GET_SUCCESS, integrationLog});
        });
    };
};


export {
    initPageAndSortThenGetIntegrationLogsList,
    clearIntegrationLogs,
    getIntegrationLogs,
    getIntegrationLogById
};



import React from "react";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as style  from "../utilities/getStyle";
import * as tag from "../constants/customMetadataConstants";
import * as ddo from "../constants/customObjConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"


export function saveMultiSelect(question, answerOption) {

    const {
        surveyEditMode,
        displayMode
    } = this.props;

    if (surveyEditMode === portalShared.EDITABLE_SURVEY) {
        // See if template or instance
        if (displayMode === portalShared.INSTANT_MODE) {
            this.props.answerQuestionOptions(question.id, answerOption.key, {selected: !answerOption.selected});
        } else {
            let tempAnswer = {...answerOption};
            tempAnswer.selected = !answerOption.selected;
            this.props.answerQuestionTemplateOptions(question.id, answerOption.key, tempAnswer);
        }
    }
}


export function multiSelectQuestion() {
    let self = this;

    const {question} = this.props;
    let customTemplateObj = undefined;
    if (tag.metadataExists(question, portalShared.CUSTOM_DDO_QUE_FORMAT)) {
        customTemplateObj = tag.metadataValueObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT);
    }

    let readonlyMode = (this.props.surveyEditMode === portalShared.READONLY_SURVEY);

    let answers = ddo.getAnswerOptions(question);

    let textObj;
    textObj = customTemplateObj?.text;

    let hoverColorText = customTemplateObj?.hoverText?.color ?? undefined;

    let CustomTopographyStyle = style.getCustomTextStyle(textObj, hoverColorText);

    let outlinedCheckboxesMode = tag.metadataExists(question, portalShared.DISPLAY_CHECKBOXES_AS_OUTLINED);

    //let unselectedColorControl = customTemplateObj?.unselectedControl?.color ?? portalShared.COLOR_GRAY_HEX;
    let unselectedColorText = customTemplateObj?.unselectedText?.color ?? undefined;

    //let hoverColorControl = customTemplateObj?.hoverControl?.color ?? portalShared.COLOR_DODGER_BLUE_HEX;

    //let selectedColorControl = customTemplateObj?.selectedControl?.color ?? portalShared.COLOR_DODGER_BLUE_HEX;
    let selectedColorText = customTemplateObj?.selectedText?.color ?? undefined;

    let sideBySide = "row";

    function getTextColor(isChecked) {
        if (isChecked) return selectedColorText;
        else return unselectedColorText;
    }

    let controlColorObj = {
        color: portalShared.COLOR_GRAY_HEX,
        selectedColor: portalShared.COLOR_DODGER_BLUE_HEX,
        hoverColor: portalShared.COLOR_DODGER_BLUE_HEX,
        hoverSelectedColor: portalShared.COLOR_DODGER_BLUE_HEX
    }

    let textColorObj = {
        color: portalShared.COLOR_GRAY_HEX,
        selectedColor: portalShared.COLOR_DODGER_BLUE_HEX,
        hoverColor: portalShared.COLOR_DODGER_BLUE_HEX,
        hoverSelectedColor: portalShared.COLOR_DODGER_BLUE_HEX
    }

    let customQuestionObj = null; // NULL if no custom question object

    if (tag.metadataExistsObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT)) {
        customQuestionObj = tag.metadataValueObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT);
    }

    if (customQuestionObj !== null) {

        controlColorObj = {
            color: portalShared.getColor(customQuestionObj.unselectedControl),
            selectedColor: portalShared.getColor(customQuestionObj.selectedControl),
            hoverColor: portalShared.getColor(customQuestionObj.hoverControl),
            hoverSelectedColor: portalShared.getColor(customQuestionObj.hoverSelectedControl),
        };

        textColorObj = {
            color: portalShared.getColor(customQuestionObj.unselectedText),
            selectedColor: portalShared.getColor(customQuestionObj.selectedText),
            hoverColor: portalShared.getColor(customQuestionObj.hoverText),
            hoverSelectedColor: portalShared.getColor(customQuestionObj.hoverSelectedText)
        }

        sideBySide = customQuestionObj.arrange;
    }

    // const StyledCheckbox = styled(Checkbox)({
    //     // color: unselectedColorControl,
    //     // "&.Mui-checked": {
    //     //     color: selectedColorControl,
    //     // },
    //     // "&:hover": {
    //     //     color: hoverColorControl, // Define the hover color
    //     // },
    //     checked: {color: "red"},
    // });


    //let sideBySide = tag.metadataExists(question, portalShared.DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE_WRAP) || tag.metadataExists(question, portalShared.DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE);
    //
    //
    // if (questionCustomObj)) {
    //     let questionCustomObj = tag.metadataValueObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT);
    //
    // }



    // NOTE: The migration to MUI v5 eliminated outlined checkboxes
    if (outlinedCheckboxesMode) {

        let outlinedOverrides = style.getOutlinedOverrides(question, textObj);

        let StyledFormControlLabel = null;

        return (
            <FormGroup style={{display: "flex", flexDirection: sideBySide ? "row" : "column", paddingLeft: "10px"}}>
                {answers.map(answer => {

                    StyledFormControlLabel = style.getStyledFormControlLabel(outlinedOverrides, answer.selected, readonlyMode);

                    return (
                        <StyledFormControlLabel
                            key={answer.id}
                            control={
                                <Checkbox
                                    onChange={saveMultiSelect.bind(self, question, answer)}
                                    disableRipple={readonlyMode}
                                    disableFocusRipple={readonlyMode}
                                    checked={answer.selected}
                                    value={answer.templateKey}
                                    // classes={{
                                    //     root: classes.root,
                                    //     checked: classes.checked,
                                    // }}
                                />
                            }
                            label={<div style={{display: "flex", alignItems: "center"}}>{<CustomTopographyStyle style={{color: getTextColor(answer.selected)}}>{answer.optionText}</CustomTopographyStyle>}</div>}
                        />
                    );
                })
                }
            </FormGroup>
        );
    } else {

        return (
            <FormGroup style={{display: "flex", flexDirection: sideBySide === "row" ? "column" : "row"}}>
                {answers.map(answer => {
                    return (
                        <FormControlLabel
                            key={answer.id}
                            control={
                                <Checkbox
                                    onChange={saveMultiSelect.bind(self, question, answer)}
                                    disableRipple={readonlyMode}
                                    disableFocusRipple={readonlyMode}
                                    checked={answer.selected}
                                    value={answer.templateKey}
                                    sx={{color: controlColorObj.color, "&.Mui-checked": {color: controlColorObj.selectedColor}, "&:hover": {color: answer.selected ? controlColorObj.hoverSelectedColor : controlColorObj.hoverColor}}}
                                />
                            }
                            label={<div style={{display: "flex", alignItems: "center"}}>{<CustomTopographyStyle sx={{color: answer.selected ? textColorObj.selectedColor : textColorObj.color, "&:hover": {color: answer.selected ? textColorObj.hoverSelectedColor : textColorObj.hoverColor}}}>{answer.optionText}</CustomTopographyStyle>}</div>}
                        />
                    );
                })
                }
            </FormGroup>
        );
    }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Popover from "@mui/material/Popover";
import Divider from '@mui/material/Divider';
import FilterListIcon from '@mui/icons-material/FilterList';
import * as TEMPLATE from "../../../constants/templateConstants";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "../../common/ArgoTextFieldSelectHook";
import ArgoCheckboxHook from "../../common/ArgoCheckboxHook";
import TagSelectOrCreate from "../../qd/tags/TagSelectOrCreate";
import ArgoButtonHook from "../../common/ArgoButtonHook";
import ArgoIconButtonHook from "../../common/ArgoIconButtonHook";
import {setFilter, clearFilter} from "../../../actions/setFilter";
import {getTagDDLB} from "../../../actions/tagActions";
import {initializeQuestionEditor, clearQuestionTemplateSearchTags} from "../../../actions/questionEditorActions"
import {pageableObjFromFilterSetPageOne, pageableObjFromFilter} from "../../../utilities/PageableAndFilterUtilities";
import {IntegrationTypes} from "../../../constants/integrationConstants";
import {initialPageSortFilterObject} from "../../../constants/filterConstants";
import * as portalShared from "../../../portal/shared/metadataConstantsAndUtilities";
import {getIntegrationLogs} from "../../../actions/integrationLogsActions";


// This fixes the DDLB transparency issue
// const theme = createTheme({
//     backgroundColor: "white"
// });


// const filterStyles = {
//     gridStyle: {
//         margin: 0
//     },
//     gridTile: {
//         padding: 12
//     },
//     popoverStyle: {
//         width: "700px",
//     },
//     cardStyle: {
//         backgroundColor: "#f2f2f2",
//         paddingTop:0,
//         paddingBottom: 0
//     }
// };

//const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';

let initialFilterClear = Object.assign({}, initialPageSortFilterObject);  // Clone initialFilterObject object.

let initialFilter = Object.assign({}, initialPageSortFilterObject);  // Clone initialFilterObject object.

class FilterPopOver extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            tagName: "",
            selectedTags: [],
            typesArray: [], // Used for question types and action types
            ...initialFilter
        };
    }

    componentDidMount() {
        document.addEventListener(KEY_EVENT_TYPE, this.onEscapeKey, false);

        this.props.getTagDDLB(pageableObjFromFilter(this.props.filterTags), this.props.filterTags);

        this.props.initializeQuestionEditor(null, 2);

        // Add All menu item to question types
        let questionTypesObj = {All: "All", ...this.props.types}; //Add All to questions types object
        let typesArray = [];
        Object.keys(questionTypesObj).map(type => typesArray.push({value: type, text: questionTypesObj[type]}));
        this.setState({typesArray: typesArray});
    }

    componentWillReceiveProps (nextProps) {

        // NOTE: Using question depth 2 to store tags for search
        let newState = {
            tagName: "",
            selectedTags: [...nextProps.questionSearchTags],
            ...nextProps.filter
        };

        newState.outstanding = (nextProps.filter !== undefined) ? nextProps.filter.outstanding : false;

        this.setState(newState)
    }

    handleIconButtonClick = (event) => {
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    };

    onChangeText = (event, fieldName) => {
        this.setState({[fieldName]: event.target.value}, () => this.setFilter(fieldName, event.target.value));
    };

    onChangeSelect = (event, fieldName) => {
        this.setState({[fieldName]: event.target.value}, () => this.setFilter(fieldName, event.target.value));
    };

    onChangeCheckbox = (event, fieldName) => {
        this.setState({[fieldName]: event.target.checked}, () => this.setFilter(fieldName, event.target.checked));
    };

    setFilter = (fieldName, value) => {
        let filter = {...this.props.filter}; // Shallow copy

        filter[fieldName] = value;

        //this.props.setFilter(pageableObjFromFilterSetPageOne(this.props.filter), filter, this.props.templateType, this.props.templateMode);
    }

    onClickClearFilter = () => {
        let filter = {...this.props.filter};

        this.props.clearQuestionTemplateSearchTags(2); // Question Editor Depth 2 is used for FilterPopOver

        this.props.clearFilter(pageableObjFromFilterSetPageOne(this.props.filter), filter, this.props.templateType, this.props.templateMode);

        this.setState(initialFilterClear);
    }

    submitFilter = () => {

        let tags = [];
        for (let i = 0; i < this.state.selectedTags.length; i++) {
            tags.push(this.state.selectedTags[i].id);
        }

        let filter = {...this.props.filter};

        filter.title = this.state.title;
        filter.nameQualifier = this.state.nameQualifier;
        filter.updatedBy = this.state.updatedBy;
        filter.status = this.state.status;
        filter.type = this.props.showQuestionType ? this.state.questionType : this.state.actionType;
        filter.integrationType = this.state.integrationType;
        filter.outstanding = this.state.outstanding;
        filter.tags = tags;
        filter.ucid = this.state.ucid;

        if(filter.ucid !== "" && this.props.integrationID !== null) {
            this.props.getIntegrationLogs(this.props.integrationID, filter.name,{pageNumber: filter.pageNumber, pageSize: filter.pageSize, name: filter.name, direction: filter.direction}, `ucid=${filter.ucid}`)
        }
        else this.props.setFilter(pageableObjFromFilterSetPageOne(this.props.filter), filter, this.props.templateType, this.props.templateMode);

        if (typeof this.props.setLoadingState === "function") {
            this.props.setLoadingState(this.closeWindow, null, "FILTER"); // Added filter param for category window for fix filter dows not turn off render animation.
        } else {
            this.closeWindow();
        }
    };

    onEscapeKey = (event) => {
        if (event.key === "Escape") {
            document.removeEventListener(KEY_EVENT_TYPE, null, false);
            this.closeWindow();
        }
    };

    closeWindow = () => {
        // if (typeof this.props.setLoadingState === "function") {
        //this.props.setLoadingState(this.closeWindow, null, "FILTER"); // Added filter param for category window for fix filter does not turn off render animation.
        // }
        // else {
        //     this.closeWindow();
        // }
        this.setState({open: false});
    }

    render() {
        if (!this.props.filter) {
            return null;
        }

        // const {
        //     style
        // } = this.props;

        const {
            integrationType
        } = this.state;

        return (
            <div style={{width: "100%", paddingRight: "5px"}}>

                <ArgoIconButtonHook
                    fileName="FilterPopOver"
                    fieldName="FilterListIcon"
                    onClick={this.handleIconButtonClick}
                    icon={<FilterListIcon/>}
                />

                <Popover
                    anchorOrigin = {{ vertical: "bottom", horizontal: "right"}}
                    // targetOrigin = {{ vertical: 'top', horizontal: 'right'}}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    slotProps={{style: {height: "455px", width: "700px", backgroundColor: "white"}}}
                    onRequestClose={this.closeWindow.bind(this)}
                >

                    <div style={{width: "100%", paddingTop: "5px", paddingLeft: "15px", height: "28px", backgroundColor: portalShared.COLOR_DODGER_BLUE_HEX}}>
                        <div style={{color: "white"}}>Filter Options</div>
                    </div>

                    <div style={{width: "100%", paddingTop: "15px", paddingLeft: "15px", paddingBottom: "15px", paddingRight: "15px"}}>

                        <div style={{height: "290px", width: "320px", float: "left"}}>

                            {this.props.showName ?
                                <React.Fragment>
                                    <ArgoTextFieldHook
                                        fileName="FilterPopOver"
                                        fieldName="title"
                                        label="Name"
                                        value={this.state.title}
                                        onChange={this.onChangeText}
                                    />

                                    <div style={{height: "30px"}}>&nbsp;</div>
                                </React.Fragment>
                                : ""}

                            {this.props.showUpdatedBy ?
                                <React.Fragment>
                                    <ArgoTextFieldHook
                                        fileName="FilterPopOver"
                                        fieldName="updatedBy"
                                        label="Updated by"
                                        value={this.state.updatedBy}
                                        onChange={this.onChangeText}
                                    />

                                    <div style={{height: "30px"}}>&nbsp;</div>
                                </React.Fragment>
                                : ""}

                            {this.props.showIntegrationTypes ?
                                <React.Fragment>
                                    <ArgoTextFieldSelectHook
                                        fileName="FilterPopOver"
                                        fieldName="integrationType"
                                        label="Integration type"
                                        value={integrationType}
                                        onChange={this.onChangeSelect}
                                        menuItems={IntegrationTypes}
                                    />

                                    <div style={{height: "30px"}}>&nbsp;</div>
                                </React.Fragment>
                                : ""}

                            {this.props.showUCID ?
                                <React.Fragment>
                                    <ArgoTextFieldHook
                                        fileName="FilterPopOver"
                                        fieldName="ucid"
                                        label="UCID"
                                        value={this.state.ucid}
                                        onChange={this.onChangeText}
                                    />

                                    <div style={{height: "30px"}}>&nbsp;</div>
                                </React.Fragment>
                                : ""}

                            {this.props.showStatus ?
                                <React.Fragment>
                                    <ArgoTextFieldSelectHook
                                        fileName="FilterPopOver"
                                        fieldName="status"
                                        label="Status"
                                        value={this.state.status}
                                        onChange={this.onChangeSelect}
                                        menuItems={this.props.statuses}
                                    />

                                    <div style={{height: "30px"}}>&nbsp;</div>
                                </React.Fragment>
                                : ""}

                            {this.props.showOutstandingQuestionnaires ?
                                <React.Fragment>
                                    <ArgoCheckboxHook
                                        fileName="FilterPopOver"
                                        fieldName="outstanding"
                                        label="Outstanding questionnaires"
                                        onChange={this.onChangeCheckbox}
                                        width="300px"
                                    />

                                    <div style={{height: "30px"}}>&nbsp;</div>
                                </React.Fragment>
                                : ""}

                        </div>

                        <div style={{height: "327px", width: "35px", float: "left"}}></div>

                        <div style={{height: "327px", width: "320px", float: "left"}}>

                            <div style={{height: "290px", width: "320px", float: "left"}}>

                                {this.props.showQualifier ?
                                    <React.Fragment>
                                        <ArgoTextFieldHook
                                            fileName="FilterPopOver"
                                            fieldName="nameQualifier"
                                            label="Qualifier"
                                            value={this.state.nameQualifier}
                                            onChange={this.onChangeText}
                                        />

                                        <div style={{height: "30px"}}>&nbsp;</div>
                                    </React.Fragment>
                                    : ""}

                                {this.props.showQuestionType ?
                                    <React.Fragment>
                                        <ArgoTextFieldSelectHook
                                            fileName="FilterPopOver"
                                            fieldName="questionType"
                                            label="Question Type"
                                            value={this.state.questionType}
                                            onChange={this.onChangeSelect}
                                            menuItems={this.state.typesArray}
                                        />

                                        <div style={{height: "30px"}}>&nbsp;</div>
                                    </React.Fragment>
                                    : ""}

                                {this.props.showActionType ?
                                    <React.Fragment>
                                    <ArgoTextFieldSelectHook
                                        fileName="FilterPopOver"
                                        fieldName="actionType"
                                        label="Action Type"
                                        value={this.state.actionType}
                                        onChange={this.onChangeSelect}
                                        menuItems={this.state.typesArray}
                                    />
                                    </React.Fragment>
                                    : ""}

                                <div>
                                    {/*NOTE: I can't explain why question depth has to be 2 here to work*/}
                                    {this.props.showSelectTag ?
                                        <TagSelectOrCreate questionDepth={2} allowCreate={false} filterMode={true}/>
                                        : <div/>
                                    }
                                </div>

                            </div>

                        </div>

                    </div>

                    <div style={{clear: "both"}}></div>

                    <div>&nbsp;</div>

                    <Divider variant="fullWidth" sx={{ opacity: 1}}/>

                    <div id="FilterPopOver-button-container-div" style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", paddingLeft: "15px", paddingRight: "15px", paddingTop: "10px", paddingBottom: "10px"}}>

                        <ArgoButtonHook
                            fileName="FilterPopOver"
                            fieldName="close"
                            variant="text"
                            label="Close"
                            width={100}
                            onClick={this.closeWindow}
                        />

                        <div id={"FilterPopOver-right-buttons-container-div"} style={{display: "flex", marginLeft: "auto"}}>

                            <ArgoButtonHook
                                fileName="FilterPopOver"
                                fieldName="clear"
                                variant="text"
                                label="Clear"
                                width={100}
                                onClick={this.onClickClearFilter}
                            />

                            <div style={{width: "20px"}}>&nbsp;</div>

                            <ArgoButtonHook
                                fileName="FilterPopOver"
                                fieldName="search"
                                variant="outlined"
                                label="Search"
                                width={100}
                                onClick={this.submitFilter}
                            />

                            <div style={{width: "40px"}}>&nbsp;</div>

                        </div>

                    </div>

                </Popover>
            </div>
        );
    }
}


FilterPopOver.defaultProps = {
    showName: true,
    showUpdatedBy: true,
    showStatus: true,
    showQualifier: true,
    showSelectTag: false,
    showQuestionType: false,
    showOutstandingQuestionnaires: false,
    showIntegrationTypes: false,
    showActionType: false,
    showUCID: false,
    integrationID: null
};


FilterPopOver.propTypes = {
    style: PropTypes.object,
    submitValue: PropTypes.func.isRequired,
    page: PropTypes.string,
    templateType: PropTypes.string.isRequired,
    templateMode: PropTypes.string.isRequired,
    filterName: PropTypes.string.isRequired,
    types: PropTypes.object,
    tags: PropTypes.bool,
    outstanding: PropTypes.bool,
    integrationTypes: PropTypes.array,
    setLoadingState: PropTypes.func,
    statuses: PropTypes.array,
    showName: PropTypes.bool,
    showUpdatedBy: PropTypes.bool,
    showStatus: PropTypes.bool,
    showQualifier: PropTypes.bool,
    showSelectTag: PropTypes.bool,
    showQuestionType: PropTypes.bool,
    showOutstandingQuestionnaires: PropTypes.bool,
    showIntegrationTypes: PropTypes.bool,
    showActionType: PropTypes.bool,
    showUCID: PropTypes.bool,
    integrationID: PropTypes.number
};

function mapStateToProps(state, props) {
    return {
        filter: state.filter[props.filterName],
        filterTags: state.filter[TEMPLATE.FILTER.all.selectExistingTags] || {},
        questionSearchTags: (state.questionEditor.length === 3) ? state.questionEditor[2].template.searchTags : [],
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {setFilter,
        clearFilter,
        getTagDDLB,
        initializeQuestionEditor,
        getIntegrationLogs,
        clearQuestionTemplateSearchTags,
        }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterPopOver);



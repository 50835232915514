import React, {Component} from "react";
import PropTypes from "prop-types";
import ArgoColorInput from "./ArgoColorInput";
import ArgoSelectPortalTextDisplayType from "./ArgoSelectPortalTextDisplayType";
import ArgoTextDisplayCustom from "../common/ArgoTextDisplayCustom";
import ArgoCheckboxHook from "./ArgoCheckboxHook";
import ArgoTextFieldHook from "./ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import ArgoLikertAnswerGenerator from "./ArgoLikertAnswerGenerator";
import * as TEMPLATE from "../../constants/templateConstants";
import * as portalShared from "../../portal/shared/metadataConstantsAndUtilities"


// ---------------------------------------------------------------------------------------------------------------------
// Display Type Radios/Checkboxes
// ---------------------------------------------------------------------------------------------------------------------

const customColorRadiosAndCheckboxesTitles = [
    "Control (Unselected)",
    "Control (Selected)",
    "Control (Hover)",
    "Control (Hover Selected)",

    "Text (Unselected)",
    "Text (Selected)",
    "Text (Hover)",
    "Text (Hover Selected)"
];

const customColorRadiosAndCheckboxesProps = [
    "unselectedControl",
    "selectedControl",
    "hoverControl",
    "hoverSelectedControl",

    "unselectedText",
    "selectedText",
    "hoverText",
    "hoverSelectedText",
];

// ---------------------------------------------------------------------------------------------------------------------
// Single Select Display Types with a control icon ie. Radios and Outlined Radios
// ---------------------------------------------------------------------------------------------------------------------

const customColorTitlesOutlined = [
    "Control (Unselected)",
    "Control (Selected)",
    "Control (Hover)",
    "Control (Hover Selected)",

    "Border (Unselected)",
    "Border (Selected)",
    "Border (Hover)",
    "Border (Hover Selected)",

    "Background (Unselected)",
    "Background (Selected)",
    "Background (Hover)",
    "Background (Hover Selected)",

    "Text (Unselected)",
    "Text (Selected)",
    "Text (Hover)",
    "Text (Hover Selected)"
];

const customColorPropsOutlined = [
    "unselectedControl",
    "selectedControl",
    "hoverControl",
    "hoverSelectedControl",

    "unselectedBorder",
    "selectedBorder",
    "hoverBorder",
    "hoverSelectedBorder",

    "unselectedBackground",
    "selectedBackground",
    "hoverBackground",
    "hoverSelectedBackground",

    "unselectedText",
    "selectedText",
    "hoverText",
    "hoverSelectedText",
];

// ---------------------------------------------------------------------------------------------------------------------
// Single Select Display Types without a control icon ie. Standard Button, Clear Button
// ---------------------------------------------------------------------------------------------------------------------

const customColorTitlesNoControl = [
    "Border (Unselected)",
    "Border (Selected)",
    "Border (Hover)",
    "Border (Hover Selected)",

    "Background (Unselected)",
    "Background (Selected)",
    "Background (Hover)",
    "Background (Hover Selected)",

    "Text (Unselected)",
    "Text (Selected)",
    "Text (Hover)",
    "Text (Hover Selected)"
];

const customColorPropsNoControl = [
    "unselectedBorder",
    "selectedBorder",
    "hoverBorder",
    "hoverSelectedBorder",

    "unselectedBackground",
    "selectedBackground",
    "hoverBackground",
    "hoverSelectedBackground",

    "unselectedText",
    "selectedText",
    "hoverText",
    "hoverSelectedText"
];

// ---------------------------------------------------------------------------------------------------------------------
// Single Select Display Type DDLB
// ---------------------------------------------------------------------------------------------------------------------

const customColorTitlesDDLB = [
    "Floating Label",
    "Selected Text",
    "Border (Unselected)",
    "Border (Hover)",
    "Border (Selected)",
    "After Selection Border"
];

const customColorPropsDDLB = [
    "floatingLabel",
    "selectedText",
    "unselectedBorder",
    "hoverBorder",
    "selectedBorder",
    "ddlbBorderColorAfterSelection"
];

// ---------------------------------------------------------------------------------------------------------------------
// Single Select Display Type DDLB
// -

const customColorTitlesFreeText = [
    "Place Holder",
    "Text (Unselected)",
    "Text (Hover)",
    "Text (Selected)",
    "Floating Label (Unselected)",
    "Floating Label (Hover)",
    "Floating Label (Selected)",
    "Border (Unselected)",
    "Border (Hover)",
    "Border (Selected)",
]

const customColorPropsFreeText = [
    "placeHolder",
    "unselectedText",
    "selectedText",
    "hoverText",
    "unselectedFloatingLabel",
    "hoverFloatingLabel",
    "selectedFloatingLabel",
    "unselectedBorder",
    "hoverBorder",
    "selectedBorder",
]


// ---------------------------------------------------------------------------------------------------------------------
//
// ---------------------------------------------------------------------------------------------------------------------

const styleList = [
    {value: "outlined", text: "Outlined"},
    {value: "standard", text: "Standard"},
    {value: "filled", text: "Filled"}
];

const sizeList = [
    {value: "small", text: "Small"},
    {value: "medium", text: "Medium"}
];

const widthFormatList = [
    {value: "percentage", text: "Percentage"},
    {value: "pixels", text: "Pixels"}
];

const labelPositionList = [
    {value: "none", text: "None"},
    {value: "above", text: "Above"},
    {value: "below", text: "Below"}
];

const arrangeList = [
    {value: "row", text: "One per Row"},
    {value: "side", text: "Side by Side"}
];

const defaultOrCustomList = [
    {value: "default", text: "Default"},
    {value: "custom", text: "Custom"}
];

const zipFormatList = [
    {value: "#####", text: "Standard (5 Digit)"},
    {value: "#####-####", text: "Extended (9 Digit)"}
]

const decimalPlacesList = [
    {value: 1, text: "1"},
    {value: 2, text: "2"},
    {value: 3, text: "3"},
    {value: 4, text: "4"},
    {value: 5, text: "5"},
    {value: 6, text: "6"},
]

// const titleTypeList = [
//     {value: "none", text: "None"},
//     {value: "standard", text: "Standard"},
//     {value: "graybar", text: "Graybar"}
// ];


class ArgoQuestionFormatCustom extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatObj: props.initToObj,
            likertTitles: "none"
        };
    }


    // Display As...
    //  -Single Select
    //  -Likert

    // Single Select Entry Style
    //  -Radios  (Default depends on number of answers)
    //  -DDLB
    //  -Clear Buttons
    //  -Outlined

    // Likert
    //  -Buttongroup
    //  -Circles
    //  -Circle Pyramid

    // Circles
    //  -Standard
    //  -Colors (Primary)
    //  -Colors (Alternate)

    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        let tempObj = {...this.state.formatObj};  // Get the state object associated with the question type

        // Addresses have properties are stored in objects
        if (fieldName.includes(".")) {
            let objDotFieldNameArray = fieldName.split(".");
            tempObj[objDotFieldNameArray[0]][objDotFieldNameArray[1]] = value; // set the changed value in an address object
            if (objDotFieldNameArray[1] === "widthType") {
                tempObj[objDotFieldNameArray[0]].width = value + (tempObj[objDotFieldNameArray[0]].widthEntry === "pixels") ? "px" : "%";
            }
        } else {
            tempObj[fieldName] = value; // set the changed value
            if (tempObj.widthType === "pixels") {
                tempObj.width = value + "px"
            } else {
                tempObj.width = value + "%"
            }
        }

        // Check to see if any related properties need to be updated
        switch (fieldName) {
            case "indent":
                tempObj["indent"] =  value + "px";
                break;
            // no default
        }

        this.setState({formatObj: tempObj}, () => {this.setMetadataValue(tempObj)});

    };


    onSelectChange = (event, fieldName) => {

        let selected = event.target.value;

        let tempObj = portalShared.cloneObj(this.state.formatObj);

        // Addresses have properties are stored in objects
        if (fieldName.includes(".")) {
            let objDotFieldNameArray = fieldName.split(".");
            tempObj[objDotFieldNameArray[0]][objDotFieldNameArray[1]] = selected; // set the changed value in an address object
            if (objDotFieldNameArray[1] === "widthType") {
                tempObj[objDotFieldNameArray[0]].width = selected + (tempObj[objDotFieldNameArray[0]].widthType === "pixels") ? "px" : "%";
            }
        } else {
            tempObj[fieldName] = selected; // set the changed value
        }

        tempObj[fieldName] = selected;

        switch (this.props.questionType) {
            case portalShared.FREE_TEXT:
            case portalShared.EMAIL:
            case portalShared.PHONE:
            case portalShared.NUMBER:
            case portalShared.CURRENCY:
            case portalShared.ADDRESS_FULL:
            case portalShared.DATE:
            case portalShared.DATE_RANGE:
                switch (fieldName) {
                    case "labelPosition":
                        if (selected === "none") {
                            tempObj.label = "";
                            tempObj.label2 = "";
                        }
                        break;
                    // no default
                }
                break;

            case portalShared.RADIO:
            case portalShared.YES_NO:

                switch (fieldName) {
                    case "displayAs":
                        // Single Select ie.. NOT Likert
                        if (selected === "single") {
                            // If single reset Style back to the default radios
                            tempObj.style = (tempObj.style === "") ? "radios" : tempObj.style
                        }
                        else {
                            tempObj.style = ""; // If likert then style is set to ""
                        }
                        tempObj[fieldName] = selected;
                        break;
                    case "style":
                        tempObj[fieldName] = selected;
                        tempObj.color = "default"; // Reset Color to default if single select style is changed
                        portalShared.setSingleSelectDefaultColors(tempObj, selected);
                        break;
                    case "titleType":
                        if (selected === "none") {
                            tempObj.titleLeft = "";
                            tempObj.titleCenter = "";
                            tempObj.titleRight = "";
                        }
                        break;
                    default:
                        console.log("ArgoQuestionFormatCustom.js  Switch default E02"); // eslint-disable-line
                }
                break;
            case portalShared.LIKERT_RADIO:
                // Likert radios have NO custom object, customization will be handled at the group level.
                break;
            default:
                console.log("ArgoQuestionFormatCustom.js  Switch default E03"); // eslint-disable-line
        }

        this.setState({formatObj: tempObj}, () => {this.setMetadataValue(tempObj)});
    };


    // selectChangeTitle = (fieldName, e, index, selected) => {
    //     this.setState({[fieldName]: selected});
    // }


    onCheckBoxChange = (event, fieldName) => {

        let checked = event.target.checked;

        let tempObj = {...this.state.formatObj};

        tempObj[fieldName] = checked;

        this.setState({formatObj: tempObj}, () => {this.setMetadataValue(tempObj)});
    };


    setColor = (fieldName, colorObj) => {

        let tempObj = {...this.state.formatObj};
        let obj = (fieldName === "text") ? tempObj[fieldName].colorObj : tempObj[fieldName]

        obj["colorType"] = colorObj.colorType;
        obj["colorIndex"] = colorObj.colorIndex;
        obj["colorEntry"] = colorObj.colorEntry;
        obj["color"] = colorObj.colorType === "select" ? colorObj.color : colorObj.colorEntry;
        obj["opacity"] = colorObj.opacity;
        obj["opacityEntry"] = colorObj.opacityEntry;

        this.setState({formatObj: tempObj}, () => {this.setMetadataValue(tempObj)});
    };


    setMetadataValue = () => {

        let mdTempObj = null;

        switch(this.props.questionType) {
            case portalShared.FREE_TEXT:
            case portalShared.EMAIL:
            case portalShared.PHONE:
            case portalShared.NUMBER:
            case portalShared.CURRENCY:
            case portalShared.DATE:
            case portalShared.DATE_RANGE:
            case portalShared.ZIPPOSTAL_ONLY:
                mdTempObj = portalShared.setCustomQuestionTextObj(this.state.formatObj);
                break;

            case portalShared.RADIO:
            case portalShared.YES_NO:
            case portalShared.STATE_ONLY:
                mdTempObj = portalShared.setCustomQuestionSingleSelectObj(this.state.formatObj);
                break;

            case portalShared.CHECKBOX:
                mdTempObj = portalShared.setCustomQuestionCheckboxObj(this.state.formatObj);
                break;

            case portalShared.ADDRESS_FULL:
                mdTempObj = portalShared.setCustomQuestionAddressObj(this.state.formatObj);
                break;
            // no default
        }

        let mpo = this.props.metadataPropsObj;

        this.setState({formatObj: mdTempObj}, () => {mpo.setCustomMetadataFunction(null, mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, "custom", mpo.metadataTag, mdTempObj, mpo.depth)});
    };



    setLabelMetadataValue = (void1, void2, void3, void4, customTextObj) => {

        let mpo = this.props.metadataPropsObj;

        let metadata = JSON.parse(mpo.template.metadata[portalShared.CUSTOM_DDO_QUE_LABEL]);

        portalShared.setObj(metadata, customTextObj);

        let template = portalShared.cloneObj(mpo.template);

        template.metadata[portalShared.CUSTOM_DDO_QUE_LABEL] = JSON.stringify(metadata);

        mpo.templateUpdateFunction(template, this.props.questionDepth);

        //mpo.setCustomMetadataFunction(mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, "custom", mpo.metadataTag, {}, mpo.depth); // Ankoor HERE !!!!!
    };

    setStateFormatObj = (formatObj) => {
        this.setState({formatObj: formatObj});
    }

    displayTextEntry = () => {

        return <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

            <ArgoTextFieldSelectHook
                fileName="ArgoQuestionFormatCustom"
                fieldName="textStyle"
                label="Text Style"
                value={this.state.formatObj.textStyle}
                onChange={this.onSelectChange}
                width="150px"
                menuItems={defaultOrCustomList}
            />

            <div style={{width: "20px"}}></div>

            {this.state.formatObj.textStyle === "custom" ?
                <ArgoTextDisplayCustom
                    templateType={TEMPLATE.TYPE.QUESTION}
                    textEntryType={TEMPLATE.TEXT_TYPE.QUESTION.ANSWER_TEXT}
                    fieldName="textStyle"
                    fieldTextObjName="useCustomObj.text.NOTE-metadata-function-setCustomMetadata-uses-this"
                    initToObj={this.state.formatObj.text}
                    metadataPropsObj={this.props.metadataPropsObj}
                    indent={false}
                    paddingTop={"-20px"}
                    showDisplay={false}
                    showOpacity={false}
                    showColor={false}
                />
                : ""
            }
        </div>;
    };


    formatCustomOptions = () => {

        let customColorsArray = [];
        let customColorPropsArray = [];

        let customLabelTextObj;
        if (this.props.metadataPropsObj.template.metadata.hasOwnProperty(portalShared.CUSTOM_DDO_QUE_LABEL)) {
            customLabelTextObj = JSON.parse(this.props.metadataPropsObj.metadata[portalShared.CUSTOM_DDO_QUE_LABEL]);
        }

        let qType = this.props.questionType;


        switch(qType) {

            case portalShared.FREE_TEXT:
            case portalShared.EMAIL:
            case portalShared.PHONE:
            case portalShared.NUMBER:
            case portalShared.CURRENCY:
            case portalShared.ZIPPOSTAL_ONLY:
            case portalShared.DATE:
            case portalShared.DATE_RANGE:

                customColorsArray = customColorTitlesFreeText;
                customColorPropsArray = customColorPropsFreeText;
                switch (this.state.formatObj.style) {
                    case "filled":
                        customColorsArray = [...customColorTitlesFreeText,
                            "Selected Background",
                            "Hover Background",
                            "Unselected Background"
                        ]
                        customColorPropsArray = [...customColorPropsFreeText,
                            "selectedBackground",
                            "hoverBackground",
                            "unselectedBackground",]
                        break;
                    // no default
                }

                return (
                    <React.Fragment>
                        <div style={{width: "100%", backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX}}>

                            <div style={{height: "10px"}}>&nbsp;</div>

                            <div style={{display: "flex", alignItems: "left", width: "100%"}}>

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="style"
                                    label="Style"
                                    value={this.state.formatObj.style}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={styleList}
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="size"
                                    label="Size"
                                    value={this.state.formatObj.size}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={sizeList}
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="widthType"
                                    label="Width Format"
                                    value={this.state.formatObj.widthType}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={widthFormatList}
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="widthEntry"
                                    label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                                    value={this.state.formatObj.widthEntry}
                                    type="number"
                                    onChange={this.onTextChange}
                                    width="70px"
                                />

                                <div style={{width: "20px"}}></div>

                                {(qType !== portalShared.DATE && qType !== portalShared.DATE_RANGE) ?
                                    <div style={{
                                        display: "flex",
                                        alignItems: "left",

                                        backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX
                                    }}>
                                        <ArgoTextFieldHook
                                            fileName="ArgoQuestionFormatCustom"
                                            fieldName="max"
                                            label="Max Characters"
                                            value={this.state.formatObj.max}
                                            type="number"
                                            onChange={this.onTextChange}
                                            width="100px"
                                        />

                                        <div style={{width: "20px"}}></div>

                                        {qType === portalShared.FREE_TEXT ?
                                            <div style={{
                                                display: "flex",
                                                alignItems: "left",

                                                backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX
                                            }}>

                                                <ArgoCheckboxHook
                                                    fileName="ArgoQuestionFormatCustom"
                                                    fieldName="multiline"
                                                    label={"Multiline"}
                                                    onChange={this.onCheckBoxChange}
                                                    checked={this.state.formatObj.multiline}
                                                    paddingTop="15px"
                                                    width="110px"
                                                />

                                                <div style={{width: "20px"}}></div>

                                                <ArgoTextFieldHook
                                                    fileName="ArgoQuestionFormatCustom"
                                                    fieldName="minRows"
                                                    label="Min Rows"
                                                    value={this.state.formatObj.minRows}
                                                    type="number"
                                                    onChange={this.onTextChange}
                                                    width="80px"
                                                    disabled={!this.state.formatObj.multiline}
                                                />

                                                <div style={{width: "20px"}}></div>

                                                <ArgoTextFieldHook
                                                    fileName="ArgoQuestionFormatCustom"
                                                    fieldName="maxRows"
                                                    label="Max Rows"
                                                    value={this.state.formatObj.maxRows}
                                                    type="number"
                                                    onChange={this.onTextChange}
                                                    width="80px"
                                                    disabled={!this.state.formatObj.multiline}
                                                />

                                                <div style={{width: "20px"}}></div>

                                            </div> : ""
                                        }
                                    </div>
                                    :
                                    <div>
                                        {qType === portalShared.DATE_RANGE ?
                                            <div style={{
                                                display: "flex",
                                                alignItems: "left",

                                                backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX
                                            }}>

                                                <ArgoTextFieldSelectHook
                                                    fileName="ArgoQuestionFormatCustom"
                                                    fieldName="arrangeDateRange"
                                                    label="Arrange"
                                                    value={this.state.formatObj.arrangeDateRange}
                                                    onChange={this.onSelectChange}
                                                    width="150px"
                                                    menuItems={arrangeList}
                                                />

                                                <div style={{width: "20px"}}></div>
                                            </div> : ""
                                        }
                                    </div>
                                }

                                <ArgoTextFieldHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="floatingLabel"
                                    label={qType === portalShared.DATE_RANGE ? "Floating Label From" : "Floating Label"}
                                    value={this.state.formatObj.unselectedFloatingLabel.text}
                                    onChange={event => {

                                        let value = event.target.value;

                                        let tempObj = {...this.state.formatObj};  // Get the state object associated with the question type

                                        tempObj.unselectedFloatingLabel.text = value; // set the changed value

                                        this.setState({formatObj: tempObj}, () => {
                                            this.setMetadataValue(tempObj)
                                        });
                                    }}
                                    width="230px"
                                />

                                {qType === portalShared.DATE_RANGE ?
                                    <div style={{display: "flex", alignItems: "left", width: "100%"}}>
                                        <div style={{width: "20px"}}></div>

                                        <ArgoTextFieldHook
                                            fileName="ArgoQuestionFormatCustom"
                                            fieldName="floatingLabel2"
                                            label="Floating Label To"
                                            value={this.state.formatObj.floatingLabel2}
                                            onChange={event => {

                                                let value = event.target.value;

                                                let tempObj = {...this.state.formatObj};  // Get the state object associated with the question type

                                                tempObj.floatingLabel2 = value; // set the changed value

                                                this.setState({formatObj: tempObj}, () => {
                                                    this.setMetadataValue(tempObj)
                                                });
                                            }}
                                            width="230px"
                                        />

                                        </div>: ""
                                    }

                                    <div style={{width: "20px"}}></div>

                                    {(qType !== portalShared.DATE && qType !== portalShared.DATE_RANGE) ?
                                        <div>
                                            <ArgoTextFieldHook
                                                fileName="ArgoQuestionFormatCustom"
                                                fieldName="placeHolder"
                                                label="Place Holder"
                                                value={this.state.formatObj.placeHolder.text}
                                                onChange={event => {

                                                    let value = event.target.value;

                                                    let tempObj = {...this.state.formatObj};  // Get the state object associated with the question type

                                                    tempObj.placeHolder.text = value; // set the changed value

                                                    this.setState({formatObj: tempObj}, () => {
                                                        this.setMetadataValue(tempObj)
                                                    });
                                                }}
                                                width="230px"
                                            />
                                        </div> : ""
                                    }

                                    </div>

                                    <div style={{display: "flex", alignItems: "left", width: "100%"}}>

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="labelPosition"
                                    label="Label Position"
                                    value={this.state.formatObj.labelPosition}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={labelPositionList}
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="label"
                                    label={qType === portalShared.DATE_RANGE ? "Label From" : "Label"}
                                    value={this.state.formatObj.label}
                                    onChange={this.onTextChange}
                                    width="200px"
                                    disabled={this.state.formatObj.labelPosition === "none"}
                                />

                                {qType === portalShared.DATE_RANGE ?
                                    <div style={{display: "flex", alignItems: "left", width: "100%"}}>
                                        <div style={{width: "20px"}}></div>

                                        <ArgoTextFieldHook
                                            fileName="ArgoQuestionFormatCustom"
                                            fieldName="label2"
                                            label="Label To"
                                            value={this.state.formatObj.label2}
                                            onChange={this.onTextChange}
                                            width="200px"
                                            disabled={this.state.formatObj.labelPosition === "none"}
                                        />
                                    </div>: ""
                                }

                                {(this.state.formatObj.labelPosition !== "none") ?
                                    <ArgoSelectPortalTextDisplayType
                                        templateType={TEMPLATE.TYPE.QUESTION}
                                        textEntryType={TEMPLATE.TEXT_TYPE.QUESTION.LABEL}
                                        fieldName={this.props.labelFieldName}
                                        labelText={"Label Display"}
                                        hideOption={false}
                                        initTo={this.state.formatObj.labelDisplay}
                                        // metadataTag={this.props.labelMetadataTag}
                                        fieldTextObjName="labelDisplay"
                                        metadataPropsObj={this.props.metadataPropsObj}
                                        setCustomMetadata={this.setLabelMetadataValue.bind(this)}
                                        questionDepth={this.props.questionDepth}
                                        setStateFormatObj={this.setStateFormatObj}
                                    />
                                    :
                                    <div></div>
                                }

                            </div>

                        <div style={{display: "flex", alignItems: "left", width: "100%"}}>

                            {((this.state.formatObj.labelDisplay === 'custom') && (this.state.formatObj.labelPosition !== "none")) ?
                                <ArgoTextDisplayCustom
                                    fieldName="labelDisplayDefinition"
                                    fieldTextObjName={this.props.fieldTextObjName}
                                    showDisplay={false}
                                    initToObj={customLabelTextObj}
                                    metadataTag={portalShared.CUSTOM_DDO_QUE_LABEL}
                                    metadataPropsObj={this.props.metadataPropsObj}
                                    setCustomMetadata={this.setLabelMetadataValue.bind(this)}/>
                                :
                                <div></div>
                            }
                        </div>

                            <div style={{display: "flex", alignItems: "left", width: "100%"}}>

                                <div style={{width: "20px"}}></div>

                                {this.displayTextEntry()}

                            </div>

                            <div style={{display: "flex", alignItems: "left", width: "100%"}}>

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="color"
                                    label="Input Color"
                                    value={this.state.formatObj.color}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={defaultOrCustomList}
                                />

                                <div style={{width: "20px"}}></div>

                                {qType === portalShared.NUMBER ?
                                    <React.Fragment>
                                        <ArgoCheckboxHook
                                            fileName="thousandSeparator"
                                            fieldName="thousandSeparator"
                                            label={"Separate Thousands"}
                                            onChange={this.onCheckBoxChange}
                                            checked={this.state.formatObj.thousandSeparator}
                                            paddingTop="25px"
                                            width={"200px"}
                                        />

                                        <ArgoCheckboxHook
                                            fileName="allowNegatives"
                                            fieldName="isNegative"
                                            label={"Allow negatives"}
                                            onChange={this.onCheckBoxChange}
                                            checked={this.state.formatObj.isNegative}
                                            paddingTop="25px"
                                            width={"165px"}
                                        />

                                        <ArgoCheckboxHook
                                            fileName="allowPercentage"
                                            fieldName="isPercentage"
                                            label={"Is Percentage"}
                                            onChange={this.onCheckBoxChange}
                                            checked={this.state.formatObj.isPercentage}
                                            paddingTop="25px"
                                            width={"150px"}
                                        />
                                    </React.Fragment> : ""
                                }

                                {qType === portalShared.NUMBER || qType === portalShared.CURRENCY ?
                                    <React.Fragment>
                                        <ArgoCheckboxHook
                                            fileName="allowDecimal"
                                            fieldName="isDecimal"
                                            label={"Is Decimal"}
                                            onChange={this.onCheckBoxChange}
                                            checked={this.state.formatObj.isDecimal}
                                            paddingTop="25px"
                                            width={"130px"}
                                        />

                                        {qType === portalShared.NUMBER && this.state.formatObj.isDecimal ?
                                            <React.Fragment>
                                                <ArgoTextFieldSelectHook
                                                    fileName="decimalScale"
                                                    fieldName="decimalScale"
                                                    label="Decimal Places"
                                                    value={this.state.formatObj.decimalScale}
                                                    onChange={this.onSelectChange}
                                                    width="170px"
                                                    menuItems={decimalPlacesList}
                                                />
                                            </React.Fragment> : ""
                                        }
                                    </React.Fragment>
                                    : ""
                                }

                                {qType === portalShared.ZIPPOSTAL_ONLY ?
                                    <React.Fragment>
                                        <ArgoTextFieldSelectHook
                                            fileName="zipFormat"
                                            fieldName="zipFormat"
                                            label="Zip Format"
                                            value={this.state.formatObj.zipFormat}
                                            onChange={this.onSelectChange}
                                            width="170px"
                                            menuItems={zipFormatList}
                                        />
                                    </React.Fragment> : ""
                                }

                            </div>

                            {((this.props.labelInitTo === 'custom') && (this.state.formatObj.labelPosition !== "none")) ?
                                <ArgoTextDisplayCustom
                                    fieldName="labelDisplayDefinition"
                                    fieldTextObjName={this.props.fieldTextObjName}
                                    initToObj={this.props.labelInitToObj}
                                    metadataTag={portalShared.CUSTOM_DDO_QUE_LABEL}
                                    metadataPropsObj={this.props.metadataPropsObj}
                                    setCustomMetadata={this.setLabelMetadataValue.bind(this)}/>
                                :
                                <div></div>
                            }

                            {(this.state.formatObj.color === "custom") ?
                                <React.Fragment>
                                    {customColorsArray.map((title, index) => (
                                        <div style={{
                                            display: "flex",
                                            alignItems: "left",
                                            width: "100%",
                                            backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX
                                        }}>

                                            <div style={{width: "40px"}}></div>

                                            <ArgoColorInput mode="questionSingleSelect"
                                                            colorLabel={title}
                                                            fieldName={customColorPropsArray[index]}
                                                            index={this.props.index}
                                                            initToObj={this.state.formatObj[customColorPropsArray[index]]}
                                                            setColor={this.setColor.bind(this)}
                                                            opacityFlag={true}
                                                            // opacityFlag={customColorPropsArray[index] === "placeHolder"}
                                            />
                                        </div>))
                                    }
                                </React.Fragment>
                                : ""
                            }
                        </div>
                    </React.Fragment>
                );

            case portalShared.ADDRESS_FULL:
                customColorsArray = customColorTitlesFreeText;
                customColorPropsArray = customColorPropsFreeText;
                switch (this.state.formatObj.style) {
                    case "filled":
                        customColorsArray = [...customColorTitlesFreeText,
                            "Selected Background",
                            "Hover Background",
                            "Unselected Background"
                        ]
                        customColorPropsArray = [...customColorPropsFreeText,
                            "selectedBackground",
                            "hoverBackground",
                            "unselectedBackground",]
                        break;
                }
                return (
                    <React.Fragment>
                        <div style={{
                            display: "flex",
                            alignItems: "left",
                            width: "100%",
                            backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX,
                            paddingTop: "20px"
                        }}>
                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="style"
                                label="Style"
                                value={this.state.formatObj.style}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={styleList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="size"
                                label="Size"
                                value={this.state.formatObj.size}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={sizeList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="labelPosition"
                                label="Label Position"
                                value={this.state.formatObj.labelPosition}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={labelPositionList}
                            />
                        </div>

                        <div>{this.getAddressRow("street1Obj")}</div>
                        <div>{this.getAddressRow("street2Obj")}</div>
                        <div>{this.getAddressRow("cityObj")}</div>
                        <div>{this.getAddressRow("stateObj")}</div>
                        <div>{this.getAddressRow("zipObj")}</div>

                        {(this.state.formatObj.labelPosition !== "none") ?
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX}}>
                                <div style={{width: "5px"}}></div>

                                <ArgoSelectPortalTextDisplayType
                                    templateType={TEMPLATE.TYPE.QUESTION}
                                    textEntryType={TEMPLATE.TEXT_TYPE.QUESTION.LABEL}
                                    fieldName={this.props.labelFieldName}
                                    labelText={"Label Display"}
                                    hideOption={false}
                                    initTo={this.state.formatObj.labelDisplay}
                                    // metadataTag={this.props.labelMetadataTag}
                                    fieldTextObjName="labelDisplay"
                                    metadataPropsObj={this.props.metadataPropsObj}
                                    setCustomMetadata={this.setLabelMetadataValue.bind(this)}
                                    questionDepth={this.props.questionDepth}
                                    setStateFormatObj={this.setStateFormatObj}
                                    width="150px"
                                />

                                <div style={{width: "3px"}}></div>

                                <div>
                                    {((this.state.formatObj.labelDisplay === 'custom') && (this.state.formatObj.labelPosition !== "none")) ?
                                        <ArgoTextDisplayCustom
                                            fieldName="labelDisplayDefinition"
                                            fieldTextObjName={this.props.fieldTextObjName}
                                            showDisplay={false}
                                            initToObj={customLabelTextObj}
                                            metadataTag={portalShared.CUSTOM_DDO_QUE_LABEL}
                                            metadataPropsObj={this.props.metadataPropsObj}
                                            setCustomMetadata={this.setLabelMetadataValue.bind(this)}
                                            paddingTop="0px"
                                        />
                                        :
                                        <div></div>
                                    }
                                </div>
                            </div>
                            :
                            <div></div>
                        }


                        <div style={{display: "flex", alignItems: "left", width: "100%", backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX}}>
                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="color"
                                label="Format"
                                value={this.state.formatObj.color}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={defaultOrCustomList}
                            />
                        </div>

                        {(this.state.formatObj.color === "custom") ?
                            <React.Fragment>
                                {customColorsArray.map((title, index) => (
                                    <div style={{
                                        display: "flex",
                                        alignItems: "left",
                                        width: "100%",
                                        backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX
                                    }}>
                                        <div style={{width: "40px"}}></div>
                                        <ArgoColorInput mode="questionSingleSelect"
                                                        colorLabel={title}
                                                        fieldName={customColorPropsArray[index]}
                                                        index={this.props.index}
                                                        initToObj={this.state.formatObj[customColorPropsArray[index]]}
                                                        setColor={this.setColor.bind(this)}
                                                        opacityFlag={customColorPropsArray[index] === "placeHolder"}
                                        />
                                    </div>))
                                }
                            </React.Fragment>
                            : ""
                        }

                        {/*<div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX}}>*/}

                        {/*        {(this.state.formatObj.labelPosition !== "none" || this.state.formatObj.labelPosition2 !== "none" || this.state.formatObj.labelPosition3 !== "none" || this.state.formatObj.labelPosition4 !== "none" || this.state.formatObj.labelPosition5 !== "none") ?*/}
                        {/*            <ArgoSelectPortalTextDisplayType*/}
                        {/*                templateType={TEMPLATE.TYPE.QUESTION}*/}
                        {/*                textEntryType={TEMPLATE.TEXT_TYPE.QUESTION.LABEL}*/}
                        {/*                fieldName={this.props.labelFieldName}*/}
                        {/*                labelText={"Labels Display"}*/}
                        {/*                hideOption={false}*/}
                        {/*                initTo={this.props.labelInitTo}*/}
                        {/*                metadataTag={this.props.labelMetadataTag}*/}
                        {/*                metadataPropsObj={this.props.metadataPropsObj}*/}
                        {/*                setCustomMetadata={this.setLabelMetadataValue.bind(this)}*/}
                        {/*            />*/}
                        {/*            :*/}
                        {/*            <div></div>*/}
                        {/*        }*/}

                        {/*    </div>*/}

                        {/*    {((this.props.labelInitTo === 'custom') && (this.state.formatObj.labelPosition !== "none" || this.state.formatObj.labelPosition2 !== "none" || this.state.formatObj.labelPosition3 !== "none" || this.state.formatObj.labelPosition4 !== "none" || this.state.formatObj.labelPosition5 !== "none")) ?*/}
                        {/*        <ArgoTextDisplayCustom*/}
                        {/*            fieldName="labelDisplayDefinition"*/}
                        {/*            fieldTextObjName={this.props.fieldTextObjName}*/}
                        {/*            initToObj={this.props.labelInitToObj}*/}
                        {/*            metadataTag={portalShared.CUSTOM_DDO_LABEL}*/}
                        {/*            metadataPropsObj={this.props.metadataPropsObj}*/}
                        {/*            setCustomMetadata={this.setLabelMetadataValue.bind(this)}/>*/}
                        {/*        :*/}
                        {/*        <div></div>*/}
                        {/*    }*/}
                    </React.Fragment>
                );


            case portalShared.STATE_ONLY:

                return (
                    <div>
                        State Only
                    </div>
                );

            case portalShared.CHECKBOX:
                customColorsArray = customColorRadiosAndCheckboxesTitles;
                customColorPropsArray = customColorRadiosAndCheckboxesProps;

                return (
                    <React.Fragment>

                        <div style={{
                            display: "flex",
                            alignItems: "left",
                            width: "100%",
                            backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX
                        }}>

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="style"
                                label="Style"
                                value={this.state.formatObj.style}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={[{value: "checkboxes", text: "Checkboxes"}]}
                            />

                            {/*, {value: "outlined", text: "Outlined Checkboxes"} ZZZZZ NO LONGER WORKS IN MUI 5*/}

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="arrange"
                                label="Arrange"
                                value={this.state.formatObj.arrange}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={arrangeList}
                            />

                            <div style={{width: "20px"}}></div>

                            {this.displayTextEntry()}

                            <div style={{display: "flex", alignItems: "left", width: "100%", backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX}}>
                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="color"
                                    label="Color"
                                    value={this.state.formatObj.color}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={defaultOrCustomList}
                                />
                            </div>
                        </div>

                        {(this.state.formatObj.color === "custom") ?
                            <React.Fragment>
                                {customColorsArray.map((title, index) => (
                                    <div style={{
                                        display: "flex",
                                        alignItems: "left",
                                        width: "100%",
                                        backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX
                                    }}>
                                        <div style={{width: "40px"}}></div>
                                        <ArgoColorInput mode="questionSingleSelect"
                                                        colorLabel={title}
                                                        fieldName={customColorPropsArray[index]}
                                                        index={this.props.index}
                                                        initToObj={this.state.formatObj[customColorPropsArray[index]]}
                                                        setColor={this.setColor.bind(this)}
                                                        opacityFlag={customColorPropsArray[index] === "placeHolder"}
                                        />
                                    </div>))
                                }
                            </React.Fragment>
                            : ""
                        }

                    </React.Fragment>
                );

            case portalShared.RADIO:
            case portalShared.YES_NO:

                customColorsArray = [];
                customColorPropsArray = [];

                switch (this.state.formatObj.style) {
                    case "radios":
                        customColorsArray = customColorRadiosAndCheckboxesTitles;
                        customColorPropsArray = customColorRadiosAndCheckboxesProps;
                        break;
                    case "outlined":
                        customColorsArray = customColorTitlesOutlined;
                        customColorPropsArray = customColorPropsOutlined;
                        break;
                    case "ddlb":
                        customColorsArray = customColorTitlesDDLB;
                        customColorPropsArray = customColorPropsDDLB;
                        break;
                    case "standard":
                    case "clear":
                        customColorsArray = customColorTitlesNoControl;
                        customColorPropsArray = customColorPropsNoControl;
                        break;
                    default:
                        break;
                }
                // let iconType = "";
                //
                // if (this.props.answers.length !== 0) {
                //     if (this.props.answers[0].metadata.hasOwnProperty(icon.ICON_TYPE)) {
                //
                //         iconType = this.props.answers[0].metadata[icon.ICON_TYPE];
                //     }
                // }

                return (
                    <React.Fragment>

                        <div style={{ alignItems: "left", width: "100%", backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX, paddingTop: "20px"}}>
                            <div style={{display: "flex"}}>
                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="displayAs"
                                    label="Display As"
                                    value={this.state.formatObj.displayAs}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={[{value: "single", text: "Single Select"}, {
                                        value: "likert",
                                        text: "Likert"
                                    }]}
                                    disabled={this.props.answerCount > 0}
                                />

                                <div style={{width: "20px"}}></div>

                                {this.state.formatObj.displayAs === "single" ?

                                    <ArgoTextFieldSelectHook
                                        fileName="ArgoQuestionFormatCustom"
                                        fieldName="style"
                                        label="Style"
                                        value={this.state.formatObj.style}
                                        onChange={this.onSelectChange}
                                        width="160px"
                                        menuItems={
                                            [
                                                {value: "radios", text: "Radios"},
                                                {value: "outlined", text: "Outlined Radios"},
                                                {value: "ddlb", text: "DDLB"},
                                                {value: "standard", text: "Standard Buttons"},
                                                {value: "clear", text: "Clear Buttons"}
                                            ]
                                        }
                                    />
                                    : ""
                                }
                                <div style={{width: "20px"}}></div>

                                {this.state.formatObj.displayAs === "single" ?
                                    (this.state.formatObj.style !== "ddlb" ?
                                        (<React.Fragment>
                                            <ArgoTextFieldSelectHook
                                                fileName="ArgoQuestionFormatCustom"
                                                fieldName="arrange"
                                                label="Arrange"
                                                value={this.state.formatObj.arrange}
                                                onChange={this.onSelectChange}
                                                width="150px"
                                                menuItems={arrangeList}
                                            />
                                        </React.Fragment>)
                                        :
                                            (<React.Fragment>
                                                <ArgoTextFieldSelectHook
                                                    fileName="ArgoQuestionFormatCustom"
                                                    fieldName="widthType"
                                                    label="Width Format"
                                                    value={this.state.formatObj.widthType}
                                                    onChange={this.onSelectChange}
                                                    width="150px"
                                                    menuItems={widthFormatList}
                                                />

                                                <div style={{width: "20px"}}></div>

                                                <ArgoTextFieldHook
                                                    fileName="ArgoQuestionFormatCustom"
                                                    fieldName="widthEntry"
                                                    label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                                                    value={this.state.formatObj.widthEntry}
                                                    type="number"
                                                    onChange={this.onTextChange}
                                                    width="150px"
                                                />

                                                <div style={{width: "20px"}}></div>

                                                <ArgoTextFieldHook
                                                    fileName="ArgoQuestionFormatCustom"
                                                    fieldName="floatingLabel"
                                                    label="Floating Label"
                                                    value={this.state.formatObj.floatingLabel?.text}
                                                    onChange={event => {

                                                        let value = event.target.value;

                                                        let tempObj = {...this.state.formatObj};  // Get the state object associated with the question type

                                                        tempObj.floatingLabel.text = value; // set the changed value

                                                        this.setState({formatObj: tempObj}, () => {
                                                            this.setMetadataValue(tempObj)
                                                        });
                                                    }}
                                                    width="230px"
                                                />
                                            </React.Fragment>)
                                    ) : ""
                                }
                            </div>

                            {this.state.formatObj.displayAs === "single" ?
                                (this.state.formatObj.style !== "ddlb" ?
                                    (<React.Fragment>
                                        <div style={{display: "flex"}}>
                                            <div style={{width: "20px"}}></div>

                                            {this.displayTextEntry()}
                                        </div>

                                        <div style={{display: "flex"}}>
                                            <div style={{width: "20px"}}></div>

                                            <ArgoTextFieldSelectHook
                                                fileName="ArgoQuestionFormatCustom"
                                                fieldName="color"
                                                label="Color"
                                                value={this.state.formatObj.color}
                                                onChange={this.onSelectChange}
                                                width="150px"
                                                menuItems={defaultOrCustomList}
                                            />
                                        </div>
                                    </React.Fragment>)
                                :
                                        (<React.Fragment>
                                            <div style={{display: "flex"}}>

                                                <div style={{width: "20px"}}></div>

                                                <ArgoTextFieldSelectHook
                                                    fileName="ArgoQuestionFormatCustom"
                                                    fieldName="color"
                                                    label="Color"
                                                    value={this.state.formatObj.color}
                                                    onChange={this.onSelectChange}
                                                    width="150px"
                                                    menuItems={defaultOrCustomList}
                                                />
                                            </div>
                                        </React.Fragment>)
                                ) : ""
                            }


                            {(this.state.formatObj.displayAs === "likert") ?
                                <ArgoLikertAnswerGenerator
                                    fileName="ArgoQuestionFormatCustom"
                                    componentMode="likert-titles"
                                    templateType={TEMPLATE.TYPE.QUESTION}
                                    template={this.props.metadataPropsObj.template}
                                    templateUpdateFunction={this.props.metadataPropsObj.templateUpdateFunction}
                                    isLikert={true}
                                    answerOptionTemplates={this.props.metadataPropsObj.answerOptionTemplates}
                                    answerErrorCallback={function () {}}
                                    questionDepth={this.props.questionDepth}
                                    setMetadataErrorText={this.setMetadataErrorText}
                                    customMetadataKey={portalShared.CUSTOM_DDO_QUE_FORMAT}
                                />
                                :
                                <div></div>
                            }
                        </div>

                        {(this.state.formatObj.color === "custom") ?
                            <React.Fragment>
                                {customColorsArray.map((title, index) => (
                                    <div style={{display: "flex", alignItems: "left", width: "100%", backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX}}>
                                        <div style={{width: "40px"}}></div>
                                        <ArgoColorInput mode="questionSingleSelect"
                                                        colorLabel={title}
                                                        fieldName={customColorPropsArray[index]}
                                                        index={this.props.index}
                                                        initToObj={this.state.formatObj[customColorPropsArray[index]]}
                                                        setColor={this.setColor.bind(this)}
                                        />
                                    </div>))
                                }
                            </React.Fragment>
                            : ""
                        }
                    </React.Fragment>
                );


            case portalShared.LIKERT_RADIO:
                return (
                    <React.Fragment>
                        <div style={{display: "flex", alignItems: "left", width: "100%", backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX}}>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="indent"
                                label="Indent Question Text"
                                value={this.state.formatObj.indent}
                                onChange={this.onTextChange}
                                width="150px"
                            />

                        </div>
                    </React.Fragment>
                );

            // no default
        }
    };

    getAddressRow = (objName) => {
        return (
            <div style={{display: "flex", alignItems: "left", width: "100%", backgroundColor: portalShared.COLOR_LIGHT_GRAY_HEX}}>
                <div style={{width: "20px"}}></div>

                <ArgoTextFieldSelectHook
                    fileName="ArgoQuestionFormatCustom"
                    fieldName={objName + ".widthType"}
                    label="Width Format"
                    value={this.state.formatObj[objName].widthType}
                    onChange={this.onSelectChange}
                    width="150px"
                    menuItems={widthFormatList}
                />

                <div style={{width: "20px"}}></div>

                <ArgoTextFieldHook
                    fileName="ArgoQuestionFormatCustom"
                    fieldName={objName + ".widthEntry"}
                    label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                    value={this.state.formatObj[objName].widthEntry}
                    type="number"
                    onChange={this.onTextChange}
                    width="150px"
                />

                <div style={{width: "20px"}}></div>

                {objName !== "stateObj" ?
                    <ArgoTextFieldHook
                        fileName="ArgoQuestionFormatCustom"
                        fieldName={objName + ".max"}
                        label="Max Characters"
                        value={this.state.formatObj[objName].max}
                        type="number"
                        onChange={this.onTextChange}
                        width="150px"
                    /> : <div style={{width: "150px"}}></div>
                }

                <div style={{width: "20px"}}></div>

                <ArgoTextFieldHook
                    fileName="ArgoQuestionFormatCustom"
                    fieldName={objName + ".floatingLabel"}
                    label="Floating Label"
                    value={this.state.formatObj[objName].floatingLabel}
                    onChange={this.onTextChange}
                    width="250px"
                />

                <div style={{width: "20px"}}></div>
                {objName !== "stateObj" ?
                    <ArgoTextFieldHook
                        fileName="ArgoQuestionFormatCustom"
                        fieldName={objName + ".placeHolder"}
                        label="Place Holder"
                        value={this.state.formatObj[objName].placeHolder}
                        onChange={this.onTextChange}
                        width="250px"
                    /> : <div style={{width: "250px"}}></div>
                }

                <div style={{width: "20px"}}></div>

                {this.state.formatObj.labelPosition !== "none" ?
                    <ArgoTextFieldHook
                        fileName="ArgoQuestionFormatCustom"
                        fieldName={objName + ".label"}
                        label="Label"
                        value={this.state.formatObj[objName].label}
                        onChange={this.onTextChange}
                        width="200px"
                        disabled={this.state.formatObj[objName].labelPosition === "none"}
                    /> : ""
                }
            </div>
        );
    }


    render() {

        return (
            <React.Fragment>
                {this.formatCustomOptions()}
            </React.Fragment>
        );
    }
}

ArgoQuestionFormatCustom.defaultProps = {
    answerCount: 0,
    index: 0,
};

ArgoQuestionFormatCustom.propTypes = {
    questionType: PropTypes.string,
    fieldName: PropTypes.string,
    fieldTextObjName: PropTypes.string,
    index: PropTypes.number,
    initToObj: PropTypes.object,
    metadataTag: PropTypes.string,
    answerCount: PropTypes.number,
    setCustomMetadata: PropTypes.func,
    labelFieldName: PropTypes.string,
    labelInitTo: PropTypes.string,
    labelInitToObj: PropTypes.object,
    labelMetadataTag: PropTypes.string,
    answers: PropTypes.array,
    addAnswerFunction: PropTypes.func,
    metadataPropsObj: PropTypes.object
};

export default ArgoQuestionFormatCustom;


import * as types from "../constants/actionConstants";
import {initialPageSortFilterObject,
    selectPageSortFilterObject,
    selectDdlbSortFilterObject,
    initialIntegrationLogsPageSortFilterObject}
from "../constants/filterConstants";
import * as TEMPLATE from "../constants/templateConstants";


const initialState = {
    selectExistingCategoryFilter: {
        templateType: TEMPLATE.TYPE.SELECT_EXISTING_CATEGORY,
        templateMode: TEMPLATE.MODE.CURRENT,
        ...selectPageSortFilterObject
    },
    selectExistingGroupFilter: {
        templateType: TEMPLATE.TYPE.SELECT_EXISTING_GROUP,
        templateMode: TEMPLATE.MODE.CURRENT,
        ...selectPageSortFilterObject
    },
    selectExistingQuestionFilter: {
        templateType: TEMPLATE.TYPE.SELECT_EXISTING_QUESTION,
        templateMode: TEMPLATE.MODE.CURRENT,
        ...selectPageSortFilterObject
    },
    selectExistingTagsFilter: {
        templateType: TEMPLATE.TYPE.SELECT_EXISTING_TAGS,
        templateMode: TEMPLATE.MODE.ALL,
        ...selectDdlbSortFilterObject
    },
    selectfilter: {
        templateType: TEMPLATE.TYPE.SELECT,
        templateMode: TEMPLATE.MODE.ALL,
        ...selectPageSortFilterObject
    },
    questionnaires: {
        templateType: TEMPLATE.TYPE.QUESTIONNAIRE,
        templateMode: TEMPLATE.MODE.CURRENT,
        ...initialPageSortFilterObject
    },
    defaultfilter: {
        templateType: TEMPLATE.TYPE.DEFAULT,
        templateMode: TEMPLATE.MODE.ALL,
        ...initialPageSortFilterObject
    },
    questionnaireVersions: {
        templateType: TEMPLATE.TYPE.QUESTIONNAIRE,
        templateMode: TEMPLATE.MODE.VERSIONS,
        ...initialPageSortFilterObject
    },
    categories: {
        templateType: TEMPLATE.TYPE.CATEGORY,
        templateMode: TEMPLATE.MODE.CURRENT,
        ...initialPageSortFilterObject
    },
    categoryVersions: {
        templateType: TEMPLATE.TYPE.CATEGORY,
        templateMode: TEMPLATE.MODE.VERSIONS,
        ...initialPageSortFilterObject
    },
    groups: {
        templateType: TEMPLATE.TYPE.GROUP,
        templateMode: TEMPLATE.MODE.CURRENT,
        ...initialPageSortFilterObject
    },
    groupVersions: {
        templateType: TEMPLATE.TYPE.GROUP,
        templateMode: TEMPLATE.MODE.VERSIONS,
        ...initialPageSortFilterObject
    },
    questions: {
        templateType: TEMPLATE.TYPE.QUESTION,
        templateMode: TEMPLATE.MODE.CURRENT,
        ...initialPageSortFilterObject
    },
    questionVersions: {
        templateType: TEMPLATE.TYPE.QUESTION,
        templateMode: TEMPLATE.MODE.VERSIONS,
        ...initialPageSortFilterObject
    },
    actions: {
        templateType: TEMPLATE.TYPE.ACTION,
        templateMode: TEMPLATE.MODE.CURRENT,
        ...initialPageSortFilterObject
    },
    actionVersions: {
        templateType: TEMPLATE.TYPE.ACTION,
        templateMode: TEMPLATE.MODE.VERSIONS,
        ...initialPageSortFilterObject
    },
    integrations: {
        templateType: TEMPLATE.TYPE.INTEGRATION,
        templateMode: TEMPLATE.MODE.ALL,
        ...initialPageSortFilterObject
    },
    integrationLogs: {
        templateType: TEMPLATE.TYPE.INTEGRATION_LOGS,
        templateMode: TEMPLATE.MODE.ALL,
        ...initialIntegrationLogsPageSortFilterObject
    },
    tags: {
        templateType: TEMPLATE.TYPE.TAG,
        templateMode: TEMPLATE.MODE.ALL,
        ...initialPageSortFilterObject
    },
};

function filterReducer(state = initialState, action) {
    let filter;

    switch(action.type) {
        case types.CLEAR_FILTER:
            filter = {...state};
            filter[action.filterName] = {
                ...state[action.filterName],
                ...action.filter
            };
            return filter;

        case types.SET_FILTER:
        case types.SORT_CHANGE:
        case types.PAGE_CHANGE:
        case types.UPDATE_PAGE_SORT_AND_FILTER:

            filter = {...state};

            // Save these fields currently from the store
            let templateKey = filter[action.filterName].templateKey;
            let templateName = filter[action.filterName].templateName;
            let templateNameQualifier = filter[action.filterName].templateNameQualifier;
            let defaultActions = filter[action.filterName].defaultActions;

            filter[action.filterName] = {
                ...state[action.filterName],
                ...action.filter
            };

            // If templateKey is blank keep these fields
            if (filter[action.filterName].templateKey === "") {
                filter[action.filterName].templateKey = templateKey;
                filter[action.filterName].templateName = templateName;
                filter[action.filterName].templateNameQualifier = templateNameQualifier;
                filter[action.filterName].defaultActions = defaultActions;
            }

            return filter;


        case types.PAGE_CHANGE_SIZE_ALL_LISTS:

            filter = {...state};

            filter["questionnaires"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["questionnaireVersions"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["categories"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["categoryVersions"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["groups"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["groupVersions"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["questions"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["questionVersions"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["actions"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["actionVersions"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["integrations"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["integrationLogs"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};
            filter["tags"] = {...filter["questionnaires"], pageSize: action.filter.pageSize};

            return filter;

        case types.QUESTIONNAIRE_TEMPLATE_LIST_GET_SUCCESS:
        case types.QUESTIONNAIRE_TEMPLATE_LIST_CURRENT_GET_SUCCESS:
        case types.CATEGORY_TEMPLATE_LIST_GET_SUCCESS:
        case types.CATEGORY_TEMPLATE_LIST_CURRENT_GET_SUCCESS:
        case types.GROUP_TEMPLATE_LIST_GET_SUCCESS:
        case types.GROUP_TEMPLATE_LIST_CURRENT_GET_SUCCESS:
        case types.QUESTION_TEMPLATE_LIST_GET_SUCCESS:
        case types.QUESTION_TEMPLATE_LIST_CURRENT_GET_SUCCESS:
        case types.ACTION_TEMPLATE_LIST_GET_SUCCESS:
        case types.ACTION_TEMPLATE_LIST_CURRENT_GET_SUCCESS:
        case types.INTEGRATION_LIST_GET_SUCCESS:
        case types.TAG_LIST_GET_SUCCESS:
            filter = {...state};
            filter[action.filterName] = {
                ...state[action.filterName],
                totalRecords: action.totalRecords
            };
            return filter;

        case types.TAG_DDLB_GET_SUCCESS:
            filter = {...state};
            filter[action.filterName] = {
                ...state[action.filterName],
                list: action.list,
                totalRecords: action.totalRecords
            };
            return filter;

        case types.QUESTIONNAIRE_TEMPLATE_LIST_VERSIONS_GET_SUCCESS:
        case types.CATEGORY_TEMPLATE_LIST_VERSIONS_GET_SUCCESS:
        case types.GROUP_TEMPLATE_LIST_VERSIONS_GET_SUCCESS:
        case types.QUESTION_TEMPLATE_LIST_VERSIONS_GET_SUCCESS:
        case types.ACTION_TEMPLATE_LIST_VERSIONS_GET_SUCCESS:
            filter = {...state};
            filter[action.filterName] = {
                ...state[action.filterName],
                totalRecords: action.totalRecords,
                templateKey: action.templateKey,
                templateName: action.templateName,
                templateNameQualifier: action.templateNameQualifier,
                defaultActions: action.defaultActions
            };
            return filter;

        case types.GET_SELECT_EXISTING_DDLB_LIST:
            filter = {...state};
            filter[action.filterName] = {
                ...state[action.filterName],
                totalRecords: action.totalRecords,
                mode: action.mode,
                list: action.list
            };
            return filter;


        case types.CLEAR_SELECT_EXISTING_DDLB_LIST:
            filter = {...state};
            filter[action.filterName] = {
                ...state[action.filterName],
                mode: action.mode,
                list: []
            };
            return filter;


        case types.INTEGRATION_LOGS_LIST_GET_SUCCESS:
            filter = {...state};
            filter["integrationLogs"] = {
                ...state["integrationLogs"],
                totalRecords: action.totalRecords
            };
            return filter;

        default:
            return state;
    }
}

export default filterReducer;

import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {connect} from "react-redux";
import App from './App';
import QuestionnaireImport from './components/qd/questionnaires/QuestionnaireImport';
import IntegrationList from './components/templateList/IntegrationList';
import IntegrationLogsList from './components/templateList/IntegrationLogsList';
import QuestionnaireList from './components/templateList/QuestionnaireList';
import CategoryListMuiExample from './components/templateList/CategoryList';
import GroupList from './components/templateList/GroupList';
import QuestionList from './components/templateList/QuestionList';
import ActionList from './components/templateList/ActionList';
import TemplateVersionsList from './components/templateList/TemplateVersionsList';
import QuestionnaireContainer from './components/qd/questionnaires/QuestionnaireContainer';
import CategoryContainer from './components/qd/categories/CategoryContainer';
import GroupContainer from './components/qd/groups/GroupContainer';
import QuestionContainer from './components/qd/questions/QuestionContainer';
import ActionContainer from './components/qd/actions/ActionContainer';
import TagList from './components/templateList/TagList';
import NoPage from './components/common/NoPage';
import MainDrawer from "./components/drawers/MainDrawer";
import ArgoAppSnackbar from "./components/common/ArgoAppSnackbar";
import PortalContainer from './portal/PortalContainer';
import {QD_URL, REACT_APP_PORTAL_MODE, QD_PORTAL} from "./constants/systemConstants";
import ChangeAppTitleToPortalHook from "./portal/utilities/ChangeAppTitleToPortalHook"
import * as TEMPLATE from "./constants/templateConstants";


class Router extends React.Component  {

    render() {
        let location = window.location; // Get URL

        return (
            <div>
                {REACT_APP_PORTAL_MODE === "true" ?
                    <>
                        <Routes>
                            <Route path={"/"} element={<App location={location} portalMode={REACT_APP_PORTAL_MODE}/>}>
                                <Route index element={<PortalContainer/>}/>
                                <Route path={QD_PORTAL} element={<PortalContainer/>}/>
                                <Route path={QD_PORTAL + "/:key"} element={<PortalContainer/>}/>
                                <Route path="*" element={<NoPage/>}/>
                            </Route>
                        </Routes>
                    </>
                    :
                    <div>
                        <Routes>

                            <Route path={"/"} element={<App location={location}/>}>
                                <Route index element={<QuestionnaireList/>}/>
                                <Route path={QD_URL + "/questionnaires"} element={<QuestionnaireList/>}/>
                                <Route path={QD_URL + "/questionnaire-editor"} element={<QuestionnaireContainer/>}/>
                                <Route path={QD_URL + "/questionnaire-editor/:templateKey/:templateId/:mode"} element={<QuestionnaireContainer/>}/>
                                <Route path={QD_URL + "/categories"} element={<CategoryListMuiExample/>}/>
                                <Route path={QD_URL + "/category-editor/:templateKey"} element={<CategoryContainer/>}/>
                                <Route path={QD_URL + "/groups"} element={<GroupList/>}/>
                                <Route path={QD_URL + "/group-editor/:templateKey"} element={<GroupContainer/>}/>
                                <Route path={QD_URL + "/questions"} element={<QuestionList/>}/>
                                <Route path={QD_URL + "/question-editor/:templateKey"} element={<QuestionContainer/>}/>
                                <Route path={QD_URL + "/actions"} element={<ActionList/>}/>
                                <Route path={QD_URL + "/action-editor/:templateKey"} element={<ActionContainer/>}/>
                                <Route path={QD_URL + "/import"} element={<QuestionnaireImport/>}/>
                                <Route path={QD_URL + "/integrations"} element={<IntegrationList/>}/>
                                <Route path={QD_URL + "/integration-logs/:id"} element={<IntegrationLogsList/>}/>
                                <Route path={QD_URL + "/questionnaire-versions/:templateKey"}
                                       element={<TemplateVersionsList templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                                                                      templateMode={TEMPLATE.MODE.VERSIONS}/>}/>
                                <Route path={QD_URL + "/category-versions/:templateKey"}
                                       element={<TemplateVersionsList templateType={TEMPLATE.TYPE.CATEGORY}
                                                                      templateMode={TEMPLATE.MODE.VERSIONS}/>}/>
                                <Route path={QD_URL + "/group-versions/:templateKey"}
                                       element={<TemplateVersionsList templateType={TEMPLATE.TYPE.GROUP}
                                                                      templateMode={TEMPLATE.MODE.VERSIONS}/>}/>
                                <Route path={QD_URL + "/question-versions/:templateKey"}
                                       element={<TemplateVersionsList templateType={TEMPLATE.TYPE.QUESTION}
                                                                      templateMode={TEMPLATE.MODE.VERSIONS}/>}/>
                                <Route path={QD_URL + "/action-versions/:templateKey"}
                                       element={<TemplateVersionsList templateType={TEMPLATE.TYPE.ACTION}
                                                                      templateMode={TEMPLATE.MODE.VERSIONS}/>}/>
                                <Route path={QD_URL + "/search-tags"} element={<TagList/>}/>
                                <Route path="*" element={<NoPage/>}/>
                            </Route>
                        </Routes>
                    </div>
                }

                <ChangeAppTitleToPortalHook mode={REACT_APP_PORTAL_MODE}/>

                <MainDrawer/>

                <ArgoAppSnackbar/>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        theme: state.portalTheme
    };
}

export default connect(mapStateToProps)(Router);


// index tells react-router that it should render the provided element when you are at the index of the route.

// Good react route article
// https://reactrouter.com/en/v6.3.0/getting-started/concepts
// https://reactrouter.com/en/v6.3.0/getting-started/concepts#index-routes

// https://reactrouter.com/en/v6.3.0/getting-started/concepts#index-route

// https://stackoverflow.com/questions/42253277/react-router-v4-how-to-get-current-route

// import { useLocation } from 'react-router-dom';
//
// const ExampleComponent: React.FC = () => {
//     const location = useLocation();
//
//     return (
//         <Router basename='/app'>
//             <main>
//                 <AppBar handleMenuIcon={this.handleMenuIcon} title={location.pathname} />
//             </main>
//         </Router>
//     );
// }
import React, {Component} from "react";
import PropTypes from "prop-types";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import ChatIcon from '@mui/icons-material/Chat';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import AnnouncementTwoToneIcon from '@mui/icons-material/AnnouncementTwoTone';
import CommentIcon from '@mui/icons-material/Comment';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';
import ArgoColorInput from "./ArgoColorInput";
import ArgoTextFieldHook from "./ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import * as portalShared from "../../portal/shared/metadataConstantsAndUtilities"
import * as TEMPLATE from "../../constants/templateConstants";


class ArgoTextDisplayCustom extends Component {

    constructor(props) {
        super(props);

        if (props.initToObj === null) {
            this.state = portalShared.getCustomTextObj(this.props.templateType, this.props.textEntryType);
        }
        else {
            this.state = portalShared.setCustomTextObject(props.initToObj);
        }
    }

    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        let stateObj = {[fieldName]: value};

        let iconObj = null;

        switch(fieldName) {
            case "fontSizeEntry":
                if (this.state.fontSizing === "pixels") {
                    stateObj = {[fieldName]: value, "fontSize": value+"px"};
                }
                else {
                    stateObj = {[fieldName]: value, "fontSize": value+"pt"};
                }
                break;
            case "iconSizeEntry":
                iconObj = {...this.state.iconObj};
                iconObj.size = value;
                stateObj = {iconObj: iconObj};
                break;
            case "iconPaddingEntry":
                iconObj = {...this.state.iconObj};
                iconObj.padding = value;
                stateObj = {iconObj: iconObj};
                break;
            default:
                console.log("ArgoTextDisplayCustom.js  Switch default E01"); // eslint-disable-line
        }

        this.setState(stateObj, () => {this.setMetadataValue()});
    };

    onSelectChange = (event, fieldName) => {
        let selected = event.target.value;

        let stateObj = {[fieldName]: selected};

        switch(fieldName) {
            case "font":
                stateObj = {[fieldName]: selected, fontFamily: portalShared.getFontFamily(selected)};
                break;
            case "fontSizing":
            case "variant":

                let fontSizing = fieldName === "fontSizing" ? selected : this.state.fontSizing;
                let variant = fieldName === "variant" ? selected : this.state.variant;

                let fontSizePoints = portalShared.convertFontVariantToPoints(variant);

                stateObj = {fontSizing: fontSizing, variant: variant, "fontSize": fontSizePoints + "pt", "fontSizeEntry": fontSizePoints};
                break;
            default:
                console.log("ArgoTextDisplayCustom.js  Switch default E03"); // eslint-disable-line

        }

        // After setting the state, call setMetadataValue to update the template
        this.setState(stateObj, () => {this.setMetadataValue()});
    };

    setMetadataValue = () => {

        let valueObj = portalShared.setCustomTextObject(this.state);

        let mpo = this.props.metadataPropsObj;

        let setCustomMetadataFunction = this.props.useMetadataPropsObjAltFunction ? mpo.setCustomMetadataFunctionAlt : mpo.setCustomMetadataFunction;

        // Check to see if a set matadata callback was passed as a prop, if not use the callback passed in the mpo (metadata props object).
        if (this.props.setCustomMetadata) {
            this.props.setCustomMetadata(this.props.fieldTextObjName, this.props.fieldName, 'default', mpo.metadataTag, valueObj);
        }
        else {
            this.setState({metadataValue: JSON.stringify(valueObj)}, () => {setCustomMetadataFunction(this.props.fieldTextObjName, mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, "custom", mpo.metadataTag, valueObj)});
        }
    };

    setColor = (colorObj) => {

        this.setState(colorObj, () => {this.setMetadataValue()});
    };

    setIconColor = (colorObj) => {
        let iconObj = {...this.state.iconObj}; // Shallow copy current saved iconObj

        portalShared.setObj(iconObj, colorObj.colorObj);

        this.setState({iconObj: iconObj}, () => {this.setMetadataValue()});
    };

    onChangeDescriptionIcon = (event) => {
        let iconObj = {...this.state.iconObj};
        iconObj.icon = event.target.value;
        this.setState({iconObj: iconObj}, () => {this.setMetadataValue()});
    };

    render() {

        return (
            <div>
                <div id="ArgoTextDisplayCustom-container-div" style={{display: "flex", flexDirection: "row", width: '99%', backgroundColor: this.props.backgroundColorOverride, paddingTop: this.props.paddingTop}}>

                    {this.props.indent ? <div style={{width: "20px"}}></div> : ""}

                    {this.props.showDisplay ?
                        <div style={{display: "flex"}}>
                            <ArgoTextFieldSelectHook
                                fileName="ArgoTextDisplayCustom"
                                fieldName="textAlign"
                                label={this.props.displayLabel}
                                value={this.state.textAlign}
                                onChange={this.onSelectChange}
                                width="160px"
                                menuItems={portalShared.textAlignList}
                            />
                            <div style={{width: "20px"}}></div>
                        </div>
                        : ""
                    }

                    {this.props.showTitleEntry ?
                        <div style={{display: "flex"}}>
                            <ArgoTextFieldHook
                                fileName="ArgoTextDisplayCustom"
                                fieldName="title"
                                label="Title"
                                value={this.state.title}
                                onChange={this.onTextChange}
                                width="140px"
                            />
                            <div style={{width: "20px"}}></div>
                        </div>
                        : ""
                    }

                    {this.props.showFont ?
                        <ArgoTextFieldSelectHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="font"
                            label="Font"
                            value={this.state.font}
                            onChange={this.onSelectChange}
                            width="140px"
                            menuItems={portalShared.fontsList}
                        /> : ""
                    }

                    <div style={{width: "20px"}}></div>

                    {this.props.showSize ?
                        <div style={{display: "flex"}}>
                            <ArgoTextFieldSelectHook
                                fileName="ArgoTextDisplayCustom"
                                fieldName="fontSizing"
                                label="Sizing"
                                value={this.state.fontSizing}
                                onChange={this.onSelectChange}
                                width="110px"
                                menuItems={portalShared.sizingTypeList}
                            />

                            <div style={{width: "20px"}}></div>

                            <div>
                            {
                                (this.state.fontSizing === 'variant') ?
                                    <ArgoTextFieldSelectHook
                                        fileName="ArgoTextDisplayCustom"
                                        fieldName="variant"
                                        label="Variant"
                                        value={this.state.variant}
                                        onChange={this.onSelectChange}
                                        width="110px"
                                        menuItems={portalShared.variantTypesList}
                                    />
                                    :
                                    <ArgoTextFieldHook
                                        fileName="ArgoTextDisplayCustom"
                                        fieldName="fontSizeEntry"
                                        label={this.state.fontSizing === 'points' ? "Points" : "Pixels"}
                                        value={this.state.fontSizeEntry}
                                        type="number"
                                        onChange={this.onTextChange}
                                        width="70px"
                                    />
                            }
                            </div>

                            <div style={{width: "20px"}}></div>
                        </div> : ""
                    }

                    {this.props.showStyle ?
                        <ArgoTextFieldSelectHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="fontStyle"
                            label="Style"
                            value={this.state.fontStyle}
                            onChange={this.onSelectChange}
                            width="110px"
                            menuItems={portalShared.fontStyleList}
                        /> : ""
                    }

                    <div style={{width: "20px"}}></div>

                    {this.props.showWeight ?
                        <ArgoTextFieldSelectHook
                            fileName="ArgoTextDisplayCustom"
                            fieldName="fontWeight"
                            label="Weight"
                            value={this.state.fontWeight}
                            onChange={this.onSelectChange}
                            width="110px"
                            menuItems={portalShared.fontWeightList}
                        /> : ""
                    }

                    <div style={{width: "20px"}}></div>

                    {this.props.showColor ?
                        <ArgoColorInput opacityFlag={this.props.showOpacity}
                                        setColor={this.setColor}
                                        initToObj={this.props.initToObj}
                                        largeHexEntry={this.props.largeHexEntry}
                                        index={this.props.index}/> : ""
                    }

                </div>
                {this.props.textEntryType === "description" ||
                 this.props.textEntryType === TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.START_PAGE_SCRIPT ||
                 this.props.textEntryType === TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.CLOSE_PAGE_SCRIPT ?
                    <div id="ArgoTextDisplayCustom-container-div" style={{
                        display: "flex",
                        flexDirection: "row",
                        width: '99%',
                        backgroundColor: this.props.backgroundColorOverride,
                        paddingTop: this.props.paddingTop,
                        paddingBottom: "10px"
                    }}>

                        <div style={{width: "20px"}}></div>

                        <TextField
                            id="descriptionIconSelect"
                            label="Icon"
                            select
                            value={this.state?.iconObj?.icon}
                            style={{width: "100px"}}
                            onChange={this.onChangeDescriptionIcon}
                            variant="standard"
                        >
                            <MenuItem value="none">
                                <div>None</div>
                            </MenuItem>
                            <MenuItem value="InfoIcon"><InfoIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="InfoOutlinedIcon"><InfoOutlinedIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="InfoTwoToneIcon"><InfoTwoToneIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="ChatBubbleIcon"><ChatBubbleIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="ChatBubbleOutlineOutlinedIcon"><ChatBubbleOutlineOutlinedIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="ChatBubbleTwoToneIcon"><ChatBubbleTwoToneIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="ChatIcon"><ChatIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="ChatOutlinedIcon"><ChatOutlinedIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="ChatTwoToneIcon"><ChatTwoToneIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="AnnouncementIcon"><AnnouncementIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="AnnouncementOutlinedIcon"><AnnouncementOutlinedIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="AnnouncementTwoToneIcon"><AnnouncementTwoToneIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="CommentIcon"><CommentIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="CommentOutlinedIcon"><CommentOutlinedIcon sx={{width: 16, height: 16}}/></MenuItem>
                            <MenuItem value="CommentTwoToneIcon"><CommentTwoToneIcon sx={{width: 16, height: 16}}/></MenuItem>
                        </TextField>

                        <div style={{width: "20px"}}></div>

                        {this.state?.iconObj?.icon !== "none" ?
                            <div id="ArgoTextDisplayCustom-icon-container-div" style={{
                                display: "flex",
                                flexDirection: "row",
                                width: '99%',
                                backgroundColor: this.props.backgroundColorOverride,
                            }}>
                                <ArgoTextFieldHook
                                    fileName="ArgoTextDisplayCustom"
                                    fieldName="iconSizeEntry"
                                    label={"Size"}
                                    value={this.state?.iconObj?.size}
                                    type="number"
                                    onChange={this.onTextChange}
                                    width="100px"
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldHook
                                    fileName="ArgoTextDisplayCustom"
                                    fieldName="iconPaddingEntry"
                                    label={"Padding"}
                                    value={this.state?.iconObj?.padding}
                                    type="number"
                                    onChange={this.onTextChange}
                                    width="100px"
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoColorInput setColor={this.setIconColor}
                                                initToObj={this.props.initToObj.iconObj}
                                                largeHexEntry={this.props.largeHexEntry}
                                                index={this.props.index}
                                />
                            </div> : ""
                        }


                    </div> : ""
                }
            </div>
        );
    }
}

ArgoTextDisplayCustom.defaultProps = {
    indent: true,
    showDisplay: true,
    showFont: true,
    showSize: true,
    showStyle: true,
    showWeight: true,
    showColor: true,
    showOpacity: true,
    showTitleEntry: false,
    displayLabel: "Display",
    backgroundColorOverride: portalShared.COLOR_LIGHT_GRAY_HEX,
    largeHexEntry: true,
    paddingTop: "10px",
    useMetadataPropsObjAltFunction: false,
    index: 0,
    setCustomMetadata: undefined
};

ArgoTextDisplayCustom.propTypes = {
    templateType:  PropTypes.string,
    textEntryType:  PropTypes.string,
    indent: PropTypes.bool,
    showDisplay: PropTypes.bool,
    showFont: PropTypes.bool,
    showSize: PropTypes.bool,
    showStyle: PropTypes.bool,
    showWeight: PropTypes.bool,
    showColor: PropTypes.bool,
    showOpacity: PropTypes.bool,
    showTitleEntry: PropTypes.bool,
    fieldName: PropTypes.string,
    fieldTextObjName: PropTypes.string,
    initToObj: PropTypes.object,
    displayLabel: PropTypes.string,
    backgroundColorOverride: PropTypes.string,
    largeHexEntry: PropTypes.bool,
    paddingTop: PropTypes.string,
    metadataPropsObj: PropTypes.object,
    useMetadataPropsObjAltFunction: PropTypes.bool,
    index: PropTypes.number,
    setCustomMetadata: PropTypes.func
};

export default ArgoTextDisplayCustom;


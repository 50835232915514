import React, {Component} from 'react';
import PropTypes from "prop-types";
import {TableGrid, TableView} from '../../containers';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {getTemplateList, updatePageAndGetList, updateSortAndGetList} from "../../actions/templateListActions";
import {openDrawer} from "../../actions/drawerActions";
import ActionReviewDrawer, {actionReviewDrawerProps} from "../drawers/ActionReviewDrawer";
import ActionCreateEditDrawer, {actionCreateEditDrawerProps} from "../drawers/ActionCreateEditDrawer";
import FilterPopOver from "../dialogs/filters/FilterPopOver";
import ArgoTemplateListCreateFabHook from "../common/ArgoTemplateListCreateFabHook";
import ArgoListTitleAndLoadingAnimationHook from "../common/ArgoListTitleAndLoadingAnimationHook";
import formatCopyText from "../../utilities/formatCopyText";
import {QD_URL} from "../../constants/systemConstants";
import * as SYSTEM from "../../constants/systemConstants";
import * as metadataTemplateTypes from "../../constants/metadataTemplateTypes";
import * as TEMPLATE from "../../constants/templateConstants";
import ArgoScreenSizePopUp from "../common/ArgoScreenSizePopUp";


class ActionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filter: false,
            createMode: false,
            closePopUp: false
        };
    }

    componentDidMount(){
        this.setState({loading: true}, () => this.props.getTemplateList(this.props.filter, TEMPLATE.TYPE.ACTION, TEMPLATE.MODE.CURRENT));
    }

    componentWillReceiveProps(nextProps) {

        if (this.state.loading) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemConstants.js
                setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false, filter: false, createMode: false});
            }

        }
    }

    // Used to test loading animation rendering
    delayedSetLoadingFalse = () => {
        this.setState({loading: false});
    }

    setLoadingStateTrue = (callback, parm1 = null) => {
        // If a prameter is passed then pass it to call back, should be the template of the item to be saved edit or created
        if (parm1 === null) {
            this.setState({loading: true, filter: true}, () => callback());
        } else {
            this.setState({loading: true, filter: true}, () => callback(parm1));
        }
    }

    openEdit = (templateKey, id, templateMode) => {
        if (!templateKey && this.props.match && this.props.match.params) {
            templateKey = this.props.match.params.templateKey;
        }

        this.props.openDrawer(actionCreateEditDrawerProps, <ActionCreateEditDrawer templateKey={templateKey} templateId={id} filter={this.props.filter} templateMode={templateMode}/>);
    };

    openCreate = () => {
        this.props.openDrawer(actionCreateEditDrawerProps,<ActionCreateEditDrawer createMode={true}/>);
        // if (e !== undefined) {
        //     e.preventDefault();
        // }
    };

    openActionReviewDrawer = (templateKey, id, name, row, event) => {
        if (!templateKey && this.props.match && this.props.match.params) {
            templateKey = this.props.match.params.templateKey;
        }
        formatCopyText(row, event, "Action:");
        this.props.openDrawer(actionReviewDrawerProps, <ActionReviewDrawer templateKey={templateKey} templateId={id}/>);
    };

    onPageChange = (page, size, event) => {
        this.setState({loading: true, filter: true},() => this.props.updatePageAndGetList(page, size, this.props.filter, TEMPLATE.TYPE.ACTION, TEMPLATE.MODE.CURRENT, event));
    };

    onSortChange = (sort, direction) => {
        this.setState({loading: true, filter: true},() => this.props.updateSortAndGetList(sort, direction, this.props.filter, TEMPLATE.TYPE.ACTION, TEMPLATE.MODE.CURRENT));
    };

    handleClosePopUp = () => {
        localStorage.setItem("hasSeenWelcomeMessage", "true");
        this.setState({closePopUp: true})
    };

    render() {
        // let {
        //     actionTemplateList
        // } = this.props;

        const customActions = [
            {
                func: this.openActionReviewDrawer,
                label: 'Review'
            },
            {
                func: this.openEdit,
                label: "Edit"
            }
        ];

        // Used by the versions list and stored in the version filters
        let defaultActions = {
            edit: this.openEdit,
            create: this.openCreate,
            review: this.openActionReviewDrawer
        };

        return (
            <div id="ActionList-container-div" style={{height: "100%", padding: "15px", marginTop: '64px'}}>
                {this.state.closePopUp ? "" : <ArgoScreenSizePopUp closeFunction={this.handleClosePopUp}/>}
                <ArgoListTitleAndLoadingAnimationHook fileName="ActionList" listTitle="Actions" loading={this.state.loading} filter={this.state.filter}/>

                <div style={{clear: "both"}}></div>

                <Card id="ActionList-card"
                      variant="outlined"
                      sx={{border: "1px solid lightgray", height: "100%", boxShadow: "2px 4px whitesmoke"
                      }}>
                    <CardContent id="ActionList-card-content">

                        <TableView
                            id="ActionList-table-view"
                            templateType={TEMPLATE.TYPE.ACTION}
                            templateMode={TEMPLATE.MODE.CURRENT}
                            setLoadingState={this.setLoadingStateTrue}
                            filter={<FilterPopOver page="actions"
                                                   templateType={TEMPLATE.TYPE.ACTION}
                                                   templateMode={TEMPLATE.MODE.CURRENT}
                                                   filterName={TEMPLATE.FILTER.current.action}
                                                   submitValue={this.props.getTemplateList}
                                                   statuses={["All", "Draft", "Published"]}
                                                   showActionType={true}
                                                   types={{INTERVENTION: 'Intervention', GOAL: 'Goal'}}
                                                   showQualifier={false}
                                                   setLoadingState={this.setLoadingStateTrue}
                            />}
                        >
                            <TableGrid
                                id="ActionList-table-grid"
                                displayType={"actions"}
                                dataSource={this.props.actionTemplateList}
                                nameDrawer={this.openActionReviewDrawer}
                                link={QD_URL +"/action-editor/"}
                                customActions={customActions}
                                pageState = {this.props.filter}
                                onPageChange = {this.onPageChange}
                                sortState = {this.props.filter}
                                onSortChange = {this.onSortChange}
                                type={metadataTemplateTypes.AnswerTemplate}
                                templateType={TEMPLATE.TYPE.ACTION}
                                templateMode={TEMPLATE.MODE.CURRENT}
                                defaultActions={defaultActions}
                                showQualifier={false}
                                showType={true}
                            />
                        </TableView>

                        <ArgoTemplateListCreateFabHook
                            fileName="ActionList"
                            fieldName="fab"
                            hoverText="Create Action"
                            onClick={this.openCreate}
                        />

                    </CardContent>

                </Card>

            </div>
        );
    }
}

ActionList.propTypes = {
    actionTemplateList: PropTypes.array,
    match: PropTypes.object,
    location: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getTemplateList, updatePageAndGetList, updateSortAndGetList, openDrawer}, dispatch);
}

function mapStateToProps(state) {
    return {
        actionTemplateList: state.actionTemplateList,
        filter: state.filter[TEMPLATE.FILTER.current.action] || {}  //Get correct filter from constant
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionList);

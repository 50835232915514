import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {updateAnswer, removeAnswer, reorderAnswer, setShowGroup, clearShowGroup} from "../../../actions/answerEditorActions";
import {getActionTemplate} from "../../../actions/actionTemplateActions";
import {getTemplateList, getTemplateListCurrent, getTemplateListForSelectFilter} from "../../../actions/templateListActions";
import {getGroupTemplate} from "../../../actions/groupTemplateActions";
import {getActionTypesList} from "../../../actions/questionnaireActionTypes";
import ArgoMetadataEditor from "../../common/ArgoMetadataEditor";
import ArgoSelectExisting from "../../common/ArgoSelectExisting";
import ArgoTextFieldSelectHook from "../../common/ArgoTextFieldSelectHook";
import ArgoAutocompleteHook from "../../common/ArgoAutocompleteHook";
import ArgoIconButtonHook from "../../common/ArgoIconButtonHook";
import ArgoSectionOpenCloseHook from "../../common/ArgoSectionOpenCloseHook";
import ArgoRadioGroupHook from "../../common/ArgoRadioGroupHook";
import ArgoSelectWithRenderValueHook from "../../common/ArgoSelectWithRenderValueHook";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoColorInput from "../../common/ArgoColorInput";
import * as metadataTemplateTypes from "../../../constants/metadataTemplateTypes";
import * as color from "../../../constants/colorConstants";
import * as icon from "../../../constants/iconConstants";
import * as TEMPLATE from "../../../constants/templateConstants";
import * as questionTypes from "../../../constants/questionTypeConstants";
import * as portalShared from "../../../portal/shared/metadataConstantsAndUtilities";
import {ALLOWED_TOKEN} from "../../../constants/dataConstants";



class AnswerEditorDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedActionType: this.props.actionTypeList[0],
            actionTemplateListLength: 0,
            actionsListBySelectedActionType: [],
            selectedActionTemplate: {},
            metadataErrorText: "",
            metadataErrorFlag: false,
            groupTemplateSearch: "",
            actionTemplateSearch: "",
            sectionActionsOpen: false,
            sectionShowGroupsOpen: false,
            sectionMetadataOpen: false,
            //showActions: true,
            isThisAnExistingGroup: "0",
            isThisAnExistingAction: "0",
            selectedActionValue: null,
            actionList: [],
            selectActionsArray: [],
            questionAssignedActionSArray: [],
            groupList: [],
            iconPosition: "0",
            iconImageValue: "0",
            iconColorValue: "0",
            iconColorObj: {},
            currentGroupTemplates: {},
            index: null
        };

        this.onUpdateActionInput = this.onUpdateActionInput.bind(this);
        this.onUpdateGroupInput = this.onUpdateGroupInput.bind(this);
    }


    componentDidMount() {

        this.props.getActionTypesList(); // Load all actions

        let iconInitObj = icon.initIconSelectEntries(this.props.answerOption.metadata, (this.props.isLikertAnswer || this.props.displayAsLikert)); // Set initial state of the icon select entries
        //
        //this.setState(iconInit);

        //let stateObj = {selectedActionType: selectedActionType, ...iconInitObj};

        this.setState(iconInitObj);


        //this.props.getTemplateListForSelectFilter(TEMPLATE.TYPE.ACTION, TEMPLATE.MODE.ALL);
        this.props.getTemplateListCurrent(null, {mode: TEMPLATE.TYPE.ACTION}, TEMPLATE.TYPE.ACTION);
        //this.loadActions(this.props);
        //this.loadGroups(this.props);
        //this.setupShowToggles(this.props);

        this.setState({currentGroupTemplates: this.props.groupTemplate});


        //this.props.getTemplateListForSelectFilter(TEMPLATE.TYPE.GROUP, TEMPLATE.MODE.CURRENT); //TODO I don't think this does anything
        //this.setDefaultActionType(this.props);


        // Must create array keys for anssers
        // const answerKeys = Object.values(this.props.answerOptionTemplates).map(template => template.templateKey)
        // if (!this.state.expanderPreset) {
        //     this.setState({expanderPreset: true, expander: expander.presetExpander(answerKeys, this.props.answerOptionTemplates, this.state.expander)});
        // }

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.actionTypeList.length > 0) {
            this.buildActionListBySelectedActionType(nextProps, this.state.selectedActionType); // Build action list by selected action type
        }



        //this.isNewAnswerAdded(nextProps);

        let actionsListBySelectedActioType = [];

        let actionsLoaded = this.loadActions(nextProps); // Load actions associated with this answer
        if (actionsLoaded && nextProps.answerOption.hasOwnProperty("importActionKeys")) {
            for (let i = 0; i < nextProps.answerOption.importActionKeys.length; i++) {
                if (nextProps.answerOption.importActionKeys[i].name === this.state.selectedActionType) {
                    actionsListBySelectedActioType.push(nextProps.answerOption[nextProps.answerOption.importActionKeys[i].templateKey]);
                }
            }
        }

        //this.loadGroups(nextProps);  // Load show groups associated with this answer (NOTE: We are limiting show groups to 1 for now)

        // //this.setupShowToggles(nextProps);
        //this.setDefaultActionType(nextProps);
        //
        // let actionList = [];
        // for (let i = 0; i < nextProps.actionTemplateList.length; i++) {
        //
        //     let keyFound = false;
        //     for (let ii = 0; ii < nextProps.answerOption.importActionKeys.length; ii++) {
        //
        //         if (nextProps.answerOption.importActionKeys[ii] === nextProps.actionTemplateList[i].templateKey) {
        //             keyFound = true;
        //             break;
        //         }
        //     }
        //
        //     if (!keyFound) {
        //         actionList.push(nextProps.actionTemplateList[i]);
        //     }
        // }
        //
        // let groupList = [];
        // for (let j = 0; j < nextProps.groupTemplateList.length; j++) {
        //
        //     let keyFound = false;
        //     for (let jj = 0; jj < nextProps.answerOption.importGroupKeys.length; jj++) {
        //
        //         if (nextProps.answerOption.importGroupKeys[jj] === nextProps.groupTemplateList[j].templateKey) {
        //             keyFound = true;
        //             break;
        //         }
        //     }
        //
        //     if (!keyFound) {
        //         groupList.push(nextProps.groupTemplateList[j]);
        //     }
        // }
        //


        let currentGroupTemplates = this.state.currentGroupTemplates;

        // See if a show group has been added
        if (this.props.newShowGroupAdded) {

            // Check to see if this is the anser that needs to add the new group as a show group
            if (this.props.answerIndex === this.props.index) {
                for (const [key, value] of Object.entries(nextProps.groupTemplate)) {
                    // If there is a new group key then it must havge been added as a show group
                    if (!this.state.currentGroupTemplates.hasOwnProperty(key)) {
                        this.addGroupToAnswer(value, nextProps.groupTemplate);
                        currentGroupTemplates = nextProps.groupTemplate;
                        this.props.clearShowGroup(false, null);
                    }
                }
            }
        }




        let iconInit = icon.initIconSelectEntries(nextProps.answerOption.metadata, (nextProps.isLikertAnswer || nextProps.displayAsLikert)); // Set initial state of the icon select entries

        let stateObj = {currentGroupTemplates: currentGroupTemplates, ...iconInit};

        this.setState(stateObj);

        this.validateAnswerOptions(nextProps.answerOption, nextProps.answerOptionTemplates)

        //this.setState({actionList: actionList, groupList: groupList, iconPosition: iconInit.position, iconImageValue: iconInit.imageIndex, iconColorValue: iconInit.colorIndex});
        //this.setState({actionList: [], groupList: [], iconPosition: iconInit.position, iconImageValue: iconInit.imageIndex, iconColorValue: iconInit.colorIndex});

    }



    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████
    //  Answer Section Functions
    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████


    onChangeAnswerText = (event, fieldName) => {

        let answer = {...this.props.answerOption};

        answer[fieldName] = event.target.value;

        this.updateAnswer(answer, this.props.index);
    };

    updateAnswer = (answer, index) => {
        this.props.updateAnswer(this.props.groupTemplateMode, answer, index, this.props.questionDepth);
    };

    validateAnswerOptions = (answerOption, answerOptionTemplates) => {

        let errorText = " ";
        let duplicateAnswersErrorText = ""

        if (answerOption.optionText.length === 0) {
            errorText = "Answer text cannot be blank.";
        }

        for (let i = 0; i < answerOptionTemplates.length; i++) {
            const answerOption = answerOptionTemplates[i].optionText;
            for (let j = 0; j < answerOptionTemplates.length; j++) {
                const compareTo = answerOptionTemplates[j].optionText;
                if (answerOption === compareTo && i !== j) {
                    duplicateAnswersErrorText = "Duplicate answer names!"
                }
            }
        }

        if (duplicateAnswersErrorText !== "") {
            errorText = errorText === " " ? duplicateAnswersErrorText : errorText + ",  " + duplicateAnswersErrorText;  // Specific error messsage for toolbar
        }

        return errorText;
    };

    onSelectChange= (event, fieldName, menuItemText, index) => {

        let value = event.target.value; // For position value = text, otherwise value is the index of the selected menuItem

        let tempMetadata = {...this.props.answerOption.metadata};

        switch(fieldName) {
            case "iconPosition":

                // If type === none remove all icon-???? properties
                if (value === icon.POSITION_NONE) {

                    if (tempMetadata.hasOwnProperty(icon.ICON_POSITION)){
                        delete tempMetadata[icon.ICON_POSITION];
                    }
                    if (tempMetadata.hasOwnProperty(icon.ICON_TYPE)){
                        delete tempMetadata[icon.ICON_TYPE];
                    }
                    if (tempMetadata.hasOwnProperty(icon.ICON_COLOR)){
                        delete tempMetadata[icon.ICON_COLOR];
                    }

                    this.setState({iconPosition: menuItemText, iconImageValue: "0", iconColorValue: "0"});
                }
                else {
                    tempMetadata[icon.ICON_POSITION] = (this.props.isLikertAnswer || this.props.displayAsLikert) ? icon.likertIcons[value].name : menuItemText;
                    this.setState({iconPosition: menuItemText});
                }

                break;
            case "iconImageValue":

                tempMetadata[icon.ICON_TYPE] = icon.types[value].text;

                this.setState({iconImageValue: value});

                break;
            case "iconColorValue":

                tempMetadata[icon.ICON_COLOR] = color.standard[value].hex;

                this.setState({iconColorValue: value});

                break;
            // no default
        }


        this.handleMetaChange(tempMetadata, index);
    };

    setIconColorObj = (index, iconColorObj) => {

        this.setState({iconColorObj: iconColorObj});

        let tempMetadata = {...this.props.answerOption.metadata};

        tempMetadata[icon.ICON_COLOR_OBJ] = JSON.stringify(iconColorObj);

        // Added for legacy compatibality, added color component in this version
        switch(iconColorObj.colorType) {
            case "select":
                tempMetadata[icon.ICON_COLOR] = iconColorObj.color;
                break;
            case "hex":
                tempMetadata[icon.ICON_COLOR] = iconColorObj.colorEntry;
                break;
            case "rgb":
                tempMetadata[icon.ICON_COLOR] = iconColorObj.colorEntry;
                break;
            // no default
        }

        this.handleMetaChange(tempMetadata, index);
    };

    handleMetaChange = (metadata, index) => {
        let updatedTemplate = {...this.props.answerOption};
        updatedTemplate.metadata = metadata;
        this.props.updateAnswer(this.props.groupTemplateMode, updatedTemplate, index, this.props.questionDepth);
    };


    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████
    //  Sections (Actions, Show Groups and Metadata) Toggle Open/Close
    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████


    onClickToggleSectionOpenClose = (event, fieldName) => {
        this.setState({[fieldName]: !this.state[fieldName]});
    };


    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████
    //  Actions Section Functions
    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████

    // showActions = () => {
    //     return this.props.answerOption.importActionKeys.length > 0;
    // };

    showSectionActions = (index) => {

        return (
            <div style={{width: "100%"}}>
                <div style={{paddingLeft: "64px"}}>

                    {(this.props.questionDepth === 0) ?
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
                            <ArgoRadioGroupHook
                                fileName="AnswerEditorDetails"
                                fieldName="isThisAnExistingAction"
                                index={index}
                                label="Is this an existing action?"
                                value={this.state.isThisAnExistingAction}
                                onChange={this.toggleIsThisAnExistingAction}
                                checked={true}
                            />
                        </div>
                        : ""}

                    <div>&nbsp;</div>

                    <ArgoTextFieldSelectHook
                        fileName="AnswerEditorDetails"
                        fieldName="selectedActionType"
                        index={index}
                        label="Select action type"
                        value={this.state.selectedActionType}
                        onChange={this.onSelectChangeActionType}
                        useTextForValue={true}
                        menuItems={this.props.actionTypeList}
                    />

                    <div>&nbsp;</div>

                    <ArgoTextFieldSelectHook
                        fileName="AnswerEditorDetails"
                        fieldName="selectedActionValue"
                        index={index}
                        label="Select action"
                        value={this.state.selectedActionTemplate.name}
                        menuItems={this.state.actionsListBySelectedActionType}
                        onChange={this.onSelectAction}
                        useTextForValue={false}
                        valueProperty={"id"}
                        textProperty={"name"}
                    />

                    <div>&nbsp;</div>

                    {this.getActions(index)}
                </div>
            </div>
        );
    };

    getActions = (index) => {

        const actionTypes = this.props.actionTypeList;

        let selectedActions = {}; // Create an "other" action type

        actionTypes.forEach((type) => {selectedActions[type] = [];}); // Add system action types to the list

        let answerOption = this.props.answerOptionTemplates[index];

        answerOption.importActionKeys.forEach((templateKey) => {
            if (!this.props.actionTemplate[templateKey]) {
                this.props.getActionTemplate(templateKey);
                return;
            }

            let type = (this.props.actionTemplate[templateKey].type) ? this.props.actionTemplate[templateKey].type : "other";
            if (selectedActions[type]) {
                selectedActions[type].push(this.props.actionTemplate[templateKey]);
            }
            else {
                console.warn("action template type is missing");// eslint-disable-line
            }
        });

        //let answerId= answerOption.id;

        return Object.keys(selectedActions).map((type) => {
                if (selectedActions[type].length > 0) {
                    return (
                        <div key={type + this.props.index} style={{ width: "100%", marginTop: "8px"}}>

                            <div key={type + "action_label_" + this.props.index} style={{textTransform: "capitalize"}}>{type}</div>

                            {selectedActions[type].map((action, actionIndex) => {
                                return (
                                    <div key={action.templateKey} id={"AnswerEditorDetails-containder-div-" + type} style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                                        <div style={{display: "flex", paddingLeft: "15px"}}>
                                            {action.name}
                                        </div>

                                        <div style={{display: "flex", marginLeft: "auto"}}>
                                            <ArgoIconButtonHook
                                                fileName="AnswerEditorDetails"
                                                fieldName={action.templateKey}
                                                onClick={this.removeAction}
                                                index={actionIndex}
                                                depth={this.props.questionDepth}
                                                icon={<RemoveCircleOutlineIcon/>}
                                            />
                                        </div>

                                    </div>
                                );
                            })}

                        </div>
                    );
                }
                return null;
            }
        );
    };


    toggleIsThisAnExistingAction = (event, fieldName, index) => {

        let value = event.target.value; // Yes = "0", No = "1"

        this.setState({openActionEditor: value === "1", isThisAnExistingAction: value});

        if (value === "1") {
            this.props.parentSetStateCallback({showActionEditor: true, answerIndex: index});
        }

        //this.toggleActionEditor(index, (value)? this.state.actionTemplateSearch: "", value);

        // this.setState({
        //     showActionEditor: value,
        //     answerIndex: answerIndex,
        //     headerInitializer: headerInitializer
        // });
    };

    onSelectActionType = (event) => {

        let type = event.target.value; // Currently Integration or Goal, TODO Need to add other as an option and default to it

        this.setState({selectedActionType: type});

        this.props.getTemplateListForSelectFilter(TEMPLATE.TYPE.ACTION, TEMPLATE.MODE.CURRENT, {type: type});
    };



    clearActionInput = () => {
        this.setState({actionTemplateSearch: ""});
    };


    onUpdateActionInput = (searchText) => {
        this.setState({actionTemplateSearch: searchText});
    };

    removeAction = (event, fieldName) => {
        let actionKey = fieldName; // Action key is passed as the fieldName

        let answer = {...this.props.answerOption};
        answer.importActionKeys = answer.importActionKeys.filter((key) => {
            return key !== actionKey;
        });
        this.updateAnswer(answer, this.props.index);
    };



    onSelectChangeActionType = (event, fieldName, menuItemText) => {
        this.setState({selectedActionType: menuItemText}, () => this.buildActionListBySelectedActionType(this.props, this.state.selectedActionType));
    }

    buildActionListBySelectedActionType = (props, actionType) => {
        let actionsListBySelectedActionType = [];
        if (props.actionTemplateList.length > 0) {
            for (let i = 0; i < props.actionTemplateList.length; i++) {
                if (props.actionTemplateList[i].type === actionType) {
                    actionsListBySelectedActionType.push(props.actionTemplateList[i]);
                }
            }
        }
        this.setState({actionsListBySelectedActionType: actionsListBySelectedActionType});
    }

    onSelectAction = (event, fieldName, menuItemText, index) => {
        let selectedActionObj = event.target.value;

        let actions = [...this.props.answerOption.importActionKeys];

        this.props.getActionTemplate(selectedActionObj.templateKey);
        if (actions.indexOf(selectedActionObj.templateKey) < 0) {
            actions.push(selectedActionObj.templateKey);
        }

        let answer = {
            ...this.props.answerOption,
            importActionKeys: actions
        };

        this.updateAnswer(answer, index);
    };


    isNewAnswerAdded = (props) => {

        let answerOptionTemplates = props.answerOptionTemplates;

        for (let i = 0; i < answerOptionTemplates.length; i++) {
            if (!this.state.expander.hasOwnProperty(answerOptionTemplates[i].templateKey)) {
                let tempExpander = this.state.expander;
                tempExpander[answerOptionTemplates[i].templateKey] = {open: false, template: answerOptionTemplates[i]};
                this.setState({expander: tempExpander});
            }
        }

    }

    setMetadataErrorText = (metadataErrorText) => {
        this.setState({metadataErrorText: metadataErrorText}, () => this.props.setMetadataErrorText(metadataErrorText));
    }

    setDefaultActionType = (props) => {
        if (props.actionTypeList && props.actionTypeList.length > 0 && !this.state.selectedActionType)
        {
            this.props.getTemplateListForSelectFilter(TEMPLATE.TYPE.GROUP, TEMPLATE.MODE.CURRENT, {type: props.actionTypeList[0]});
            this.setState({selectedActionType: props.actionTypeList[0]});
        }
    };

    // setupShowToggles = (props) => {
    //     let showGroups,
    //         showActions;
    //     const answer = props.answerOption;
    //
    //     showActions = !!answer && answer.importActionKeys.length > 0;
    //     showGroups = !!answer && answer.importGroupKeys.length > 0;
    //
    //     if (this.state.showActions !== showActions || this.state.showGroups !== showGroups) {
    //         this.setState({showActions, showGroups});
    //     }
    // };

    loadActions = (props) => {

        let actionLoaded = true;
        // Loop thru answers actionKeys, if action is not loaded the get it
        props.answerOption && props.answerOption.importActionKeys.forEach((key) => {
            if (!props.actionTemplate[key]) {
                actionLoaded = false;
                props.getActionTemplate(key);
            }
        });
        return actionLoaded;
    };

    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████
    //  Section Show Groups Functions
    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████

    loadGroups = (props) => {

        props.answerOption && props.answerOption.importGroupKeys.forEach((key) => {
            if (!props.groupTemplate[key]) {
                props.getGroupTemplate(key);
            }
        });
    };

    // showGroups = () => {
    //     return this.props.answerOption.importGroupKeys.length > 0;
    // };

    showSectionShowGroups = (index) => {

        // NOTE: Group answers do NOT allow a show group to be created, they can only be added from existing groups

        return (
            <div className="layout vertical" style={{width: "100%"}}>
                <div style={{paddingLeft: "64px"}}>
                    {(this.props.answerOption.importGroupKeys.length > 0) ? "" :
                        <div>
                            {!this.props.groupTemplateMode ?
                                <div className="layout horizontal center"
                                     style={{width: "100%", height: "64px", minHeight: "64px"}}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                        <ArgoRadioGroupHook
                                            fileName="AnswerEditorDetails"
                                            fieldName="isThisAnExistingGroup"
                                            index={this.props.index}
                                            label="Is this an existing group?"
                                            value={this.state.isThisAnExistingGroup}
                                            onChange={this.toggleIsThisAnExistingGroup}
                                            fontSize="18px"
                                            checked={true}
                                        />
                                    </div>
                                </div> : ""
                            }

                            <ArgoSelectExisting
                                mode={TEMPLATE.TYPE.GROUP}
                                onAddTemplate={this.addGroupToAnswer.bind(this)}
                            />

                        </div>

                    }
                    <div>&nbsp;</div>
                    {this.getGroups(index)}
                    <div>&nbsp;</div>
                </div>
            </div>);
    };

    getGroups = (index) => {

        // NOTE: At some point we limited show groups so that only one could be added.  I think portal will support more, but testing is needed
        return this.props.answerOption.importGroupKeys.map((key) => {
            let group = this.props.groupTemplate[key];
            if (!group) {
                this.props.getGroupTemplate(key);
                console.warn("Group missing", key); // eslint-disable-line
                return null;
            }

            return (
                <div id="AnswerEditorDetails-show-group-comntainer-div" style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
                    <div id="AnswerEditorDetails-show-group-name-container-div" style={{display: "flex"}}>
                        {group.name}
                    </div>
                    <div id={"AnswerEditorDetails-remove-show-group-icon-button-container-div" + index} style={{display: "flex", marginLeft: "auto"}}>
                        <ArgoIconButtonHook
                            fileName="AnswerEditorDetails"
                            fieldName={key}
                            onClick={this.onClickRemoveGroup}
                            icon={<RemoveCircleOutlineIcon/>}
                        />
                    </div>
                    {/*<IconButton id={"AnswerEditorDetails-answer-" + self.props.answerOption.id + "-remove-group" + group.id} name={"AnswerEditorDetails-answer-" + self.props.answerOption.id + "-remove-group" + group.id} */}
                    {/*            onClick={self.removeGroup.bind(self, key)} */}
                    {/*            style={{alignSelf: "flex-end"}} */}
                    {/*            tabIndex={1}>*/}
                    {/*    <RemoveCircleOutlineIcon/>*/}
                    {/*</IconButton>*/}
                </div>
            );
        });
    };

    // createModeGroupSelector = () => {
    //     let answerId= this.props.answerOption.id;
    //     return (
    //         <div className="layout horizontal center" style={{width: "100%", height: "64px", minHeight: "64px"}}>
    //
    //             {(this.props.questionDepth === 0) ?
    //                 <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
    //                     <ArgoRadioGroupHook
    //                         fileName="AnswerEditorDetails"
    //                         fieldName="isThisAnExistingGroup"
    //                         index={this.props.index}
    //                         label="Is this an existing group?"
    //                         value={this.state.isThisAnExistingGroup}
    //                         onChange={this.toggleIsThisAnExistingGroup}
    //                         fontSize="18px"
    //                     />
    //                 </div>
    //                 : ""}
    //
    //         </div>
    //     );
    // };

    toggleIsThisAnExistingGroup = (event) => {
        let value = event.target.value; // Yes = "0", No = "1"

        // Set the current groups in this.props.groupTemplate

        if (value === "1") {
            this.props.setShowGroup(false, this.props.index);
            this.props.parentSetStateCallback({showGroupEditor: true});
        }

    };

    addGroupToAnswer = (chosenGroup, groupTemplates = this.props.groupTemplate) => {

        let groups = [...this.props.answerOption.importGroupKeys];

        if (groups.indexOf(chosenGroup.templateKey) < 0) {
            groups.push(chosenGroup.templateKey);
        }

        let answer = {
            ...this.props.answerOption,
            importGroupKeys: groups
        };

        this.updateAnswer(answer, this.props.index); // ZZZZZ

        this.setState({currentGroupTemplates: groupTemplates}); // Reset current group templates
    };

    onUpdateGroupInput = (searchText) => {
        this.setState({groupTemplateSearch: searchText});
    };

    clearGroupInput = () => {
        this.setState({groupTemplateSearch: ""});
    };

    onClickRemoveGroup = (event, fieldName) => {

        let groupKey = fieldName; // NOTE: groupKey is group template key is passed to ArgoIconButtonHook as the fieldName.

        let answer = {...this.props.answerOption};
        answer.importGroupKeys = answer.importGroupKeys.filter((key) => {
            return key !== groupKey;
        });
        this.updateAnswer(answer, this.props.index);

        let groupTemplates = this.state.currentGroupTemplates;
        for (const [key, value] of Object.entries(this.state.currentGroupTemplates)) {
            // If there is a new group key then it must havge been added as a show group
            if (key !== groupKey) {
                groupTemplates[key] = value;  // remove the removed key
            }
        }

        this.setState({currentGroupTemplates: groupTemplates}); // Reset current group templates
    };

    toggleGroupEditor = (answerIndex, headerInitializer, value) => {
        this.setState({
            showGroupEditor: value,
            answerIndex,
            headerInitializer
        });
    };

    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████
    //  Section Metadata Functions
    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████

    handleAnswerMetadataChange = (answerTemplate) => {
        let updatedAnswer = {...this.props.answerOption};
        updatedAnswer.metadata = answerTemplate.metadata;
        this.updateAnswer(updatedAnswer, this.props.index);
    };

    disableIfStateOrAddressQuestionType = () => {

        if (this.props.questionTemplate === null) {
            return false; // This condition is when in group mode
        }
        let type = this.props.questionTemplate.questionType;
        return (type === questionTypes.STATE_ONLY || type === questionTypes.ADDRESS_FULL);
    }

    disableIfYesNoQuestionType = () => {
        if (this.props.questionTemplate === null) {
            return false; // This condition is when in group mode
        }
        let type = this.props.questionTemplate.questionType;
        return (type === questionTypes.YES_NO);
    }

    onResponsiveLabelChange = (event, fieldName, index, validationType) => {
        let updatedAnswer = portalShared.cloneObj(this.props.answerOption);
        updatedAnswer.metadata[portalShared.USE_LIKERT_RESPONSIVE_LABEL] = event.target.value;
        this.updateAnswer(updatedAnswer, this.props.index);
    }

    // This would not work in the render so moved it to a function!!!!
    iconPosition = (isLikertAnswer, displayAsLikert, iconPosition, index) => {

        if (isLikertAnswer || displayAsLikert) {

            let value = "0";
            for (let i = 0; i < icon.likertModesTitlesAndPositions.length; i++) {
                if (iconPosition === icon.likertModesTitlesAndPositions.name) {
                    value = icon.likertModesTitlesAndPositions[i].value;
                }
            }

            return (
                (iconPosition !== icon.ICON_NO) ?
                    <ArgoTextFieldSelectHook
                        fileName="AnswerEditorDetails"
                        fieldName="iconPosition"
                        index={index}
                        label="Icon"
                        value={value}
                        onChange={this.onSelectChange}
                        width="100px"
                        disabled={true}
                        menuItems={icon.likertModesTitlesAndPositions}
                        paddingBottom={"5px"}
                    /> : ""
            );
        } else {
            return (
                <ArgoTextFieldSelectHook
                    fileName="AnswerEditorDetails"
                    fieldName="iconPosition"
                    index={index}
                    label="Position"
                    value={this.state.iconPosition}
                    onChange={this.onSelectChange}
                    width="100px"
                    menuItems={icon.positions}
                    useTextForValue={true}
                    paddingBottom={"5px"}
                />
            );
        }
    }

    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████
    //  RENDER
    // -████████████████████████████████████████████████████████████████████████████████████████████████████████████████

    render() {

        let iconAllowed = true;
        if (!this.props.isLikertAnswer) {

            switch (this.props.questionTemplate.questionType) {
                case questionTypes.YES_NO:
                case questionTypes.RADIO:
                    iconAllowed = true;
                    break;
                default:
                    iconAllowed = false;
            }
        }

        let index = this.props.index;
        let answerOption = this.props.answerOption;

        let iconInitObj = icon.initIconSelectEntries(answerOption.metadata, (this.props.isLikertAnswer || this.props.displayAsLikert)); // Set initial state of the icon select entries

        if (typeof iconInitObj.iconColorObj === "string") {
            iconInitObj.iconColorObj = JSON.parse(iconInitObj.iconColorObj);
        }

        return (
            this.props.answerOption ?
            <div>
                <div id={"AnswerEditorDetails-container-div-index-" + index} style={{width: "100%"}}>

                    <div key={answerOption.templateKey + index}
                         id={"AnswerEditorDetails-answer-row-" + index + "-container-div"}
                         style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                        <div style={{display: "flex", alignItems: "center"}}>

                            <ArgoAutocompleteHook
                                fileName="AnswerEditorDetails"
                                fieldName="optionText"
                                index={index}
                                label={"Answer " + (index + 1)}
                                onChange={this.onChangeAnswerText}
                                value={answerOption.optionText}
                                maxLength="255"
                                multiline={true}
                                errorText={this.validateAnswerOptions(answerOption, this.props.answerOptionTemplates)}
                                width="650px"
                                menuItems={ALLOWED_TOKEN}
                            />

                            <div style={{width: "15px"}}>&nbsp;</div>

                            {!iconAllowed ? "" :
                                <div style={{display: "flex", alignItems: "center", paddingTop: "3px"}}>

                                    <div style={{width: "20px"}}></div>

                                    <div style={{display: "flex", alignItems: "center"}}>
                                        {this.iconPosition(this.props.displayAsLikert, this.props.isLikertAnswer, iconInitObj.iconPosition, index)}
                                    </div>

                                    <div style={{width: "20px"}}></div>

                                    {(iconInitObj.iconPosition === icon.POSITION_NO || iconInitObj.iconPosition === icon.POSITION_NONE || iconInitObj.iconPosition === icon.ICON_NO) ?
                                        <div style={{width: "25%"}}></div>
                                        :
                                        <React.Fragment>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <ArgoSelectWithRenderValueHook
                                                    fileName="AnswerEditorDetails"
                                                    fieldName="iconImageValue"
                                                    index={index}
                                                    label="Image"
                                                    defaultValue={iconInitObj.iconImageValue}
                                                    disabled={iconInitObj.iconPosition === icon.POSITION_ICON}
                                                    onChange={this.onSelectChange}
                                                    width="200px"
                                                    menuItems={icon.iconImagesMenuItems}
                                                    // disabled={this.props.groupTemplateMode || this.props.displayAsLikert}
                                                    paddingBottom={iconInitObj.iconImageValue === "2" ? "22px" : "9px"}
                                                />
                                            </div>

                                            <div style={{width: "20px"}}></div>

                                            {this.props.showCustomColor ?
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    paddingBottom: "5px"
                                                }}>
                                                    <ArgoColorInput mode="title"
                                                                    colorLabel={"Selected Icon Color"}
                                                                    fieldName={"iconColorObj"}
                                                                    initToObj={iconInitObj.iconColorObj}
                                                                    opacityFlag={true}
                                                                    largeHexEntry={false}
                                                                    setColor={this.setIconColorObj.bind(this, index)}
                                                                    index={index}
                                                                    height={'17px'}
                                                                    defaultColorIndex={portalShared.COLOR_DODGER_BLUE_INDEX}
                                                                    defaultColorEntry={portalShared.COLOR_DODGER_BLUE_HEX}
                                                    />
                                                </div> : ""
                                            }


                                        </React.Fragment>
                                    }

                                </div>
                            }

                        </div>

                    </div>

                    {answerOption.metadata.hasOwnProperty(portalShared.USE_LIKERT_RESPONSIVE_LABEL) ?
                        <div>
                            <ArgoTextFieldHook
                                fileName="AnswerEditorDetails"
                                fieldName="responsiveLabel"
                                index={index}
                                label="Responsive Label"
                                value={answerOption.metadata[portalShared.USE_LIKERT_RESPONSIVE_LABEL]}
                                maxLength={40}
                                multiline={true}
                                onChange={this.onResponsiveLabelChange}
                                width="300px"
                                divContainerHeight="52px"
                            />

                            <div style={{width: "20px"}}>&nbsp;</div>
                        </div> : ""
                    }


                    <div id={"AnswerEditorDetails-action-showgroup-metadata-sections-container-div-index-" + index}>

                        <div id={"AnswerEditorDetails-action-container-div-index-" + index}>
                            <ArgoSectionOpenCloseHook
                                fileName="AnswerEditorDetails"
                                fieldName="sectionActionsOpen"
                                sectionTitle={"Actions: (" + answerOption.importActionKeys.length + ")"}
                                onClick={this.onClickToggleSectionOpenClose}
                                open={this.state.sectionActionsOpen}
                            />

                            {this.state.sectionActionsOpen ? this.showSectionActions(index) : <div></div>}
                        </div>

                        <div id={"AnswerEditorDetails-showgroups-container-div-index-" + index}>
                            <ArgoSectionOpenCloseHook
                                fileName="AnswerEditorDetails"
                                fieldName="sectionShowGroupsOpen"
                                sectionTitle={"Show Group: (" + (answerOption.importGroupKeys.length ? "Yes" : "No") + ")"}
                                onClick={this.onClickToggleSectionOpenClose}
                                open={this.state.sectionShowGroupsOpen}
                            />

                            {this.state.sectionShowGroupsOpen ? this.showSectionShowGroups(index) : <div></div>}
                        </div>

                        <div id={"AnswerEditorDetails-metadata-container-div-index-" + index}>
                            <ArgoMetadataEditor
                                entityType={metadataTemplateTypes.AnswerTemplate}
                                template={answerOption}
                                depth={this.props.questionDepth}
                                onChangeCallback={this.handleAnswerMetadataChange}
                                metadata={answerOption.metadata}
                                errorText={this.state.metadataErrorText}
                                setMetadataErrorText={this.setMetadataErrorText}
                            />
                        </div>
                    </div>

                </div>

            </div> : ""
        );
    }
}

AnswerEditorDetails.defaultProps = {
    isLikertAnswer: false,
    showCustomColor: true,
    groupTemplateMode: false
};

AnswerEditorDetails.propTypes = {
    index: PropTypes.number,
    answerOption: PropTypes.object,
    questionDepth: PropTypes.number,
    parentSetStateCallback: PropTypes.func,  // Cound QuestionEditor or GroupEditor
    answerEditorSetStateCallback: PropTypes.func,
    groupTemplateMode: PropTypes.bool,
    isLikertAnswer: PropTypes.bool,
    displayAsLikert: PropTypes.bool,
    showCustomColor: PropTypes.bool,
    setMetadataErrorText: PropTypes.func,
    templateKey: PropTypes.string,
    hasAnswers: PropTypes.bool,
    template: PropTypes.object,
    answerOptionTemplates: PropTypes.array
};

AnswerEditorDetails.state = {
    groupTemplateSearch: ""
};

function mapStateToProps(state, props) {

    let isGroupLikertType = (props.groupTemplateMode && props.isLikertAnswer);

    return {
        //answerOption: isGroupLikertType ? state.groupEditor.template.answerOptionTemplates[props.index] : state.questionEditor[props.questionDepth].answerOptionTemplates[props.index],
        //answerOptionLength: props.groupEditor.template.answerOptionTemplates.length : state.questionEditor[props.questionDepth].answerOptionTemplates.length,
        answerOptionLength: props.answerOptionTemplates.length,
        //answerOptionTemplates: isGroupLikertType ? state.groupEditor.template.answerOptionTemplates : state.questionEditor[props.questionDepth].answerOptionTemplates,
        actionTypeList: state.actionTypeList,
        groupTemplateList: state.groupTemplateList,
        groupTemplate: state.groupTemplate,
        groupTemplateCount: Object.keys(state.groupTemplate).length,
        actionTemplateList: state.actionTemplateList,
        actionTemplate: state.actionTemplate,
        questionTemplate: isGroupLikertType ? null : state.questionEditor[props.questionDepth].template,
        metadataDefinition: state.metadataDefinition[metadataTemplateTypes.AnswerTemplate],
        answerIndex: state.showGroup.answerIndex,
        newShowGroupAdded: state.showGroup.newShowGroupAdded
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateAnswer,
        removeAnswer,
        reorderAnswer,
        getTemplateList,
        getActionTemplate,
        getGroupTemplate,
        getActionTypesList,
        getTemplateListCurrent,
        getTemplateListForSelectFilter,
        setShowGroup,
        clearShowGroup
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerEditorDetails);

import * as portalShared from "../shared/metadataConstantsAndUtilities"


export function metadataExists(templateObj, metadataName, prefixStr = "", custom = false) {

    let metadataExists = false;

    if (templateObj !== null && templateObj !== undefined) {

        if (templateObj.metadata !== undefined && templateObj.metadata.metadataItems !== undefined && templateObj.metadata.metadataItems.hasOwnProperty(metadataName)) {
            if (prefixStr !== "") {

                // This check is for when specific overrides in the metadata value like "src=" for displaying an image inplace of a string
                if (templateObj.metadata.metadataItems[metadataName].substring(0,prefixStr.length) === prefixStr) {
                    metadataExists = true;
                }
            }
            else {
                metadataExists = true;
            }
        }
        else if (templateObj.metadata !== undefined && templateObj.metadata.hasOwnProperty(metadataName)) {
            if (prefixStr !== "") {

                // This check is for when specific overrides in the metadata value like "src=" for displaying an image inplace of a string
                if (templateObj.metadata[metadataName].substring(0, prefixStr.length) === prefixStr) {
                    metadataExists = true;
                }
            }
            else {
                metadataExists = true;
            }
        }

    }

    // Check for a UI created metadata
    if (!custom) {
        let returnObj = customMetadataValue(templateObj, metadataName);
        metadataExists = returnObj.exists ? true : metadataExists;
    }

    return metadataExists;
}

export function metadataExistsObj(templateObj, metadataName) {

    let tempMetadata = {};
    let exists = false;

    if (templateObj !== null && templateObj !== undefined) {

        if (templateObj.metadata !== undefined) {

            // NOTE: Metadata JSON structure for an instance is different then for a template. instances have instanceObj.metadata.metadataItems;
            if (templateObj.metadata.metadataItems !== undefined) {
                tempMetadata = templateObj.metadata.metadataItems;
            } else {
                tempMetadata = templateObj.metadata;
            }

            if (tempMetadata.hasOwnProperty(metadataName)) {
                exists = true;
            }
        }
    }

    return exists;
}

export function metadataExistsInObj(templateObj, metadataName, propName, propValue) {

    let metadataExists = false;

    // Does template exists
    if (templateObj !== null && templateObj !== undefined) {

        if (templateObj.metadata !== undefined) { // Does template have metadata
            let template;

            // Is template an instance or template
            if (templateObj.metadata.metadataItems !== undefined) {
                template = templateObj.metadata.metadataItems;
            } else {
                template = templateObj.metadata;
            }
            if (template.hasOwnProperty(metadataName)) {
                let metadataObj = template[metadataName];
                metadataExists = metadataObj[propName] === propValue;
            }

        }
    }

    return metadataExists;
}


export function metadataValue(templateObj, metadataName, prefixStr = "", defaultValue = "", custom = false) {

    let metadataValue = defaultValue;

    if (templateObj !== null && templateObj !== undefined) {

        if (templateObj.metadata !== undefined) {

            let tempMetadata;

            // NOTE: Metadata JSON structure for an instance is different then for a template. instances have instanceObj.metadata.metadataItems;
            if (templateObj.metadata.metadataItems !== undefined) {
                tempMetadata = templateObj.metadata.metadataItems;
            } else {
                tempMetadata = templateObj.metadata;
            }

            if (tempMetadata.hasOwnProperty(metadataName)) {
                // If there is a prefix string then parse it out
                if (prefixStr !== "") {
                    metadataValue = tempMetadata[metadataName].substring(0, prefixStr.length);
                }
                else {
                    metadataValue = tempMetadata[metadataName];
                }
            }
        }
    }

    // Check for a UI created metadata
    if (!custom) {
        let returnObj = customMetadataValue(templateObj, metadataName);
        if (returnObj.exists) {
            metadataValue = returnObj.value;
        }
    }

    return metadataValue;
}


export function metadataValueObj(templateObj, metadataName) {

    let tempMetadata = {};

    if (templateObj !== null && templateObj !== undefined) {

        if (templateObj.metadata !== undefined) {

            // NOTE: Metadata JSON structure for an instance is different then for a template. instances have instanceObj.metadata.metadataItems;
            if (templateObj.metadata.metadataItems !== undefined) {
                tempMetadata = templateObj.metadata.metadataItems;
            } else {
                tempMetadata = templateObj.metadata;
            }

            if (tempMetadata.hasOwnProperty(metadataName)) {
                if (metadataName === portalShared.QNR_START_PAGE_SCRIPT || metadataName === portalShared.QNR_CLOSE_PAGE_SCRIPT) {
                    tempMetadata = tempMetadata[metadataName];
                } else {
                    tempMetadata = JSON.parse(tempMetadata[metadataName]);
                }
            }
        }
    }

    return tempMetadata;
}

export function metadataValueStr(templateObj, metadataName) {

    let tempMetadata = {};

    if (templateObj !== null && templateObj !== undefined) {

        if (templateObj.metadata !== undefined) {

            // NOTE: Metadata JSON structure for an instance is different then for a template. instances have instanceObj.metadata.metadataItems;
            if (templateObj.metadata.metadataItems !== undefined) {
                tempMetadata = templateObj.metadata.metadataItems;
            } else {
                tempMetadata = templateObj.metadata;
            }

            if (tempMetadata.hasOwnProperty(metadataName)) {
                tempMetadata = tempMetadata[metadataName];
            }
        }
    }

    return tempMetadata;
}


export function metadataValueObjPropertyValue(templateObj, metadataName, property, defaultValue) {

    let tempMetadata = {};
    let customObj = null;
    let propertyValue = null;

    if (templateObj !== null && templateObj !== undefined) {

        if (templateObj.metadata !== undefined) {

            // NOTE: Metadata JSON structure for an instance is different then for a template. instances have instanceObj.metadata.metadataItems;
            if (templateObj.metadata.metadataItems !== undefined) {
                tempMetadata = templateObj.metadata.metadataItems;
            } else {
                tempMetadata = templateObj.metadata;
            }

            if (tempMetadata.hasOwnProperty(metadataName)) {
                customObj = JSON.parse(tempMetadata[metadataName]);

                let propertyNameArray = property.split(".");
                switch(propertyNameArray.length) {
                    case 1:
                        propertyValue = customObj[propertyNameArray[0]];
                        break;
                    case 2:
                        propertyValue = customObj[propertyNameArray[0]][propertyNameArray[1]];
                        break;
                    case 3:
                        propertyValue = customObj[propertyNameArray[0]][propertyNameArray[1]][propertyNameArray[2]];
                        break;
                    // no default
                }
            }
        }
    }

    if (propertyValue === null) {
        propertyValue = defaultValue;
    }

    return propertyValue;
}


export function customMetadataValue(templateObj, metadataName) {
// QQQQQ
    let returnObj = {"exists": false, "tag": "", "value": ""};

    switch (metadataName) {

        // ---------------------------------------------------------------------------------------------- Questionnaires

        case portalShared.CUSTOM_QNR_START_PAGE_OBJ:

            checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataName, portalShared.CUSTOM_QNR_START_PAGE_OBJ);
            break;

        case portalShared.CUSTOM_DDO_QUE_FORMAT:

            checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataName, portalShared.CUSTOM_DDO_QUE_FORMAT);
            break;

        // case portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ:
        //     // QQQQQ NEW CODE HERE
        //     // QQQQQ
        //     break;

        // case portalShared.FORMAT_FULL_PAGE:
        // case portalShared.FORMAT_ONE_QUESTION_PER_PAGE:
        case portalShared.RESTART_QUESTION_NUMBERS_EACH_PAGE:
        case portalShared.SHOW_QUESTION_NUMBERS:
        case portalShared.SUBNUMBER_LIKERT_QUESTIONS:
        case portalShared.SUBNUMBER_SHOWGROUP_QUESTIONS:

            checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataName, portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ);
            break;


        // ---------------------------------------------------------------------------------------------------- Category



        // ------------------------------------------------------------------------------------------------------ Titles
        case portalShared.CUSTOM_DDO_NAME:
            checkMetadataTitleObject(templateObj, portalShared.CUSTOM_DDO_NAME, returnObj);
            break;
        case portalShared.CUSTOM_DDO_DESC:
            checkMetadataTitleObject(templateObj, portalShared.CUSTOM_DDO_DESC, returnObj);
            break;


        // -------------------------------------------------------------------------------- Group TEMP FIX FOR NEW OBJ ADDED 6/24

        case portalShared.CUSTOM_DDO_GRP_FORMAT:
            returnObj = {exists: true, tag: portalShared.CUSTOM_DDO_GRP_FORMAT, value: "{}"};
            break;

        // -------------------------------------------------------------------------------- Question Type: Free Text


        // case portalShared.DISPLAY_DATE_RANGE_AS_SIDE_BY_SIDE:
        // case portalShared.DISPLAY_DATE_RANGE_AS_ONE_PER_ROW:
        //
        //     checkMetadataQuestionTextValueObject(templateObj, returnObj, metadataName);
        //     break;

        // case portalShared.DISPLAY_CHECKBOXES_AS_OUTLINED:
        //
        //     checkMetadataQuestionCheckboxValueObject(templateObj, returnObj, metadataName);
        //     break;

        // case portalShared.SELECTED_COLOR_BORDER:
        // case portalShared.SELECTED_COLOR_BACKGROUND:
        // case portalShared.SELECTED_COLOR_CONTROL:
        // case portalShared.SELECTED_COLOR_TEXT:
        // case portalShared.UNSELECTED_COLOR_BORDER:
        // case portalShared.UNSELECTED_COLOR_BACKGROUND:
        // case portalShared.UNSELECTED_COLOR_CONTROL:
        // case portalShared.UNSELECTED_COLOR_TEXT:
        //
        //     checkMetadataQuestionOutlinedValueObject(templateObj, returnObj, metadataName);
        //     break;

        // no default
    }

    return returnObj;
}


export function checkMetadataTitleObject(templateObj, metadataTag, returnObj) {

    returnObj.tag = metadataTag;
    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        returnObj.exists = true;
        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);

        if (returnObj.value !== "") {
            if (returnObj.value !== "hide") {
                returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);
            }
        }
    }
}


export function checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataTag, customTag) {
// QQQQQ
    returnObj.tag = customTag;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {

            // -------------------------------------------------------------------------------------- Application Header

            // case portalShared.APPBAR:
            //     tempExists = true;
            //     tempValue = "";
            //     break;
            // case portalShared.HEADER_APPBAR_TITLE_LEFT:
            //     tempExists = returnObj.value.textLeftType === "text" && returnObj.value.textLeft !== ""
            //     tempValue = returnObj.value.textLeftFormat === "default" ? returnObj.value.textLeft : returnObj.value.textLeftCustomObj;
            //     if (returnObj.value.textLeftFormat === "custom") {
            //         tempValue.title = returnObj.value.textLeft;
            //     }
            //     break;
            // case portalShared.HEADER_APPBAR_TITLE_CENTER:
            //     tempExists = returnObj.value.textCenterType === "text" && returnObj.value.textCenter !== ""
            //     tempValue = returnObj.value.textCenterFormat === "default" ? returnObj.value.textCenter : returnObj.value.textCenterCustomObj;
            //     if (returnObj.value.textCenterFormat === "custom") {
            //         tempValue.title = returnObj.value.textCenter;
            //     }
            //     break;
            // case portalShared.HEADER_APPBAR_TITLE_RIGHT:
            //     tempExists = returnObj.value.textRightType === "text" && returnObj.value.textRight !== ""
            //     tempValue = returnObj.value.textRightFormat === "default" ? returnObj.value.textRight : returnObj.value.textRightCustomObj;
            //     if (returnObj.value.textRightFormat === "custom") {
            //         tempValue.title = returnObj.value.textRight;
            //     }
            //     break;
            // case APPBAR_IMAGE_LEFT:
            //     tempExists = returnObj.value.textLeftType === "image";
            //     tempValue = "";
            //     break;
            // case APPBAR_IMAGE_CENTER:
            //     tempExists = returnObj.value.textCenterType === "image";
            //     tempValue = "";
            //     break;
            // case APPBAR_IMAGE_RIGHT:
            //     tempExists = returnObj.value.textRightType === "image";
            //     tempValue = "";
            //     break;

            // ---------------------------------------------------------------------------------------------- Start Page

            case portalShared.CUSTOM_QNR_START_PAGE_OBJ:
                tempExists = true;
                tempValue = "";
                break;


            // ---------------------------------------------------------------------------------------------- Main Pages

            // case portalShared.FORMAT_FULL_PAGE:
            //     tempExists = returnObj.value.pagingMode === "full";
            //     tempValue = "";
            //     break;
            // case portalShared.FORMAT_ONE_QUESTION_PER_PAGE:
            //     tempExists = returnObj.value.pagingMode === "one";
            //     tempValue = "";
            //     break;
            case portalShared.RESTART_QUESTION_NUMBERS_EACH_PAGE:
                tempExists = returnObj.value.questionNumbering === "page";
                tempValue = "";
                break;
            case portalShared.SHOW_QUESTION_NUMBERS:
                tempExists = returnObj.value.questionNumbering === "all" || returnObj.value.questionNumbering === "page";
                tempValue = returnObj.value.questionNums !== undefined ? returnObj.value.questionNums.substring(1) : "";
                break;
            case portalShared.SUBNUMBER_LIKERT_QUESTIONS:
                tempExists = returnObj.value.questionNumbering === "all" || returnObj.value.questionNumbering === "page";
                tempValue = returnObj.value.questionNumsLikert;
                break;
            case portalShared.SUBNUMBER_SHOWGROUP_QUESTIONS:
                tempExists = returnObj.value.questionNumbering === "all" || returnObj.value.questionNumbering === "page";
                tempValue = returnObj.value.questionNumsShowGroup;
                break;
                // CUSTOM_QNR_MAIN_PAGES_OBJ
            // case portalShared.PAGE_HEADER:
            //     tempExists = (returnObj.value.pageHeader !== "no" &&
            //         (returnObj.value.pageHeaderLeftText !== "" ||
            //             returnObj.value.pageHeaderCenterText !== "" ||
            //             returnObj.value.pageHeaderRightText !== ""))
            //     tempValue = tempExists ? returnObj.value : "";
            //     break;
            // case portalShared.PAGE_HEADER_LEFT:
            //     tempExists = (returnObj.value.pageHeader !== "no" && returnObj.value.pageHeaderLeftText !== "");
            //     tempValue = returnObj.value.pageHeaderLeftTextFormat === "default" ? returnObj.value.pageHeaderLeftText : returnObj.value.pageHeaderLeftTextCustomObj;
            //     if (returnObj.value.pageHeaderLeftTextFormat === "custom") {
            //         tempValue.title = returnObj.value.pageHeaderLeftText;
            //     }
            //     break;
            // case portalShared.PAGE_HEADER_CENTER:
            //     tempExists = (returnObj.value.pageHeader !== "no" && returnObj.value.pageHeaderCenterText !== "");
            //     tempValue = returnObj.value.pageHeaderCenterTextFormat === "default" ? returnObj.value.pageHeaderCenterText : returnObj.value.pageHeaderCenterTextCustomObj;
            //     if (returnObj.value.pageHeaderCenterTextFormat === "custom") {
            //         tempValue.title = returnObj.value.pageHeaderCenterText;
            //     }
            //     break;
            // case portalShared.PAGE_HEADER_RIGHT:
            //     tempExists = (returnObj.value.pageHeader !== "no" && returnObj.value.pageHeaderRightText !== "");
            //     tempValue = returnObj.value.pageHeaderRightTextFormat === "default" ? returnObj.value.pageHeaderRightText : returnObj.value.pageHeaderRightTextCustomObj;
            //     if (returnObj.value.pageHeaderRightTextFormat === "custom") {
            //         tempValue.title = returnObj.value.pageHeaderRightText;
            //     }
            //     break;
            // case portalShared.PAGE_FOOTER1:
            //     tempExists = (returnObj.value.pageFooter1 !== "no" &&
            //         (returnObj.value.pageFooter1LeftText !== "" ||
            //             returnObj.value.pageFooter1CenterText !== "" ||
            //             returnObj.value.pageFooter1RightText !== ""))
            //     tempValue = tempExists ? returnObj.value : "";
            //     break;
            // case portalShared.PAGE_FOOTER1_LEFT:
            //     tempExists = (returnObj.value.pageFooter1 !== "no" && returnObj.value.pageFooter1LeftText !== "");
            //     tempValue = returnObj.value.pageFooter1LeftTextFormat === "default" ? returnObj.value.pageFooter1LeftText : returnObj.value.pageFooter1LeftTextCustomObj;
            //     if (returnObj.value.pageFooter1LeftTextFormat === "custom") {
            //         tempValue.title = returnObj.value.pageFooter1LeftText;
            //     }
            //     break;
            // case portalShared.PAGE_FOOTER1_CENTER:
            //     tempExists = (returnObj.value.pageFooter1 !== "no" && returnObj.value.pageFooter1CenterText !== "");
            //     tempValue = returnObj.value.pageFooter1CenterTextFormat === "default" ? returnObj.value.pageFooter1CenterText : returnObj.value.pageFooter1CenterTextCustomObj;
            //     if (returnObj.value.pageFooter1CenterTextFormat === "custom") {
            //         tempValue.title = returnObj.value.pageFooter1CenterText;
            //     }
            //     break;
            // case portalShared.PAGE_FOOTER1_RIGHT:
            //     tempExists = (returnObj.value.pageFooter1 !== "no" && returnObj.value.pageFooter1RightText !== "");
            //     tempValue = returnObj.value.pageFooter1RightTextFormat === "default" ? returnObj.value.pageFooter1RightText : returnObj.value.pageFooter1RightTextCustomObj;
            //     if (returnObj.value.pageFooter1RightTextFormat === "custom") {
            //         tempValue.title = returnObj.value.pageFooter1RightText;
            //     }
            //     break;
            // case portalShared.PAGE_FOOTER2:
            //     tempExists = (returnObj.value.pageFooter2 !== "no" &&
            //         (returnObj.value.pageFooter2LeftText !== "" ||
            //             returnObj.value.pageFooter2CenterText !== "" ||
            //             returnObj.value.pageFooter2RightText !== ""))
            //     tempValue = tempExists ? returnObj.value : "";
            //     break;
            // case portalShared.PAGE_FOOTER2_LEFT:
            //     tempExists = (returnObj.value.pageFooter2 !== "no" && returnObj.value.pageFooter2LeftText !== "");
            //     tempValue = returnObj.value.pageFooter2LeftTextFormat === "default" ? returnObj.value.pageFooter2LeftText : returnObj.value.pageFooter2LeftTextCustomObj;
            //     if (returnObj.value.pageFooter2LeftTextFormat === "custom") {
            //         tempValue.title = returnObj.value.pageFooter2LeftText;
            //     }
            //     break;
            // case portalShared.PAGE_FOOTER2_CENTER:
            //     tempExists = (returnObj.value.pageFooter2 !== "no" && returnObj.value.pageFooter2CenterText !== "");
            //     tempValue = returnObj.value.pageFooter2CenterTextFormat === "default" ? returnObj.value.pageFooter2CenterText : returnObj.value.pageFooter2CenterTextCustomObj;
            //     if (returnObj.value.pageFooter2CenterTextFormat === "custom") {
            //         tempValue.title = returnObj.value.pageFooter2CenterText;
            //     }
            //     break;
            // case portalShared.PAGE_FOOTER2_RIGHT:
            //     tempExists = (returnObj.value.pageFooter2 !== "no" && returnObj.value.pageFooter2RightText !== "");
            //     tempValue = returnObj.value.pageFooter2RightTextFormat === "default" ? returnObj.value.pageFooter2RightText : returnObj.value.pageFooter2RightTextCustomObj;
            //     if (returnObj.value.pageFooter2RightTextFormat === "custom") {
            //         tempValue.title = returnObj.value.pageFooter2RightText;
            //     }
            //     break;

            // ---------------------------------------------------------------------------------------------- Close Page

            case portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ:
                tempExists = true;
                tempValue = "";
                break;
            // case portalShared.CLOSE_PAGE_NO_GREEN_CHECK_ICON:
            //     tempExists = returnObj.value.image === "none";
            //     tempValue = "";
            //     break;
            // case portalShared.CLOSE_PAGE_NO_DEFAULT_TEXT:
            //     tempExists = returnObj.value.text === "none";
            //     tempValue = "";
            //     break;

            // -------------------------------------------------------------------------------------------------- Alerts

            // case portalShared.ALERT_TEXT_TYPE:
            //     tempExists = true;
            //     tempValue = "";
            //     break;
            // case portalShared.ALERT_TEXT_INVALID_KEY:
            //     tempExists = returnObj.value.text === "none";
            //     tempValue = returnObj.value.invalidKeyText;
            //     break;
            // case portalShared.ALERT_TEXT_COMPLEATED:
            //     tempExists = returnObj.value.text === "none";
            //     tempValue = returnObj.value.compleatedText;
            //     break;
            // case portalShared.ALERT_TEXT_DEACTIVATED:
            //     tempExists = returnObj.value.text === "none";
            //     tempValue = returnObj.value.deactivatedText;
            //     break;
            // case portalShared.ALERT_TEXT_EXPIRED:
            //     tempExists = returnObj.value.text === "none";
            //     tempValue = returnObj.value.expiredText;
            //     break;
            // case portalShared.ALERT_TEXT_CANCELED:
            //     tempExists = returnObj.value.text === "none";
            //     tempValue = returnObj.value.canceledText;
            //     break;
            // case portalShared.CANCEL_TEXT_OVERRIDE:
            //     tempExists = returnObj.value.text === "none";
            //     tempValue = "";
            //     break;

            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;
    }
    else {

        // if does not exist may be a default
        // switch (metadataTag) {
        //
        //     case portalShared.FORMAT_FULL_PAGE:
        //         tempExists = true;
        //         tempValue = "";
        //         break;
        //
        //     // no default
        // }


        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;
    }
}


export function checkMetadataCategoryValueObject(templateObj, returnObj, metadataTag, customTag) {

    returnObj.tag = customTag;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {

            // case portalShared.CATEGORY_NEW_PAGE:
            //     tempExists = (returnObj.value.portalPageBreak);
            //     tempValue = "";
            //     break;
            // case portalShared.CATEGORY_TITLE_BAR:
            //     tempExists = (returnObj.value.titlebar);
            //     tempValue = "";
            //     break;
            // case portalShared.CATEGORY_TITLE_BAR_LEFT:
            // case portalShared.CATEGORY_TITLE_BAR_CENTER:
            // case portalShared.CATEGORY_TITLE_BAR_RIGHT:
            //     tempExists = (returnObj.value.title !== ""); ///YYYYYY
            //     tempValue = returnObj.value.title;
            //     break;

            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;
    }
}


export function checkMetadataQuestionTextValueObject(templateObj, returnObj, metadataTag) {

    returnObj.tag = portalShared.CUSTOM_DDO_QUE_FORMAT;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {

            // case portalShared.TEXT_FLOATING_LABEL:
            //     tempExists = (returnObj.value.floatingLabel !== "");
            //     tempValue = tempExists ? returnObj.value.floatingLabel : "";
            //     break;
            // case portalShared.TEXT_PLACEHOLDER:
            //     tempExists = (returnObj.value.placeHolder !== "");
            //     tempValue = tempExists ? returnObj.value.placeHolder : "";
            //     break;
            // case portalShared.TEXT_VARIANT:
            //     tempExists = (returnObj.value.style !== "");
            //     tempValue = tempExists ? returnObj.value.style : "";
            //     break;
            // case portalShared.TEXT_SIZE:
            //     tempExists = (returnObj.value.size !== "");
            //     tempValue = tempExists ? returnObj.value.size : "";
            //     break;
            // case portalShared.TEXT_WIDTH:
            //     tempExists = (returnObj.value.width !== "");
            //     tempValue = tempExists ? returnObj.value.width : "";
            //     break;
            // case portalShared.TEXT_MULTILINE:
            //     tempExists = (returnObj.value.multiline);
            //     break;
            // case portalShared.TEXT_MAXLENGTH:
            //     tempExists = (returnObj.value.max !== "");
            //     tempValue = tempExists ? returnObj.value.max : "";
            //     break;
            // case portalShared.TEXT_MAXROW:
            //     tempExists = (returnObj.value.maxRows !== "");
            //     tempValue = tempExists ? returnObj.value.maxRows : "";
            //     break;
            // case portalShared.TEXT_LABEL_ABOVE:
            //     tempExists = (returnObj.value.labelPosition === "above");
            //     tempValue = tempExists ? returnObj.value.label : "";
            //     break;
            // case portalShared.TEXT_LABEL_BELOW:
            //     tempExists = (returnObj.value.labelPosition === "below");
            //     tempValue = tempExists ? returnObj.value.label : "";
            //     break;

            // ---------------------------------------------------------------------------------------------------------

            // case portalShared.DISPLAY_DATE_RANGE_AS_SIDE_BY_SIDE:
            //     tempExists = (returnObj.value.arrange === "side");
            //     tempValue = tempExists ? returnObj.value.arrange : "";
            //     break;
            // case portalShared.DISPLAY_DATE_RANGE_AS_ONE_PER_ROW:
            //     tempExists = (returnObj.value.arrange === "row");
            //     tempValue = tempExists ? returnObj.value.arrange : "";
            //     break;

            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;

    }
}


export function checkMetadataQuestionRadioValueObject(templateObj, returnObj) {

    returnObj.tag = portalShared.CUSTOM_DDO_QUE_FORMAT;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;

    }
}


export function checkMetadataQuestionCheckboxValueObject(templateObj, returnObj, metadataTag) {

    returnObj.tag = portalShared.CUSTOM_DDO_QUE_FORMAT;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {
            case portalShared.DISPLAY_CHECKBOXES_AS_OUTLINED:
                tempExists = (returnObj.value.style==="outlined");
                break;

            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;

    }
}


export function checkMetadataQuestionOutlinedValueObject(templateObj, returnObj, metadataTag) {

    returnObj.tag = portalShared.CUSTOM_DDO_QUE_FORMAT;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {
            case portalShared.SELECTED_COLOR_BORDER:
                if (returnObj.value.selectedBorder !== undefined) {
                    tempExists = (returnObj.value.selectedBorder.colorType !== "default");
                    tempValue = (returnObj.value.selectedBorder.colorType !== "select") ? returnObj.value.selectedBorder.colorEntry : returnObj.value.selectedBorder.color;
                }
                break;
            case portalShared.SELECTED_COLOR_BACKGROUND:
                if (returnObj.value.selectedBackground !== undefined) {
                    tempExists = (returnObj.value.selectedBackground.colorType !== "default");
                    tempValue = (returnObj.value.selectedBackground.colorType !== "select") ? returnObj.value.selectedBackground.colorEntry : returnObj.value.selectedBackground.color;
                }
                break;
            case portalShared.SELECTED_COLOR_CONTROL:
                if (returnObj.value.selectedControl !== undefined) {
                    tempExists = (returnObj.value.selectedControl.colorType !== "default");
                    tempValue = (returnObj.value.selectedControl.colorType !== "select") ? returnObj.value.selectedControl.colorEntry : returnObj.value.selectedControl.color;
                }
                break;
            case portalShared.SELECTED_COLOR_TEXT:
                if (returnObj.value.selectedText !== undefined) {
                    tempExists = (returnObj.value.selectedText.colorType !== "default");
                    tempValue = (returnObj.value.selectedText.colorType !== "select") ? returnObj.value.selectedText.colorEntry : returnObj.value.selectedText.color;
                }
                break;
            case portalShared.UNSELECTED_COLOR_BORDER:
                if (returnObj.value.unselectedBorder !== undefined) {
                    tempExists = (returnObj.value.unselectedBorder.colorType !== "default");
                    tempValue = (returnObj.value.unselectedBorder.colorType !== "select") ? returnObj.value.unselectedBorder.colorEntry : returnObj.value.unselectedBorder.color;
                }
                break;
            case portalShared.UNSELECTED_COLOR_BACKGROUND:
                if (returnObj.value.unselectedBackground !== undefined) {
                    tempExists = (returnObj.value.unselectedBackground.colorType !== "default");
                    tempValue = (returnObj.value.unselectedBackground.colorType !== "select") ? returnObj.value.unselectedBackground.colorEntry : returnObj.value.unselectedBackground.color;
                }
                break;
            case portalShared.UNSELECTED_COLOR_CONTROL:
                if (returnObj.value.unselectedControl !== undefined) {
                    tempExists = (returnObj.value.unselectedControl.colorType !== "default");
                    tempValue = (returnObj.value.unselectedControl.colorType !== "select") ? returnObj.value.unselectedControl.colorEntry : returnObj.value.unselectedControl.color;
                }
                break;
            case portalShared.UNSELECTED_COLOR_TEXT:
                if (returnObj.value.unselectedText !== undefined) {
                    tempExists = (returnObj.value.unselectedText.colorType !== "default");
                    tempValue = (returnObj.value.unselectedText.colorType !== "select") ? returnObj.value.unselectedText.colorEntry : returnObj.value.unselectedText.color;
                }
                break;
            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;

    }
}


// tempObj = {
//     questionType: this.props.questionType,
//     displayAs: "single",        // single, likert
//     style: "radios",            // radios, ddlb, standard, clear, outlined
//     likertStyle: "radios",      // radios, buttongroup, circle, pyramid
//     circleType: "standard",     // standard, primary, alternate
//     arrange: "",                // row, side, wrap
//     state: "",
//     property: "",
//     color: "default",           // default, custom
//     selectedBackground: "",
//     selectedControl: "",
//     selectedText: "",
//     unselectedBackground: "",
//     unselectedControl: "",
//     unselectedText: ""
// };




// returnObj.tag = CUSTOM_DDO_QUE_FORMAT;
// if (metadataExists(templateObj, returnObj.tag, "", true)) {
//     returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
//     returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);
//     if (returnObj.value.questionType === "") {
//         if (returnObj.value.zzzz === "radios") {
//             returnObj.exists = true;
//         }
//     }
// }



// export const YES_NO = "YES_NO";
// export const CHECKBOX = "CHECKBOX";
// export const RADIO = "RADIO";
// export const LIKERT_CHECKBOX = "LIKERT_CHECKBOX";
// export const LIKERT_RADIO = "LIKERT_RADIO";
// export const NUMBER = "NUMBER";
// export const CURRENCY = "CURRENCY";
// export const PHONE = "PHONE";
// export const DATE = "DATE";
// export const DATE_RANGE = "DATE_RANGE";
// export const EMAIL = "EMAIL";
// export const FREE_TEXT = "FREE_TEXT";
// export const IMAGE = "IMAGE";
// export const INFO = "INFO";
// export const MAP = "MAP";
// export const ADDRESS_FULL = "ADDRESS_FULL";
// export const ZIPPOSTAL_ONLY = "ZIPPOSTAL_ONLY";
// export const STATE_ONLY = "STATE_ONLY";
// export const LIKERT = "LIKERT";

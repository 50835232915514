import * as types from "../constants/actionConstants";
import TemplateApi from "../api/TemplateApi";
import * as system from "../constants/systemConstants";
import {msalInstance} from '../authProvider';
import {STORAGE_IS_SSO} from "../portal/constants/systemConstants";


const getToken = () => {
    return localStorage.getItem(system.STORAGE_TOKEN) || sessionStorage.getItem(system.STORAGE_TOKEN);
};

const fetchToken = (userName, password, rememberMe = false) => {
    return (dispatch) => {
        dispatch({type: types.TOKEN_GET, rememberMe, username: userName});
        if (rememberMe) {
            localStorage.setItem(system.STORAGE_USERNAME, userName);
        }
        else {
            localStorage.removeItem(system.STORAGE_USERNAME);
        }
        localStorage.setItem(STORAGE_IS_SSO,"false");
        TemplateApi.getAuthorizationToken(userName, password, rememberMe).then((token) => {
            if (token[system.STORAGE_TOKEN]) {
                dispatch(setToken(token[system.STORAGE_TOKEN]));
            }
        });
    };
};


const initializeAuthorization = () => {
    let login = {
        loggedIn: false,
        username: "",
    };   
    
    login.loggedIn = !!(localStorage.getItem(system.STORAGE_TOKEN) || 
                    sessionStorage.getItem(system.STORAGE_TOKEN));

    login.username = localStorage.getItem(system.STORAGE_USERNAME) || 
    sessionStorage.getItem(system.STORAGE_USERNAME) || 
    '';
   
    return {
        type: types.TOKEN_INITIALIZE,
        payload: login
    };
};

const logout = () => {
    var m = clearToken("");
    var res = localStorage.getItem(STORAGE_IS_SSO) || 'false';
    if (res === 'true'){
        msalInstance.logoutRedirect().then(() => {
            return m;
        });
    }
    else{
        return m;
    }

};


const setToken = (token) => {
    localStorage.setItem(system.STORAGE_TOKEN, token);

    return {
        type: types.TOKEN_SET
    };
};

const clearToken = (message) => {
    localStorage.removeItem(system.STORAGE_TOKEN);

    return {
        type: types.TOKEN_CLEAR,
        message
    };
};

const rejectLogin = (message) => {
    localStorage.removeItem(system.STORAGE_TOKEN);

    return {
        type: types.TOKEN_REJECT_LOGIN,
        message
    };
};

export {
    getToken,
    fetchToken,
    setToken,
    clearToken,
    rejectLogin,
    initializeAuthorization,
    logout,
};

//TODO consider adding hooks to the local storage events to watch for logout?

import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ALLOWED_TOKEN} from "../../constants/dataConstants";
import ArgoAutocompleteHook from "./ArgoAutocompleteHook";
import ArgoSelectPortalTextDisplayType from "./ArgoSelectPortalTextDisplayType";
import ArgoTextDisplayCustom from "./ArgoTextDisplayCustom";


class ArgoTextEntryWithCustomOverride extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

    }

    componentDidMount() {

        // let test = portalShared.setCustomTextObject(this.props.templateType, TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.NAME);

    }

    componentDidUpdate(nextProps) {

    }

    render() {

        let fileName = this.props.propsObj.fileName;
        let fieldName = this.props.fieldName;

        let value = "";

        if (this.props.textEntryType.includes("user-script")) {
            if (this.props.propsObj.metadata.hasOwnProperty(fieldName)) {
                value = this.props.propsObj.metadata[fieldName];
            }
        } else {
            value = this.props.propsObj.template[fieldName];
        }

        return (

            <div id="ArgoTextEntryWithCustomOverride-container-div" style={{width: "100%"}}>

                <div id={"ArgoTextEntryWithCustomOverride-" + fileName + "-" + fieldName + "-container-div"} style={{display: "flex", flexDirection: "row", width: "100%"}}>

                    {this.props.hideText ? "" :
                        <ArgoAutocompleteHook
                            fileName={fileName}
                            fieldName={fieldName}
                            index={this.props.index}
                            label={this.props.label}
                            onChange={this.props.propsObj.onTextChange}
                            value={value}
                            menuItems={ALLOWED_TOKEN}
                            maxLength={this.props.maxlength}
                            error={this.props.errorText !== " "}
                            errorText={this.props.errorText === " " ? " " : this.props.errorText}
                            multiline={true}
                            width="96%"
                        />
                    }

                    <ArgoSelectPortalTextDisplayType
                        templateType={this.props.templateType}
                        textEntryType={this.props.textEntryType}
                        fieldName={fieldName}
                        fieldTextObjName={this.props.fieldTextObjName}
                        index={this.props.index}
                        initTo={this.props.initTo}
                        hideOnly={this.props.hideOnly}
                        hideOption={this.props.hideOption}
                        metadataPropsObj={this.props.propsObj}
                    />

                </div>

                <div id={fileName + "-script-container-div"}>
                    {(this.props.initTo === 'custom') ?
                        <ArgoTextDisplayCustom
                            templateType={this.props.templateType}
                            textEntryType={this.props.textEntryType}
                            fieldName={fieldName}
                            fieldTextObjName={this.props.fieldTextObjName}
                            initToObj={this.props.initToObj}
                            displayLabel={this.props.labelCustom}
                            metadataPropsObj={this.props.propsObj}
                        />
                        :
                        <div></div>
                    }
                </div>

                <div>&nbsp;</div>

            </div>
        );
    }
}

ArgoTextEntryWithCustomOverride.defaultProps = {
    hideText: false,
    maxlength: 255,
    errorText: " ",
    hideOption: true,
    hideOnly: false,
    index: 0
};

ArgoTextEntryWithCustomOverride.propTypes = {
    hideText: PropTypes.bool,
    templateType: PropTypes.string,
    textEntryType: PropTypes.string, // templateType and textEntryType determine the text default properties.
    fileName: PropTypes.string,
    fieldName: PropTypes.string,
    fieldTextObjName: PropTypes.string,
    errorText: PropTypes.string,
    maxlength: PropTypes.number,
    label: PropTypes.string,
    labelCustom: PropTypes.string,
    value: PropTypes.string,
    initTo: PropTypes.string,
    initToObj: PropTypes.object,
    hideOnly: PropTypes.bool,  // Categories only, if category is a titlebar then all the text entries can be only HIDDEN
    hideOption: PropTypes.bool,
    propsObj: PropTypes.object,
    index: PropTypes.number,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(null, mapDispatchToProps)(ArgoTextEntryWithCustomOverride);

import {Component} from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {getMetadataDefinitions} from "../../actions/metadataDefinitionActions";
import ArgoTextFieldHook from "./ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import ArgoAutocompleteHook from "./ArgoAutocompleteHook";
import ArgoSectionOpenCloseHook from "./ArgoSectionOpenCloseHook";
import ArgoIconButtonHook from "./ArgoIconButtonHook";
import ArgoButtonHook from "./ArgoButtonHook";
import * as metadataUtils from "../../utilities/metadata";
import * as metadataTemplateTypes from "../../constants/metadataTemplateTypes";


//const autocompleteConfig = {text: 'name', value: 'type'};

const inputTypes = {
    FREETEXT: "FREETEXT",
    BOOLEAN: "BOOLEAN",
    INTEGER: "INTEGER",
    DECIMAL: "DECIMAL",
    DATE: "DATE",
    JSON: "JSON",
    LIST: "LIST"
};


class ArgoMetadataEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sectionMetadataOpen: false,
            nameErrorTextArray: [],
            valueErrorTextArray: [],
            metadataEntries: Object.entries(props.metadata),  // Creates an array of arrays ie.. [[name1, value1],[name2, value2]...] from JSON object {name1: value1, name2, value2, ...}
            currentEntryIndex: 0,
            base64TextString: "",
            bypassComponentWillReceiveProps: false
        };
    }

    componentWillMount() {
        this.props.getMetadataDefinitions(this.props.entityType);
    }

    componentWillReceiveProps(nextProps) {

        if (this.state.bypassComponentWillReceiveProps) {
            // debugger;
        } else {

            let entries; // [...this.state.metadataEntries];
            //let metadataPropsArray = Object.getOwnPropertyNames(nextProps.metadata);
            let nameErrorTextArray;
            let valueErrorTextArray;

            //if (entries.length !== metadataPropsArray.length) {
            entries = Object.entries(nextProps.metadata); // Convert objects to Array

            // NOTE: I really don't like the sorting of the entries, so commented out for now
            // if (!this.state.sectionMetadataOpen) {
            //     //Sort alphabetically the first time metadata is loaded. Don't sort again while the user is editing.
            //     entries.sort((v1, v2) => v1[0].localeCompare(v2[0]));
            // }

            // Preset error message arrays
            nameErrorTextArray = [];
            valueErrorTextArray = [];
            for (let i = 0; i < entries.length; i++) {
                nameErrorTextArray.push(" ");
                valueErrorTextArray.push(" ");
            }
            //}

            // Store the object as an array to preserve the order.
            this.setState({metadataEntries: entries, nameErrorTextArray: nameErrorTextArray});
        }
    }

    onClickToggleSectionOpenClose = (event, fieldName) => {
        this.setState({[fieldName]: !this.state[fieldName]});
    };


    // // Handle free text entered into Autocomplete field
    // onChangeAutocomplete(field, entryIndex, searchText, dataSource, params) {
    //     console.log(this.state.metadataEntries)
    //     this.handleOnChange(field, entryIndex, null, searchText)
    //
    // }

    // // Handle item selected from Autocomplete field
    // handleNewRequest(field, entryIndex, chosenRequest, index) {
    //     this.handleOnChange(field, entryIndex, null, chosenRequest.name)
    // }
    //
    // Wrapper for handling Select Fields
    onSelectChange = (event, fieldName, menuItemText, index) => {
        this.handleOnChange(fieldName, index, event, menuItemText);
    };
    //
    // // Wrapper for handling Integer input Field
    // handleOnIntegerChange(field, entryIndex, event, value) {
    //     // Regex for whole numbers
    //     if (value.match(/^\d+$/) || value === "") {
    //         this.handleOnChange(field, entryIndex, event, value)
    //     }
    // };
    //
    // // Wrapper for handling Integer input Field
    // handleOnDecimalChange(field, entryIndex, event, value) {
    //     // Regex for decimal numbers. Allows decimal and negative sign
    //     if (value.match(/^-?\d+\.?\d*$/) || value === "") {
    //         this.handleOnChange(field, entryIndex, event, value)
    //     }
    // };


    onTextChange = (event, fieldName, index) => {

        let value = event.target.value;
        let nameErrorTextArray = this.state.nameErrorTextArray;

        // get metadata array of metadata arrays, metadataEntries is an array of arrays [["name", "value]...] converted from a list of objects name/value pairs
        let metadataEntries = this.state.metadataEntries;

        // Check to see if name field is in metadata presets ie.. Autocomplete
        if (fieldName === "name") {

            // If name has changed then delete any value entry
            if (metadataEntries[index][0] !== value) {
                metadataEntries[index][1] = "";
            }

            metadataEntries[index][0] = value; // set name, metadataEntries is an array of arrays [["name", "value]...]

            nameErrorTextArray = this.validate(metadataEntries);
        }
        else {
            // Must be Value field

            let preDefinedName  = this.getMetadataDefinition(metadataEntries[index][0]);
            let preDefinedNameType = inputTypes.FREETEXT;
            if (preDefinedName !== undefined) {
                preDefinedNameType = this.getMetadataDefinitionInputType(metadataEntries[index][0]);
            }

            if (preDefinedNameType === inputTypes.DECIMAL) {
                // Regex for decimal numbers. Allows decimal and negative sign
                if (value.match(/^-?\d+\.?\d*$/) || value === "") {
                    metadataEntries[index][1] = value;  // only update if passes validation
                }
            }
            else {
                metadataEntries[index][1] = value;  // set value, metadataEntries is an array of arrays [["name", "value]...]
            }

        }

        this.setState({nameErrorTextArray: nameErrorTextArray, metadataEntries: metadataEntries}, () => this.handleOnChange(fieldName, index, event, value));
    };


    handleOnChange = (field, index, event, value) => {

        let entry = this.state.metadataEntries[index];
        const {0: prevKey, 1: prevValue} = entry;
        let updatedState = {};

        let metadataEntries = [...this.state.metadataEntries];

        if (field === "name") {
            // Check if inputType has changed
            let newInputType  = this.getMetadataDefinitionInputType(value);
            let prevInputType = this.getMetadataDefinitionInputType(prevKey);

            if (newInputType === prevInputType) {
                // Insert updated key/value at original index
                metadataEntries[index] = [value, prevValue]
            } else {
                // Input type changed, clear value to avoid type issues
                metadataEntries[index] = [value, ""];
            }

        } else {
            metadataEntries[index] = [prevKey, value];
        }

        updatedState.metadataEntries = metadataEntries;

        // Update validation message and props
        updatedState.nameErrorTextArray = this.state.nameErrorTextArray;

        this.handleValidateAndUpdate(metadataEntries);

        this.setState(updatedState);
    }

    handleValidateAndUpdate = (metadataEntries) => {
        let nameErrorTextArray = this.validate(metadataEntries);
        this.setState({metadataEntries: metadataEntries, nameErrorTextArray: nameErrorTextArray}, () => this.setMetadataErrorText(nameErrorTextArray));
    };

    setMetadataErrorText = (nameErrorTextArray) => {
        // Had to move out of handleValidateAndUpdate because state was NOT setting nameErrorTextArray
        let joinedNameErrorText = nameErrorTextArray.filter(error => error !== " ").join(", ");
        if (this.props.setMetadataErrorText) {
            this.props.setMetadataErrorText(joinedNameErrorText);
        }
        this.hasError()
    }


    // Force setState
    hasError = () => {

        let hasError = (this.state.nameErrorTextArray.find((str) => str !== " ") !== undefined);

        if (!hasError) {
            // Map the array of entries back to an object. The property will remain at the original index
            let metadata = this.fromEntries(this.state.metadataEntries);

            let updatedTemplate = {...this.props.template};
            updatedTemplate.metadata = metadata;
            // ON CHANGE is UPDATE TEMPLATE
            // Only save on when valid to avoid issues with duplicate JS Object keys
            this.props.onChangeCallback(updatedTemplate, this.props.depth);
            //this.props.onChange(updatedTemplate, this.props.depth); // Update metadata in template
        }
    }


    /**
     * Validates Metadata key/value pairs
     * @param entries An array of key/value pairs where entry[0] contains the key and entry[1] contains the value.
     */
    validate = (entries) => {
        let hasDuplicate = false;
        let isNumber = false;
        let hasInvalidChars = false;
        let hasBlankName = false;
        let nameErrorTextArray = [];

        // Set all metadata errors to blank
        for (let i = 0; i < entries.length; i++) {
            nameErrorTextArray[i] = " ";
        }

        // Validate that all keys are distinct
        let keys = [];
        for (let i = 0; i < entries.length; i++) {
            keys.push(entries[i][0]);
        }
        let saveIndex = null;

        // Check for duplicate names
        // Start with newest entry and work backwards
        for (let i = entries.length-1; i >= 0; i--) {
            let matchIndex = keys.indexOf(entries[i][0]);
            if (matchIndex >= 0) {
                // Can't be a dup of itself
                if (matchIndex !== i) {
                    hasDuplicate = true;
                    saveIndex = i;
                }
            }
        }

        if (hasDuplicate) {
            nameErrorTextArray[saveIndex] = "Metadata property names must not be duplicates";
        }
        else {

            // Check if any keys are a number.
            // Start with newest entry and work backwards
            saveIndex = null;
            for (let i = entries.length-1; i >= 0; i--) {
                if (!isNaN(entries[i][0]) && entries[i][0].trim() !== "") {
                    isNumber = true;
                    saveIndex = i;
                    break;
                }
            }

            if (isNumber) {
                nameErrorTextArray[saveIndex] = "Metadata property names cannot be a number";
            }
            else {

                // Check if name has any invalid characters
                // Start with newest entry and work backwards
                saveIndex = null;
                for (let i = entries.length-1; i >= 0; i--) {
                    console.log(entries[i][0])
                    if (entries[i][0].match(/^([a-zA-Z0-9._-])+$/) === null &&  entries[i][0] !== "") {
                        hasInvalidChars = true;
                        saveIndex = i;
                        break;
                    }
                }

                if (hasInvalidChars) {
                    nameErrorTextArray[saveIndex] = "Metadata property name contains invalid character(s)"
                }
                else {

                    // Check for blank names
                    // Start with newest entry and work backwards
                    saveIndex = null;
                    for (let i = entries.length-1; i >= 0; i--) {
                        if (entries[i][0].trim() === "") {
                            hasBlankName = true;
                            saveIndex = i;
                            break;
                        }
                    }

                    if (hasBlankName) {
                        nameErrorTextArray[saveIndex] = "Metadata property names cannot be blank";
                    }
                }
            }
        }

        let metadataErrorText = " ";
        for (let i = 0; i < nameErrorTextArray.length; i++) {
            if (nameErrorTextArray[i] !== " ") {
                metadataErrorText = nameErrorTextArray[i];
            }
        }

        this.setState({nameErrorTextArray: nameErrorTextArray});

        //this.props.setMetadataErrorText(metadataErrorText); // Send error to editor page to display ZZZZZ

        return nameErrorTextArray;
    };

    onImageUploadChange = (e, entryIndex) => {
        let file = e.target.files[0];

        if (file) {
            if (file.size > 125000) {
                alert("File size too large");
                e.target.value = "";
            } else {
                const reader = new FileReader();
                reader.onload = this._handleReaderLoader.bind(this);

                reader.readAsBinaryString(file);

                this.setState({
                    currentEntryIndex: entryIndex
                });
            }
        }
    };

    _handleReaderLoader = (readerEvt) => {
        let binaryString = readerEvt.target.result;

        let base64String = btoa(binaryString);

        this.setState({
            base64TextString: base64String
        });

        this.handleOnChange("value", this.state.currentEntryIndex, null, base64String);
    };

    // handleOnBlur = (e) => {
    //     let errorMessage = this.handleValidateAndUpdate(this.state.metadataEntries);
    //     this.setState({nameErrorTextArray: errorMessage})
    // };

    addProperty = () => {
        // Add empty value to the end of the list
        let metadataEntries = [...this.state.metadataEntries, ["", ""]]; // metadata is stored in an array name and value, add a new empty entry
        this.setState({bypassComponentWillReceiveProps: true}, () => this.handleValidateAndUpdate(metadataEntries));
    };


    onClickRemoveMetadata = (event, fieldName, index) => {

        let filteredEntries = this.state.metadataEntries.filter((entry, entryIndex) => entryIndex !== index);

        // Rerun validation since this may have fixed the cause of the error message
        this.handleValidateAndUpdate(filteredEntries);
        this.setState({metadataEntries: filteredEntries});
    };

    /**
     * Equivalent of Object.fromEntries(), which is not yet standard.
     * This method takes a list of key-value pairs and returns a new object whose properties are given by those entries.
     * This is the inverse of Object.entries().
     */
    fromEntries = (entries) => {
        return [...entries].reduce((obj, {0: key, 1: val}) => Object.assign(obj, {[key]: val}), {});
    };

    getMetadataDefinitionInputType = (name) => {
        let metadataDefinition = this.getMetadataDefinition(name);
        return metadataDefinition !== undefined ? metadataDefinition.type : inputTypes.FREETEXT;  // get type if from metadata prefefined list
    };

    getMetadataDefinition = (name) => {
        // Search thru metadata presets to see if name matches, if not must be manually or progmatically entered and return undefined
        return this.props.metadataDefinition.find(def => def.name === name);
    };


    // filterMetadataAutocomplete = (metadata, searchText, key) => {
    //     // Check if this metadata key is already being used
    //     let duplicateKey = Object.keys(metadata).indexOf(key) !== -1;
    //
    //     // Match search string
    //     return !duplicateKey && key.indexOf(searchText) !== -1;
    // };




    // Used by date entry fields
    onChangeDateField(fieldName, index, value) {

        // get metadata array of metadata arrays, metadataEntries is an array of arrays [["name", "value]...] converted from a list of objects name/value pairs
        let metadataEntries = this.state.metadataEntries;

        let formattedValue = dayjs(value).format("YYYY-MM-DD");

        if (index !== undefined) {
            metadataEntries[index][1] = formattedValue;  // set value, metadataEntries is an array of arrays [["name", "value]...]
        }
        else {
            metadataEntries[metadataEntries.length-1][1] = formattedValue;  // NOTE: metadata entries ["",""] have already been added to array
        }

        this.setState({metadataEntries: metadataEntries});
    }


    // -----------------------------------------------------------------------------------------------------------------
    // Dynamically render the correct type of input field based on the Metadata definition for this key
    // Name = metadata Name, value =
    //
    renderValueField = (name, value, index) => {

        // Don't display ICON and JSON related metadata, user MUST use UI DDLB to set and configure.

        let html;

        let type = inputTypes.FREETEXT;  // Assume FREETEXT
        let listValues = [];  // Assume empty array

        let metadataDefinition = this.getMetadataDefinition(name);  // Search thru preset metadata for match

        // If not found in presets it must be a manually or progmatically entered. So treat as freetext  ie.. could be stringified JSON, Icon or Image
        if (metadataDefinition !== undefined) {
            type = metadataDefinition.type;
            listValues = metadataDefinition.listValues;
        }

        // It appears the predefined list of available metadta presets varies by template type so the check
        if (name.startsWith("image-") && ((this.props.entityType === metadataTemplateTypes.CategoryTemplate) || (this.props.entityType === metadataTemplateTypes.QuestionnaireTemplate))) {

            html = <div id="ArgoMetadataEditor-MetadataValue-container-div" style={{display: "flex", width: "350px"}}>
                <div style={{alignSelf: "center"}}>
                    <div style={{color: "red"}}>
                        File must be smaller than 125kB
                    </div>
                    <form onChange={(e) => this.onImageUploadChange(e, index)}>
                        <input
                            type="file"
                            name="image"
                            id="file"
                            accept=".jpeg, .png, .jpg"
                        />
                    </form>
                    <ArgoTextFieldHook
                        fileName={"ArgoMetadataEditor"}
                        fieldName="value"
                        label="Value"
                        value={this.props.template.value}
                        width="300px"
                        multiLine={true}
                        onChange={this.onTextChange}
                        index={this.props.questionNumber}
                    />
                    {value ?
                        <div style={{width: '40%', textAlign: "center", alignSelf: "center"}}>
                            <img src={`data:image/png;base64,${value}`} alt="" style={{verticalAlign: 'middle', height: '60px'}}/>
                        </div> : null
                    }
                </div>
            </div>
        }
        else if (type === inputTypes.DATE) {

            html = <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    id="ArgoMetadataEditor-date"
                    fullWidth
                    value={value}
                    onChange={e=>this.onChangeDateField("value", index, e, e["$d"])}
                    label="Value"
                    container="inline"
                    autoFocus
                    renderInput={(params) => <TextField {...params}  variant="standard" error={false} sx={{width: "350px", marginBottom: "22px"}}/>}
                />
            </LocalizationProvider>
        }
        else if ((type === inputTypes.BOOLEAN) || (type === inputTypes.LIST))  {

            // Note: "None" has been added to key metric category on screen but not in metadata config, added HIDE to portalShared.KEY_METRIC_CATEGORY_QUESTION metadata so can't be changed using metadata
            // Change to boolean to create a list so it works like key metric categories list of strings
            if (type === inputTypes.BOOLEAN) {
                listValues = ["false", "true"];
            }

            let listIndex = listValues.indexOf(value);

            html = <ArgoTextFieldSelectHook
                fileName="ArgoMetadataEditor"
                fieldName={(type === inputTypes.BOOLEAN) ? "type-boolean" : "type-list"}
                label="Value"
                value={listIndex}
                width="350px"
                onChange={this.onSelectChange}
                menuItems={listValues}
                index={index}
            />
        }
        else {

            let placeholder = (type === inputTypes.INTEGER) ? "Enter an integer." : (type === inputTypes.INTEGER ? "Enter a decimal." : "");

            html = <ArgoTextFieldHook
                fileName={"ArgoMetadataEditor-" + type.toLowerCase()}
                fieldName="value"
                label="Value"
                value={value}
                placeholder={placeholder}
                type={type === inputTypes.INTEGER ? "number" : "text"}
                multiLine
                autoFocus
                onChange={this.onTextChange}
                width="350px"
                maxLength={4095}
                errorText={this.state.valueErrorTextArray.length > 0 ? this.state.valueErrorTextArray[index] : " "}
                index={index}
                validationType={type}
                divContainerHeight="70px"
            />

        }

        return html;
    };


    handleMetadataChange = (metadata) => {
        let updatedTemplate = {...this.props.template};
        updatedTemplate.metadata = metadata;
        this.props.updateTemplate(updatedTemplate, this.props.questionDepth);
    };


    render() {

        return <div>
                <ArgoSectionOpenCloseHook
                    fileName="ArgoMetadataEditor"
                    fieldName="sectionMetadataOpen"
                    sectionTitle={"Metadata Properties: (" + metadataUtils.countNumberOfVisibleMetadataEntries(this.props.template.metadata) + ")"}
                    onClick={this.onClickToggleSectionOpenClose}
                    open={this.state.sectionMetadataOpen}
                />

                {this.state.sectionMetadataOpen && <div>
                    {this.state.metadataEntries.map(({0: name, 1: value}, index) => {
                        return (
                            (metadataUtils.isHiddenMetadata(name)) ? "" :
                                <div>
                                    <div key={index} id={"ArgoMetadataEditor-metadata-line-container-div" + index} style={{display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "32px", width: "100%", height: "52px"}}>

                                        <ArgoAutocompleteHook
                                            fileName={"ArgoMetadataEditor-" + this.props.questionNumber + "-" + index}
                                            fieldName="name"
                                            index={index}
                                            label="Name"
                                            //onChange={this.onChangeAutocomplete.bind(this, "key", index)}
                                            value={name}
                                            onChange={this.onTextChange}
                                            menuItems={this.props.metadataDefinition.map(d=>d.name)}
                                            maxLength="255"
                                            multiline={true}
                                            openOnFocus
                                            errorText={this.state.nameErrorTextArray.length > 0 ? this.state.nameErrorTextArray[index] : " "}
                                            width="350px"
                                        />

                                        {/*<ArgoAutocompleteHook*/}
                                        {/*    fileName={"ArgoMetadataEditor-question-" + this.props.questionNumber + "-" + index}*/}
                                        {/*    fieldName="name"*/}
                                        {/*    label="Name"*/}
                                        {/*    style={{marginRight: "32px", width: "100%"}}*/}
                                        {/*    tabIndex={1}*/}
                                        {/*    errorText={this.state.nameErrorTextArray}*/}
                                        {/*    fullWidth*/}
                                        {/*    maxLength="255"*/}
                                        {/*    onChange={this.onChangeAutocomplete.bind(this, "key", index)}*/}
                                        {/*    openOnFocus*/}
                                        {/*    autoFocus*/}
                                        {/*/>*/}

                                        {/*<AutoComplete*/}
                                        {/*    id={"ArgoMetadataEditor-question-" + this.props.questionNumber + "-autocomplete-property-" + index}*/}
                                        {/*    name={"ArgoMetadataEditor-question-" + this.props.questionNumber + "-autocomplete-property-" + index}*/}
                                        {/*    floatingLabelText="Name"*/}
                                        {/*    searchText={name}*/}
                                        {/*    style={{marginRight: "32px", width: "100%"}}*/}
                                        {/*    textFieldStyle={{width: "100%"}}*/}
                                        {/*    menuStyle={{maxHeight: "200px", overflowY: 'auto'}}*/}
                                        {/*    dataSource={this.props.metadataDefinition}*/}
                                        {/*    dataSourceConfig={autocompleteConfig}*/}
                                        {/*    openOnFocus={true}*/}
                                        {/*    filter={this.filterMetadataAutocomplete.bind(this, this.props.metadata)}*/}
                                        {/*    onUpdateInput={this.onChangeAutocomplete.bind(this, "key", index)}*/}
                                        {/*    onNewRequest={this.handleNewRequest.bind(this, "key", index)}*/}
                                        {/*    onBlur={this.handleOnBlur}*/}
                                        {/*    tabIndex={1}*/}
                                        {/*/>*/}

                                        <div style={{width: "20px"}}>&nbsp;</div>

                                        <div id="ArgoMetadataEditor-MetadataValue-container-div" style={{display: "flex", width: "350px"}}>
                                            {this.renderValueField(name, value, index)}
                                        </div>

                                        <ArgoIconButtonHook
                                            fileName="ArgoMetadataEditor"
                                            fieldName="remove-metadtata"
                                            index={index}
                                            depth={this.props.depth}
                                            onClick={this.onClickRemoveMetadata}
                                            icon={<HighlightOffOutlinedIcon/>}
                                        />

                                    </div>
                                    <div>&nbsp;</div>
                                </div>

                        );
                    })
                    }

                    <div>&nbsp;</div>

                    <div style={{paddingLeft: "30px"}}>
                        <ArgoButtonHook
                            fileName="ArgoMetadataEditor"
                            fieldName="AddProperty"
                            label="Add a Property"
                            onClick={this.addProperty}
                            width={160}
                            disabled={this.state.nameErrorTextArray.find((str) => str !== " ") !== undefined}
                        />
                    </div>

                    {/*<div style={{marginBottom: "16px", marginTop: "16px", marginLeft: "32px"}}>*/}
                    {/*    <Button*/}
                    {/*        id="ArgoMetadataEditor-add-property"*/}
                    {/*        variant="contained"*/}
                    {/*        onClick={this.addProperty}*/}
                    {/*        tabIndex={1}>Add a Property</Button>*/}
                    {/*</div>*/}

                </div>
                }
                {/*<div style={{marginBottom: "16px", marginTop: "16px", marginLeft: "32px", color: "#FF0000"}}>*/}
                {/*    {this.state.errorText}*/}
                {/*</div>*/}
            </div>

    }
}

ArgoMetadataEditor.defaultProps = {
    metadata: {},
    errorText: " ",
    questionNumber: 0,
    onChangeCallback: function () {}
};

ArgoMetadataEditor.propTypes = {
    entityType: PropTypes.string,
    template: PropTypes.object,
    onChange: PropTypes.func,
    onChangeCallback: PropTypes.func,
    depth: PropTypes.number,
    metadata: PropTypes.object,
    setMetadataErrorText:  PropTypes.func,
    questionNumber: PropTypes.number
};

function mapStateToProps(state, props) {
    return {
        metadataDefinition: state.metadataDefinition[props.entityType]
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getMetadataDefinitions}, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(ArgoMetadataEditor);

import * as types from "../constants/actionConstants";

const initialState = {
    createdBy: null,
    createdDate: null,
    lastModifiedBy: null,
    lastModifiedDate: null,
    //showTemplateKey: null,            // These properties come from the server
    //showAdditionalProperties: null,   // These properties come from the server
    //questionnaireTypeDisplayed: null, // These properties come from the server
    //allowCloning: null,               // These properties come from the server
    showMetadataProperties: true,
    showQuestionRequired: false
};

function systemSettingReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_SYSTEM_SETTING:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default systemSettingReducer;

import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    getIntegrationLogs,
    clearIntegrationLogs,
    initPageAndSortThenGetIntegrationLogsList
} from "../../actions/integrationLogsActions";
import IntegrationLogDetailsDrawer, {integrationLogDetailsDrawerProps} from "../drawers/IntegrationLogDetailsDrawer";
import {openDrawer} from "../../actions/drawerActions";
import {TableGrid, TableView} from '../../containers';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArgoListTitleAndLoadingAnimationHook from "../common/ArgoListTitleAndLoadingAnimationHook";
import {updatePage, updateSort} from "../../actions/pageAndSortActions";
import * as TEMPLATE from "../../constants/templateConstants";
import * as SYSTEM from "../../constants/systemConstants";
import FilterPopOver from "../dialogs/filters/FilterPopOver";
import {getTemplateList} from "../../actions/templateListActions";



class IntegrationLogsList extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            filter: false,
            id: null
        }
    }

    componentDidMount() {
        let pathArray = window.location.pathname.split('/');
        let id = parseInt(pathArray[pathArray.length-1]);
        this.setState({loading: true, id: id});
        this.props.initPageAndSortThenGetIntegrationLogsList(id, this.props.name, {pageNumber: this.props.filter.pageNumber, pageSize: this.props.filter.pageSize, name: this.props.filter.name, direction: this.props.filter.direction});
    }

    componentWillReceiveProps(nextProps) {
        // Called twice, first pass set filter false, then on second call list is loaded and loading animation can be stopped
        if (this.state.filter) {
            this.setState({filter: false});
        }
        else if (this.state.loading) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemConstants.js
                setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false});
            }
        }
    }

    // Used to test loading animation rendering
    delayedSetLoadingFalse = () => {
        this.setState({loading: false});
    }

    setLoadingStateTrue = (callback, parm1 = null) => {
        // If a prameter is passed then pass it to call back, should be the template of the item to be saved edit or created
        if (parm1 === null) {
            this.setState({loading: true, filter: false}, () => callback());
        } else {
            this.setState({loading: true, filter: false}, () => callback(parm1));
        }
    }

    componentWillUnmount() {
        this.props.clearIntegrationLogs();
    }

    onPageChange = (pageNumber, pageSize, event) => {
        this.props.updatePage(TEMPLATE.FILTER.all.integrationLogs, pageNumber, pageSize, event);
        this.setState({loading: true, filter: false}, () => this.props.getIntegrationLogs(this.props.id, this.props.name, {pageNumber: pageNumber, pageSize: pageSize, name: this.props.filter.name, direction: this.props.filter.direction}));
    };

    onSortChange = (name, direction) => {
        this.props.updateSort(TEMPLATE.FILTER.all.integrationLogs, name, direction);
        this.setState({loading: true, filter: false}, () => this.props.getIntegrationLogs(this.props.id, this.props.name,  {pageNumber: this.props.filter.pageNumber, pageSize: this.props.filter.pageSize, name: name, direction: direction}));
    };

    viewLogDetails = (name, id) => {
        this.props.openDrawer(integrationLogDetailsDrawerProps, <IntegrationLogDetailsDrawer id={id}/>);
    };

    render() {

        //Add custom actions to row actions
        const customActions = [
            {
                func: this.viewLogDetails,
                label: "Details"
            }
        ];

        return (
            <div id="IntegrationLogsList-container-div" style={{height: "100%", padding: "15px", marginTop: '64px'}}>

                <ArgoListTitleAndLoadingAnimationHook
                    fileName="IntegrationLogsList"
                    listTitle={"Integration Logs: " + this.props.name}
                    loading={this.state.loading}
                    filter={this.state.filter}
                    showOutstandingAlert={false}/>

                <div style={{clear: "both"}}/>

                <Card id="IntegrationLogsList-card" variant="outlined" sx={{border: "1px solid lightgray", height: "100%", boxShadow: "2px 4px whitesmoke", overflow: "auto"}}>

                    <CardContent id="IntegrationLogsList-card-content">

                        <TableView
                            templateType={TEMPLATE.TYPE.INTEGRATION_LOGS}
                            templateMode={TEMPLATE.MODE.ALL}
                            filter={<FilterPopOver page="integrationsLogList"
                                                   templateType={TEMPLATE.TYPE.INTEGRATION_LOGS}
                                                   templateMode={TEMPLATE.MODE.ALL}
                                                   filterName={TEMPLATE.FILTER.all.integrationLogs}
                                                   submitValue={this.props.getTemplateList}
                                                   showName={false}
                                                   showQualifier={false}
                                                   showUpdatedBy={false}
                                                   showUCID={true}
                                                   integrationID={this.state.id}
                                                   setLoadingState={this.setLoadingStateTrue}

                            />}
                        >
                            <TableGrid
                                id="IntegrationsLogsList-table-grid"
                                displayType={"integrationLogs"}
                                dataSource={this.props.integrationLogs}
                                nameDrawer={this.viewLogDetails}
                                customActions={customActions}
                                pageState = {this.props.filter}
                                onPageChange = {this.onPageChange}
                                sortState = {this.props.filter}
                                onSortChange = {this.onSortChange}
                                templateType={TEMPLATE.TYPE.INTEGRATION_LOG}
                                templateMode={TEMPLATE.MODE.ALL}
                                showQualifier={false}
                                showName={false}
                                showLastUpdated={false}
                                showUpdatedBy={false}
                                showStatus={false}
                                showIntegrationLogs={true}
                                showGridActionVersions={false}
                                uniqueIdentifier={"id"}
                            />
                        </TableView>

                    </CardContent>

                </Card>

            </div>
        );
    }
}

IntegrationLogsList.propTypes = {
    tableStyle: PropTypes.object,
    title: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        integrationLogs: state.integrationLogsList.list,
        id: parseInt(state.integrationLogsList.id, 10),
        name: state.integrationLogsList.name,
        filter: state.filter[TEMPLATE.FILTER.all.integrationLogs] || {}
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {openDrawer,
            getIntegrationLogs,
            initPageAndSortThenGetIntegrationLogsList,
            clearIntegrationLogs,
            updatePage,
            updateSort,
            getTemplateList
        }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationLogsList);

